.dkg-requriments-screen-mainCon {
    padding: 5px 40px;

    .dkg-requriments-blockCon {
        .dkg-requriments-blockCols {
            .dkg-requriments-headerCon {
                .dkg-requriments-title {
                    background: transparent !important;
                    border: none !important;
                    border-radius: 3px 3px 0 0;
                    color: #333;
                    display: block;
                    font-size: 18px;
                    // font-weight: 600;
                    padding: 8px 10px;
                    text-align: center;
                    text-transform: uppercase;

                    &.dkg-jobreq-right-title {
                        color: #DA3D5E;
                    }
                }
            }

            .dkg-requriments-boxCon {
                .dkg-requriments-tableCon {
                    .dkg-requriments-table {
                        border: none !important;
                        border-radius: 10px;
                        -webkit-border-radius: 10px;
                        border-collapse: collapse;
                        margin-bottom: 0;

                        tbody {
                            tr {
                                td {
                                    vertical-align: middle;
                                    background: #fff;
                                    border: 1px solid #ddd;
                                    color: #4d4a4a;
                                    font-size: 13px;
                                    font-weight: 400;
                                    padding: 7px !important;
                                    text-align: center;
                                    text-transform: capitalize;
                                    vertical-align: middle;
                                    white-space: nowrap;

                                    input {
                                        text-align: left;
                                        background-color: #fff;

                                        &:focus {
                                            box-shadow: none;
                                            outline: 0;
                                        }
                                    }

                                    &:nth-child(01) {
                                        width: 30%;
                                        text-align: left;
                                        // font-weight: bold;
                                        text-transform: uppercase;
                                        font-size: 13px;

                                        input {
                                            background-color: #fff;
                                            // font-weight: bold;
                                        }
                                    }

                                    &:nth-child(02) {
                                        width: 70%;
                                        padding: 0;
                                        text-align: left;
                                        background-color: #fff;
                                        color: #DA3D5E;
                                        font-size: 14px;
                                        text-transform: capitalize;
                                    }
                                }

                                &:first-child {
                                    td:first-child {
                                        border-top-left-radius: 10px;
                                    }

                                    td:last-child {
                                        border-top-right-radius: 10px;
                                    }
                                }

                                &:last-child {
                                    color: #333;

                                    td:first-child {
                                        border-bottom-left-radius: 10px;
                                    }

                                    td:last-child {
                                        border-bottom-right-radius: 10px;
                                    }
                                }
                            }
                        }
                    }

                    .dkg-jobrequriments-table-2 {
                        tbody {
                            tr {
                                td {
                                    vertical-align: middle;
                                    background: #fff;
                                    border: 1px solid #ddd;
                                    color: #4d4a4a;
                                    font-size: 13px;
                                    font-weight: 400;
                                    padding: 7px !important;
                                    text-align: center;
                                    text-transform: capitalize;
                                    vertical-align: middle;
                                    white-space: nowrap;

                                    input {
                                        text-align: left;
                                        background-color: #fff;
                                        color: #DA3D5E;
                                        font-size: 14px;
                                        text-transform: capitalize;
                                        height: calc(1.5em + 0.6445rem + 2px);

                                        &:focus {
                                            box-shadow: none;
                                            outline: 0;
                                        }
                                    }
                                }

                                &:first-child {
                                    td:first-child {
                                        border-top-left-radius: 10px;
                                    }

                                    td:last-child {
                                        border-top-right-radius: 10px;
                                    }
                                }

                                &:last-child {
                                    color: #333;

                                    td:first-child {
                                        border-bottom-left-radius: 10px;
                                    }

                                    td:last-child {
                                        border-bottom-right-radius: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}