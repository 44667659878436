.dk-navBarMain {
    &.dk-accountingNavbar {
        &.dk-acc-expincome-Navbar {
            background-color: #5a4c72 !important;

            ul {
                li {
                    a {
                        &.active {
                            background-color: #eaeaea;
                        }

                        &.white-active {
                            background-color: #fff;
                            color: #333;
                        }
                    }

                    &:first-child {
                        a {
                            &.active {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}