.dk-navBarMain.dk-onboardingNavbar {
    background: #3c4c62;
    position: fixed;
    top: 52px;
    z-index: 9;
    width: 100%;
    left: 0;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: auto;

        li {
            display: inline-block;
            width: 100%;
            max-width: 10%;

            a {
                width: 100%;
                background-color: #3c4c62;
                display: flex;
                color: hsla(0, 0%, 100%, .9);
                font-size: 14px;
                padding: 13px 0 !important;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                min-height: 46px;
                max-height: 46px;
                font-family: Verdana, sans-serif;
                transition: all 0.5s;

                span.dk-badge {
                    background-color: #ec407a;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    margin: 0 5px;
                }

                &:hover {
                    background: #eaeaea;
                    color: #333;
                    transition: all 0.5s;
                }

                i {
                    font-size: 11px;
                    display: inline-block;
                    margin: 0 2px;
                }
            }

            a.active {
                background: #fff !important;
                color: #333;
            }

            a.gray-active {
                background: #eaeaea;
                color: #333;
            }

            a.activeDark {
                background: #2e5a7c;
                color: #fff;
            }
        }
    }
}

.dk-navBarMain.dk-onboardingNavbar.dkgCloudMainNavbar {
    background: #3c97b6;

    ul {
        li {
            a {
                background: #3c97b6;

                &.gray-active {
                    background-color: #eaeaea;
                    color: #333;

                }

                &:hover {
                    background-color: #eaeaea;
                }

            }

            &:first-child {
                a {
                    &.active {
                        background-color: #fff;
                    }

                    &:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }
}