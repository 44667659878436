.dkg-pagetype-album-ablum-Modal{

    .modal-dialog{
        min-width: 650px;
        max-width: 650px;
        .modal-content{
            border-radius: 5px;
            .dkg-video-ablum-Header{
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: start;
                align-items: flex-start;
                -ms-flex-pack: justify;
                justify-content: center;
                padding: 0.5rem 1rem;
                border-bottom: 1px solid #dee2e6;
                border-top-left-radius: 0.3rem;
                border-top-right-radius: 0.3rem;
                position: relative;
                .modal-title{
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    width: 100%;
                    text-align: center;
                }
                .close{
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin: 0;
                    border-top-right-radius: 0.3rem;
                    &:hover{
                        background-color: #eaeaea;
                        transition: ease-in-out all 0.5sec;
                    }
                }
            }
            .dkg-image-ablum-Body{
                .dkg-album-ablum-mainCon{
                  .dkg-edit-album-inputCon{
                    width: 100%;
                    margin-bottom: 1rem;
                    .dkg-edit-album-inputgrp{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        .dkg-edit-album-input{
                            width: 100%;
                            border: 1px solid #ddd;
                            height: 35px;
                            text-align: center;
                        }
                        .dkg-edit-album-btnCon{
                            div{
                                div{
                                    .dkg-edit-album-btn{
                                        background-color: #d39e00;
                                        color: #fff;
                                        border: 1px solid #d39e00;
                                        border-radius: 0;
                                    }
                                }
                            }
                        }
                    }
                  }
                }
                form{
                    .dkg-ablum-frmgrp{
                        margin-bottom: 1rem;
                        label{
                            font-size: 0.95rem;
                        }
                        input{
                            border-radius: 0;
                            text-align: left;
                        }
                        .dkg-submit-BtnCon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            // div{
                            //     div{
                            //         .dkg-submit-Btn{
                            //             box-shadow: none;
                            //             color: #fff;
                            //             background-color: #525a8e;
                            //             border-color: #525a8e;
                            //             margin: 0;
                            //             padding: 9px 12px;
                            //             margin-right: 0.5rem;
                            //             width: 80px;
                            //         }

                            //     }
                            // }
                            .dkg-submit-Btn{
                                box-shadow: none;
                                color: #fff;
                                background-color: #525a8e;
                                border-color: #525a8e;
                                margin: 0;
                                padding: 9px 12px;
                                margin-right: 0.5rem;
                                width: 80px;
                            }
                            .dkg-close-btn{
                                color: #fff;
                                background-color: #6c757d;
                                border-color: #6c757d;
                                margin: 0;
                                padding: 9px 12px;
                                margin-right: 0.5rem;
                                width: 80px;
                                box-shadow: none;

                            }
                        }
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
                &.dkg-image-ablum-Body{
                    .dkg-album-ablum-mainCon{
                        .dkg-album-ablum-boxs{
                            display: flex;
                            flex-wrap: wrap;
                            .dkg-album-ablum-cols{
                                width: 100%;
                                max-width: 33.33%;
                                .dkg-album-file-wrapper{
                                    width: 100%;
                                    height: 150px;
                                    border: 2px solid gray;
                                    border-bottom: none;
                                    border-left: none;
                                    position: relative;
                                    margin: auto;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    input[type="file"] {
                                        position: absolute;
                                        width: 80%;
                                        height: 65%;
                                        opacity: 0;
                                        z-index: 99999;
                                        cursor: pointer;
                                    }
                                    // &:after {
                                    //     content: '+';
                                    //     position: absolute;
                                    //     top: 0;
                                    //     bottom: 0;
                                    //     left: 0;
                                    //     right: 0;
                                    //     margin: auto;
                                    //     width: max-content;
                                    //     height: max-content;
                                    //     display: block;
                                    //     max-height: 85px;
                                    //     font-size: 70px;
                                    //     font-weight: bolder;
                                    //     color: gray;
                                    // }
                                    &.dkg-edit-album-filewrapper{
                                        &::after{
                                            content: inherit !important;
                                        }
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .dkg-plus-icon{
                                            position: absolute;
                                            left: 50%;
                                            top: 50%;
                                            transform: translate(-50% , -50%);
                                            svg {
                                                    width: 38px;
                                                    height: 38px;
                                                    cursor: pointer;
                                                    color: gray;
                                                    &:hover {
                                                        color: #333;
                                                    }
                                                }
                                        }
                                        .dk-myprofile-editicon{
                                            position: absolute;
                                            bottom: 2px;
                                            right: 4px;
                                            width: 24px;
                                            height: 24px;
                                            border-radius: 50%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            background-color: #eaeaea;
                                            svg{
                                                width: 11px;
                                                height: 11px;
                                                color: red;
                                                cursor: pointer;
                                                &:hover{
                                                    color: #da3d5e;
                                                }
                                            }
                                            &:hover{
                                                background-color: #f2f2f2;
                                            }
                                        }
                                        .dk-myprofile-userImgCon{
                                            width: 100%;
                                            height: 100%;
                                            #edit_image{
                                                margin: 0 !important;
                                                width: 100%;
                                                height: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            }
                                        }
                                    }
                                    .dk-myprofile-userImgCon{
                                        width: 100%;
                                        height: 100%;
                                        #edit_image{
                                            margin: 0 !important;
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dkg-changIcon{
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                                &:nth-last-child(01),
                                &:nth-last-child(02),
                                &:nth-last-child(03){
                                    border-bottom: 2px solid gray;
                                }
                                &:nth-child(01),
                                &:nth-child(04),
                                &:nth-child(07),
                                &:nth-child(10){

                                    border-left: 2px solid gray;
                                }
                            }
                        }
                        .dkg-view-ablum-btnCon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            padding-top: 1rem;
                            .dkg-view-ablum-btn{
                                color: #006E8F;
                                display: inline-block;
                                padding: 7px 10px;
                                font-size: 1rem;
                                border-radius: 5px;
                                text-transform: inherit;
                                cursor: text;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }

}