.dk-ducMCon {
    .dk-ducMTopHead {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;

        .dk-selectFiles {
            position: relative;
            width: 100%;
            max-width: 35px;
            height: 35px;

            span {
                position: absolute;
                top: 0;
                background-color: #7a8ba2;
                color: #fff;
                text-align: center;
                display: inline-block;
                width: 30px;
                height: 30px;
                z-index: 1;
                left: 0;
                line-height: 35px;
            }

            input {
                width: 30px;
                height: 30px;
                position: relative;
                z-index: 9;
                opacity: 0;
            }
        }

        .dk-addnewBtnSelect {
            position: relative;
            display: inline-block;
            width: 100%;
            max-width: 140px;
            height: 30px;
            margin-left: 10px;

            &:hover {
                span {
                    background: #234158;
                    transition: all 0.5s;
                    cursor: pointer;
                }
            }

            input {
                width: 140px;
                height: 30px;
                position: relative;
                z-index: 9;
                opacity: 0;
                cursor: pointer;
            }

            span {
                display: inline-block;
                background: #7a8ba2;
                color: #fff;
                width: 100%;
                height: 30px;
                line-height: 30px;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                &:hover {
                    background: #234158;
                }
            }
        }

        .title {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            margin: 0 !important;
            font-size: 18px;
            font-weight: 600;
            color: #333;
            margin-bottom: 12px
        }

        .btn-Con {
            a {
                display: inline-block;
                border: 1px solid #7a8ba2;
                padding: 2px 5px;
                background: #7a8ba2;
                color: #fff;

            }

            a.dk-addNewbtn {
                display: inline-block;
                padding: 0px 12px;
                border-radius: 0;
                text-align: center;
                color: #fff;
                font-size: 14px;
                white-space: nowrap;
                margin-left: 10px;
                height: 30px;
                line-height: 28px;

                svg {
                    display: inline-block;
                    position: relative;
                    bottom: 1px;
                }
            }

            .dkg-addnewbtn-Con {
                span {
                    background: #7a8ba2;
                    color: #fff;
                    display: inline-block;
                    height: 32px;
                    line-height: 22px;
                    text-align: center;
                    width: 100%;
                    max-width: 160px;
                    cursor: pointer;
                    padding: 5px 7px;
                    font-size: 13px;
                    // border-radius: 5px;

                    &:hover {
                        background: #234158;
                    }

                }
            }
        }
    }

    .dk-ducMTableCon {
        margin-top: 5px;

        .dk-ducMTable {
            thead {
                tr th {
                    border-color: #336879;
                    border: 1px solid #336879;
                    background: #234158;
                    border-bottom: none;
                    font-weight: 600;
                    color: #fff;
                    white-space: nowrap;
                    text-align: center;
                    font-size: 14px;
                    padding: 10px;
                }

                th:nth-child(1) {
                    width: 100px;
                }

                th:nth-child(2) {
                    width: 500px;
                }

                th:nth-child(5) {
                    width: 200px;
                }
            }

            tbody {
                tr td {
                    background: #fff;
                    padding: 2px 10px;
                    color: #333;
                    font-weight: 500;
                    text-align: center;
                    border: 1px solid #dee2e6;
                    padding: 5px 10px;
                    vertical-align: middle;

                    .dk-fileTypeDropdown {
                        width: 100%;
                        max-width: 114px;
                        margin: auto;

                        .dropdown-toggle {
                            box-shadow: none;
                            outline: none;
                            background: #f2f2f2;
                            border: 1px solid #ccc;
                            padding: 5px 10px;
                            width: 100%;
                            max-width: 114px;
                            white-space: nowrap;
                            text-transform: capitalize;
                        }

                        .dropdown-menu {
                            transform: inherit !important;
                            border-radius: 0;
                            padding: 0;
                            top: 37px !important;
                            width: 100%;
                            min-width: 114px;
                            max-width: 114px;
                            left: 5px !important;
                            right: inherit !important;
                            margin: 0 !important;

                            a {
                                display: block;
                                font-size: 12px;
                                padding: 5px 12px;
                                border-bottom: 1px solid #ddd;

                                &:hover {
                                    background: #f2f2f2;
                                }

                                &:focus {
                                    background: #f2f2f2;
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    .actionColumn {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        a {
                            background: #7a8ba2;
                            text-align: center;
                            padding: 5px 10px;

                            &:hover {
                                background: #234158;
                                transition: all 0.5s;
                            }

                            i {
                                color: #fff;
                            }

                            svg {
                                color: #fff;
                                font-size: 14px;
                                position: relative;
                                bottom: 2px;
                            }
                        }
                    }
                }

            }
        }

    }
}

.dk-editCommentModalMain {
    .modal-dialog {
        .modal-content {
            border: none;
            box-shadow: none;
            border-radius: 0;

            .modal-header {
                background: #234158;
                padding: 7px 12px;
                border-radius: 0;

                .modal-title {
                    font-size: 14px;
                    color: #fff;
                    text-transform: uppercase;
                    display: block;
                    text-align: center;
                    width: 100%;
                }

                .close {
                    color: #fff;
                    text-shadow: none;
                    opacity: 1;
                    outline: none;
                }
            }

            .modal-body {
                padding: 25px;

                label {
                    width: 100%;
                    display: block;
                    font-size: 14px;
                }

                input {
                    border-radius: 0;
                    display: block;
                    width: 100%;
                    box-shadow: none;
                    outline: none;
                    height: 35px;
                    font-size: 14px;
                    color: #333;
                    border: 1px solid #ddd !important;
                    text-align: left;
                    background: #f2f2f2;
                }

                select {
                    border-radius: 0;
                    display: block;
                    width: 100%;
                    box-shadow: none;
                    outline: none;
                    height: 35px;
                    font-size: 14px;
                    color: #333;
                    border: 1px solid #ddd !important;
                    text-align: left;
                    background: #f2f2f2;
                }

                button.dk-saveBtn {
                    display: inline-block;
                    background: #234158;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 14px;
                    outline: none;
                    padding: 6px 20px;
                    border-radius: 3px;
                    border: none;
                }
            }
        }
    }
}


// ADD NEW DOCUMENTS MODAL SCSS
.dkg-addnewdocs-Modal {
    &.modal-dialog {
        min-width: 70%;
        max-width: 70%;

        .modal-content {
            border-radius: 5px;
            border: none;
            background-color: transparent;

            .dk-addnewdocs-ModalHeader {
                color: #fff;
                background: #26597d;
                padding: 8px 10px;
                border-radius: 5px 5px 0 0;

                .modal-title {
                    margin-top: 0;
                    font-size: 17px;
                    font-weight: 500;
                    font-family: Roboto, sans-serif;
                    text-align: center;
                    width: 100%;
                    text-transform: uppercase;
                    color: #fff;
                }

                .close {
                    font-size: 1.58em;
                    padding: 0px;
                    right: 10px;
                    position: absolute;
                    top: 4px;
                    opacity: 1;
                    color: #fff !important;
                    text-shadow: none;
                    outline: none;
                    font-weight: normal;
                    padding: 0.35rem;
                    margin: 0;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            .dk-addnewdocs-ModalBody {
                color: #333;
                background-color: #fff;
                padding: 0;

                border-radius: 0 0 5px 5px;
                height: calc(100vh - 289px);


                .dkg-docs-topheaderCon {
                    .dkcabparseFormCon {
                        height: calc(100vh - 340px);
                        overflow-y: auto;
                        background-color: #eaeaea;
                        border-radius: 5px 5px 5px 5px;
                        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                        padding: 15px 15px;
                        width: 100%;
                        max-width: 95%;
                        margin: 25px auto;

                        .dkg-addnew-doc-dropdownCon {
                            width: 100%;
                            max-width: 25%;
                            margin: 10px auto;

                            .dkg-addnew-doc-dropdown {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;

                                .dropdown-toggle {
                                    background-color: #f2f2f2 !important;
                                    color: #333;
                                    text-transform: capitalize;
                                    border-radius: 0;
                                    box-shadow: none;
                                    border: 1px solid #ddd;
                                    font-size: 13px;
                                    padding: 7px 10px;
                                    height: 40px;
                                    min-width: 240px;
                                    position: relative;

                                    &::after {
                                        position: absolute;
                                        right: 10px;
                                        top: 17px;
                                    }
                                }

                                .dkg-addnew-doc-dropdown-menu {
                                    border-radius: 0;
                                    padding: 0;
                                    margin: 0;
                                    width: 100%;
                                    min-width: 240px;

                                    .dkg-addnew-doc-item {
                                        padding: 7px 10px;
                                        font-size: 13px;
                                        background-color: #fff;
                                        color: #333;
                                        border-bottom: 1px solid #ddd;

                                        &:last-child {
                                            border-bottom: none;
                                        }
                                    }
                                }
                            }
                        }

                        form {
                            .row {
                                .col-md-12 {
                                    .dk-parseImgBrowserCon {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 100%;
                                        width: 100%;
                                        padding: 30px 20px 0;

                                        &.hv-auto {
                                            height: calc(100vh - 500px);
                                        }

                                        .image-input {
                                            width: 100%;

                                            input {
                                                display: none;
                                            }

                                            label {
                                                display: block;
                                                color: #333;
                                                background: transparent;
                                                cursor: pointer;
                                                padding: 50px 25px;
                                                border-radius: 5px;
                                                border: 3px dashed #959595;
                                                width: 100%;
                                                margin-bottom: 15px;
                                                text-align: center;
                                            }

                                            img {
                                                max-width: 140px;
                                                display: none;
                                                margin: 0 auto;
                                            }

                                            span {
                                                display: none;
                                                text-align: center;
                                                cursor: pointer;
                                            }
                                        }
                                    }

                                    .dkg-docs-savebtn-Con {
                                        margin-top: 18px;

                                        div {
                                            div {
                                                .dkg-docs-savebtn.MuiButton-containedPrimary {
                                                    background-color: #26597d !important;

                                                    &:hover {
                                                        color: #fff;
                                                        background-color: #7a8ba2 !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//END NEW  DOCUMENTS MODAL SCSS 