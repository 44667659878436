/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-mdHeaderMain{
        &.dk-dkgcrm-header{
            &.dkg-crm-mobile-header{
                .dk-mbtopheaderCon{
                    .dk-accountDropdown.headerUniqueForSearch{
                        button.dropdown-toggle{
                            &::after{
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .dkg-myacc-navbar{
        .Lsidebar{
            top: 68px !important;
            
            // .navbar-toggler{
            //     display: block;
            //     border-color: #fff;
            //     color: #fff;
            // }
            // .dkg-mynavbar-tabNav{
            //     .dkg-mynavbar-tabNav-ul{
            //         display: block;
            //         width: inherit;
            //     }
            // }
            .d-flex {
                display: none !important;
                width: 100%;
                .profile-info{
                    display: none;
                }
                .navbar-toggler{
                    .navbar-toggler-icon{
                        border-color: #fff;
                        color: #fff;
                    }
                    
                }
                .dkg-mynavbar-tabNav{
                    &.navbar-collapse{
                        &.collapse{
                            position: relative;
                            &.show{
                                .dkg-mynavbar-tabNav-ul{
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    border-radius: 0;
                                    li{
                                        max-width: 100%;
                                        &:first-child{
                                            a{
                                                border-radius: 0;
                                            }
                                        }
                                        &:last-child{
                                            border-radius: 0;
                                        }
                                        a{
                                            text-align: left;
                                            padding: 7px 10px;
                                            border-bottom: 1px solid #848484;
                                        }
                                        &.dkg-myacc-nav-main-li{
                                            &.dkg-setting-li{
                                                position: relative;
                                                .dkg-myacc-submenu-ul{
                                                    display: block;
                                                    width: 100%;
                                                    li{
                                                        max-width: 100%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}