.tooltipMain {
    position: inherit;
    display: inline-block;

    img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }

    .tooltipText {
        display: none;
        white-space: nowrap;
        background: #333;
        border-radius: 3px;
        color: #fff;
        padding: 2px 8px;
        position: absolute;
        left: 50%;
        top: 42px;
        transform: translate(-50%);
        z-index: 99;
        font-size: 11px;
        text-transform: capitalize;

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 12px 7.5px;
            border-color: transparent transparent #333333 transparent;
            line-height: 0px;
            _border-color: #000000 #000000 #333333 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            position: absolute;
            top: -11px;
            left: 50%;
            transform: translate(-50%);
        }
    }

    &:hover {
        .tooltipText {
            // display: inline-block;
            display: none;
        }
    }
}