.dkg-contact-mainCon{
    margin-top: 153px;
    height: calc(100vh - 167px);
    background-color: #eaeaea;
    width: 100%;
    padding: 0 15px;
    .dkg-contact-main{
        width: 100%;
        display: block;
        position: relative;
        max-width: 1600px;
        margin: 0 auto;
        .dkg-desired-pageheader{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 9px 10px;
            .dkg-desired-pagetitle{
                width: 100%;
                text-align: center;
                font-weight: 600;
                font-size: 22px;
                text-transform: uppercase;   
                margin-bottom: 0;    
                color: #333; 
            }
        }
        .dkg-contact-mainRow{
            padding-top: 20px;
            .dkg-contact-main-sideCol{
                max-width: 230px;
                .dkg-aboutus-navpills{
                    background-color: #f2f2f2;
                    display: block;
                    width: 100%;
                    max-width: 165px;
                    .dkg-aboutus-navitem{
                        .dkg-aboutus-navlink{
                            border-radius: 0;
                            background-color: #fff;
                            color: #666;
                            border: 1px solid #ccc;
                            border-bottom: none;
                            border-right: none;
                            padding: 12px 10px;
                            font-size: 13px;
                            &.active{
                                background-color: #1f394c;
                                color: #ddd;
                                position: relative;
                                &:before {
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-bottom: 14px solid transparent;
                                    border-top: 12px solid transparent;
                                    border-left: 14px solid #1f394c;
                                    position: absolute;
                                    top: 12px;
                                    right: -14px;
                                }
                            }
                        }
                        &:last-child{
                            .dkg-aboutus-navlink{
                                &.active{
                                    border-bottom: none;
                                }
                                border-bottom: 1px solid #ccc;
                            }

                        }
                    }
                    &.dkg-mydesired-job-navpills{
                       .dkg-aboutus-navitem{
                        .dkg-aboutus-navlink{
                            &.active{
                                background-color: #1f394c;
                                color: #fff;
                                &:before {
                                    border-left: 14px solid #1f394c !important;
                                }
                            }
                        }
                       } 
                    }
                }
            }
            .dkg-contact-mainCol{
                flex: inherit;
                max-width: calc(100% - 230px);
                .dkg-aboutus-tabcontent{
                    .dkg-aboutus-tabpane{
                        .dkg-aboutus-header{
                            padding: 10px 15px;
                            border-bottom: 1px solid #cdcbcb;
                            border-radius: 5px 5px 0 0;
                            display: block;
                            text-align: center;
                            background-color: #1f394c;
                            text-transform: uppercase;
                            .dkg-contact-title{
                                font-weight: 700;
                                color: #ddd;
                                font-size: 14px;
                            }
                        }
                        .dkg-desiredjob-header{
                            border: 1px solid #ccc;
                            border-bottom: none;
                            padding: 9px 10px;
                            background-color: #1f394c;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            .dkg-contact-title{
                                font-weight: 600;
                                font-size: 18px;
                                text-transform: uppercase;   
                                margin-bottom: 0;    
                                color: #d7d7d7; 
                            }
                            .dkg-desired-info{
                                // position: absolute;
                                // left: 55%;
                                // top: 2px;
                                transform: translate(55% , 2%);
                                background-color: #eaeaea;
                                width: 24px;
                                height: 24px;
                                border-radius: 50%;
                                border: 1px solid #ddd;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                // margin-left: 0.5rem;
                                svg{
                                    width: 11px;
                                    height: 11px;
                                }
                            }
                        }
                        .dkg-aboutus-mainCon{
                            background-color: #fff;
                            border: 1px solid #ddd;
                            height: calc(100vh - 245px);
                            overflow-y: auto;
                            &.dkg-contactus-mainCon{
                                overflow-y: hidden;
                                .dkg-other-project-header{
                                    .dkg-otherprject-title{
                                        background-color: #f2f2f2;
                                        color: #666;
                                    }
                                }
                            }
                            &.dkg-socail-media-mainCon{
                                height: calc(100vh - 195px) !important;
                                background-color: transparent;
                                border: none;
                                overflow-y: hidden;
                            }
                            .dkg-other-project-header{
                                .dkg-otherprject-title{
                                    font-size: 0.95rem;
                                    width: 100%;
                                    text-align: justify;
                                    color: #333;
                                    padding: 6px 10px;
                                    background-color: #f2f2f2;
                                    min-height: 55px;
                                    border-bottom: 1px solid #ddd;
                                    line-height: 25px;
                                }
                            } 
                            .dkg-contact-card{
                                background-color: #243b4c;
                                background-clip: border-box;
                                border-radius: 1px;
                                border: 1px solid #243b4c;
                                width: 100%;
                                max-width: 75%;
                                margin: 0 auto;
                                margin-top: 40px;
                                .dkg-contact-cardRow{
                                    .dkg-contact-card-leftCol{
                                        .dkg-contact-card-detail{
                                            background: #fff;
                                            margin: 0 15% 0 -30%;
                                            border: 1px solid #ccc;
                                            padding: 30px 30px 5px;    
                                            position: relative;
                                            top: -15px;                        
                                            .dkg-card-detail-title{
                                                margin: 0 0 15px;
                                                font-size: 18px;
                                                color: #25455a;
                                                font-weight: 600;
                                            }
                                            .dkg-contact-card-ul{
                                                margin: 0;
                                                padding: 0 0 0 0px;
                                                list-style: none;
                                                .dkg-contact-card-li{
                                                    margin: 0;
                                                    padding: 0 0 15px 15px;
                                                    color: #25455a;
                                                    position: relative;
                                                    i{
                                                        position: absolute;
                                                        left: 0;
                                                        top: 5px
                                                    }
                                                    a{
                                                        color: #333;
                                                    }
                                                }
                                            }
                                            .dkg-contact_social{
                                                padding-left: 22px;
                                                a{
                                                    padding-right: 8px;
                                                    img{
                                                        width: 35px;
                                                        height: 35px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dkg-contact-card-rightCol{
                                        color: #25455a;
                                        form{
                                            padding: 30px;
                                            padding-top: 10px;
                                            .row{
                                                div{
                                                    h2{
                                                        margin: 0 0 15px;
                                                        font-size: 18px;
                                                        color: #243b4c;
                                                    }
                                                }
                                                .form-group{
                                                    margin-bottom: 1rem;
                                                    .form-control{
                                                        height: 34px;
                                                        padding: 0.1rem 0.5rem;
                                                        font-size: 13px;
                                                        background-color: #fff;
                                                        line-height: 1.3;
                                                        border: #fff;
                                                        text-align: left;
                                                    }
                                                    textarea.form-control {
                                                        height: 90px;
                                                        text-align: left;
                                                    }
                                                }
                                                
                                            }
                                            p{
                                                margin-bottom: 1rem;
                                                color: #ddd;
                                                font-size: 14px;
                                                input[type="checkbox"] {
                                                    cursor: pointer;
                                                    appearance: auto;
                                                    background: #fff;
                                                    border-radius: 0.25rem;
                                                    box-sizing: border-box;
                                                    position: relative;
                                                    width: 14px;
                                                    height: 14px;
                                                    border-width: 0;
                                                    transition: all 0.3s linear;
                                                    border: 1px solid #ddd;
                                                    margin-right: 0.5rem;
                                                    border-radius: 0;
                                                }
                                                a{
                                                    color: rgb(93, 157, 226);
                                                    margin: 0 0.5rem;
                                                }
                                            }
                                            .btn-light {
                                                color: #212529;
                                                background-color: #f8f9fa;
                                                border-color: #f8f9fa;
                                                border: 1px solid transparent;
                                                padding: 0.375rem 0.75rem;
                                                font-size: 1rem;
                                                line-height: 1.5;
                                                border-radius: 0.25rem;
                                            }
                                            .form-group{
                                                textarea{
                                                    text-align: left;
                                                    font-size: 13px;
                                                    padding: 0.25rem 0.5rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-otherprojects-main{
                                // background: url('../../../../../assets/images/login_bg.jpg') no-repeat;
                                background-color: #fff;
                                background-size: cover;
                                width: 100%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                flex-direction: column;
                                height: calc(100vh - 220px);
                                overflow-y: hidden;
                                .dk-otherprojectMain { 
                                    width: 100%;  
                                    .dkg-other-project-header{
                                        .dkg-otherprject-page-title{
                                            font-size: 0.95rem;
                                            width: 100%;
                                            text-align: justify;
                                            color: #666;
                                            padding: 6px 10px;
                                            background-color: #f2f2f2;
                                            min-height: 55px;
                                            border-bottom: 1px solid #ddd;
                                            line-height: 25px;
                                        }
                                    }                         
                                    .dkg-visitwebaite-cardCon {
                                        width: 100%;
                                        margin-top: 10px;
                                        padding: 0 10px;
                                        height: calc(100vh - 300px);
                                        overflow-y: auto;
                                        // overflow: hidden;
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                        .dk-otherproject-cols {
                                            width: 100%;
                                            height: 50%;
                                            // max-width: 1100px;
                                            margin: auto;
                                            display: flex;
                                            justify-content: center;
                                            margin: 20px auto;
                                
                                            &.dkg-top-level-boxCon {
                                                max-width: 366px;
                                                margin-bottom: 30px;
                                            }
                                
                                            .dk-otherproject-box {
                                                display: inline-block;
                                                margin: 0 10px;
                                                width: 100%;
                                
                                                a {
                                                    padding: 30px 20px;
                                                    background: #fefefe;
                                                    border-radius: 8px 8px 0 0;
                                                    height: 90%;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .dk-visitLogo {
                                                        text-align: center;
                                                        display: block;
                                
                                                        img {
                                                            width: 100%;
                                                            max-width: 150px;
                                                            margin: auto;
                                                            transform: scale(1);
                                                            transition: all 0.5s;
                                                            object-fit: cover;
                                                        }
                                
                                                        .dkg-adminboard-title {
                                                            color: #333;
                                                            transition: all 0.5s;
                                                            font-weight: 700;
                                                            font-size: 20px;
                                                        }
                                                    }
                                                    
                                                    &:hover {
                                                        img {
                                                            transform: scale(1.1);
                                                            transition: all 0.5s;
                                                        }
                                                    }
                                
                                                    .title {
                                                        font-size: 14px;
                                                        text-transform: uppercase;
                                                        color: #26596d;
                                                        font-weight: 600;
                                                        text-align: center;
                                                        display: block;
                                                        margin-top: 10px;
                                                    }
                                                    
                                                }
                                                .dkg-visit-prjbtn{
                                                    width: 100%;
                                                    border-top: 1px solid #ddd;
                                                    padding: 7px 10px;
                                                    display: block;
                                                    background-color: #f2f2f2;
                                                    color: #333;
                                                    text-transform: capitalize;
                                                    border-radius: 0 0 8px 8px;
                                                    margin: 0;
                                                    box-shadow: none;
                                                    font-size: 14px;
                                                    .dkg-resume-icon {
                                                        margin-left: 0.5rem;
                                                    }
                                                    &:hover{
                                                        background-color: #eaeaea;
                                                        transition: all 0.5s;
                                                    }
                                                }
                                                .dkg-project-btn-progess-Con{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;
                                                    width: 100%;
                                                    border-top: 1px solid #ddd;
                                                    // padding: 7px 10px;
                                                    display: block;
                                                    background-color: #f2f2f2;
                                                    color: #333;
                                                    text-transform: capitalize;
                                                    border-radius: 0 0 8px 8px;
                                                    .dkg-project-infobtn{
                                                        width: 30%;
                                                        svg{
                                                            width: 23px;
                                                            height: 23px;
                                                        }
                                                    }
                                                    .dkg-info-icon{
                                                        width: 50%;
                                                        border-right: 1px solid #ddd;
                                                        padding: 7px 10px;
                                                        text-align: center;
                                                        cursor: pointer;
                                                        color: #555;
                                                        &:hover{
                                                            box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                            background-color: #eaeaea;
                                                            color: #3c97b6;
                                                        }
                                                        svg{
                                                            width: 20px;
                                                            height: 20px;
                                                        }
                                                    }
                                                    .dkg-progress-barCon{
                                                        width: 50%;
                                                        padding: 7px 10px;
                                                        text-align: center;
                                                        color: #555;
                                                    }

                                                }
                                            }
                                            // &:first-child{
                                            //     margin-bottom: 0;
                                            // }
                                            // &:last-child{
                                            //     margin-top: 0;
                                            // }
                                        }
                                    }
                                    .dkg-projectsbox-Con{
                                        width: 100%;
                                        padding: 15px;
                                        padding-left: 10px;
                                        padding-right: 0;
                                        background-color: #1f394c;
                                        height: calc(100vh - 285px);
                                        overflow-y: auto;
                                        .dkg-projectbox-mainCon{
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            justify-content: center;
                                            .dk-compnyproject-cols{
                                                display: flex;
                                                width: 100%;
                                                .dkg-compnyproject-box {
                                                    display: inline-block;
                                                    margin: 10px;
                                                    width: 100%;
                                                    a {
                                                        padding: 15px 20px;
                                                        background-color: #fff;
                                                        border-radius: 8px 8px 0 0;
                                                        height: 115px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        border: 1px solid #ddd;
                                                        .dk-visitLogo {
                                                            text-align: center;
                                                            display: block;
                                    
                                                            img {
                                                                width: 100%;
                                                                max-width: 150px !important;
                                                                margin: auto;
                                                                // transform: scale(1);
                                                                // transition: all 0.5s;
                                                                object-fit: cover;
                                                            }
                                    
                                                            .dkg-adminboard-title {
                                                                color: #333;
                                                                transition: all 0.5s;
                                                                font-weight: 700;
                                                                font-size: 20px;
                                                            }
                                                        }
                                                        
                                                        // &:hover {
                                                        //     img {
                                                        //         transform: scale(1.1);
                                                        //         transition: all 0.5s;
                                                        //     }
                                                        // }
                                    
                                                        .title {
                                                            font-size: 14px;
                                                            text-transform: uppercase;
                                                            color: #26596d;
                                                            font-weight: 600;
                                                            text-align: center;
                                                            display: block;
                                                            margin-top: 10px;
                                                        }
                                                        
                                                    }
                                                    .dkg-project-content{
                                                        background-color: #eaeaea;
                                                        padding: 30px 15px;
                                                        padding-top: 10px;
                                                        border-radius: 0 0 5px 5px;
                                                        border: 1px solid #ddd;
                                                        border-top: none;
                                                        min-height: 170px;
                                                        max-height: 170px;
                                                        .dkg-content-title-desc{
                                                            padding-bottom: 0.93rem;
                                                            .dkg-pj-content-title{
                                                                color: #333;
                                                                font-size: 1rem;
                                                                font-weight: 600;
                                                                margin-bottom: 0.75rem;
                                                                text-align: center;
                                                                display: none;
                                                            }
                                                            .dkg-pj-content-desc{
                                                                color: #333;
                                                                font-size: 0.85rem;
                                                                text-align: center;
                                                                margin-bottom: 0.5rem;
                                                            }
                                                        }
                                                        .dkg-content-bottom-sec{
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: flex-start;
                                                            .dkg-pj-leftCon{
                                                                .dkg-pj-bottom-title{
                                                                    font-size: 0.9rem;
                                                                    color: #333;
                                                                }
                                                                .dkg-roject-cmplition-Con{
                                                                    width: 100%;
                                                                    display: flex;
                                                                    align-items: center;
                                                                    justify-content: center;
                                                                    .dkg-project-comletetion{
                                                                        width: 100%;
                                                                        max-width: 90%;
                                                                        margin: 0 auto;
                                                                        border: 1px solid #ddd;
                                                                        color: green;
                                                                    }
                                                                }
                                                            }
                                                            .dkg-pj-rightCon{
                                                                display: none;
                                                                .dkg-pj-bottom-btn{
                                                                    display: inline-block;
                                                                    border: none;
                                                                    background-color: green;
                                                                    color: #fff;
                                                                    padding: 5px 9px;
                                                                    border-radius: 5px;
                                                                    font-size: 0.85rem;

                                                                }
                                                            }
                                                        }
                                                        .dkg-content-bottom-sec-Con{
                                                            .dkg-roject-cmplition-Con{
                                                                width: 100%;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                                .dkg-project-comletetion{
                                                                    width: 100%;
                                                                    max-width: 75%;
                                                                    margin: 0 auto;
                                                                    border: 1px solid #ccc;
                                                                    color: #333;
                                                                    padding: 7px 10px;
                                                                    background-color: #eaeaea;
                                                                    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
                                                                    &:focus{
                                                                        border:none;
                                                                        outline: none;
                                                                    }
                                                                    &:hover{
                                                                        background-color: #f2f2f2;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-visit-prjbtn{
                                                        width: 100%;
                                                        border-top: 1px solid #ddd;
                                                        padding: 7px 10px;
                                                        display: block;
                                                        background-color: #f2f2f2;
                                                        color: #333;
                                                        text-transform: capitalize;
                                                        border-radius: 0 0 8px 8px;
                                                        margin: 0;
                                                        box-shadow: none;
                                                        font-size: 14px;
                                                        .dkg-resume-icon {
                                                            margin-left: 0.5rem;
                                                        }
                                                        &:hover{
                                                            background-color: #eaeaea;
                                                            transition: all 0.5s;
                                                        }
                                                    }
                                                    .dkg-project-btn-progess-Con{
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: space-between;
                                                        width: 100%;
                                                        border-top: 1px solid #ddd;
                                                        // padding: 7px 10px;
                                                        background-color: #f2f2f2;
                                                        color: #333;
                                                        text-transform: capitalize;
                                                        border-radius: 0 0 8px 8px;
                                                        display: none !important;
                                                        .dkg-project-infobtn{
                                                            width: 30%;
                                                            svg{
                                                                width: 23px;
                                                                height: 23px;
                                                            }
                                                        }
                                                        .dkg-info-icon{
                                                            width: 50%;
                                                            border-right: 1px solid #ddd;
                                                            padding: 7px 10px;
                                                            text-align: center;
                                                            cursor: pointer;
                                                            color: #555;
                                                            &:hover{
                                                                box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                                background-color: #eaeaea;
                                                                color: #3c97b6;
                                                            }
                                                            svg{
                                                                width: 20px;
                                                                height: 20px;
                                                            }
                                                        }
                                                        .dkg-progress-barCon{
                                                            width: 50%;
                                                            padding: 7px 10px;
                                                            text-align: center;
                                                            color: #555;
                                                        }
    
                                                    }
                                                    &:last-child{
                                                        margin-right: 15px;
                                                    }
                                                }
                                                &:last-child{
                                                    padding-bottom: 11px !important;
                                                }
    
                                            }

                                        }
                                    }
                                }
                            }
                            .dkg-myacc-companytab{
                                .dkg-other-project-header{
                                    width: 100%;
                                    .dkg-other-prjects-title{
                                        font-size: 0.95rem;
                                        width: 100%;
                                        text-align: justify;
                                        color: #666;
                                        padding: 6px 10px;
                                        background-color: #f2f2f2;
                                        min-height: 55px;
                                        border-bottom: 1px solid #ddd;
                                        line-height: 25px;
                                    }
                                }
                                .dkg-company-table-con{
                                    // height: calc(100vh - 247px);
                                    // overflow-y: auto;
                                    height: calc(100vh - 247px);
                                    .dkg-company-table{
                                        border: none !important;
                                        tbody{
                                            tr{
                                                td{
                                                    border: 1px solid #ddd;
                                                    padding: 8px;
                                                    text-align: left;
                                                    background-color: #fff;
                                                    vertical-align: middle;
                                                    &:nth-child(1) {
                                                        width: 22%;
                                                        background-color: #ddd;
                                                        color: #ccc;
                                                        font-size: 14px !important;
                                                        text-transform: uppercase;
                                                        border: 1px solid #cdcbcb;
                                                        border-left: none;
                                                        color: #333;

                                                    }
                                                    &:nth-child(02){
                                                        border-right: none;
                                                    }
                                                }
                                                // &:first-child{
                                                //     td{
                                                //         border-top: none;
                                                //     }
                                                // }
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-socialmedia-tab{
                                height: calc(100vh - 255px);
                                .dkg-social-box-cols{
                                    padding: 0;
                                    height: 100%;
                                    .dkg-social-media-box{
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        border: 1px solid #ddd;
                                        background-color: #fff;
                                        margin-bottom: 1rem;
                                        height: 100%;
                                         max-height: 19%;
                                        .dkg-socail-media-icon{
                                            width: 120px;
                                            height: 85px;
                                            border-right: 1px solid #ddd;
                                            .dkg-sm-imgcon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                height: 100%;
                                                width: 100%;
                                                .dkg-sm-img{
                                                    width: 55px;
                                                    height: 55px;
                                                }
                                            }
                                        }
                                        .dkg-send-urlbtn-con{
                                            width: 140px;
                                            height: 85px;
                                            border-left: 1px solid #ddd;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            div{
                                                div{
                                                    .dkg-send-urlbtn{
                                                        color: #fff;
                                                        background-color: #1f394c;
                                                        padding: 7px 10px;
                                                        border-radius: 5px;
                                                        font-size: 14px;
                                                        &:hover{
                                                            background-color: #3C97B6;
                                                        }
                                                    
                                                    } 
                                                }
                                            }
                                        }
                                        .dkg-sm-nameurl-con{
                                            width: 100%;
                                            height: 85px;
                                            .dkg-timeflex{
                                                width: 100%;
                                                .dkg-social-media-name{
                                                    display: block;
                                                    height: 43px;
                                                    line-height: 42px;
                                                    font-size: 13px;
                                                    border-bottom: 1px solid #ddd;
                                                    text-align: center;
                                                    width: 100%;
                                                    
                                                }
                                                .dkg-social-media-url{
                                                    display: block;
                                                    height: 42px;
                                                    line-height: 42px;
                                                    font-size: 13px;
                                                    text-align: center;
                                                    width: 100%;
                                                }
                                            }
                                        }
                                        // &:first-child{
                                        //     margin-top: 1.75rem;
                                        // }
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                            .dkg-ourservices-mainCon{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                .dkg-ourservices-firstRow{
                                    height: calc(100vh - 256px);
                                    border: 1px solid #ddd;
                                    width: 100%;
                                    margin-right: 0;
                                    background-color: #f2f2f2;
                                    border-radius: 5px;
                                    margin-left: 10px;
                                    .dkg-ourservice-header{
                                        padding: 10px 15px;
                                        border-bottom: 1px solid #ccc;
                                        border-radius: 5px 5px 0 0;
                                        display: block;
                                        text-align: center;
                                        background-color: #1f394c;
                                        text-transform: uppercase;
                                        .dkg-ourservice-title{
                                            font-weight: 700;
                                            color: #ddd;
                                            font-size: 14px;

                                        }
                                    }
                                    .dkg-ourservice-body{
                                        background-color: #fff;
                                        padding: 10px 15px;
                                        height: calc(100vh - 300px);
                                        .dkg-service-desc{
                                            font-size: 14px;
                                            margin-bottom: 0;
                                        }
                                        &.dkg-ourservice-new-bodyCon{
                                            padding: 10px 0 !important;
                                            .dkg-service-desc{
                                                font-size: 14px;
                                                margin-bottom: 0;
                                                padding: 10px 15px !important;
                                                background-color: #f2f2f2;
                                                &.dkg-ourservice-desc{
                                                    max-height: 121px;
                                                    overflow-y: auto;
                                                    div{
                                                        p{
                                                            margin-bottom: 0 !important;
                                                            color: #666;
                                                            span{
                                                                margin-bottom: 0 !important;
                                                                color: #666 !important;
                                                            }
                                                        }
                                                    }
                                                }
                                               } 
                                               &.dkg-ourservice-new-bodyCon{
                                                padding: 0 !important;
                                                padding-bottom: 10px !important;
                                               }
                                            .dkg-ourservice-tableCon{
                                                height: calc(100vh - 380px);
                                                overflow-y: auto;
                                                .dkg-ourservice-table{
                                                    margin-bottom: 0;
                                                    border-left: none;
                                                    border-right: none;
                                                    tbody{
                                                        tr{
                                                            td{
                                                                padding: 0.65rem 0.7rem;
                                                                &:nth-child(01){
                                                                    text-align: center;
                                                                    width: 45px;
                                                                    border-left: none;
                                                                }
                                                                &:nth-child(02){
                                                                    border-right: none;
                                                                }
                                                            }
                                                            &:last-child{
                                                                td{
                                                                    border-bottom: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-services-middle-div{
                                    background-color: #eaeaea;
                                    height: 20px;
                                }
                                .dkg-ourservices-secondRow{
                                    height: calc(100vh - 256px);
                                    border: 1px solid #ddd;
                                    width: 100%;
                                    margin-left: 7px;
                                    background-color: #f2f2f2;
                                    border-radius: 5px;
                                    margin-right: 15px;
                                    .dkg-ourservice-header{
                                        padding: 10px 15px;
                                        border-bottom: 1px solid #ccc;
                                        border-radius: 5px 5px 0 0;
                                        display: block;
                                        text-align: center;
                                        background-color: #1f394c;
                                        text-transform: uppercase;
                                        .dkg-ourservice-title{
                                            font-weight: 700;
                                            color: #ddd;
                                            font-size: 14px;

                                        }
                                    }
                                    .dkg-ourservice-body{
                                        background-color: #fff;
                                        padding: 10px 15px;
                                        height: calc(100vh - 300px);
                                       .dkg-service-desc{
                                        font-size: 0.95rem;
                                        margin-bottom: 0;
                                        padding: 10px 15px !important;
                                        background-color: #f2f2f2;
                                        &.dkg-ourservice-desc{
                                            max-height: 121px;
                                            overflow-y: auto;
                                            div{
                                                p{
                                                    margin-bottom: 0 !important;
                                                    color: #666;
                                                }
                                            }
                                        }
                                       } 
                                       &.dkg-ourservice-new-bodyCon{
                                        padding: 0 !important;
                                        padding-bottom: 10px !important;
                                        .dkg-ourservice-tableCon{
                                            .dkg-ourservice-table{
                                                margin-bottom: 0;
                                                border-left: none;
                                                border-right: none;
                                                tbody{
                                                    tr{
                                                        td{
                                                            padding: 0.65rem 0.7rem;
                                                            &:nth-child(01){
                                                                text-align: center;
                                                                width: 45px;
                                                                border-left: none;
                                                            }
                                                            &:nth-child(02){
                                                                border-right: none;
                                                            }
                                                        }
                                                        &:last-child{
                                                            td{
                                                                border-bottom: none;

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                       }
                                    }
                                }
                            }
                            &.dkg-aboutus-servicesCon{
                                border: none;
                                height: calc(100vh - 210px);
                                overflow-y: hidden;
                                background-color: transparent;
                            }
                            &.dkg-company-tabpane-Con{
                                height: calc(100vh - 245px);
                                overflow-y: hidden;
                                .dkg-company-table-con{
                                    height: calc(100vh - 287px);
                                    overflow-y: auto;
                                    .dkg-company-table {
                                        border: none !important;
                                        height: 97%;
                                        tbody{
                                            tr{
                                                &:last-child{
                                                    color: #234158;
                                                    td{
                                                        border-bottom: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
    
                            }
                        }
                        .dkg-desired-job-mainCon{
                            background-color: #fff;
                            border: 1px solid #ccc;
                            height: calc(100vh - 270px);
                            .dkg-desired-textarea-Con{
                                .dkg-desired-textarea{
                                    background-color: #f2f2f2;
                                    height: 55px;
                                    padding: 7px 15px;
                                    display: flex;
                                    align-items: center;
                                    justify-content:center;
                                    border: none;
                                }
                            }
                            .dkg-desired-center-sec{
                                border-top: 1px solid #ddd;
                                background-color: #fff;
                                // height: calc(100vh - 410px);
                                .dkg-mailbox-editor-mainCon{
                                    max-height: inherit;
                                    .jodit-react-container{
                                        margin-top: 0;
                                        .jodit-container{
                                            height: calc(100vh - 412px) !important;
                                            border: none;
                                            border-bottom: 1px solid #dadada;
                                            .jodit-toolbar__box{
                                                border-top: none;
                                            }
                                            .jodit-workplace{
                                                .jodit-wysiwyg{
                                                    min-height: inherit !important;
                                                }
                                            }
                                        }
                                    }
                                }
                                &.dkg-desired-job-centersec{
                                    width: 100%;
                                    display: flex;
                                    .dkg-desired-job-leftCon{
                                        width: 50%;
                                        border-right: 1px solid #ddd;
                                        height: calc(100vh - 329px);
                                        .dkg-desired-job-titleCon{
                                            padding: 65px 30px 10px 30px;
                                            .dkg-desired-job-title{
                                                font-weight: 600;
                                                font-size: 19px;
                                                text-transform: uppercase;
                                                margin-bottom: 0;
                                                color: #333;
                                                text-transform: capitalize;
                                            }
                                        }
                                        .dkg-selected-tagCon{
                                            height: 61%;
                                            border-bottom: 1px solid #ddd;    
                                            .dkg-selected-title{
                                                color: #333;
                                                display: block;
                                                font-size: 1.15rem;
                                                padding: 7px 10px;
                                                position: relative;
                                                width: 100%;
                                                font-weight: 600;
                                                text-align: center;
                                                border-bottom: 1px solid #ddd;
                                            }
                                            .dkg-st-tag-job-btn-tagCon{
                                                align-items: flex-start;
                                                display: flex;
                                                justify-content: flex-start;
                                                width: 100%;
                                                flex-wrap: wrap;
                                                padding: 5px 7px;
                                                .dkg-st-tag-btntext{
                                                    display: inline-block;
                                                    border-radius: 12px;
                                                    color: #445;
                                                    font-size: 13px!important;
                                                    padding: 6px 22px;
                                                    text-transform: capitalize;
                                                    background-color: #f2f2f2 !important;
                                                    border: 1px solid #ddd;
                                                    box-shadow: none;
                                                    position: relative;
                                                    margin-top: 8px;
                                                        .dkg-tag-icon {
                                                            color: #445;
                                                            cursor: pointer;
                                                            display: none;
                                                            margin-left: 5px;
                                                            padding: 0;
                                                            position: absolute;
                                                            right: 5px;
                                                            top: 6px;
                                                        }
                                                        &:hover{
                                                            .dkg-tag-icon{
                                                                display: block;
                                                                i{
                                                                    color: #445;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    
                                                }
                                        }
                                        .dkg-dj-rightsecond-cols{
                                            height: 40%;
                                            width: 100%;
                                            background-color: #DCE5E9;
                                            display: flex;
                                            align-items: center;
                                            flex-direction: column;
                                            justify-content: center;
                                            .dkg-desired-job-inputCon{
                                                width: 100%;
                                                .dkg-createnew-tag-title{
                                                    color: #333;
                                                    display: block;
                                                    font-size: 1.15rem;
                                                    padding: 7px 10px;
                                                    position: relative;
                                                    width: 100%;
                                                    font-weight: 600;
                                                    text-transform: uppercase;
                                                    text-align: center;
                                                }                                    
                                                
                                                .dkg-desired-job-inputCon{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    flex-direction: column;
                                                    // height: 90%;
                                                    .dkg-desired-job-input{
                                                        border: 1px solid #ddd;
                                                        background-color: #fff;
                                                        width: 100%;
                                                        max-width: 90%;
                                                        text-align: left;
                                                        margin-left: 10px;
        
                                                    }
                                                    .dkg-desired-job-save-btnCon{
                                                        margin-top: 10px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        div{
                                                            div{
                                                                .dkg-desired-job-save-btn{
                                                                    background-color: #26597d;
                                                                    // text-transform: capitalize;
                                                                    &:hover{
                                                                        color: #fff;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    .dkg-createcv-textarea{
                                        width: 100%;
                                        height: calc(100vh - 413px);
                                        padding: 7px 15px;
                                        border: none;
                                        border-bottom: 1px solid #ddd;
                                        &:focus{
                                            outline: 0;
                                        }
                                        &.dkg-profile-textareCon{
                                            height: 250px ;
                                        }
                                    }
                                    &.dkg-profile-summCon{
                                        background-color: #eaeaea;
                                    }
                                }
                                .dkg-desired-job-rightCon{
                                    width: 50%;
                                    height: calc(100vh - 328px);
                                    .dkg-dj-rightfirst-cols{
                                        height: 100%;
                                        border-bottom: 1px solid #ddd;
                                        .dkg-saved-cv-tag-title{
                                            color: #333;
                                            display: block;
                                            font-size: 1.15rem;
                                            padding: 7px 10px;
                                            position: relative;
                                            width: 100%;
                                            font-weight: 600;
                                            text-align: center;
                                            border-bottom: 1px solid #ddd;
                                        }
                                        .dkg-desired-job-btn-tagCon{
                                            display: flex;
                                            justify-content: flex-start;
                                            align-items: flex-start;
                                            width: 100%;
                                            flex-wrap: wrap;
                                            padding: 5px 7px;
                                            .dkg-desired-btntext{ 
                                            display: inline-block;
                                            border-radius: 12px;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 6px 22px;
                                            text-transform: capitalize;
                                            background-color: #366995 !important;
                                            box-shadow: none;
                                            position: relative;
                                            margin-top: 8px;
                                            .dkg-tag-icon {
                                                color: #fff;
                                                cursor: pointer;
                                                display: none;
                                                margin-left: 5px;
                                                padding: 0;
                                                position: absolute;
                                                right: 5px;
                                                top: 6px;
                                            }
                                            &:hover{
                                                .dkg-tag-icon{
                                                    display: block;
                                                    i{
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                    
                                    
                                            }
                                    }
                                    .dkg-dj-rightsecond-cols{
                                        height: 40%;
                                        width: 100%;
                                        background-color: #DCE5E9;
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                        justify-content: center;
                                        .dkg-desired-job-inputCon{
                                            width: 100%;
                                            .dkg-createnew-tag-title{
                                                color: #333;
                                                display: block;
                                                font-size: 1.15rem;
                                                padding: 7px 10px;
                                                position: relative;
                                                width: 100%;
                                                font-weight: 600;
                                                text-transform: uppercase;
                                                text-align: center;
                                            }                                    
                                            
                                            .dkg-desired-job-inputCon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                flex-direction: column;
                                                // height: 90%;
                                                .dkg-desired-job-input{
                                                    border: 1px solid #ddd;
                                                    background-color: #fff;
                                                    width: 100%;
                                                    max-width: 90%;
                                                    text-align: left;
                                                    margin-left: 10px;
    
                                                }
                                                .dkg-desired-job-save-btnCon{
                                                    margin-top: 10px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    div{
                                                        div{
                                                            .dkg-desired-job-save-btn{
                                                                background-color: #26597d;
                                                                // text-transform: capitalize;
                                                                &:hover{
                                                                    color: #fff;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                
                                    
                                }
                            }
                            .dkg-desired-save-btnCon{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                padding: 25px 0;
                                background-color: #f2f2f2;
                                div{
                                    div{
                                        .dkg-desired-save-btn{
                                            background-color:  rgb(38, 89, 125);
                                            &:hover{
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-myresume-newblock-main{
                                .dkg-myprofile-con{
                                    width: 100%;
                                    max-width: 1140px;
                                    margin: 0 auto;
                                    padding-top: 0;
                                    .dkg-myprofile-mainRow{
                                        .dkg-myprofile-leftcon,
                                        .dkg-myprofile-rightcon{
                                            border: 1px solid #d7d7d7;
                                            padding: 0;
                                            border-radius: 5px;
                                            margin-top: 20px;
                                            .dkg-profile-frm-title{
                                                background: #1f394c;
                                                border-top-left-radius: 5px;
                                                border-top-right-radius: 5px;
                                                color: #ebdede;
                                                font-size: 18px;
                                                height: 50px;
                                                line-height: 50px;
                                                padding: 0 10px;
                                                text-align: center;
                                                margin-bottom: 0;
                                            
                                            }
                                            .dkg-profile-frm-subtitle{
                                                background-color: #f2f2f2;
                                                color: #333;
                                                padding: 1.15rem 15px;
                                                text-align: center;
                                                width: 100%;
                                                margin-bottom: 0;
                                                height: 50px;
                                                line-height: 50px;
                                                border-bottom: 1px solid #ddd;
                                                .dkg-frm-subtitle-desc{
                                                    font-size: 14px;
                                                    text-align: center;
                                                    margin-bottom: 0;
                                                    color: #666;
                            
                                                }
                                            }
                                            .dkg-profile-frmgrp{
                                                display: flex;
                                                align-items: center;
                                                width: 100%;
                                                margin-bottom: 1rem;
                                                padding: 0 15px;
                                                .dkg-profile-label{
                                                    min-width: 220px;
                                                    max-width: 220px;
                                                    margin-bottom: 0;
                                                    font-size: 13px;
                                                    .dkg-profile-star{
                                                        color: #e2445b;
                                                    }
                                                }
                                                .dkg-profile-input{
                                                    font-size: 13px;
                                                    border: 1px solid #ddd;
                                                    background-color: #f2f2f2;
                                                    &::placeholder {
                                                        color: #676767;
                                                        opacity: 1; /* Firefox */
                                                        font-size: 13px;
                                                    }  
                                                    &::-ms-input-placeholder { /* Edge 12-18 */
                                                        color: #676767;
                                                        font-size: 13px;
                                                    }
                                                    &.dkg-dullgrey-input{
                                                        &::placeholder {
                                                            color: #676767;
                                                            font-size: 13px;
                                                            opacity: 1; /* Firefox */
                                                        }  
                                                        &::-ms-input-placeholder { /* Edge 12-18 */
                                                            color: #676767;
                                                            font-size: 13px;
                                                        }
                                                    }
                                                    
                                                }
                                                .dkg-info-uplaod-image {
                                                    .dk-contactPerson-image {
                                                        width: 100%;
                                                        max-width: 120px;
                                                        border: 1px solid #ccc;
                                                        padding: 0;
                                                        margin-right: 15px;
                                                        height: 120px;
                                                        display: flex;
                                                        align-items: center;
                                                        background: #fff;
                                                        justify-content: center;
                                                        overflow: hidden;
                                                        border-radius: 50%;
                            
                                                        img {
                                                            width: 100%;
                                                            object-fit: cover;
                                                            border-radius: 50%;
                                                            height: 120px;
                                                            object-position: top center;
                                                        }
                                                    }
                            
                                                    .dk-edit-userImgCon {
                                                        width: 100%;
                                                        max-width: 100px;
                                                        border: 1px solid #ccc;
                                                        padding: 0;
                                                        margin-right: 15px;
                                                        height: 100px;
                                                        display: flex;
                                                        align-items: center;
                                                        background: #fff;
                                                        justify-content: center;
                                                        // overflow: hidden;
                                                        border-radius: 50%;
                                                        margin-bottom: 10px;
                                                        position: relative;
                                                        input {
                                                            width: 100%;
                                                            height: 100px;
                                                            max-width: 100px;
                                                            position: relative;
                                                            z-index: 9;
                                                            opacity: 0;
                                                            cursor: pointer;
                                                        }
                            
                                                        span.dk-myprofile-userImgCon {
                                                            position: absolute;
                                                            top: -1px;
                                                            left: 0;
                                                            cursor: pointer;
                                                            width: 100%;
                                                            max-width: 100px;
                                                            border-radius: 50%;
                            
                                                            img.dk-myprofile-userImg {
                                                                width: 100%;
                                                                height: auto;
                                                                border-radius: 50%;
                                                            }
                            
                                                            figure {
                                                                .dkg-update-profile-234 {
                                                                    max-width: 120px;
                                                                    border: 1px solid #ccc;
                                                                    padding: 0;
                                                                    height: 120px;
                                                                    display: flex;
                                                                    align-items: center;
                                                                    background: #fff;
                                                                    justify-content: center;
                                                                    overflow: hidden;
                                                                    border-radius: 50%;
                                                                    position: relative;
                                                                    right: 4px;
                                                                }
                                                            }
                                                        }
                            
                                                        span.dk-myprofile-editicon {
                                                            position: absolute;
                                                            bottom: 17px;
                                                            right: -2px;
                                                            background-color: #234158;
                                                            width: 30px;
                                                            height: 30px;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            border-radius: 50%;
                            
                                                            svg {
                                                                color: #fff;
                                                            }
                                                        }
                                                    }
                                                }
                                                .dkg-dob-inputCon{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .dkg-dob-input{
                                                        margin-right: 0.7rem;
                                                        background-color: #f2f2f2;
                                                        border: 1px solid #ddd;
                                
                                                    }
                                                }
                                                &:last-child{
                                                    margin-bottom: 1.5rem;
                                                }
                                            }
                                            .dkg-resume-rightcon{
                                                height: calc(100vh - 340px);
                                            }
                                            &.dkg-resume-leftcon,
                                            &.dkg-resume-rightcon{
                                                border: none;
                                                .dkg-resume-leftbody,
                                                .dkg-resume-rightbody{
                                                    border: none;
                                                    height: 275px !important;
                                                    background-color: #fff;
                                                    border-radius: 0 0 5px 5px;
                                                    border: 1px solid #ddd;
                                                    border-top: none;
                                                }
                                                .dkg-profile-frm-title{
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
                                        .dkg-myprofile-leftcon {
                                            background-color: #fff;
                                 
                                            @media (min-width: 768px){
                                                &.col-md-6 {
                                                    flex: 0 0 46%;
                                                    max-width: 46%;
                                                    position: relative;
                                                    &::after{
                                                        content: "";
                                                        position: absolute;
                                                        right: -48px;
                                                        top: 0;
                                                        border-right: 0.5px solid #ccc;
                                                        height: 100%;
                                                        display: none;
                                                    }
                                                }
                                            }
                                            &.dkg-resume-leftcon{
                                                .dkg-resume-frm-title{
                                                    text-transform: inherit;
                                                    border: 1px solid #ddd;
                                                }
                                                .dkg-resume-leftbody{
                                                    width: 100%;
                                                    height: calc(100vh - 250px);
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    flex-direction: column;
                                                    .dkg-white-block{
                                                        width: 150px;
                                                        height: 150px;
                                                        background-color: transparent;
                                                        border: 1px solid transparent;
                                                        position: relative;
                                                        top: 5px;
                                                        .dkg-myresume-cvimgCon{
                                                            width: 100%;
                                                            height: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            position: absolute;
                                                            top: -18px;
                                                            left: 0;
                                                            .dkg-myresume-cvimg{
                                                                width: 100px;
                                                                height: auto;
                                                                background-repeat: no-repeat;
                                                                background-size: cover;                                    
                                                            }
                                                            input {
                                                                width: 100%;
                                                                height: 100px;
                                                                max-width: 100px;
                                                                position: relative;
                                                                z-index: 9;
                                                                opacity: 0;
                                                                cursor: pointer;
                                                            }
                                
                                                            span.dkg-myresume-sapnimgCon {
                                                                position: absolute;
                                                                top: -1px;
                                                                left: 0;
                                                                cursor: pointer;
                                                                width: 100%;
                                                                max-width: 100px;
                                                                border-radius: 50%;
                                
                                                                img.dkg-myresume-cvimg {
                                                                    width: 100%;
                                                                    height: auto;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-resume-view-btnCon{
                                                        margin-top: 30px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 100%;
                                                        position: relative;
                                                        top: -10px;
                                                        .dkg-resume-view-btn{
                                                            display: inline-block;
                                                            background-color: #f2f2f2;
                                                            color: #333;
                                                            border: 1px solid #ddd;
                                                            text-transform: capitalize;
                                                            border-radius: 0;
                                                            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                                            padding: 7px 12px;
                                                            font-size: 0.875rem;
                                                            box-sizing: border-box;
                                                            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                                            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                                            font-weight: 500;
                                                            line-height: 1.75;
                                                            letter-spacing: 0.02857em;
                                                            &:hover{
                                                                box-shadow: none;
                                                                background-color: #3C97B6;
                                                                color: #fff;
                                                            }
                                                            .dkg-resume-icon{
                                                                margin-left: 0.5rem;
                                                                svg{
                                                                    width: 0.95rem;
                                                                    height: 0.95rem;
                                                                    cursor: pointer;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                            
                                        }
                                        .dkg-myprofile-rightcon{
                                            background-color: #fff;
                            
                                            @media (min-width: 768px){
                                                &.col-md-6 {
                                                    flex: 0 0 46%;
                                                    max-width: 46%;
                                                    margin-left: auto;
                                                }
                                            }
                                            &.dkg-profile-info-rightCon{
                                                color: inherit;
                                            }
                                            &.dkg-resume-rightcon{
                                                .dkg-resume-frm-title{
                                                    text-transform: inherit;
                                                    border: 1px solid #ddd;
                                                }
                                                .dkg-resume-rightbody{
                                                    width: 100%;
                                                    height: calc(100vh - 250px);
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    flex-direction: column;
                                                    .dkg-white-block{
                                                        width: 150px;
                                                        height: 150px;
                                                        background-color: transparent;
                                                        border: 1px solid transparent;
                                                        position: relative;
                                                        top: -5px;
                                                        .dkg-myresume-cvimgCon{
                                                            width: 100%;
                                                            height: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            .dkg-myresume-cvimg{
                                                                width: 100px;
                                                                height: 100px;
                                                                background-repeat: no-repeat;
                                                                background-size: cover;
                                                            }
                                                            input {
                                                                width: 100%;
                                                                height: 100px;
                                                                max-width: 100px;
                                                                position: relative;
                                                                z-index: 9;
                                                                opacity: 0;
                                                                cursor: pointer;
                                                            }
                                
                                                            span.dkg-myresume-sapnimgCon {
                                                                position: absolute;
                                                                top: -1px;
                                                                left: 0;
                                                                cursor: pointer;
                                                                width: 100%;
                                                                max-width: 100px;
                                                                border-radius: 50%;
                            
                                                                img.dkg-myresume-cvimg {
                                                                    width: 100%;
                                                                    height: auto;
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .dkg-resume-btngrp-Con{
                                                        margin-top: 30px;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        width: 100%;
                                                        position: relative;
                                                        top: -10px;
                                                        .dkg-resume-btn{
                                                            .dkg-resume-view-btn{
                                                                display: inline-block;
                                                                background-color: #f2f2f2;
                                                                color: #333;
                                                                border: 1px solid #ddd;
                                                                text-transform: capitalize;
                                                                border-radius: 0;
                                                                box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                                                padding: 7px 12px;
                                                                font-size: 0.875rem;
                                                                box-sizing: border-box;
                                                                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                                                font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                                                font-weight: 500;
                                                                line-height: 1.75;
                                                                letter-spacing: 0.02857em;
                                                                &:hover{
                                                                    box-shadow: none;
                                                                    background-color: #3C97B6;
                                                                    color: #fff;
                                                                }
                                                                .dkg-resume-icon{
                                                                    margin-left: 0.5rem;
                                                                    svg{
                                                                        width: 0.95rem;
                                                                        height: 0.95rem;
                                                                        cursor: pointer;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &.dkg-cv-preview-main{
                                .dkg-cvpreview-center-sec{
                                    border-top: 1px solid #ddd;
                                    background-color: #fff;
                                    height: calc(100vh - 328px);
                                    .dkg-cvpreview-textareaCon{
                                        .dkg-cvpreview-textarea{
                                            background-color: #fff;
                                            height: 55px;
                                            padding: 7px 15px;
                                            text-align: left;
                                            width: 80%;
                                            margin: 50px auto;
                                            border: 1px solid #ddd;
                                        }
                                        .dkg-cvpreview-save-btnCon{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            width: 100%;
                                            padding: 10px 0;
                                            .dkg-cvpreview-save-btn{
                                                background-color: #26597d;
                                                &:hover{
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }
                                    &.dkg-workexpirece-mainCon{
                                        width: 100%;
                                        display: flex;
                                        .dkg-workexpirece-leftcon{
                                            width: 35%;
                                            height: calc(100vh - 329px);
                                            border-right: 1px solid #ddd;
                                            .dkg-work-exp-btn-group{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                flex-direction: column;
                                                gap: 35px;
                                                width: 100%;
                                                // max-width: 70%;
                                                // margin: 0 auto;
                                                height: 100%;
                                                .dkg-work-exp-btn{
                                                    color: #fff;
                                                    cursor: pointer;
                                                    display: inline-block;
                                                    font-size: 1rem;
                                                    font-weight: 400;
                                                    width: 100%;
                                                    max-width: 320px; 
                                                    position: relative;
                                                    text-decoration: none;
                                                    text-transform: capitalize;
                                                    background-color: #26597d;
                                                    padding: 0.8rem 0.75rem;
                                                }
                                            }

                                        }
                                        .dkg-workexpirece-rightcon{
                                            width: 100%;
                                            height: 100%;
                                            .dkg-work-exp-right-header{
                                                width: 100%;
                                                position: relative;
                                                .dkg-work-exp-right-title{
                                                    color: #495057;
                                                    display: block;
                                                    font-size: 1.15rem;
                                                    padding: 7px 10px;
                                                    position: relative;
                                                    width: 100%;
                                                    font-weight: 600;
                                                    text-align: center;
                                                    border-bottom: 1px solid #ddd;
                                                }
                                                .dkg-work-exp-right-btn-ul{
                                                    position: absolute;
                                                    top: 0;
                                                    right: 0;
                                                    display: flex;
                                                    align-items: center;
                                                    list-style: none;
                                                    margin: 0;
                                                    padding: 0;
                                                    .dkg-work-exp-right-btn-li{
                                                        border-right: 1px solid #ddd;
                                                        height: 39px;
                                                        line-height: 23px;
                                                        // width: 110px;
                                                        text-align: center;
                                                        .dkg-work-exp-right-btn{
                                                            display: inline-block;
                                                            width: 100%;
                                                            font-size: 1rem;
                                                            color: #3c97b6;
                                                            padding: 7px 15px;
                                                            &:hover {
                                                                background-color: #eaeaea;
                                                                color: #3c97b6;
                                                                box-shadow: 0 10px 20px #00000030, 0 6px 6px #0000003b;
                                                                transition: all .5s ease;
                                                            }
                                                        }
                                                        &:first-child{
                                                            border-left: 1px solid #ddd;
                                                        }
                                                        &:last-child{
                                                            border-right: none;
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-work-exp-textarea-Con{
                                                .dkg-work-exp-textarea{
                                                    width: 100%;
                                                    height: calc(100vh - 369px);
                                                    border: none;
                                                    padding: 7px 10px;
                                                    font-size: 0.95rem;
                                                    &:focus{
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                            .dkg-desired-summ-body-234{
                                width: 100%;
                                height: calc(100vh - 328px);
                                overflow-y: auto;
                                .dkg-desired-summ-box-cols{
                                    width: 100%;
                                    height: 100%;
                                    max-height: 12.5%;
                                    display: flex;
                                    border-bottom: 1px solid #ccc;
                                    .dkg-desired-summ-1stcols{
                                        width: 275px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        border-right: 1px solid #ccc;
                                        background-color: #ddd;
                                        .dkg-desired-summ-title{
                                            font-size: 1rem;
                                            padding: 0 15px;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .dkg-desired-summ-2ndcols{
                                        width: 100%;
                                        height: 100%;
                                        .dkg-desired-texareaCon{
                                            width: 100%;
                                            height: 100%;
                                            .dkg-desired-texarea{
                                                width: 100%;
                                                text-align: left;
                                                padding: 5px 10px;
                                                height: 100%;
                                            }
                                        }
                                        .dkg-myprofile-summCon{
                                            width: 100%;
                                            height: 100%;
                                            .dkg-myprofile-summ-btn-tagCon{
                                                display: flex;
                                                justify-content: flex-start;
                                                align-items: center;
                                                width: 100%;
                                                height: 100%;
                                                flex-wrap: wrap;
                                                padding: 0 10px;
                                                .dkg-desired-btntext{
                                                    display: inline-block;
                                                    border-radius: 12px;
                                                    color: #fff;
                                                    font-size: 13px;
                                                    padding: 6px 22px;
                                                    text-transform: capitalize;
                                                    background-color: #366995 !important;
                                                    box-shadow: none;
                                                    position: relative;
                                                    .dkg-tag-icon{
                                                        color: #fff;
                                                        cursor: pointer;
                                                        display: none;
                                                        margin-left: 5px;
                                                        padding: 0;
                                                        position: absolute;
                                                        right: 5px;
                                                        top: 6px;
                                                    }
                                                    &:hover{
                                                        .dkg-tag-icon{
                                                            display: block;
                                                            i{
                                                                color: #fff;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-covermsg-textCon{
                                            width: 100%;
                                            height: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
                                            flex-wrap: wrap;
                                            padding: 7px 10px;
                                            .dkg-covermsg-text{
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                    &:first-child{
                                        border-top: 1px solid #ddd;
                                    }
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }
                            }
                            &.dkg-cv-profile-summ-main{
                                background-color: #f2f2f2;
                            }
                        }
                        .dkg-createcv-job-mainCon{
                            background-color: #fff;
                            border: 1px solid #ccc;
                            .dkg-createcv-textarea-Con{
                                width: 100%;
                                height: 100%;
                                .dkg-createcv-textarea{
                                    background-color: #f2f2f2;
                                    height: 55px;
                                    padding: 7px 15px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-wrap: wrap;
                                    border: none;
                                }
                            }
                        }
                        .dkg-create-cv-center-sec.dkg-create-cv-job-centersec{
                            width: 100%;
                            display: flex;
                            background-color: #f2f2f2;
                            .dkg-create-cv-job-leftCon{
                                width: 50%;
                                border-right: 1px solid #ddd;
                                height: calc(100vh - 329px);
                                .dkg-selected-title {
                                    color: #495057;
                                    display: block;
                                    font-size: 1.15rem;
                                    padding: 7px 10px;
                                    position: relative;
                                    width: 100%;
                                    font-weight: 600;
                                    text-align: center;
                                    border-bottom: 1px solid #ddd;
                                    background-color: #fff;
                                }
                                .dkg-select-cv-cols{
                                    display: flex;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    .dkg-uploadimg-mainCon{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                        width: 100%;
                                        height: calc(100vh - 370px);
                                        background-color: #fff;
                                        .dkg-info-uplaod-image {
                                            position: relative;
                                            top: -40px;
                                            .dk-contactPerson-image {
                                                width: 100%;
                                                max-width: 120px;
                                                border: 1px solid #ccc;
                                                padding: 0;
                                                margin-right: 15px;
                                                height: 120px;
                                                display: flex;
                                                align-items: center;
                                                background: #fff;
                                                justify-content: center;
                                                overflow: hidden;
                                                border-radius: 50%;
                    
                                                img {
                                                    width: 100%;
                                                    object-fit: cover;
                                                    border-radius: 50%;
                                                    height: 120px;
                                                    object-position: top center;
                                                }
                                            }
                    
                                            .dk-edit-userImgCon {
                                                width: 100%;
                                                max-width: 100px;
                                                border: 1px solid #ccc;
                                                padding: 0;
                                                margin-right: 15px;
                                                height: 100px;
                                                display: flex;
                                                align-items: center;
                                                background: #fff;
                                                justify-content: center;
                                                // overflow: hidden;
                                                border-radius: 50%;
                                                margin-bottom: 10px;
                                                position: relative;
                                                input {
                                                    width: 100%;
                                                    height: 100px;
                                                    max-width: 100px;
                                                    position: relative;
                                                    z-index: 9;
                                                    opacity: 0;
                                                    cursor: pointer;
                                                }
                    
                                                span.dk-myprofile-userImgCon {
                                                    position: absolute;
                                                    top: -1px;
                                                    left: 0;
                                                    cursor: pointer;
                                                    width: 100%;
                                                    max-width: 100px;
                                                    border-radius: 50%;
                    
                                                    img.dk-myprofile-userImg {
                                                        width: 100%;
                                                        height: auto;
                                                        border-radius: 50%;
                                                    }
                    
                                                    figure {
                                                        .dkg-update-profile-234 {
                                                            max-width: 120px;
                                                            border: 1px solid #ccc;
                                                            padding: 0;
                                                            height: 120px;
                                                            display: flex;
                                                            align-items: center;
                                                            background: #fff;
                                                            justify-content: center;
                                                            overflow: hidden;
                                                            border-radius: 50%;
                                                            position: relative;
                                                            right: 4px;
                                                        }
                                                    }
                                                    &.dk-browse-edit-userImgCon{
                                                        top: 23px;
                                                        figure{
                                                            .dkg-browse-imge-textCon{
                                                                display: flex;
                                                                align-items: center;
                                                                flex-direction: column;
                                                                svg{
                                                                    width: 23px;
                                                                    height: 23px;
                                                                    color: #C5C5C5;
                                                                }
                                                                .dkg-browse-title{
                                                                    text-transform: uppercase;
                                                                    color: #324148;
                                                                    padding: 10px 0;
                                                                    font-size: 0.85rem;
                                                                    font-weight: 400;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                    
                                                span.dk-myprofile-editicon {
                                                    position: absolute;
                                                    bottom: 17px;
                                                    right: -2px;
                                                    background-color: #234158;
                                                    width: 30px;
                                                    height: 30px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 50%;
                    
                                                    svg {
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-upload-cv-btnCon{
                                            position: relative;
                                            top: -40px;
                                            .dkg-upload-cv-btn{
                                                background-color: #f2f2f2;
                                                color: #333;
                                                border: 1px solid #ddd;
                                                box-shadow: none;
                                                padding: 10px 12px;
                                                width: 140px;
                                                &:hover{
                                                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                                }
                                                .dkg-upload-icon{
                                                    svg{
                                                        color: #333;
                                                    }
                                                }
                                            }
                                            .dkg-remove-cv-btn{
                                                background-color: #f2f2f2;
                                                color: #333;
                                                border: 1px solid #ddd;
                                                box-shadow: none;
                                                padding: 10px 12px;
                                                width: 100px;
                                                &:hover{
                                                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                                }
                                            }
                                        }
                                        .dkg-avatar-block-image-Con{
                                            position: relative;
                                            top: -40px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex-direction: column;
                                            .dkg-avatar-image-Con{
                                                width: 100px;
                                                height: 100px;
                                                border: 1px solid #ccc;
                                                padding: 0;
                                                display: flex;
                                                align-items: center;
                                                background: #fff;
                                                justify-content: center;
                                                overflow: hidden;
                                                border-radius: 50%;
                                                object-fit: contain;
                                                margin-bottom: 0.5rem;
                                                img.dkg-avatar-image{
                                                    width: 100%;
                                                    object-fit: contain;
                                                    border-radius: 50%;
                                                    object-fit: contain;
                                                }
                                            }
                                            .dkg-avatar-cv-btnCon{
                                               
                                                .dkg-avatar-cv-btn{
                                                    background-color: #f2f2f2;
                                                    color: #333;
                                                    border: 1px solid #ddd;
                                                    box-shadow: none;
                                                    display: inline-block;
                                                    padding: 10px 12px;
                                                    width: 140px;
                                                    &:hover{
                                                        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                                    }
                                                    .dkg-upload-icon{
                                                        svg{
                                                            color: #333;
                                                        }
                                                    }
                                                }
                                            }
                                        }
    
                                    }
                                    .dkg-select-check-content{
                                        position: absolute;
                                        bottom: 110px;
                                        left: 50px;
                                        .dkg-cv-select-checkbox-con{
                                            display: flex;
                                            align-items: center;
                                            input[type=checkbox] {
                                                -webkit-appearance: none;
                                                appearance: none;
                                                background: #fff;
                                                border: 1px solid #dc3545;
                                                border-radius: 1px;
                                                box-sizing: border-box;
                                                cursor: pointer;
                                                transition: all .3s linear;
                                                width: 16px;
                                                height: 16px;
                                                z-index: 0;
                                            }
                                            input[type=checkbox]:checked {
                                                background-color: #dc3545 !important;
                                            }
                                            .dkg-cv-checkbox-label{
                                                margin-left: 0.5rem;
                                                color: #dc3545;
                                                font-size: 1.15rem;
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-create-cv-job-rightCon{
                                width: 50%;
                                height: calc(100vh - 329px);
                                .dkg-selected-title {
                                    color: #495057;
                                    display: block;
                                    font-size: 1.15rem;
                                    padding: 7px 10px;
                                    position: relative;
                                    width: 100%;
                                    font-weight: 600;
                                    text-align: center;
                                    border-bottom: 1px solid #ddd;
                                    background-color: #fff;
                                }
                                .dkg-createcv-rightbody{
                                    padding: 15px;
                                    overflow-y: auto;
                                    height: calc(100vh - 370px);
                                    background-color: #fff;
                                    .dkg-createcv-frmgrp{
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        margin-bottom: 1rem;
                                        .dkg-createcv-label{
                                            min-width: 145px;
                                            margin-bottom: 0;
                                            text-align: left;
                                            font-size: 0.85rem;
                                        }
                                        .dkg-createcv-frm-control{
                                            border: 1px solid #ddd;
                                            border-radius: 0;
                                            background-color: #f2f2f2;
                                        }
                                        .dkg-createcv-dropdwnCon{
                                            width: 100%;
                                            .dkg-createcv-dropdwn{
                                                width: 100%;
                                                .dkg-ccv-dropdwn-toggle{
                                                    box-shadow: none;
                                                    width: 100%;
                                                    border: 1px solid #ddd;
                                                    text-transform: capitalize;
                                                    padding: 7px 10px;
                                                    height: 30px;
                                                    margin: 0;
                                                    position: relative;
                                                    background-color: #f2f2f2;
                                                    &::after{
                                                        position: absolute;
                                                        right: 15px;
                                                    }
                                                }
                                                .dkg-ccv-dropdwn-menu{
                                                    width: 100%;
                                                    border-radius: 0;
                                                    .dkg-ccv-dropdwn-item{
                                                        display: block;
                                                        padding: 5px 10px;
                                                        font-size: 13px;
                                                        border-bottom: 1px solid #ddd;
                                                        &:last-child{
                                                            border-bottom: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &:last-child{
                                            margin-bottom: 0;
                                        }

                                    }
                                    &::-webkit-scrollbar {
                                        display: none;
                                      }
                                      
                                      /* Hide scrollbar for IE, Edge and Firefox */
                                      &{
                                        -ms-overflow-style: none;  /* IE and Edge */
                                        scrollbar-width: none;  /* Firefox */
                                      }
                                }
                                .dkg-createcv-rightbody{
                                    -ms-overflow-style: none;  /* IE and Edge */
                                    scrollbar-width: none;  /* Firefox */
                                }
                            }
                        }
                        &.dkg-contactus-tabpane{
                            overflow-y: hidden;
                            .dkg-contactus-cardCon{
                                width: 100%;
                                height: calc(100vh - 300px);
                                overflow-y: auto;
                                background-color: #fff;
                                // .dkg-contact-card{
                                // }
                            }

                        }
                        &.dkg-desired-job-summ-mainCon{
                            overflow-y: auto;
                        }
                    }
                }
            }
        }
    }
}

.dkg-cand-abouts-moCon{
    display: none;
}
.dkg-cand-resumes-moCon{
    display: none;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-contact-mainCon{
        &.dkg-cand-abouts-pageCon{
            margin-top: 35px;
            height: calc(100vh - 49px);
            overflow-y: auto;
            .dkg-cand-abouts-moCon{
                display: block;
                .dkg-abouts-header-centerCon{
                    padding-top: 1.25rem;
                    width: 100%;
                    .dkg-abouts-DropdownCon{
                        width: 100%;
                        .dkg-abouts-Dropdown{
                            width: 100%;
                            max-width: 70%;
                            margin: 0 auto;
                            .dkg-abouts-Dropdown-toggle{
                                text-transform: capitalize;
                                box-shadow: none;
                                background-color: #fff;
                                border: 1px solid #ddd;
                                font-size: 0.85rem;
                                padding: 0.45rem 0.7rem;
                                margin: 0;
                                width: 100%;
                                text-align: left;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    top: 1.02rem;
                                    right: 0.85rem;
                                }
                            }
                            .dkg-abouts-Dropdown-Menu{
                                border-radius: 0;
                                min-width: 180px;
                                overflow-y: auto;
                                padding: 0;
                                border-radius: 0;
                                .dkg-abouts-Dropdown-Item,
                                .dropdown-item{
                                    border-bottom: 1px solid #ddd;
                                    font-size: 0.85rem;
                                    padding: 0.5rem 0.95rem;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .dkg-myacc-companytab{
                    padding-top: 1rem;
                    .dkg-other-project-header{
                        width: 100%;
                        .dkg-other-prjects-title{
                            font-size: 0.95rem;
                            width: 100%;
                            text-align: justify;
                            color: #444;
                            padding: 6px 10px;
                            background-color: #fff;
                            min-height: 55px;
                            border-bottom: 1px solid #ddd;
                            line-height: 25px;
                        }
                    }
                    .dkg-company-table-con{
                        // height: calc(100vh - 247px);
                        // overflow-y: auto;
                        height: calc(100vh - 440px);
                        overflow-y: auto;
                        margin-top: 1rem;
                        .dkg-company-table{
                            border: none !important;
                            tbody{
                                tr{
                                    td{
                                        border: 1px solid #ddd;
                                        padding: 8px;
                                        text-align: left;
                                        background-color: #fff;
                                        vertical-align: middle;
                                        white-space: nowrap;
                                        font-size: 0.8rem;
                                        &:nth-child(1) {
                                            background-color: #ddd;
                                            color: #ccc;
                                            font-size: 0.8rem !important;
                                            text-transform: uppercase;
                                            border: 1px solid #cdcbcb;
                                            border-left: none;
                                            color: #333;

                                        }
                                        &:nth-child(02){
                                            border-right: none;
                                        }
                                    }
                                    // &:first-child{
                                    //     td{
                                    //         border-top: none;
                                    //     }
                                    // }
                                }
                            }
                        }
                    }
                }
                .dkg-ourservices-mainCon{
                    width: 100%;
                    height: 100%;
                    margin-top: 1rem;
                    .dkg-ourservices-firstRow{
                        height: calc(100vh - 256px);
                        border: 1px solid #ddd;
                        width: 100%;
                        background-color: #f2f2f2;
                        border-radius: 5px;
                        .dkg-ourservice-header{
                            padding: 10px 15px;
                            border-bottom: 1px solid #ccc;
                            border-radius: 5px 5px 0 0;
                            display: block;
                            text-align: center;
                            background-color: #1f394c;
                            text-transform: uppercase;
                            .dkg-ourservice-title{
                                font-weight: 700;
                                color: #ddd;
                                font-size: 14px;

                            }
                        }
                        .dkg-ourservice-body{
                            background-color: #fff;
                            padding: 10px 15px;
                            height: calc(100vh - 300px);
                            .dkg-service-desc{
                                font-size: 14px;
                                margin-bottom: 0;
                            }
                            &.dkg-ourservice-new-bodyCon{
                                padding: 10px 0 !important;
                                .dkg-service-desc{
                                    font-size: 14px;
                                    margin-bottom: 0;
                                    padding: 10px 15px !important;
                                    background-color: #fff;
                                    &.dkg-ourservice-desc{
                                        max-height: 121px;
                                        overflow-y: auto;
                                        div{
                                            p{
                                                margin-bottom: 0 !important;
                                                color: #666;
                                                span{
                                                    margin-bottom: 0 !important;
                                                    color: #666 !important;
                                                }
                                            }
                                        }
                                    }
                                   } 
                                   &.dkg-ourservice-new-bodyCon{
                                    padding: 0 !important;
                                    padding-bottom: 10px !important;
                                   }
                                .dkg-ourservice-tableCon{
                                    height: calc(100vh - 380px);
                                    overflow-y: auto;
                                    .dkg-ourservice-table{
                                        margin-bottom: 0;
                                        border-left: none;
                                        border-right: none;
                                        tbody{
                                            tr{
                                                td{
                                                    padding: 0.65rem 0.7rem;
                                                    &:nth-child(01){
                                                        text-align: center;
                                                        width: 45px;
                                                        border-left: none;
                                                    }
                                                    &:nth-child(02){
                                                        border-right: none;
                                                    }
                                                }
                                                &:last-child{
                                                    td{
                                                        border-bottom: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-services-middle-div{
                        background-color: #eaeaea;
                        height: 20px;
                    }
                    .dkg-ourservices-secondRow{
                        height: calc(100vh - 256px);
                        border: 1px solid #ddd;
                        width: 100%;
                        background-color: #f2f2f2;
                        border-radius: 5px;
                        .dkg-ourservice-header{
                            padding: 10px 15px;
                            border-bottom: 1px solid #ccc;
                            border-radius: 5px 5px 0 0;
                            display: block;
                            text-align: center;
                            background-color: #1f394c;
                            text-transform: uppercase;
                            .dkg-ourservice-title{
                                font-weight: 700;
                                color: #ddd;
                                font-size: 14px;

                            }
                        }
                        .dkg-ourservice-body{
                            background-color: #fff;
                            padding: 10px 15px;
                            height: calc(100vh - 300px);
                           .dkg-service-desc{
                            font-size: 0.95rem;
                            margin-bottom: 0;
                            padding: 10px 15px !important;
                            background-color: #fff;
                            &.dkg-ourservice-desc{
                                max-height: 121px;
                                overflow-y: auto;
                                div{
                                    p{
                                        margin-bottom: 0 !important;
                                        color: #666;
                                    }
                                }
                            }
                           } 
                           &.dkg-ourservice-new-bodyCon{
                            padding: 0 !important;
                            padding-bottom: 10px !important;
                            .dkg-ourservice-tableCon{
                                .dkg-ourservice-table{
                                    margin-bottom: 0;
                                    border-left: none;
                                    border-right: none;
                                    tbody{
                                        tr{
                                            td{
                                                padding: 0.65rem 0.7rem;
                                                &:nth-child(01){
                                                    text-align: center;
                                                    width: 45px;
                                                    border-left: none;
                                                }
                                                &:nth-child(02){
                                                    border-right: none;
                                                }
                                            }
                                            &:last-child{
                                                td{
                                                    border-bottom: none;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                           }
                        }
                    }
                }
                .dkg-socialmedia-tab{
                    margin-top: 1rem;
                    .dkg-social-box-cols{
                        .dkg-social-media-box{
                            background-color: #fff;
                            padding: 0.9rem;
                            border-radius: 0.25rem;
                            border: 1px solid #ddd;
                            margin-bottom: 0.94375rem;
                            position: relative;
                            .dkg-socail-media-icon{
                                .dkg-sm-imgcon{
                                    .dkg-sm-img{
                                        width: 2.5rem;
                                        height: 2.5rem;
                                    }
                                }
                            }
                            .dkg-send-urlbtn-con{
                                position: absolute;
                                top: 1rem;
                                right: 1.25rem;
                                div{
                                    div{
                                        .dkg-send-urlbtn{
                                            background-color: rgb(31, 57, 76);
                                            color: rgb(255, 255, 255);
                                            font-size: 0.85rem;
                                            border-radius: 5px;
                                            padding: 0.4rem 0.65rem;
                                            text-transform: capitalize;
                                        }
                                    }
                                }
                            }
                            .dkg-sm-nameurl-con{
                                padding: 0.5rem;
                                .dkg-timeflex{
                                    .dkg-social-media-name{
                                        display: block;
                                    }
                                }
                            }
                        }
                    }
                }
                .dk-otherprojectMain{
                    margin-top: 1rem;
                    .dkg-other-project-header{
                        .dkg-otherprject-page-title{
                            font-size: 0.95rem;
                            text-align: justify;
                        }
                    }
                    .dkg-projectsbox-Con{
                        margin-top: 1rem;
                        .dkg-projectbox-mainCon{
                            .dk-compnyproject-cols{
                                .dkg-compnyproject-box{
                                    margin-bottom: 1rem;
                                    a{
                                        background-color: #fff;
                                        display: inline-block;
                                        height: 115px;
                                        border-width: 1px;
                                        border-style: solid;
                                        border-color: rgb(221, 221, 221);
                                        border-image: initial;
                                        border-radius: 0.5rem 0.5rem 0px 0px;
                                        padding: 0.94rem 1.25rem;
                                        width: 100%;
                                        .dk-visitLogo {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            height: 100%;
                                            img {
                                                object-fit: cover;
                                                width: 100%;
                                                max-width: 170px !important;
                                                margin: auto;
                                            }
                                        }
                                    }
                                    .dkg-project-content {
                                        background-color: rgb(255, 255, 255);
                                        max-height: inherit;
                                        min-height: inherit;
                                        border-width: 1px 1px 1px;
                                        border-style: none solid solid;
                                        border-color: rgb(221, 221, 221) rgb(221, 221, 221) rgb(221, 221, 221);
                                        border-image: initial;
                                        border-radius: 0px 0px 0.5rem 0.5rem;
                                        border-top: none;
                                        padding: 0.75rem 0.94375rem 1.95rem;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: space-between;
                                        .dkg-content-title-desc {
                                            padding-bottom: 0.85rem;
                                        }
                                        .dkg-content-bottom-sec-Con{
                                            .dkg-roject-cmplition-Con {
                                                align-items: center;
                                                display: flex;
                                                justify-content: center;
                                                width: 100%;
                                                .dkg-project-comletetion {
                                                    background-color: rgb(234, 234, 234);
                                                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 3px, rgba(0, 0, 0, 0.1) 0px 2px 4px;
                                                    color: rgb(51, 51, 51);
                                                    max-width: 100%;
                                                    width: 100%;
                                                    border-width: 1px;
                                                    border-style: solid;
                                                    border-color: rgb(204, 204, 204);
                                                    border-image: initial;
                                                    margin: 0px auto;
                                                    padding: 7px 10px;
                                                }
                                            }
                                        }
                                    }
                                    .dkg-project-btn-progess-Con{
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
                .dkg-aboutus-mainCon{
                    &.dkg-contactus-mainCon{
                        .dkg-other-project-header{
                           margin-top: 1rem;
                           .dkg-otherprject-title{
                             font-size: 0.95rem;
                             text-align: justify;
                           }
                        }
                        .dkg-contact-card{
                            background-color: #243b4c;
                            background-clip: border-box;
                            border-radius: 1px;
                            border: 1px solid #243b4c;
                            width: 100%;
                            max-width: 100%;
                            margin-top: 1rem;
                            .dkg-contact-cardRow{
                                .dkg-contact-card-leftCol{
                                    .dkg-contact-card-detail{
                                        background: #fff;
                                        /* margin: 0 15% 0 -30%; */
                                        border: 1px solid #ccc;
                                        padding: 0.5rem 0.75rem;
                                        position: relative;
                                        top: 1rem;
                                        margin: 0 1rem;                   
                                        .dkg-card-detail-title{
                                            margin: 0 0 15px;
                                            font-size: 18px;
                                            color: #25455a;
                                            font-weight: 600;
                                        }
                                        .dkg-contact-card-ul{
                                            margin: 0;
                                            padding: 0 0 0 0px;
                                            list-style: none;
                                            .dkg-contact-card-li{
                                                margin: 0;
                                                padding: 0 0 15px 20px;
                                                color: #25455a;
                                                position: relative;
                                                i{
                                                    position: absolute;
                                                    left: 0;
                                                    top: 5px
                                                }
                                                a{
                                                    color: #333;
                                                    font-size: 0.85rem;
                                                }
                                            }
                                        }
                                        .dkg-contact_social{
                                            padding-left: 22px;
                                            a{
                                                padding-right: 8px;
                                                img{
                                                    width: 35px;
                                                    height: 35px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-contact-card-rightCol{
                                    color: #25455a;
                                    form{
                                        padding: 17px;
                                        padding-top: 10px;
                                        background-color: #fff;
                                        margin: 14px;
                                        .row{
                                            div{
                                                h2{
                                                    font-size: 1.1rem;
                                                    color: #485864;
                                                    font-weight: 600;
                                                    margin-top: 0.5rem;
                                                    margin-bottom: 0.75rem;
                                                    text-align: center;
                                                }
                                            }
                                            .form-group{
                                                margin-bottom: 1rem;
                                                .form-control{
                                                    height: 34px;
                                                    padding: 0.1rem 0.5rem;
                                                    font-size: 0.80rem;
                                                    background-color: #fff;
                                                    line-height: 1.3;
                                                    border: 1px solid #ddd;
                                                    text-align: left;
                                                }
                                                textarea.form-control {
                                                    height: 80px;
                                                    text-align: left;
                                                }
                                            }
                                            
                                        }
                                        p{
                                            margin-bottom: 1rem;
                                            color: #444;
                                            font-size: 0.711rem;
                                            display: flex;
                                            flex-wrap: nowrap;
                                            flex-direction: row;
                                            justify-content: center;
                                            width: 100%;
                                            input[type="checkbox"] {
                                                cursor: pointer;
                                                appearance: auto;
                                                background: #fff;
                                                border-radius: 0.25rem;
                                                box-sizing: border-box;
                                                position: relative;
                                                width: 14px;
                                                height: 14px;
                                                border-width: 0;
                                                transition: all 0.3s linear;
                                                border: 1px solid #ddd;
                                                margin-right: 0.5rem;
                                                border-radius: 0;
                                            }
                                            a{
                                                color: rgb(93, 157, 226);
                                                margin: 0 0.5rem;
                                            }
                                        }
                                        .btn-light {
                                            color: #fff;
                                            background-color: #243b4c !important;
                                            border-color: #243b4c;
                                            border: 1px solid transparent;
                                            padding: 0.375rem 0.75rem;
                                            font-size: 0.85rem;
                                            line-height: 1.5;
                                            border-radius: 0.25rem;
                                            text-align: center;
                                            display: block;
                                            width: 100%;
                                            max-width: 85px;
                                            margin: 0 auto;
                                        }
                                        .form-group{
                                            textarea{
                                                text-align: left;
                                                font-size: 13px;
                                                padding: 0.25rem 0.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.dkg-cand-resumes-pageCon{
            margin-top: 35px;
            height: calc(100vh - 49px);
            overflow-y: auto;
            .dkg-cand-resumes-moCon{
                display: block;
                padding-bottom: 1.5rem;
                .dkg-resumes-header-centerCon{
                    padding-top: 1.25rem;
                    width: 100%;
                    .dkg-resumes-DropdownCon{
                        width: 100%;
                        .dkg-resumes-Dropdown{
                            width: 100%;
                            max-width: 70%;
                            margin: 0 auto;
                            .dkg-resumes-Dropdown-toggle{
                                text-transform: capitalize;
                                box-shadow: none;
                                background-color: #fff;
                                border: 1px solid #ddd;
                                font-size: 0.85rem;
                                padding: 0.45rem 0.7rem;
                                margin: 0;
                                width: 100%;
                                text-align: left;
                                position: relative;
                                &::after{
                                    position: absolute;
                                    top: 1.02rem;
                                    right: 0.85rem;
                                }
                            }
                            .dkg-resumes-Dropdown-Menu{
                                border-radius: 0;
                                min-width: 180px;
                                height: 230px;
                                overflow-y: auto;
                                padding: 0;
                                border-radius: 0;
                                .dkg-abouts-Dropdown-Item,
                                .dropdown-item{
                                    border-bottom: 1px solid #ddd;
                                    font-size: 0.85rem;
                                    padding: 0.5rem 0.95rem;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }
                }
                .dkg-aboutus-header{
                    padding: 10px 15px;
                    border-bottom: 1px solid #cdcbcb;
                    border-radius: 5px 5px 0 0;
                    display: block;
                    text-align: center;
                    background-color: #1f394c;
                    text-transform: uppercase;
                    .dkg-contact-title{
                        font-weight: 700;
                        color: #ddd;
                        font-size: 14px;
                    }
                }
                .dkg-desiredjob-header{
                    margin-top: 1rem;
                    padding: 10px 15px;
                    border-bottom: 1px solid #ccc;
                    border-radius: 5px 5px 0 0;
                    display: block;
                    text-align: center;
                    background-color: #1f394c;
                    text-transform: uppercase;
                    .dkg-contact-title{
                        font-weight: 600;
                        font-size: 18px;
                        text-transform: uppercase;   
                        margin-bottom: 0;    
                        color: #d7d7d7; 
                    }
                    .dkg-desired-info{
                        // position: absolute;
                        // left: 55%;
                        // top: 2px;
                        transform: translate(55% , 2%);
                        background-color: #eaeaea;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        border: 1px solid #ddd;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // margin-left: 0.5rem;
                        svg{
                            width: 11px;
                            height: 11px;
                        }
                    }
                }
                .dkg-aboutus-mainCon{
                    background-color: #fff;
                    border: 1px solid #ddd;
                    height: calc(100vh - 245px);
                    overflow-y: auto;
                    &.dkg-contactus-mainCon{
                        overflow-y: hidden;
                        .dkg-other-project-header{
                            .dkg-otherprject-title{
                                background-color: #f2f2f2;
                                color: #666;
                            }
                        }
                    }
                    &.dkg-socail-media-mainCon{
                        height: calc(100vh - 195px) !important;
                        background-color: transparent;
                        border: none;
                        overflow-y: hidden;
                    }
                    .dkg-other-project-header{
                        .dkg-otherprject-title{
                            font-size: 0.95rem;
                            width: 100%;
                            text-align: justify;
                            color: #333;
                            padding: 6px 10px;
                            background-color: #f2f2f2;
                            min-height: 55px;
                            border-bottom: 1px solid #ddd;
                            line-height: 25px;
                        }
                    } 
                    .dkg-contact-card{
                        background-color: #243b4c;
                        background-clip: border-box;
                        border-radius: 1px;
                        border: 1px solid #243b4c;
                        width: 100%;
                        max-width: 75%;
                        margin: 0 auto;
                        margin-top: 40px;
                        .dkg-contact-cardRow{
                            .dkg-contact-card-leftCol{
                                .dkg-contact-card-detail{
                                    background: #fff;
                                    margin: 0 15% 0 -30%;
                                    border: 1px solid #ccc;
                                    padding: 30px 30px 5px;    
                                    position: relative;
                                    top: -15px;                        
                                    .dkg-card-detail-title{
                                        margin: 0 0 15px;
                                        font-size: 18px;
                                        color: #25455a;
                                        font-weight: 600;
                                    }
                                    .dkg-contact-card-ul{
                                        margin: 0;
                                        padding: 0 0 0 0px;
                                        list-style: none;
                                        .dkg-contact-card-li{
                                            margin: 0;
                                            padding: 0 0 15px 15px;
                                            color: #25455a;
                                            position: relative;
                                            i{
                                                position: absolute;
                                                left: 0;
                                                top: 5px
                                            }
                                            a{
                                                color: #333;
                                            }
                                        }
                                    }
                                    .dkg-contact_social{
                                        padding-left: 22px;
                                        a{
                                            padding-right: 8px;
                                            img{
                                                width: 35px;
                                                height: 35px;
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-contact-card-rightCol{
                                color: #25455a;
                                form{
                                    padding: 30px;
                                    padding-top: 10px;
                                    .row{
                                        div{
                                            h2{
                                                margin: 0 0 15px;
                                                font-size: 18px;
                                                color: #243b4c;
                                            }
                                        }
                                        .form-group{
                                            margin-bottom: 1rem;
                                            .form-control{
                                                height: 34px;
                                                padding: 0.1rem 0.5rem;
                                                font-size: 13px;
                                                background-color: #fff;
                                                line-height: 1.3;
                                                border: #fff;
                                                text-align: left;
                                            }
                                            textarea.form-control {
                                                height: 80px;
                                                text-align: left;
                                            }
                                        }
                                        
                                    }
                                    p{
                                        margin-bottom: 1rem;
                                        color: #ddd;
                                        font-size: 14px;
                                        input[type="checkbox"] {
                                            cursor: pointer;
                                            appearance: auto;
                                            background: #fff;
                                            border-radius: 0.25rem;
                                            box-sizing: border-box;
                                            position: relative;
                                            width: 14px;
                                            height: 14px;
                                            border-width: 0;
                                            transition: all 0.3s linear;
                                            border: 1px solid #ddd;
                                            margin-right: 0.5rem;
                                            border-radius: 0;
                                        }
                                        a{
                                            color: rgb(93, 157, 226);
                                            margin: 0 0.5rem;
                                        }
                                    }
                                    .btn-light {
                                        color: #212529;
                                        background-color: #f8f9fa;
                                        border-color: #f8f9fa;
                                        border: 1px solid transparent;
                                        padding: 0.375rem 0.75rem;
                                        font-size: 1rem;
                                        line-height: 1.5;
                                        border-radius: 0.25rem;
                                    }
                                    .form-group{
                                        textarea{
                                            text-align: left;
                                            font-size: 13px;
                                            padding: 0.25rem 0.5rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.dkg-otherprojects-main{
                        // background: url('../../../../../assets/images/login_bg.jpg') no-repeat;
                        background-color: #fff;
                        background-size: cover;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-direction: column;
                        height: calc(100vh - 220px);
                        overflow-y: hidden;
                        .dk-otherprojectMain { 
                            width: 100%;  
                            .dkg-other-project-header{
                                .dkg-otherprject-page-title{
                                    font-size: 0.95rem;
                                    width: 100%;
                                    text-align: justify;
                                    color: #666;
                                    padding: 6px 10px;
                                    background-color: #f2f2f2;
                                    min-height: 55px;
                                    border-bottom: 1px solid #ddd;
                                    line-height: 25px;
                                }
                            }                         
                            .dkg-visitwebaite-cardCon {
                                width: 100%;
                                margin-top: 10px;
                                padding: 0 10px;
                                height: calc(100vh - 300px);
                                overflow-y: auto;
                                // overflow: hidden;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                .dk-otherproject-cols {
                                    width: 100%;
                                    height: 50%;
                                    // max-width: 1100px;
                                    margin: auto;
                                    display: flex;
                                    justify-content: center;
                                    margin: 20px auto;
                        
                                    &.dkg-top-level-boxCon {
                                        max-width: 366px;
                                        margin-bottom: 30px;
                                    }
                        
                                    .dk-otherproject-box {
                                        display: inline-block;
                                        margin: 0 10px;
                                        width: 100%;
                        
                                        a {
                                            padding: 30px 20px;
                                            background: #fefefe;
                                            border-radius: 8px 8px 0 0;
                                            height: 90%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dk-visitLogo {
                                                text-align: center;
                                                display: block;
                        
                                                img {
                                                    width: 100%;
                                                    max-width: 150px;
                                                    margin: auto;
                                                    transform: scale(1);
                                                    transition: all 0.5s;
                                                    object-fit: cover;
                                                }
                        
                                                .dkg-adminboard-title {
                                                    color: #333;
                                                    transition: all 0.5s;
                                                    font-weight: 700;
                                                    font-size: 20px;
                                                }
                                            }
                                            
                                            &:hover {
                                                img {
                                                    transform: scale(1.1);
                                                    transition: all 0.5s;
                                                }
                                            }
                        
                                            .title {
                                                font-size: 14px;
                                                text-transform: uppercase;
                                                color: #26596d;
                                                font-weight: 600;
                                                text-align: center;
                                                display: block;
                                                margin-top: 10px;
                                            }
                                            
                                        }
                                        .dkg-visit-prjbtn{
                                            width: 100%;
                                            border-top: 1px solid #ddd;
                                            padding: 7px 10px;
                                            display: block;
                                            background-color: #f2f2f2;
                                            color: #333;
                                            text-transform: capitalize;
                                            border-radius: 0 0 8px 8px;
                                            margin: 0;
                                            box-shadow: none;
                                            font-size: 14px;
                                            .dkg-resume-icon {
                                                margin-left: 0.5rem;
                                            }
                                            &:hover{
                                                background-color: #eaeaea;
                                                transition: all 0.5s;
                                            }
                                        }
                                        .dkg-project-btn-progess-Con{
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                            border-top: 1px solid #ddd;
                                            // padding: 7px 10px;
                                            display: block;
                                            background-color: #f2f2f2;
                                            color: #333;
                                            text-transform: capitalize;
                                            border-radius: 0 0 8px 8px;
                                            .dkg-project-infobtn{
                                                width: 30%;
                                                svg{
                                                    width: 23px;
                                                    height: 23px;
                                                }
                                            }
                                            .dkg-info-icon{
                                                width: 50%;
                                                border-right: 1px solid #ddd;
                                                padding: 7px 10px;
                                                text-align: center;
                                                cursor: pointer;
                                                color: #555;
                                                &:hover{
                                                    box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                    background-color: #eaeaea;
                                                    color: #3c97b6;
                                                }
                                                svg{
                                                    width: 20px;
                                                    height: 20px;
                                                }
                                            }
                                            .dkg-progress-barCon{
                                                width: 50%;
                                                padding: 7px 10px;
                                                text-align: center;
                                                color: #555;
                                            }

                                        }
                                    }
                                    // &:first-child{
                                    //     margin-bottom: 0;
                                    // }
                                    // &:last-child{
                                    //     margin-top: 0;
                                    // }
                                }
                            }
                            .dkg-projectsbox-Con{
                                width: 100%;
                                padding: 15px;
                                padding-left: 10px;
                                padding-right: 0;
                                background-color: #1f394c;
                                height: calc(100vh - 285px);
                                overflow-y: auto;
                                .dkg-projectbox-mainCon{
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    .dk-compnyproject-cols{
                                        display: flex;
                                        width: 100%;
                                        .dkg-compnyproject-box {
                                            display: inline-block;
                                            margin: 10px;
                                            width: 100%;
                                            a {
                                                padding: 15px 20px;
                                                background-color: #fff;
                                                border-radius: 8px 8px 0 0;
                                                height: 115px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                border: 1px solid #ddd;
                                                .dk-visitLogo {
                                                    text-align: center;
                                                    display: block;
                            
                                                    img {
                                                        width: 100%;
                                                        max-width: 150px !important;
                                                        margin: auto;
                                                        // transform: scale(1);
                                                        // transition: all 0.5s;
                                                        object-fit: cover;
                                                    }
                            
                                                    .dkg-adminboard-title {
                                                        color: #333;
                                                        transition: all 0.5s;
                                                        font-weight: 700;
                                                        font-size: 20px;
                                                    }
                                                }
                                                
                                                // &:hover {
                                                //     img {
                                                //         transform: scale(1.1);
                                                //         transition: all 0.5s;
                                                //     }
                                                // }
                            
                                                .title {
                                                    font-size: 14px;
                                                    text-transform: uppercase;
                                                    color: #26596d;
                                                    font-weight: 600;
                                                    text-align: center;
                                                    display: block;
                                                    margin-top: 10px;
                                                }
                                                
                                            }
                                            .dkg-project-content{
                                                background-color: #eaeaea;
                                                padding: 30px 15px;
                                                padding-top: 10px;
                                                border-radius: 0 0 5px 5px;
                                                border: 1px solid #ddd;
                                                border-top: none;
                                                min-height: 170px;
                                                max-height: 170px;
                                                .dkg-content-title-desc{
                                                    padding-bottom: 0.93rem;
                                                    .dkg-pj-content-title{
                                                        color: #333;
                                                        font-size: 1rem;
                                                        font-weight: 600;
                                                        margin-bottom: 0.75rem;
                                                        text-align: center;
                                                        display: none;
                                                    }
                                                    .dkg-pj-content-desc{
                                                        color: #333;
                                                        font-size: 0.85rem;
                                                        text-align: center;
                                                        margin-bottom: 0.5rem;
                                                    }
                                                }
                                                .dkg-content-bottom-sec{
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: flex-start;
                                                    .dkg-pj-leftCon{
                                                        .dkg-pj-bottom-title{
                                                            font-size: 0.9rem;
                                                            color: #333;
                                                        }
                                                        .dkg-roject-cmplition-Con{
                                                            width: 100%;
                                                            display: flex;
                                                            align-items: center;
                                                            justify-content: center;
                                                            .dkg-project-comletetion{
                                                                width: 100%;
                                                                max-width: 90%;
                                                                margin: 0 auto;
                                                                border: 1px solid #ddd;
                                                                color: green;
                                                            }
                                                        }
                                                    }
                                                    .dkg-pj-rightCon{
                                                        display: none;
                                                        .dkg-pj-bottom-btn{
                                                            display: inline-block;
                                                            border: none;
                                                            background-color: green;
                                                            color: #fff;
                                                            padding: 5px 9px;
                                                            border-radius: 5px;
                                                            font-size: 0.85rem;

                                                        }
                                                    }
                                                }
                                                .dkg-content-bottom-sec-Con{
                                                    .dkg-roject-cmplition-Con{
                                                        width: 100%;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                        .dkg-project-comletetion{
                                                            width: 100%;
                                                            max-width: 75%;
                                                            margin: 0 auto;
                                                            border: 1px solid #ccc;
                                                            color: #333;
                                                            padding: 7px 10px;
                                                            background-color: #eaeaea;
                                                            box-shadow: 0 0px 3px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
                                                            &:focus{
                                                                border:none;
                                                                outline: none;
                                                            }
                                                            &:hover{
                                                                background-color: #f2f2f2;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-visit-prjbtn{
                                                width: 100%;
                                                border-top: 1px solid #ddd;
                                                padding: 7px 10px;
                                                display: block;
                                                background-color: #f2f2f2;
                                                color: #333;
                                                text-transform: capitalize;
                                                border-radius: 0 0 8px 8px;
                                                margin: 0;
                                                box-shadow: none;
                                                font-size: 14px;
                                                .dkg-resume-icon {
                                                    margin-left: 0.5rem;
                                                }
                                                &:hover{
                                                    background-color: #eaeaea;
                                                    transition: all 0.5s;
                                                }
                                            }
                                            .dkg-project-btn-progess-Con{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                border-top: 1px solid #ddd;
                                                // padding: 7px 10px;
                                                background-color: #f2f2f2;
                                                color: #333;
                                                text-transform: capitalize;
                                                border-radius: 0 0 8px 8px;
                                                display: none !important;
                                                .dkg-project-infobtn{
                                                    width: 30%;
                                                    svg{
                                                        width: 23px;
                                                        height: 23px;
                                                    }
                                                }
                                                .dkg-info-icon{
                                                    width: 50%;
                                                    border-right: 1px solid #ddd;
                                                    padding: 7px 10px;
                                                    text-align: center;
                                                    cursor: pointer;
                                                    color: #555;
                                                    &:hover{
                                                        box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
                                                        background-color: #eaeaea;
                                                        color: #3c97b6;
                                                    }
                                                    svg{
                                                        width: 20px;
                                                        height: 20px;
                                                    }
                                                }
                                                .dkg-progress-barCon{
                                                    width: 50%;
                                                    padding: 7px 10px;
                                                    text-align: center;
                                                    color: #555;
                                                }

                                            }
                                            &:last-child{
                                                margin-right: 15px;
                                            }
                                        }
                                        &:last-child{
                                            padding-bottom: 11px !important;
                                        }

                                    }

                                }
                            }
                        }
                    }
                    .dkg-myacc-companytab{
                        .dkg-other-project-header{
                            width: 100%;
                            .dkg-other-prjects-title{
                                font-size: 0.95rem;
                                width: 100%;
                                text-align: justify;
                                color: #666;
                                padding: 6px 10px;
                                background-color: #f2f2f2;
                                min-height: 55px;
                                border-bottom: 1px solid #ddd;
                                line-height: 25px;
                            }
                        }
                        .dkg-company-table-con{
                            // height: calc(100vh - 247px);
                            // overflow-y: auto;
                            height: calc(100vh - 247px);
                            .dkg-company-table{
                                border: none !important;
                                tbody{
                                    tr{
                                        td{
                                            border: 1px solid #ddd;
                                            padding: 8px;
                                            text-align: left;
                                            background-color: #fff;
                                            vertical-align: middle;
                                            &:nth-child(1) {
                                                width: 22%;
                                                background-color: #ddd;
                                                color: #ccc;
                                                font-size: 14px !important;
                                                text-transform: uppercase;
                                                border: 1px solid #cdcbcb;
                                                border-left: none;
                                                color: #333;

                                            }
                                            &:nth-child(02){
                                                border-right: none;
                                            }
                                        }
                                        // &:first-child{
                                        //     td{
                                        //         border-top: none;
                                        //     }
                                        // }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-socialmedia-tab{
                        height: calc(100vh - 255px);
                        .dkg-social-box-cols{
                            padding: 0;
                            height: 100%;
                            .dkg-social-media-box{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                border: 1px solid #ddd;
                                background-color: #fff;
                                margin-bottom: 1rem;
                                height: 100%;
                                 max-height: 19%;
                                .dkg-socail-media-icon{
                                    width: 120px;
                                    height: 85px;
                                    border-right: 1px solid #ddd;
                                    .dkg-sm-imgcon{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 100%;
                                        width: 100%;
                                        .dkg-sm-img{
                                            width: 55px;
                                            height: 55px;
                                        }
                                    }
                                }
                                .dkg-send-urlbtn-con{
                                    width: 140px;
                                    height: 85px;
                                    border-left: 1px solid #ddd;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    div{
                                        div{
                                            .dkg-send-urlbtn{
                                                color: #fff;
                                                background-color: #1f394c;
                                                padding: 7px 10px;
                                                border-radius: 5px;
                                                font-size: 14px;
                                                &:hover{
                                                    background-color: #3C97B6;
                                                }
                                            
                                            } 
                                        }
                                    }
                                }
                                .dkg-sm-nameurl-con{
                                    width: 100%;
                                    height: 85px;
                                    .dkg-timeflex{
                                        width: 100%;
                                        .dkg-social-media-name{
                                            display: block;
                                            height: 43px;
                                            line-height: 42px;
                                            font-size: 13px;
                                            border-bottom: 1px solid #ddd;
                                            text-align: center;
                                            width: 100%;
                                            
                                        }
                                        .dkg-social-media-url{
                                            display: block;
                                            height: 42px;
                                            line-height: 42px;
                                            font-size: 13px;
                                            text-align: center;
                                            width: 100%;
                                        }
                                    }
                                }
                                // &:first-child{
                                //     margin-top: 1.75rem;
                                // }
                                &:last-child{
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .dkg-ourservices-mainCon{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        .dkg-ourservices-firstRow{
                            height: calc(100vh - 256px);
                            border: 1px solid #ddd;
                            width: 100%;
                            margin-right: 0;
                            background-color: #f2f2f2;
                            border-radius: 5px;
                            margin-left: 10px;
                            .dkg-ourservice-header{
                                padding: 10px 15px;
                                border-bottom: 1px solid #ccc;
                                border-radius: 5px 5px 0 0;
                                display: block;
                                text-align: center;
                                background-color: #1f394c;
                                text-transform: uppercase;
                                .dkg-ourservice-title{
                                    font-weight: 700;
                                    color: #ddd;
                                    font-size: 14px;

                                }
                            }
                            .dkg-ourservice-body{
                                background-color: #fff;
                                padding: 10px 15px;
                                height: calc(100vh - 300px);
                                .dkg-service-desc{
                                    font-size: 14px;
                                    margin-bottom: 0;
                                }
                                &.dkg-ourservice-new-bodyCon{
                                    padding: 10px 0 !important;
                                    .dkg-service-desc{
                                        font-size: 14px;
                                        margin-bottom: 0;
                                        padding: 10px 15px !important;
                                        background-color: #f2f2f2;
                                        &.dkg-ourservice-desc{
                                            max-height: 121px;
                                            overflow-y: auto;
                                            div{
                                                p{
                                                    margin-bottom: 0 !important;
                                                    color: #666;
                                                    span{
                                                        margin-bottom: 0 !important;
                                                        color: #666 !important;
                                                    }
                                                }
                                            }
                                        }
                                       } 
                                       &.dkg-ourservice-new-bodyCon{
                                        padding: 0 !important;
                                        padding-bottom: 10px !important;
                                       }
                                    .dkg-ourservice-tableCon{
                                        height: calc(100vh - 380px);
                                        overflow-y: auto;
                                        .dkg-ourservice-table{
                                            margin-bottom: 0;
                                            border-left: none;
                                            border-right: none;
                                            tbody{
                                                tr{
                                                    td{
                                                        padding: 0.65rem 0.7rem;
                                                        &:nth-child(01){
                                                            text-align: center;
                                                            width: 45px;
                                                            border-left: none;
                                                        }
                                                        &:nth-child(02){
                                                            border-right: none;
                                                        }
                                                    }
                                                    &:last-child{
                                                        td{
                                                            border-bottom: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dkg-services-middle-div{
                            background-color: #eaeaea;
                            height: 20px;
                        }
                        .dkg-ourservices-secondRow{
                            height: calc(100vh - 256px);
                            border: 1px solid #ddd;
                            width: 100%;
                            margin-left: 7px;
                            background-color: #f2f2f2;
                            border-radius: 5px;
                            margin-right: 15px;
                            .dkg-ourservice-header{
                                padding: 10px 15px;
                                border-bottom: 1px solid #ccc;
                                border-radius: 5px 5px 0 0;
                                display: block;
                                text-align: center;
                                background-color: #1f394c;
                                text-transform: uppercase;
                                .dkg-ourservice-title{
                                    font-weight: 700;
                                    color: #ddd;
                                    font-size: 14px;

                                }
                            }
                            .dkg-ourservice-body{
                                background-color: #fff;
                                padding: 10px 15px;
                                height: calc(100vh - 300px);
                               .dkg-service-desc{
                                font-size: 0.95rem;
                                margin-bottom: 0;
                                padding: 10px 15px !important;
                                background-color: #f2f2f2;
                                &.dkg-ourservice-desc{
                                    max-height: 121px;
                                    overflow-y: auto;
                                    div{
                                        p{
                                            margin-bottom: 0 !important;
                                            color: #666;
                                        }
                                    }
                                }
                               } 
                               &.dkg-ourservice-new-bodyCon{
                                padding: 0 !important;
                                padding-bottom: 10px !important;
                                .dkg-ourservice-tableCon{
                                    .dkg-ourservice-table{
                                        margin-bottom: 0;
                                        border-left: none;
                                        border-right: none;
                                        tbody{
                                            tr{
                                                td{
                                                    padding: 0.65rem 0.7rem;
                                                    &:nth-child(01){
                                                        text-align: center;
                                                        width: 45px;
                                                        border-left: none;
                                                    }
                                                    &:nth-child(02){
                                                        border-right: none;
                                                    }
                                                }
                                                &:last-child{
                                                    td{
                                                        border-bottom: none;

                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                               }
                            }
                        }
                    }
                    &.dkg-aboutus-servicesCon{
                        border: none;
                        height: calc(100vh - 210px);
                        overflow-y: hidden;
                        background-color: transparent;
                    }
                    &.dkg-company-tabpane-Con{
                        height: calc(100vh - 245px);
                        overflow-y: hidden;
                        .dkg-company-table-con{
                            height: calc(100vh - 287px);
                            overflow-y: auto;
                            .dkg-company-table {
                                border: none !important;
                                height: 97%;
                                tbody{
                                    tr{
                                        &:last-child{
                                            color: #234158;
                                            td{
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
                .dkg-desired-job-mainCon{
                    background-color: #fff;
                    border: 1px solid #ccc;
                    height: calc(100vh - 205px);
                    .dkg-desired-textarea-Con{
                        .dkg-desired-textarea{
                            background-color: #f2f2f2;
                            height: 55px;
                            padding: 7px 15px;
                            display: flex;
                            align-items: center;
                            justify-content:center;
                            border: none;
                            font-size: 0.9rem;
                        }
                    }
                    .dkg-desired-center-sec{
                        border-top: 1px solid #ddd;
                        background-color: #fff;
                        // height: calc(100vh - 410px);
                        .dkg-mailbox-editor-mainCon{
                            max-height: inherit;
                            .jodit-react-container{
                                margin-top: 0;
                                .jodit-container{
                                    height: calc(100vh - 412px) !important;
                                    border: none;
                                    border-bottom: 1px solid #dadada;
                                    .jodit-toolbar__box{
                                        border-top: none;
                                    }
                                    .jodit-workplace{
                                        .jodit-wysiwyg{
                                            min-height: inherit !important;
                                        }
                                    }
                                }
                            }
                        }
                        &.dkg-desired-job-centersec{
                            width: 100%;
                            display: flex;
                            .dkg-desired-job-leftCon{
                                width: 50%;
                                border-right: 1px solid #ddd;
                                height: calc(100vh - 329px);
                                .dkg-desired-job-titleCon{
                                    padding: 65px 30px 10px 30px;
                                    .dkg-desired-job-title{
                                        font-weight: 600;
                                        font-size: 19px;
                                        text-transform: uppercase;
                                        margin-bottom: 0;
                                        color: #333;
                                        text-transform: capitalize;
                                    }
                                }
                                .dkg-selected-tagCon{
                                    height: 61%;
                                    border-bottom: 1px solid #ddd;    
                                    .dkg-selected-title{
                                        color: #333;
                                        display: block;
                                        font-size: 1.15rem;
                                        padding: 7px 10px;
                                        position: relative;
                                        width: 100%;
                                        font-weight: 600;
                                        text-align: center;
                                        border-bottom: 1px solid #ddd;
                                    }
                                    .dkg-st-tag-job-btn-tagCon{
                                        align-items: flex-start;
                                        display: flex;
                                        justify-content: flex-start;
                                        width: 100%;
                                        flex-wrap: wrap;
                                        padding: 5px 7px;
                                        .dkg-st-tag-btntext{
                                            display: inline-block;
                                            border-radius: 12px;
                                            color: #445;
                                            font-size: 13px!important;
                                            padding: 6px 22px;
                                            text-transform: capitalize;
                                            background-color: #f2f2f2 !important;
                                            border: 1px solid #ddd;
                                            box-shadow: none;
                                            position: relative;
                                            margin-top: 8px;
                                                .dkg-tag-icon {
                                                    color: #445;
                                                    cursor: pointer;
                                                    display: none;
                                                    margin-left: 5px;
                                                    padding: 0;
                                                    position: absolute;
                                                    right: 5px;
                                                    top: 6px;
                                                }
                                                &:hover{
                                                    .dkg-tag-icon{
                                                        display: block;
                                                        i{
                                                            color: #445;
                                                        }
                                                    }
                                                }
                                            }
                                            
                                        }
                                }
                                .dkg-dj-rightsecond-cols{
                                    height: 40%;
                                    width: 100%;
                                    background-color: #DCE5E9;
                                    display: flex;
                                    align-items: center;
                                    flex-direction: column;
                                    justify-content: center;
                                    .dkg-desired-job-inputCon{
                                        width: 100%;
                                        .dkg-createnew-tag-title{
                                            color: #333;
                                            display: block;
                                            font-size: 1.15rem;
                                            padding: 7px 10px;
                                            position: relative;
                                            width: 100%;
                                            font-weight: 600;
                                            text-transform: uppercase;
                                            text-align: center;
                                        }                                    
                                        
                                        .dkg-desired-job-inputCon{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex-direction: column;
                                            // height: 90%;
                                            .dkg-desired-job-input{
                                                border: 1px solid #ddd;
                                                background-color: #fff;
                                                width: 100%;
                                                max-width: 90%;
                                                text-align: left;
                                                margin-left: 10px;

                                            }
                                            .dkg-desired-job-save-btnCon{
                                                margin-top: 10px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                div{
                                                    div{
                                                        .dkg-desired-job-save-btn{
                                                            background-color: #26597d;
                                                            // text-transform: capitalize;
                                                            &:hover{
                                                                color: #fff;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-createcv-textarea{
                                width: 100%;
                                height: calc(100vh - 413px);
                                padding: 7px 15px;
                                border: none;
                                border-bottom: 1px solid #ddd;
                                &:focus{
                                    outline: 0;
                                }
                                &.dkg-profile-textareCon{
                                    height: 250px ;
                                }
                            }
                            &.dkg-profile-summCon{
                                background-color: #eaeaea;
                            }
                        }
                        .dkg-desired-job-rightCon{
                            width: 50%;
                            height: calc(100vh - 328px);
                            .dkg-dj-rightfirst-cols{
                                height: 100%;
                                border-bottom: 1px solid #ddd;
                                .dkg-saved-cv-tag-title{
                                    color: #333;
                                    display: block;
                                    font-size: 1.15rem;
                                    padding: 7px 10px;
                                    position: relative;
                                    width: 100%;
                                    font-weight: 600;
                                    text-align: center;
                                    border-bottom: 1px solid #ddd;
                                }
                                .dkg-desired-job-btn-tagCon{
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    width: 100%;
                                    flex-wrap: wrap;
                                    padding: 5px 7px;
                                    .dkg-desired-btntext{ 
                                    display: inline-block;
                                    border-radius: 12px;
                                    color: #fff;
                                    font-size: 13px;
                                    padding: 6px 22px;
                                    text-transform: capitalize;
                                    background-color: #366995 !important;
                                    box-shadow: none;
                                    position: relative;
                                    margin-top: 8px;
                                    .dkg-tag-icon {
                                        color: #fff;
                                        cursor: pointer;
                                        display: none;
                                        margin-left: 5px;
                                        padding: 0;
                                        position: absolute;
                                        right: 5px;
                                        top: 6px;
                                    }
                                    &:hover{
                                        .dkg-tag-icon{
                                            display: block;
                                            i{
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            
                            
                                    }
                            }
                            .dkg-dj-rightsecond-cols{
                                height: 40%;
                                width: 100%;
                                background-color: #DCE5E9;
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                justify-content: center;
                                .dkg-desired-job-inputCon{
                                    width: 100%;
                                    .dkg-createnew-tag-title{
                                        color: #333;
                                        display: block;
                                        font-size: 1.15rem;
                                        padding: 7px 10px;
                                        position: relative;
                                        width: 100%;
                                        font-weight: 600;
                                        text-transform: uppercase;
                                        text-align: center;
                                    }                                    
                                    
                                    .dkg-desired-job-inputCon{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                        // height: 90%;
                                        .dkg-desired-job-input{
                                            border: 1px solid #ddd;
                                            background-color: #fff;
                                            width: 100%;
                                            max-width: 90%;
                                            text-align: left;
                                            margin-left: 10px;

                                        }
                                        .dkg-desired-job-save-btnCon{
                                            margin-top: 10px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            div{
                                                div{
                                                    .dkg-desired-job-save-btn{
                                                        background-color: #26597d;
                                                        // text-transform: capitalize;
                                                        &:hover{
                                                            color: #fff;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        
                            
                        }
                    }
                    .dkg-desired-save-btnCon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding: 25px 0;
                        background-color: #f2f2f2;
                        div{
                            div{
                                .dkg-desired-save-btn{
                                    background-color:  rgb(38, 89, 125);
                                    &:hover{
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                    &.dkg-myresume-newblock-main{
                        .dkg-myprofile-con{
                            width: 100%;
                            max-width: 1140px;
                            margin: 0 auto;
                            padding-top: 0;
                            .dkg-myprofile-mainRow{
                                .dkg-myprofile-leftcon,
                                .dkg-myprofile-rightcon{
                                    border: 1px solid #d7d7d7;
                                    padding: 0;
                                    border-radius: 5px;
                                    margin-top: 20px;
                                    .dkg-profile-frm-title{
                                        background: #1f394c;
                                        border-top-left-radius: 5px;
                                        border-top-right-radius: 5px;
                                        color: #ebdede;
                                        font-size: 18px;
                                        height: 50px;
                                        line-height: 50px;
                                        padding: 0 10px;
                                        text-align: center;
                                        margin-bottom: 0;
                                    
                                    }
                                    .dkg-profile-frm-subtitle{
                                        background-color: #f2f2f2;
                                        color: #333;
                                        padding: 1.15rem 15px;
                                        text-align: center;
                                        width: 100%;
                                        margin-bottom: 0;
                                        height: 50px;
                                        line-height: 50px;
                                        border-bottom: 1px solid #ddd;
                                        .dkg-frm-subtitle-desc{
                                            font-size: 14px;
                                            text-align: center;
                                            margin-bottom: 0;
                                            color: #666;
                    
                                        }
                                    }
                                    .dkg-profile-frmgrp{
                                        display: flex;
                                        align-items: center;
                                        width: 100%;
                                        margin-bottom: 1rem;
                                        padding: 0 15px;
                                        .dkg-profile-label{
                                            min-width: 220px;
                                            max-width: 220px;
                                            margin-bottom: 0;
                                            font-size: 13px;
                                            .dkg-profile-star{
                                                color: #e2445b;
                                            }
                                        }
                                        .dkg-profile-input{
                                            font-size: 13px;
                                            border: 1px solid #ddd;
                                            background-color: #f2f2f2;
                                            &::placeholder {
                                                color: #676767;
                                                opacity: 1; /* Firefox */
                                                font-size: 13px;
                                            }  
                                            &::-ms-input-placeholder { /* Edge 12-18 */
                                                color: #676767;
                                                font-size: 13px;
                                            }
                                            &.dkg-dullgrey-input{
                                                &::placeholder {
                                                    color: #676767;
                                                    font-size: 13px;
                                                    opacity: 1; /* Firefox */
                                                }  
                                                &::-ms-input-placeholder { /* Edge 12-18 */
                                                    color: #676767;
                                                    font-size: 13px;
                                                }
                                            }
                                            
                                        }
                                        .dkg-info-uplaod-image {
                                            .dk-contactPerson-image {
                                                width: 100%;
                                                max-width: 120px;
                                                border: 1px solid #ccc;
                                                padding: 0;
                                                margin-right: 15px;
                                                height: 120px;
                                                display: flex;
                                                align-items: center;
                                                background: #fff;
                                                justify-content: center;
                                                overflow: hidden;
                                                border-radius: 50%;
                    
                                                img {
                                                    width: 100%;
                                                    object-fit: cover;
                                                    border-radius: 50%;
                                                    height: 120px;
                                                    object-position: top center;
                                                }
                                            }
                    
                                            .dk-edit-userImgCon {
                                                width: 100%;
                                                max-width: 100px;
                                                border: 1px solid #ccc;
                                                padding: 0;
                                                margin-right: 15px;
                                                height: 100px;
                                                display: flex;
                                                align-items: center;
                                                background: #fff;
                                                justify-content: center;
                                                // overflow: hidden;
                                                border-radius: 50%;
                                                margin-bottom: 10px;
                                                position: relative;
                                                input {
                                                    width: 100%;
                                                    height: 100px;
                                                    max-width: 100px;
                                                    position: relative;
                                                    z-index: 9;
                                                    opacity: 0;
                                                    cursor: pointer;
                                                }
                    
                                                span.dk-myprofile-userImgCon {
                                                    position: absolute;
                                                    top: -1px;
                                                    left: 0;
                                                    cursor: pointer;
                                                    width: 100%;
                                                    max-width: 100px;
                                                    border-radius: 50%;
                    
                                                    img.dk-myprofile-userImg {
                                                        width: 100%;
                                                        height: auto;
                                                        border-radius: 50%;
                                                    }
                    
                                                    figure {
                                                        .dkg-update-profile-234 {
                                                            max-width: 120px;
                                                            border: 1px solid #ccc;
                                                            padding: 0;
                                                            height: 120px;
                                                            display: flex;
                                                            align-items: center;
                                                            background: #fff;
                                                            justify-content: center;
                                                            overflow: hidden;
                                                            border-radius: 50%;
                                                            position: relative;
                                                            right: 4px;
                                                        }
                                                    }
                                                }
                    
                                                span.dk-myprofile-editicon {
                                                    position: absolute;
                                                    bottom: 17px;
                                                    right: -2px;
                                                    background-color: #234158;
                                                    width: 30px;
                                                    height: 30px;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    border-radius: 50%;
                    
                                                    svg {
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                        .dkg-dob-inputCon{
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dkg-dob-input{
                                                margin-right: 0.7rem;
                                                background-color: #f2f2f2;
                                                border: 1px solid #ddd;
                        
                                            }
                                        }
                                        &:last-child{
                                            margin-bottom: 1.5rem;
                                        }
                                    }
                                    .dkg-resume-rightcon{
                                        height: calc(100vh - 340px);
                                    }
                                    &.dkg-resume-leftcon,
                                    &.dkg-resume-rightcon{
                                        border: none;
                                        .dkg-resume-leftbody,
                                        .dkg-resume-rightbody{
                                            border: none;
                                            height: 275px !important;
                                            background-color: #fff;
                                            border-radius: 0 0 5px 5px;
                                            border: 1px solid #ddd;
                                            border-top: none;
                                        }
                                        .dkg-profile-frm-title{
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                                .dkg-myprofile-leftcon {
                                    background-color: #fff;
                         
                                    @media (min-width: 768px){
                                        &.col-md-6 {
                                            flex: 0 0 46%;
                                            max-width: 46%;
                                            position: relative;
                                            &::after{
                                                content: "";
                                                position: absolute;
                                                right: -48px;
                                                top: 0;
                                                border-right: 0.5px solid #ccc;
                                                height: 100%;
                                                display: none;
                                            }
                                        }
                                    }
                                    &.dkg-resume-leftcon{
                                        .dkg-resume-frm-title{
                                            text-transform: inherit;
                                            border: 1px solid #ddd;
                                        }
                                        .dkg-resume-leftbody{
                                            width: 100%;
                                            height: calc(100vh - 250px);
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex-direction: column;
                                            .dkg-white-block{
                                                width: 150px;
                                                height: 150px;
                                                background-color: transparent;
                                                border: 1px solid transparent;
                                                position: relative;
                                                top: 5px;
                                                .dkg-myresume-cvimgCon{
                                                    width: 100%;
                                                    height: 100%;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    position: absolute;
                                                    top: -18px;
                                                    left: 0;
                                                    .dkg-myresume-cvimg{
                                                        width: 100px;
                                                        height: auto;
                                                        background-repeat: no-repeat;
                                                        background-size: cover;                                    
                                                    }
                                                    input {
                                                        width: 100%;
                                                        height: 100px;
                                                        max-width: 100px;
                                                        position: relative;
                                                        z-index: 9;
                                                        opacity: 0;
                                                        cursor: pointer;
                                                    }
                        
                                                    span.dkg-myresume-sapnimgCon {
                                                        position: absolute;
                                                        top: -1px;
                                                        left: 0;
                                                        cursor: pointer;
                                                        width: 100%;
                                                        max-width: 100px;
                                                        border-radius: 50%;
                        
                                                        img.dkg-myresume-cvimg {
                                                            width: 100%;
                                                            height: auto;
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-resume-view-btnCon{
                                                margin-top: 30px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;
                                                position: relative;
                                                top: -10px;
                                                .dkg-resume-view-btn{
                                                    display: inline-block;
                                                    background-color: #f2f2f2;
                                                    color: #333;
                                                    border: 1px solid #ddd;
                                                    text-transform: capitalize;
                                                    border-radius: 0;
                                                    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                                    padding: 7px 12px;
                                                    font-size: 0.875rem;
                                                    box-sizing: border-box;
                                                    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                                    font-weight: 500;
                                                    line-height: 1.75;
                                                    letter-spacing: 0.02857em;
                                                    &:hover{
                                                        box-shadow: none;
                                                        background-color: #3C97B6;
                                                        color: #fff;
                                                    }
                                                    .dkg-resume-icon{
                                                        margin-left: 0.5rem;
                                                        svg{
                                                            width: 0.95rem;
                                                            height: 0.95rem;
                                                            cursor: pointer;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                    
                                }
                                .dkg-myprofile-rightcon{
                                    background-color: #fff;
                    
                                    @media (min-width: 768px){
                                        &.col-md-6 {
                                            flex: 0 0 46%;
                                            max-width: 46%;
                                            margin-left: auto;
                                        }
                                    }
                                    &.dkg-profile-info-rightCon{
                                        color: inherit;
                                    }
                                    &.dkg-resume-rightcon{
                                        .dkg-resume-frm-title{
                                            text-transform: inherit;
                                            border: 1px solid #ddd;
                                        }
                                        .dkg-resume-rightbody{
                                            width: 100%;
                                            height: calc(100vh - 250px);
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            flex-direction: column;
                                            .dkg-white-block{
                                                width: 150px;
                                                height: 150px;
                                                background-color: transparent;
                                                border: 1px solid transparent;
                                                position: relative;
                                                top: -5px;
                                                .dkg-myresume-cvimgCon{
                                                    width: 100%;
                                                    height: 100%;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    .dkg-myresume-cvimg{
                                                        width: 100px;
                                                        height: 100px;
                                                        background-repeat: no-repeat;
                                                        background-size: cover;
                                                    }
                                                    input {
                                                        width: 100%;
                                                        height: 100px;
                                                        max-width: 100px;
                                                        position: relative;
                                                        z-index: 9;
                                                        opacity: 0;
                                                        cursor: pointer;
                                                    }
                        
                                                    span.dkg-myresume-sapnimgCon {
                                                        position: absolute;
                                                        top: -1px;
                                                        left: 0;
                                                        cursor: pointer;
                                                        width: 100%;
                                                        max-width: 100px;
                                                        border-radius: 50%;
                    
                                                        img.dkg-myresume-cvimg {
                                                            width: 100%;
                                                            height: auto;
                                                        }
                                                    }
                                                }
                                            }
                                            .dkg-resume-btngrp-Con{
                                                margin-top: 30px;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;
                                                position: relative;
                                                top: -10px;
                                                .dkg-resume-btn{
                                                    .dkg-resume-view-btn{
                                                        display: inline-block;
                                                        background-color: #f2f2f2;
                                                        color: #333;
                                                        border: 1px solid #ddd;
                                                        text-transform: capitalize;
                                                        border-radius: 0;
                                                        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                                        padding: 7px 12px;
                                                        font-size: 0.875rem;
                                                        box-sizing: border-box;
                                                        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                                        font-weight: 500;
                                                        line-height: 1.75;
                                                        letter-spacing: 0.02857em;
                                                        &:hover{
                                                            box-shadow: none;
                                                            background-color: #3C97B6;
                                                            color: #fff;
                                                        }
                                                        .dkg-resume-icon{
                                                            margin-left: 0.5rem;
                                                            svg{
                                                                width: 0.95rem;
                                                                height: 0.95rem;
                                                                cursor: pointer;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.dkg-cv-preview-main{
                        .dkg-cvpreview-center-sec{
                            border-top: 1px solid #ddd;
                            background-color: #fff;
                            height: calc(100vh - 295px);
                            .dkg-cvpreview-textareaCon{
                                .dkg-cvpreview-textarea{
                                    background-color: #fff;
                                    height: 55px;
                                    padding: 7px 15px;
                                    text-align: left;
                                    width: 80%;
                                    margin: 50px auto;
                                    border: 1px solid #ddd;
                                }
                                .dkg-cvpreview-save-btnCon{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    padding: 10px 0;
                                    .dkg-cvpreview-save-btn{
                                        background-color: #26597d;
                                        &:hover{
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                            &.dkg-workexpirece-mainCon{
                                width: 100%;
                                display: flex;
                                .dkg-workexpirece-leftcon{
                                    width: 35%;
                                    height: calc(100vh - 329px);
                                    border-right: 1px solid #ddd;
                                    .dkg-work-exp-btn-group{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        flex-direction: column;
                                        gap: 35px;
                                        width: 100%;
                                        // max-width: 70%;
                                        // margin: 0 auto;
                                        height: 100%;
                                        .dkg-work-exp-btn{
                                            color: #fff;
                                            cursor: pointer;
                                            display: inline-block;
                                            font-size: 1rem;
                                            font-weight: 400;
                                            width: 100%;
                                            max-width: 320px; 
                                            position: relative;
                                            text-decoration: none;
                                            text-transform: capitalize;
                                            background-color: #26597d;
                                            padding: 0.8rem 0.75rem;
                                        }
                                    }

                                }
                                .dkg-workexpirece-rightcon{
                                    width: 100%;
                                    height: 100%;
                                    .dkg-work-exp-right-header{
                                        width: 100%;
                                        position: relative;
                                        display: flex;
                                        flex-direction: column;
                                        .dkg-work-exp-right-title{
                                            color: #495057;
                                            display: block;
                                            font-size: 1rem;
                                            padding: 7px 10px;
                                            position: relative;
                                            width: 100%;
                                            font-weight: 600;
                                            text-align: center;
                                            border-bottom: 1px solid #ddd;

                                        }
                                        .dkg-work-exp-right-btn-ul{
                                            display: flex;
                                            align-items: center;
                                            list-style: none;
                                            margin: 0;
                                            padding: 0;
                                            white-space: nowrap;
                                            border-bottom: 1px solid #ddd;
                                            .dkg-work-exp-right-btn-li{
                                                border-right: 1px solid #ddd;
                                                text-align: center;
                                                .dkg-work-exp-right-btn{
                                                    display: inline-block;
                                                    width: 100%;
                                                    font-size: 0.9rem;
                                                    color: #3c97b6;
                                                    padding: 7px;
                                                    &:hover {
                                                        background-color: #eaeaea;
                                                        color: #3c97b6;
                                                        box-shadow: 0 10px 20px #00000030, 0 6px 6px #0000003b;
                                                        transition: all .5s ease;
                                                    }
                                                }
                                                &:first-child{
                                                    border-left: 1px solid #ddd;
                                                }
                                                &:last-child{
                                                    border-right: none;
                                                }
                                            }
                                        }
                                    }
                                    .dkg-work-exp-textarea-Con{
                                        .dkg-work-exp-textarea{
                                            width: 100%;
                                            height: calc(100vh - 325px);
                                            border: none;
                                            padding: 7px 10px;
                                            font-size: 0.95rem;
                                            &:focus{
                                                outline: 0;
                                                box-shadow: none;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                    .dkg-desired-summ-body-234{
                        width: 100%;
                        height: calc(100vh - 328px);
                        overflow-y: auto;
                        .dkg-desired-summ-box-cols{
                            width: 100%;
                            height: 100%;
                            max-height: 12.5%;
                            display: flex;
                            border-bottom: 1px solid #ccc;
                            .dkg-desired-summ-1stcols{
                                width: 275px;
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                border-right: 1px solid #ccc;
                                background-color: #ddd;
                                .dkg-desired-summ-title{
                                    font-size: 1rem;
                                    padding: 0 15px;
                                    text-transform: uppercase;
                                }
                            }
                            .dkg-desired-summ-2ndcols{
                                width: 100%;
                                height: 100%;
                                .dkg-desired-texareaCon{
                                    width: 100%;
                                    height: 100%;
                                    .dkg-desired-texarea{
                                        width: 100%;
                                        text-align: left;
                                        padding: 5px 10px;
                                        height: 100%;
                                    }
                                }
                                .dkg-myprofile-summCon{
                                    width: 100%;
                                    height: 100%;
                                    .dkg-myprofile-summ-btn-tagCon{
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        width: 100%;
                                        height: 100%;
                                        flex-wrap: wrap;
                                        padding: 0 10px;
                                        .dkg-desired-btntext{
                                            display: inline-block;
                                            border-radius: 12px;
                                            color: #fff;
                                            font-size: 13px;
                                            padding: 6px 22px;
                                            text-transform: capitalize;
                                            background-color: #366995 !important;
                                            box-shadow: none;
                                            position: relative;
                                            .dkg-tag-icon{
                                                color: #fff;
                                                cursor: pointer;
                                                display: none;
                                                margin-left: 5px;
                                                padding: 0;
                                                position: absolute;
                                                right: 5px;
                                                top: 6px;
                                            }
                                            &:hover{
                                                .dkg-tag-icon{
                                                    display: block;
                                                    i{
                                                        color: #fff;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .dkg-covermsg-textCon{
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                    padding: 7px 10px;
                                    .dkg-covermsg-text{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                            &:first-child{
                                border-top: 1px solid #ddd;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                    &.dkg-cv-profile-summ-main{
                        background-color: #f2f2f2;
                    }
                }
                .dkg-createcv-job-mainCon{
                    background-color: #fff;
                    border: 1px solid #ccc;
                    .dkg-createcv-textarea-Con{
                        width: 100%;
                        height: 100%;
                        .dkg-createcv-textarea{
                            background-color: #f2f2f2;
                            height: 55px;
                            padding: 7px 15px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            border: none;
                        }
                    }
                }
                .dkg-create-cv-center-sec.dkg-create-cv-job-centersec{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: #f2f2f2;
                    .dkg-create-cv-job-leftCon{
                        width: 100%;
                        .dkg-selected-title {
                            color: #495057;
                            display: block;
                            font-size: 1.15rem;
                            padding: 7px 10px;
                            position: relative;
                            width: 100%;
                            font-weight: 600;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            background-color: #fff;
                        }
                        .dkg-select-cv-cols{
                            display: block;
                            width: 100%;
                            height: 100%;
                            position: relative;

                            .dkg-uploadimg-mainCon{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                width: 100%;
                                background-color: #fff;
                                gap: 2.5rem;
                                .dkg-info-uplaod-image {
                                    position: relative;
                                    top: 0;
                                    .dk-contactPerson-image {
                                        width: 100%;
                                        max-width: 90px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        margin-right: 15px;
                                        height: 90px;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        overflow: hidden;
                                        border-radius: 50%;
            
                                        img {
                                            width: 100%;
                                            object-fit: cover;
                                            border-radius: 50%;
                                            height: 90px;
                                            object-position: top center;
                                        }
                                    }
            
                                    .dk-edit-userImgCon {
                                        width: 100%;
                                        max-width: 90px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        margin-right: 15px;
                                        height: 90px;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        // overflow: hidden;
                                        border-radius: 50%;
                                        margin-bottom: 10px;
                                        position: relative;
                                        input {
                                            width: 100%;
                                            height: 90px;
                                            max-width: 90px;
                                            position: relative;
                                            z-index: 9;
                                            opacity: 0;
                                            cursor: pointer;
                                        }
            
                                        span.dk-myprofile-userImgCon {
                                            position: absolute;
                                            top: -1px;
                                            left: 0;
                                            cursor: pointer;
                                            width: 100%;
                                            max-width: 90px;
                                            border-radius: 50%;
            
                                            img.dk-myprofile-userImg {
                                                width: 100%;
                                                height: auto;
                                                border-radius: 50%;
                                            }
            
                                            figure {
                                                .dkg-update-profile-234 {
                                                    max-width: 120px;
                                                    border: 1px solid #ccc;
                                                    padding: 0;
                                                    height: 120px;
                                                    display: flex;
                                                    align-items: center;
                                                    background: #fff;
                                                    justify-content: center;
                                                    overflow: hidden;
                                                    border-radius: 50%;
                                                    position: relative;
                                                    right: 4px;
                                                }
                                            }
                                            &.dk-browse-edit-userImgCon{
                                                top: 23px;
                                                figure{
                                                    .dkg-browse-imge-textCon{
                                                        display: flex;
                                                        align-items: center;
                                                        flex-direction: column;
                                                        svg{
                                                            width: 23px;
                                                            height: 23px;
                                                            color: #C5C5C5;
                                                        }
                                                        .dkg-browse-title{
                                                            text-transform: uppercase;
                                                            color: #324148;
                                                            padding: 10px 0;
                                                            font-size: 0.75rem;
                                                            font-weight: 600;
                                                            position: relative;
                                                            top: -12px;                                                    
                                                        }
                                                    }
                                                }
                                            }
                                        }
            
                                        span.dk-myprofile-editicon {
                                            position: absolute;
                                            bottom: 17px;
                                            right: -2px;
                                            background-color: #234158;
                                            width: 30px;
                                            height: 30px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            border-radius: 50%;
            
                                            svg {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                                .dkg-upload-cv-btnCon{
                                    position: relative;
                                    top: -40px;
                                    .dkg-upload-cv-btn{
                                        background-color: #f2f2f2;
                                        color: #333;
                                        border: 1px solid #ddd;
                                        box-shadow: none;
                                        padding: 10px 12px;
                                        width: 140px;
                                        &:hover{
                                            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                        }
                                        .dkg-upload-icon{
                                            svg{
                                                color: #333;
                                            }
                                        }
                                    }
                                    .dkg-remove-cv-btn{
                                        background-color: #f2f2f2;
                                        color: #333;
                                        border: 1px solid #ddd;
                                        box-shadow: none;
                                        padding: 10px 12px;
                                        width: 100px;
                                        &:hover{
                                            box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                        }
                                    }
                                }
                                .dkg-avatar-block-image-Con{
                                    position: relative;
                                    top: -40px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;
                                    .dkg-avatar-image-Con{
                                        width: 100px;
                                        height: 100px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        overflow: hidden;
                                        border-radius: 50%;
                                        object-fit: contain;
                                        margin-bottom: 0.5rem;
                                        img.dkg-avatar-image{
                                            width: 100%;
                                            object-fit: contain;
                                            border-radius: 50%;
                                            object-fit: contain;
                                        }
                                    }
                                    .dkg-avatar-cv-btnCon{
                                       
                                        .dkg-avatar-cv-btn{
                                            background-color: #f2f2f2;
                                            color: #333;
                                            border: 1px solid #ddd;
                                            box-shadow: none;
                                            display: inline-block;
                                            padding: 10px 12px;
                                            width: 140px;
                                            &:hover{
                                                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                            }
                                            .dkg-upload-icon{
                                                svg{
                                                    color: #333;
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                            .dkg-select-check-content{
                                position: absolute;
                                bottom: 110px;
                                left: 50px;
                                display: none;
                                .dkg-cv-select-checkbox-con{
                                    display: flex;
                                    align-items: center;
                                    input[type=checkbox] {
                                        -webkit-appearance: none;
                                        appearance: none;
                                        background: #fff;
                                        border: 1px solid #dc3545;
                                        border-radius: 1px;
                                        box-sizing: border-box;
                                        cursor: pointer;
                                        transition: all .3s linear;
                                        width: 16px;
                                        height: 16px;
                                        z-index: 0;
                                    }
                                    input[type=checkbox]:checked {
                                        background-color: #dc3545 !important;
                                    }
                                    .dkg-cv-checkbox-label{
                                        margin-left: 0.5rem;
                                        color: #dc3545;
                                        font-size: 1.15rem;
                                    }
                                }
                            }
                        }
                    }
                    .dkg-create-cv-job-rightCon{
                        width: 100%;
                        .dkg-selected-title {
                            color: #495057;
                            display: block;
                            font-size: 1.15rem;
                            padding: 7px 10px;
                            position: relative;
                            width: 100%;
                            font-weight: 600;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            background-color: #fff;
                        }
                        .dkg-createcv-rightbody{
                            padding: 15px;
                            overflow-y: auto;
                            height: calc(100vh - 370px);
                            background-color: #fff;
                            .dkg-createcv-frmgrp{
                                display: flex;
                                align-items: center;
                                width: 100%;
                                margin-bottom: 1rem;
                                .dkg-createcv-label{
                                    min-width: 145px;
                                    margin-bottom: 0;
                                    text-align: left;
                                    font-size: 0.85rem;
                                }
                                .dkg-createcv-frm-control{
                                    border: 1px solid #ddd;
                                    border-radius: 0;
                                    background-color: #f2f2f2;
                                }
                                .dkg-createcv-dropdwnCon{
                                    width: 100%;
                                    .dkg-createcv-dropdwn{
                                        width: 100%;
                                        .dkg-ccv-dropdwn-toggle{
                                            box-shadow: none;
                                            width: 100%;
                                            border: 1px solid #ddd;
                                            text-transform: capitalize;
                                            padding: 7px 10px;
                                            height: 30px;
                                            margin: 0;
                                            position: relative;
                                            background-color: #f2f2f2;
                                            &::after{
                                                position: absolute;
                                                right: 15px;
                                            }
                                        }
                                        .dkg-ccv-dropdwn-menu{
                                            width: 100%;
                                            border-radius: 0;
                                            .dkg-ccv-dropdwn-item{
                                                display: block;
                                                padding: 5px 10px;
                                                font-size: 13px;
                                                border-bottom: 1px solid #ddd;
                                                &:last-child{
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }
                                }
                                &:last-child{
                                    margin-bottom: 0;
                                }

                            }
                            &::-webkit-scrollbar {
                                display: none;
                              }
                              
                              /* Hide scrollbar for IE, Edge and Firefox */
                              &{
                                -ms-overflow-style: none;  /* IE and Edge */
                                scrollbar-width: none;  /* Firefox */
                              }
                        }
                        .dkg-createcv-rightbody{
                            -ms-overflow-style: none;  /* IE and Edge */
                            scrollbar-width: none;  /* Firefox */
                        }
                    }
                }
                &.dkg-contactus-tabpane{
                    overflow-y: hidden;
                    .dkg-contactus-cardCon{
                        width: 100%;
                        height: calc(100vh - 300px);
                        overflow-y: auto;
                        background-color: #fff;
                        // .dkg-contact-card{
                        // }
                    }

                }
                &.dkg-desired-job-summ-mainCon{
                    overflow-y: auto;
                }
            }
        }

    }
    .dkg-contact-main{
        display: none !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}