.dk-checkbox-popup-234 {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 22px 104px -6px rgba(0, 0, 0, 0.3411764706);
    width: 100%;
    max-width: 800px;
    margin: auto;
    position: fixed;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -70%);
    display: none;
    // height: 263px;
    z-index: 999;
    &.activePopup{
        display: block;
    }
    .dkg-checkbox-header{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        background-color: #1f394c;
        color: #fff;
        border-radius: 5px 5px 0 0;
        .dkg-checkbox-leftCon{
            padding: 9px 15px;
            .dkg-checkbox-title{
                font-size: 1.25rem;
                font-weight: bold;
                color: #e2445b;
                text-transform: uppercase;
            }
            p{
                color: #6d7276 !important;
            }
        }
        .dkg-checkbox-rightCon{
            
            .dkg-cross-icon{
                svg{
                    position: absolute;
                    top: 16px;
                    right: 12px;
                    cursor: pointer;
                    width: 15px;
                    height: 15px;
                    &:hover{
                        opacity: 0.85;
                    }

                }
            }
        }
    }
    .dkg-checkbox-body{
        padding: 0;
        width: 100%;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        .dkg-deactive-acc-leftCon{
            display: block;
            width: 100%;
            .dkg-resion-title{
                font-size: 1rem !important;
                margin-top: .85rem;
                margin-bottom: 0.85rem;
                padding-left: 15px;
                font-weight: bold;
            }
            .dkg-checkbox-body-ul{
                margin: 0 !important;
                padding: 0 !important;
                .dkg-checkbox-body-li{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 12px 15px;
                    border-bottom: 1px solid #ddd;
                    &:first-child{
                        border-top: 1px solid #ddd;
                    }
                    .dkg-list-title{
                        font-size: 13px;
                        color: #000;
                    }
                    .dkg-list-radio{
                        // .dkg-deactive-radio{

                        // }
                        input[type="radio"] {
                            position: relative;
                            appearance: none;
                            -webkit-appearance: none;
                            transition: linear 0.8s;
                            height: 0;
                            width: 0;
                            -webkit-tap-highlight-color: transparent;
                            &:after {
                                content: "";
                                position: absolute;
                                height: 18px;
                                width: 18px;
                                top: -14px;
                                left: -30px;
                                border-radius: 20px;
                                border: 2px solid transparent;
                                transition: linear 0.2s;
                                cursor: pointer;
                                background-color: #eaeaea;
                            }
                        }
                    
                        input[type="radio"]:checked:after {
                            content: "";
                            position: absolute;
                            height: 18px;
                            width: 18px;
                            background: #3a88f6;
                            transition: linear 0.2s;
                            cursor: pointer;
                        }
                        
                        input[type="radio"]:checked:before {
                            content: "";
                            position: absolute;
                            height: 10px;
                            width: 10px;
                            background: #fff;
                            left: -26px;
                            top: -9px;                        
                            border-radius: 50%;
                            z-index: 1;
                            cursor: pointer;
                        }
                        
                        .radio-item-container {
                            display: flex;
                            flex-direction: column;
                            border: 2px solid #3a88f6;
                            border-top: 0;
                            background: #fff;
                            border-radius: 0 0 10px 10px;
                            padding: 10px 0;
                        }
                    }
                    &:hover{
                        background-color: #eaeaea;
                        cursor: pointer;
                        .dkg-list-radio{
                            input[type="radio"] {
                           
                            &:after {
                                background-color: #fff;
                            }
                        }
                        }
                    }
                }
            }
            .dkg-detactive-textareaCon{
                padding: 15px;
                .dkg-detactive-textarea{
                    background-color: #eaeaea;
                    height: 80px;
                    border: 1px solid #ddd;
                    width: 100%;
                    padding: 5px 7px;
                    font-size: 13px;
                }
            }
        }
        .dkg-deactive-acc-rightCon{
            display: block;
            width: 100%;
            padding: 15px;
            background-color: #eaeaea;
            .dkg-deactive-acc-title{
                font-size: 1.25rem;
                font-weight: 600;
                margin:  0;
                padding-bottom: 10px;
                display: none;
            }
            .dkg-deactive-desc{
                color: #6d7276;
            }
        }
    }
    .dkg-checkbox-footer{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 15px;
        .dkg-deactive-btnCon{
            display: flex;
            align-items: center;
            div{
                div{
                    .dkg-delete-btn{
                        background-color: #e2445b;
                        color: #fff;
                        border-radius: 5px;
                        font-size: 1rem;
                        text-transform: capitalize;
                    }
                }
            }
            .dkg-ancel-btn{
                background-color: #eaeaea;
                display: inline-block;
                padding: 7px 10px;
                border: 1px soplid #ddd;
                border-radius: 5px;
                font-size: 1rem;
                text-transform: capitalize;

            }
        }
    }
}