.dk-screenform-tabcon-24 {
    background-color: #eaeaea;

    .dk-tabs-right-nav-24 {
        margin-bottom: 0;
        width: 100%;

        .dk-tabs-right-navitem-24 {
            .dk-tabs-right-navlink-24 {
                // background: #244158;
                // border-bottom: 1px solid #3e5b73;
                color: #333;
                display: block;
                font-family: Roboto, sans-serif !important;
                font-size: 15px;
                font-weight: 400;
                padding: 11px 15px;
                text-transform: inherit;
                width: 100%;
                border-radius: 0;

                &.active,
                &.show>.nav-link {
                    background-color: #eaeaea;
                    color: #333;
                }
            }
        }

        .dk-tabs-right-tabcontent-24 {
            .dk-tabs-right-tabpane-24 {
                .dk-candprescreeningTableCon {
                    height: calc(100vh - 200px);
                    overflow-y: auto;

                    .dk-candprescreeningTable {
                        tbody {
                            tr {
                                td {
                                    border: 1px solid #cdc5c5 !important;

                                    .dk-prescreen-recuiter-con {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        .dk-prescreen-recuiter-img {
                                            width: 28px;
                                            height: 28px;
                                            border-radius: 50%;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dk-prescreenbtnCon {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0.95rem 0;
        width: 100%;
        position: relative;
        top: 13px;

        .dk-prescreenbtn {
            background-color: #234158;
            border: 1px solid #234158;
            border-radius: 0.1875rem;
            color: #fff;
            display: inline-block;
            font-size: 13px;
            line-height: 1.5385;
            padding: 5px 12px;
            text-align: center;
            text-transform: uppercase;
            width: 100px;
            position: relative;
            left: 90px;
        }
    }
}