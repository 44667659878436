.dk-navBarMain.dk-accountingNavbar {
    background: #57496D;
    position: fixed;
    top: 52px;
    z-index: 9;
    width: 100%;
    left: 0;

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: auto;

        li {
            display: inline-block;
            width: 100%;
            max-width: 9.999%;

            a {
                width: 100%;
                background-color: #5A4C72;
                display: flex;
                color: hsla(0, 0%, 100%, .9);
                font-size: 14px;
                padding: 13px 0 !important;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                min-height: 46px;
                max-height: 46px;
                font-family: Verdana, sans-serif;
                transition: all 0.5s;

                span.dk-badge {
                    background-color: #ec407a;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    margin: 0 5px;
                }

                &:hover {
                    background: #eaeaea;
                    color: #333;
                    transition: all 0.5s;
                }

                i {
                    font-size: 11px;
                    display: inline-block;
                    margin: 0 2px;
                }

                span.dk-workplan-badgeCon {
                    border-radius: 50%;
                    color: #fff;
                    display: inline-block;
                    font-size: 9px;
                    height: 20px;
                    line-height: 20px;
                    margin: 0 5px;
                    text-align: center;
                    width: 20px;
                }

                span.dk-workplan-badgeCon.dk-payments-topay-bgCon {
                    background-color: #d58b5d
                }

                span.dk-workplan-badgeCon.dk-payments-paid-bgCon {
                    background-color: #3a9469
                }

                span.dk-workplan-badgeCon.dk-payments-achived-bgCon {
                    background-color: #d91010
                }
            }

            a.active {
                background: #fff;
                color: #333;
            }

            a.gray-active {
                background: #eaeaea !important;
                color: #333;
            }

            a.activeDark {
                background: #2e5a7c;
                color: #fff;
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinancePayrollMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceVatFilesMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceBankingMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }

            a.grey-active {
                background: #eaeaea;
                color: #333;
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceCashFlowMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}


.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceBusinessOverviewMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceExpenseMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }

            a.grey-active {
                background: #eaeaea;
                color: #333;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceProfitLossMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceContractsMainMenu {
    background: #535255;

    ul {
        li {
            a {
                background: #535255;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceKnowledgeBaseMainMenu {
    background: #5A4C72;

    ul {
        li {
            a {
                background: #5A4C72;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalfinanceLegalMainMenu {
    background: #535255;

    ul {
        li {
            a {
                background: #535255;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminResourceMainMenu {
    background: #a43b63;

    ul {
        li {
            a {
                background: #a43b63;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminPrMainMenu {
    background: #657d74;

    ul {
        li {
            a {
                background: #657d74;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminMarketingMainMenu {
    background: #4c5f59;

    ul {
        li {
            a {
                background: #4c5f59;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminClientsMainMenu {
    background: #535255;

    ul {
        li {
            a {
                background: #535255;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminSupplierMainMenu {
    background: #409a9b;

    ul {
        li {
            a {
                background: #409a9b;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminBdMainMenu {
    background: #5c737d;

    ul {
        li {
            a {
                background: #5c737d;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminHrMainMenu {
    background: #d58b5d;

    ul {
        li {
            a {
                background: #d58b5d;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminMyFilesMainMenu {
    background: #535255;

    ul {
        li {
            a {
                background: #535255;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminManagementMainMenu {
    background: #884C5E;

    ul {
        li {
            a {
                background: #884C5E;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminKnowledgeBaseMainMenu {
    background: #4c5f59 !important;

    ul {
        li {
            a {
                background: #4c5f59 !important;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminTrainingMainMenu {
    background: #396a95;

    ul {
        li {
            a {
                background: #396a95;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }

    &.commonMainNav {
        background-color: #3c97b6;

        ul {
            li {
                a {
                    background-color: #3c97b6;

                    &:hover {
                        color: #333;
                        background: #fff;
                    }
                }

                a.active {
                    background-color: #fff !important;

                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }

    &.dkg-training-faqNavbar {
        background-color: #3c97b6;

        ul {


            li {
                a {
                    background-color: #3c97b6;

                    &:hover {
                        color: #333;
                        background: #fff;
                    }
                }

                a.active {
                    background-color: #fff;

                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-recruitmentTraining-nav {
    background: #396a95;

    ul {
        li {
            a {
                background: #396a95;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}


.dk-navBarMain.dk-accountingNavbar.dk-dkglobalMainMenu {
    background: #258FB4;

    ul {
        background: #258FB4;

        li {
            max-width: 10%;

            a {
                background: #258FB4;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;
                color: #333;
            }
        }
    }
}