.dkg-myacc-jobprofile-main-Con {
    margin-top: 110px;
    background-color: #eaeaea;
    padding: 25px 15px;
    height: 100%;
    // height: calc(100vh - 125px);
    .dk-cliBoardViewPageCont{
        margin-top: 0 !important;
        padding: 0;
        .dk-qulaifyTopHead{
            .dk-qulaifyLeftTopHead{
                .dk-SrchInputBox{
                    .input-group{
                        .input-group-append{
                            button{
                                background-color: #b59ca4 !important;
                                i{
                                    color: #fff !important;
                                }
                            }
                        }
                    }
                }
            }
            .dk-qulaifyRightTopHead {
                .dkg-jobsfaq-tab{
                    background-color: #fff;
                }
            }
        }
        .dk-jobboardTableCon{
            height: calc(100vh - 205px) !important;
            overflow-y: auto;
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-myacc-jobprofile-main-Con {
        margin-top: 35px;
        height: calc(100vh - 49px);
        overflow-y: hidden;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}