.dk-navBarMain.dk-modulesNavbar {
    background: #3c97b6;
    position: fixed;
    top: 14px;
    z-index: 9;
    width: 100%;
    left: 0;

    button.mobileNav-btn {
        display: none;
    }

    @media(max-width:992px) {
        position: absolute;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 9999;

        button.mobileNav-btn {
            display: block;
            width: 100%;
            max-width: 40px;
            background: transparent;
            border: none;
            outline: none;
            position: fixed;
            top: 79px;
            right: 15px;
            line-height: 5px;
            text-align: right;

            span {
                display: inline-block;
                width: 35px;
                height: 2px;
                background: #333;

                &:nth-child(02) {
                    width: 26px;
                }

                &:nth-child(03) {
                    width: 16px;
                }
            }
        }
    }

    ul.mobileNav.show {
        left: 0;
        transition: all 0.5s;
        width: 50%;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: auto;
        padding-top: 40px;

        .dk-closeBtn {
            display: none;
        }

        @media(max-width:992px) {
            position: fixed;
            left: -100%;
            height: 100vh;
            background: #3c97b6;
            width: 100%;
            flex-wrap: wrap;
            display: block;
            transition: all 0.5s;
            padding-top: 0;

            .dk-closeBtn {
                position: absolute;
                right: 0;
                top: 0;
                display: inline-block;
                color: #fff;
                font-size: 24px;
                background: #244158;
                outline: none;
                border: none;
                width: 35px;
                height: 41px;
            }
        }

        li {
            display: inline-block;
            width: 100%;
            max-width: 9.999%;

            @media(max-width:992px) {
                max-width: 100%;
            }

            a {
                width: 100%;
                background: #3c97b6 !important;
                display: flex;
                color: hsla(0, 0%, 100%, .9);
                font-size: 14px;
                padding: 13px 0 !important;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                min-height: 46px;
                max-height: 46px;
                font-family: Verdana, sans-serif;
                transition: all 0.5s;

                &:hover {
                    background-color: #eaeaea !important;
                }

                span.dk-badgeTracking {
                    background-color: #207993;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 10px;
                    margin: 0 5px;
                    position: relative;
                    bottom: 0;
                }

                .dkg-taskbadgecon {
                    background-color: #5E766E;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 9px;
                    margin: 0 5px;
                }

                @media(max-width:992px) {
                    padding: 10px 15px !important;
                    justify-content: flex-start;
                    max-height: auto;
                    min-height: auto;
                }

                span.dkg-taskbadgecon.blue {
                    background-color: #26597d !important;
                }

                span.dk-workplan-badgeCon.blue {
                    background-color: #26597d !important;
                }

                span.dkg-mailbox-badgeColor {
                    background-color: #26597d !important;
                }

                span.dkg-tasks-badgeColor {
                    background-color: #26597d !important;
                }

                span.dkg-notification-badgeColor {
                    background-color: #26597d !important;
                }

                span.dk-workplan-badgeCon {
                    background-color: #ec407a;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    margin: 0 5px;

                    @media(max-width:992px) {
                        margin-left: 0;
                    }

                    &.dk-noti-badgeCon {
                        background-color: #396A95;
                    }

                    &.dk-announce-badgeCon {
                        background-color: #007D7F;
                    }

                    &.dkg-taskbadgecon {
                        background-color: #5E766E;
                    }

                    &.dkg-task-today-badgecon {
                        background-color: #26596d;
                    }

                    &.dk-jobs-badgeCon {
                        background-color: #26597d;
                    }
                }

                .dkg-taskbadgecon {
                    background-color: #D58B5D;
                }

                &:hover {
                    background: #fff;
                    color: #333;
                    transition: all 0.5s;
                }

                i {
                    font-size: 11px;
                    display: inline-block;
                    margin: 0 2px;
                }
            }

            a.active {
                background: #eaeaea !important;
                color: #333;
            }

            a.dkg-vistsite-active {
                background: #fff !important;
                color: #fff;
            }

            a.white-active {
                background: #fff;
                color: #333;
            }

            // &:first-child,
            // &:nth-child(02) {
            //     a.active {
            //         background: #fff !important;
            //         color: #333;

            //         &.dkg-vistsite-active {
            //             background: #fff !important;
            //             color: #333 !important;
            //         }
            //     }
            // }

            a.activeGeryBg {
                background: #eaeaea;
                color: #333;
            }


        }
    }
}

// background: #3c97b6;
// span.dk-workplan-badgeCon {
//     background-color: #ec407a;
//     display: inline-block;
//     width: 18px;
//     height: 18px;
//     border-radius: 50%;
//     color: #fff;
//     text-align: center;
//     line-height: 18px;
//     font-size: 11px;
//     margin: 0 5px;
//     &.dk-noti-badgeCon {
//         background-color: #396A95;
//     }
//     &.dk-announce-badgeCon{
//         background-color: #007D7F;
//     }
// }
// &:hover{
//     background: #eaeaea;
//     color: #333;
//     transition: all 0.5s;
// }