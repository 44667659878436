.dk-suMMCaseStatusDropDwn {
    width: 100%;

    .dkg-sendouts-kpi-toggle {
        background-color: #fff;

        .react-contextmenu-wrapper {
            a {
                color: gray;
                height: 31px;
                border: 1px solid #ddd;
                line-height: 23px;
                text-transform: capitalize;
                padding: 7px 10px;
            }

        }

        .dkg-sendouts-kpi-dropdown-menu {
            input {
                border-bottom: 1px solid #ddd;
            }

            ul {
                a {
                    background-color: #fff !important;
                    color: #333 !important;
                    border-bottom: 1px solid #ddd;
                    text-align: left;
                }
            }
        }
    }
}