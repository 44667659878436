.dk-navBarMain.dk-recruitmentNavbar {
    background: #3c4c62;
    position: fixed;
    top: 52px;
    z-index: 9;
    width: 100%;
    left: 0;

    button.mobileNav-btn {
        display: none;
    }

    @media(max-width:992px) {
        position: absolute;
        top: 0;
        z-index: 9999;
        width: 100%;
        left: 0;

        button.mobileNav-btn {
            display: block;
            width: 100%;
            max-width: 40px;
            background: transparent;
            border: none;
            outline: none;
            position: fixed;
            top: 19px;
            right: 15px;
            line-height: 5px;
            text-align: right;

            span {
                display: inline-block;
                width: 35px;
                height: 2px;
                background: #333;

                &:nth-child(02) {
                    width: 26px;
                }

                &:nth-child(03) {
                    width: 16px;
                }
            }
        }
    }

    ul.mobileNav.show {
        left: 0;
        transition: all 0.5s;
        width: 50%;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: auto;

        .dk-closeBtn {
            display: none;
        }

        @media(max-width:992px) {
            position: fixed;
            left: -100%;
            height: 100vh;
            background: transparent;
            width: 100%;
            flex-wrap: wrap;
            display: block;
            transition: all 0.5s;
            background: #3c4c62;

            .dk-closeBtn {
                position: absolute;
                right: 0;
                top: 0;
                display: inline-block;
                color: #fff;
                font-size: 24px;
                background: #24738b;
                outline: none;
                border: none;
                height: 41px;
                padding: 0 10px;

                &:hover {
                    background: #1c424e;
                }
            }
        }

        li {
            display: inline-block;
            width: 100%;
            max-width: 9.999%;

            @media(max-width:992px) {
                max-width: 100%;
            }

            a {
                // display: inline-block;
                // padding: 14px 0;
                // width: 100%;
                // color: #ececec;
                // text-align: center;
                // font-weight:400;
                // letter-spacing: 0.3px;
                // font-size: 14px;
                width: 100%;
                // background-color: #26596d;
                display: flex;
                color: hsla(0, 0%, 100%, .9);
                font-size: 14px;
                padding: 13px 0 !important;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                min-height: 46px;
                max-height: 46px;
                font-family: Verdana, sans-serif;
                transition: all 0.5s;

                @media(max-width:992px) {
                    padding: 10px 15px !important;
                    justify-content: flex-start;
                    max-height: auto;
                    min-height: auto;
                }

                span.dk-workplan-badgeCon {
                    background-color: #ec407a;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    margin: 0 5px;
                }

                span.dk-workplan-badge {
                    background-color: #2e97b6;
                    display: inline-block;
                    width: 17px;
                    height: 17px;
                    border-radius: 2px;
                    color: #fff;
                    text-align: center;
                    line-height: 17px;
                    font-size: 10px;
                    margin-right: 8px;
                    margin-top: 1px;
                }

                span.dk-noti-badgeCon {
                    background-color: #396A95;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    margin: 0 5px;
                }

                &:hover {
                    background: #eaeaea;
                    color: #333;
                    transition: all 0.5s;
                }

                i {
                    font-size: 11px;
                    display: inline-block;
                    margin: 0 2px;
                }
            }

            a.active {
                background: #eaeaea;
                color: #333;
            }

            a.activeDark {
                background: #2e5a7c;
                color: #fff;
            }

            a.white-active {
                background: #fff;
                color: #333;
            }
        }
    }

    &.dkg-marketingNavbarBg {
        background-color: #4c5f59;

        ul {
            li {
                a {
                    background-color: #4c5f59;
                    color: #fff;

                    &.active {
                        background-color: #eaeaea !important;
                        color: #333;
                    }
                    &.white-active{
                        background-color:  #fff;
                        color: #333;
                    }
                }

                &:first-child {
                    a {
                        &.active {
                            background-color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-dkGlobalFaqDepartmentsNavbar {
    background: #3c4c62;

    ul {
        li {
            a {
                background: #3c4c62;

                &.active {
                    background-color: #fff;
                    color: #333;
                }
            }
        }
    }
}

.dk-navBarMain.dk-accountingNavbar.dk-RecFaqDepartmentsNavbar {
    background: #26596d;

    ul {
        li {
            a {
                background: #26596d;
            }
        }
    }
}

.dk-navBarMain.dk-recruitmentNavbar.dk-trainingMainMenu.dk-mainModuleTrainingMenu {
    ul {
        li {
            max-width: 11.11%;
        }
    }
}

.dk-navBarMain.dk-recruitmentNavbar.dk-trainingMainMenu {
    background: #d58b5d;

    ul {
        li {
            a {
                background: #d58b5d;

                &:hover {
                    color: #fff;
                }
            }

            a.active {
                background: #fff;
                color: #333;
            }
        }
    }
}

.dk-navBarMain.dk-recruitmentNavbar.dk-itTeamMainMenu {
    background: #b55355;

    ul {
        li {
            a {
                background: #b55355;

                &:hover {
                    color: #fff;
                }

                span.dk-badgeBox {
                    background: #1b5a6f;
                }
            }

            a.active {
                background: #fff;
                color: #333;
            }
        }
    }
}