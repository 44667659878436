.dkg-envelope-Modal-98{
    .modal-dialog{
        min-width: 600px;
        max-width: 600px;
        .modal-content{
            background-color: transparent;
            .dkg-envelope-ModalHeader{
                border-radius: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #2196f3;
                padding: 10px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                border-bottom: none;
                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }
                .close {
                    text-shadow: none;
                    color: #fff;
                    opacity: 1;
                    overflow: hidden;
                    &:hover{
                        background-color: #eaeaea;
                        color: #333;
                        overflow: hidden;
                        border-top-right-radius: 5px;
                    }
                }
            }
            .dkg-envelope-ModalBody {
                background-color: #fff;
                height: 300px;
                border-radius:  0 0 5px 5px;
            }
        }
    }
}