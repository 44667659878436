.dkg-myacc-contracts-main-Con {
    margin-top: 123px;
    background-color: #eaeaea;
    padding: 25px 0 0;

    .dkg-contracts-mainCon {

        .dkg-contracts-boxCon {
            .dk-moduleBankingProcess-rightPanel.bgTransparent {
                background: #244158;
                padding: 33px 25px 20px;

                .dk-financeClients-tableMain {
                    padding: 0;
                }
            }

            .dk-moduleBankingProcess-rightPanel {
                width: 100%;

                .dk-statmentFlex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .dk-searchCols {
                        width: 100%;
                        max-width: 160px;
                        position: relative;

                        input {
                            text-align: left;
                            padding: 0 12px;
                            border: 1px solid #ccc;
                            height: 32px;

                            &:focus::placeholder {
                                opacity: 0;
                            }
                        }

                        button {
                            position: absolute;
                            top: 0;
                            right: 0;
                            display: inline-block;
                            width: 32px;
                            text-align: center;
                            border: 1px solid #ddd;
                            outline: none;
                            background: transparent;
                            height: 32px;
                            color: #757575;
                            font-size: 13px;
                        }
                    }
                }

                .dk-financeYears-main {
                    display: inline-flex;
                    position: absolute;
                    left: 0;
                    top: 10px;

                    .dk-financeYears-cols {
                        a {
                            margin-right: 18px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #737373;
                            display: inline-block;
                            border-radius: 3px;
                            border-bottom: 3px solid transparent;
                        }

                        a.active {
                            border-bottom: 3px solid #3c97b6;
                            color: #3c97b6;
                        }
                    }
                }

                .dk-financeYears-addNew {
                    a {
                        display: inline-block;
                        background: #fff;
                        padding: 5px 13px;
                        border-radius: 3px;
                        color: #333;
                        border: 1px solid #ddd;

                        &:hover {
                            background: #2e75b6;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                }

                @media(max-width:992px) {
                    margin-left: 0;
                    height: calc(100vh - 194px);
                    padding: 0 12px;
                    margin-top: 10px;
                }

                .title {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 600;
                    padding: 11px 0;
                    color: #fff;

                    @media(max-width:992px) {
                        text-align: left;
                        padding: 10px 15px;
                    }
                }

                .dk-getStartedRight-detail {
                    .nav {
                        position: absolute;
                        right: 18px;
                        top: 8px;

                        .nav-item {
                            margin-right: 18px;

                            &:last-child {
                                margin-right: 0;
                            }

                            a {
                                border-bottom: 3px solid transparent;
                                box-shadow: none;
                                color: #737373;
                                font-family: Roboto, sans-serif !important;
                                font-size: 16px;
                                font-weight: 400;
                                padding: 0 0 2px;
                                text-transform: capitalize;
                            }

                            a.active {
                                border-bottom: 3px solid #3c97b6;
                                color: #3c97b6;
                            }
                        }
                    }
                }

                .dk-moduleBankingProcess-rightCols {
                    width: 100%;
                    background: #244158;
                    height: calc(100vh - 246px);
                    border: none;

                    @media(max-width:992px) {
                        height: auto;
                        min-height: 300px;
                        border-top: none;
                    }

                    .dk-getStartedRight-detail {
                        .dk-rightDetail-panel {
                            .d-flex {
                                @media(max-width:992px) {
                                    flex-wrap: wrap;
                                }
                            }

                            .dk-detailLefttabsMain {
                                background: #eaeaea;
                                display: inline-block;
                                height: calc(100vh - 168px);
                                margin-right: 0;
                                max-width: 195px;
                                width: 100%;

                                @media(max-width:992px) {
                                    height: auto;
                                    max-width: 100%;
                                }

                                .MuiBox-root::-webkit-scrollbar {
                                    width: 5px;
                                    height: 5px;
                                }

                                .MuiBox-root::-webkit-scrollbar-track {
                                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                }

                                .MuiBox-root::-webkit-scrollbar-thumb {
                                    background-color: darkgrey;
                                    outline: 0px solid slategrey;
                                }

                                .MuiBox-root {
                                    width: 100%;

                                    @media(max-width:992px) {
                                        display: flex;
                                        overflow-x: auto;
                                    }

                                    .MuiTabs-root {
                                        flex-wrap: wrap;
                                        min-height: auto;
                                        overflow: visible;
                                        width: 100%;

                                        .MuiTabs-scroller {
                                            overflow: visible !important;
                                            white-space: normal;

                                            .MuiTabs-indicator {
                                                display: none;
                                            }

                                            .MuiTabs-flexContainer {
                                                flex-wrap: wrap;
                                                justify-content: space-between;
                                                overflow: visible;

                                                .MuiButtonBase-root {
                                                    align-items: flex-start;
                                                    align-items: left;
                                                    border-bottom: 1px solid #ddd;
                                                    color: #757575;
                                                    display: block;
                                                    flex-shrink: inherit;
                                                    font-size: 15px;
                                                    font-weight: 400;
                                                    max-width: 100%;
                                                    min-height: auto;
                                                    min-width: inherit !important;
                                                    opacity: 1;
                                                    outline: none;
                                                    overflow: visible;
                                                    padding: 12px 15px;
                                                    position: relative;
                                                    text-align: center;
                                                    text-transform: capitalize;
                                                    width: 100%;

                                                    @media(max-width:992px) {
                                                        white-space: nowrap;
                                                    }
                                                }

                                                .MuiButtonBase-root.Mui-selected {
                                                    background: #fff;
                                                    color: #606060;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .dk-financeClients-tableMain {
                padding: 0;
                overflow-x: auto;
                overflow-y: auto;

                table {
                    margin-bottom: 0;
                    border: none;

                    tr {
                        th {
                            padding: 10px 10px;
                            vertical-align: middle;
                            font-size: 13px;
                            background: #67818e;
                            border-bottom: none;
                            color: #fff;
                            text-align: center;
                            border-color: #8e8e8e;
                            white-space: nowrap;
                        }

                        td {
                            background: #f3f3f3;
                            color: #333;
                            vertical-align: middle;
                            font-size: 13px;
                            text-align: center;
                            padding: 4px 10px;
                            border: 1px solid #ccc;

                            // &:nth-child(01) {
                            //     width: 70px;
                            // }

                            // &:nth-child(05) {
                            //     width: 70px;
                            // }

                            // &:nth-child(06) {
                            //     width: 70px;
                            // }

                            .dk-invoiceStatus {
                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                        margin: 0;
                                        box-shadow: none;
                                        border: none;
                                        color: #fff;
                                        text-transform: inherit;
                                        width: 100%;

                                        div {
                                            width: 100%;
                                            height: 38px;
                                            line-height: 38px;
                                            font-size: 14px;
                                        }

                                        &::after {
                                            display: none;
                                        }
                                    }

                                    .dropdown-menu {
                                        padding: 0;
                                        border-radius: 0;
                                        border: none;
                                        width: 100%;
                                        min-width: 110px;
                                        transform: inherit !important;
                                        top: 40px !important;
                                        margin: 0 !important;

                                        a {
                                            display: block;
                                            font-size: 14px;
                                            padding: 6px 10px;
                                            color: #fff;
                                        }
                                    }
                                }
                            }

                            .dk-financeAction {
                                a {
                                    display: inline-block;
                                    width: 30px;
                                    height: 30px;
                                    line-height: 30px;
                                    border-radius: 3px;
                                    color: #fff;
                                    text-align: center;
                                    margin: 0 3px;
                                }

                                a.viewBtn {
                                    background: #ffb11c;
                                }

                                a.downloadBtn {
                                    background: #3a9469;
                                }
                            }

                            .dk-checkinputIcon {
                                left: 7px;
                                position: relative;
                                top: 3px;

                                input[type=checkbox] {
                                    background-color: #f1f1f1;

                                    &:checked {
                                        background-color: #34976e;
                                    }
                                }
                            }
                        }
                    }

                    tfoot {
                        tr {
                            td {
                                padding: 10px 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dkg-contracts-mainCon {
    .dkg-contracts-boxCon.dk-contractsMain-container {
        @media(min-width:768px) {
            flex: 100% !important;
            max-width: 100% !important;
            margin-left: 0 !important;
        }
    }
}

.dk-contractsLeftpanel {
    background: #eaeaea;
    height: calc(100vh - 149px);
    margin-right: 0;
    margin-top: 0;
    max-width: 170px;
    overflow-y: auto;
    padding: 0 !important;
    width: 100%;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            width: 100%;

            a {
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid #ccc;
                border-radius: 0;
                color: #000;
                display: block;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                margin: 0;
                padding: 12px;
                text-transform: uppercase;
            }

            a.active {
                background: #244158;
                color: #fff;
            }
        }
    }
}