.dk-task-for-dropdown{
    a.dk-taskforDropdwntoggle, a.dk-taskforDropdwntoggle:hover{
        box-shadow: none;
        border: 1px solid #c1c9dc;
        padding: 5px 14px;
        height: 34px;
        border-radius: 3px;
        background: #fff;
        margin-top: 0px;
        font-family: Verdana,sans-serif;
        font-size: 14px;
        text-transform: capitalize;
        line-height: 22px;
        color: #797474;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        &::after{
            position: absolute;
            right: 10px;
            top: 15px;
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: '';
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
        }
        .dk-taskByCon{
            border: none !important;
            padding: 0 !important;
            margin: 0;
            position: absolute;
            left: 0;
        }
    }
    .dk-task-for-dropdown-menu{
        padding: 0;
        border-radius: 0;
        width: 100%;
        border: none;
        input{
            border-radius: 0;
            border: 1px dotted #ddd;
            border-bottom: none;
            background-color: #f2f2f2;
            padding: 7px 9px;

        }
        ul{
            border-radius: 0;
            margin: 0;
            padding: 0;
            border: 1px solid #7f9db9;
            border-radius: 0 0 3px 3px;
            position: absolute;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            z-index: 101;
            background-color: #fff;
            overflow-y: scroll;
            max-height: 250px;
            list-style: none;
            left: -1px;
            right: -1px;
            box-shadow: 0 8px 24px rgba(0,0,0,0.10);
            .dropdown-item{
                padding: 5px;
                font-size: 12px;
                color: #333;
                border-bottom: 1px solid #dddddd;
                &.active , &:hover{
                    background-color: #39f;
                    color: #fff;
                }
                &:last-child{
                    border-bottom: none;
                }
                &:after{
                    display: none !important;
                }
                .dk-userCon{
                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                }
                .dk-taskforuserName{
                    font-size: 14px;
                    margin-left: 45px;
                    margin-top: 5px;
                }
            }
        }
    }
}