.dk-candprescreenModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 90% !important;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dk-candprescreenModalHeader {
                background-color: #234158;
                padding: 12px 10px;
                border-radius: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: none;
                position: relative;
                min-height: 49px;

                .dk-prescrenrefreshIcon,
                .dk-prescreensaveIcon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    opacity: 1;
                    cursor: pointer;

                    i {
                        color: #fff;
                        font-size: 1.2rem;
                        opacity: 1;
                    }

                    &:hover {
                        background-color: #eaeaea;

                        i {
                            color: #333;

                        }
                    }
                }

                .dk-prescreensaveIcon {
                    position: absolute;
                    left: 52px;
                }

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: absolute;
                    left: 40%;
                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: 1;
                    color: #fff;
                    font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 40px;
                    position: absolute;
                    right: 2px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dk-candprescreenModalBody {
                background-color: #fff;
                padding: 0;
                height: calc(100vh - 45px);
                overflow-y: auto;

                .dkg-canpresrcreen-tab-mainCon {
                    .dkg-canpresrcreen-tabCon {
                        .dkg-canpresrcreen-tab-navpills {
                            margin-bottom: 0;
                            width: 100%;
                            background-color: #eaeaea;

                            .dkg-canpresrcreen-tab-nav-item {
                                max-width: 14.28%;
                                // max-width: 12.5%;
                                width: 100%;

                                .dkg-canpresrcreen-tab-navlink {
                                    background-color: #eaeaea;
                                    border-radius: 0;
                                    color: #5a5858;
                                    font-size: .85rem;
                                    padding: 0.65rem 1rem;
                                    text-align: center;
                                    text-transform: uppercase;

                                    &.active,
                                    &.show>.nav-link {
                                        background-color: #fff;
                                        color: #333;
                                    }
                                }
                            }
                        }

                        .dkg-canpresrcreen-tabcontent {
                            .dkg-canpresrcreen-tabpane {
                                background-color: #fff;
                                padding: 15px 0 !important;
                                height: calc(100vh - 81px);
                                // padding-top: 25px !important;

                                .dk-candprescreeningTableCon {
                                    .dk-candprescreeningTable {
                                        margin: 0;

                                        tbody tr td {
                                            border-bottom: 1px solid #cdc5c5;
                                            padding-top: 0;
                                            padding-bottom: 0;

                                            &:first-child {
                                                min-width: 140px;
                                                white-space: nowrap;
                                                color: #000;
                                                vertical-align: middle;
                                                font-size: 14px;

                                                .dk-prescreenleftCon {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: space-between;

                                                    .dkprescreen-info {
                                                        .MuiTypography-root {
                                                            background-color: #f2f2f2;
                                                            width: 24px;
                                                            height: 24px;
                                                            border-radius: 50%;
                                                            border: 1px solid #ddd;
                                                            display: flex;
                                                            justify-content: center;
                                                            align-items: center;
                                                            cursor: pointer;

                                                            .MuiSvgIcon-root {
                                                                cursor: pointer;
                                                                color: #7A7B80;

                                                                i {
                                                                    font-size: 14px;
                                                                }
                                                            }

                                                            i {
                                                                font-size: 11px;
                                                                color: #524f4f;
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            &:last-child {
                                                background-color: #fff;
                                                width: 100%;
                                                padding-left: 0;
                                                padding-right: 5px;

                                                input[type="text"] {
                                                    border: none;
                                                    background-color: #fff;
                                                    color: #00f;
                                                    font-size: 14px;
                                                    text-align: left;
                                                    width: 100%;
                                                    height: calc(1.5385em + 1rem + 10px);
                                                    padding: 0.375rem 0.5rem;

                                                    &:focus {
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }

                                                textarea:focus {
                                                    box-shadow: none;
                                                    outline: 0;
                                                }

                                                textarea.form-control {
                                                    height: auto;
                                                    border: none;
                                                    background-color: #fff;
                                                    color: #00f;
                                                    font-size: 14px;
                                                    text-align: left;
                                                    width: 100%;
                                                    padding: 0.375rem 0.5rem;

                                                    &:focus {
                                                        outline: 0;
                                                        box-shadow: none;
                                                    }
                                                }

                                            }
                                        }
                                    }

                                    .dk-prescreenbtnCon {
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 100%;
                                        padding: 1.25rem 0;

                                        .dk-prescreenbtn,
                                        .dk-prescrenrefreshIcon {
                                            background-color: #234158;
                                            border: 1px solid #234158;
                                            color: #fff;
                                            width: 100px;
                                            text-align: center;
                                            display: inline-block;
                                            padding: 5px 12px;
                                            text-transform: uppercase;
                                            font-size: 13px;
                                            line-height: 1.5385;
                                            border-radius: 0.1875rem;

                                            &:hover {
                                                background: #2685A5;
                                                border: 1px solid #2685A5;
                                            }
                                        }
                                    }
                                }

                                .dk-newprescrenformTableCon {
                                    padding: 15px;
                                    overflow-x: auto;
                                    overflow-y: auto;

                                    .dk-newprescrenformTable {
                                        margin-bottom: 0;

                                        thead tr th {
                                            border: 1px solid #ddd !important;
                                            background-color: #3c97b6;
                                            color: #fff;
                                            padding: 9px 10px;
                                            text-align: center;
                                            vertical-align: middle;
                                            white-space: nowrap;

                                        }

                                        tbody tr td,
                                        tfoot tr td {
                                            background-color: #f2f2f2;
                                            color: #333;
                                            padding: 6px 10px;
                                            text-align: center;
                                            vertical-align: middle;
                                            border: 1px solid #ddd;
                                            white-space: nowrap;

                                            &:nth-child(05),
                                            &:nth-child(06),
                                            &:nth-child(07),
                                            &:nth-child(08),
                                            &:nth-child(09),
                                            &:nth-child(10),
                                            &:nth-child(11) {
                                                text-align: left;
                                            }

                                            .dkg-action-cols {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            }

                                            .dk-checkinputIconCon {
                                                align-items: center;
                                                display: flex;
                                                justify-content: center;

                                                .dk-checkinputIcon {
                                                    left: 10px;
                                                    position: relative;
                                                    top: 4px;

                                                    input[type=checkbox] {
                                                        background-color: #f1f1f1;
                                                    }

                                                    input[type=checkbox]:checked {
                                                        background-color: #34976e;
                                                    }
                                                }
                                            }

                                            .newactionCols {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                            }

                                            .dk-prescreen-recuiter-con {
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;

                                                .dk-prescreen-recuiter-img {
                                                    width: 28px;
                                                    height: 28px;
                                                    border-radius: 50%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-candprescreenModal.fade .modal-dialog {
    right: -90%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-candprescreenModal.show .modal-dialog {
    right: 0;
}

.modal.dk-candprescreenModal {
    z-index: 9999 !important;
}