.other-InfoMainCon{
    .title{
        font-size: 18px;
        font-weight: 600;
        color: #333;
        margin-bottom: 0.5;
    }
    .dk-skillsCols{
        p {
            font-size: 14px;
        }
    }
    .table.dk-otherInfoTable{
        tbody{
            tr td{
                padding: 12px 10px;
                font-weight: 500;
                border: none;
                font-size: 14px !important;
                vertical-align: middle;
                background: transparent;
                text-align: left;
            }
            tr td:first-child {
                font-weight: 600;
                width: 245px;
            }
            tr td:nth-child(02) {
                width: 50px;
            }
        }
    }
}