.dk-candidateProfile80.modal-dialog{
    &.dkg-jobsfaq-popupMain{
        min-width: 75% !important;
        max-width: 75% !important;
        .modal-content{
            // .dk-candModalHeader{

            // }
            .dk-livejobs-ModalBody{
                height: calc(100vh - 32px);
                overflow-y: auto;
                .dkg-payment-faq-pageCon{
                    .dkg-candprescreen-mainRow{
                        color: #1f3748;
                        background-color: transparent !important;
                        .dkg-clientlist-faq-mainCon{
                            .dkg-payment-faq-tab-mainCon{
                                @media (min-width: 768px) {
                                    &.col-md-10.dkg-faq-rightMenuCon-234 {
                                        flex: 0 0 80.333333%;
                                        max-width: 80.333333%;
                                    }
                                }

                                .dkg-payment-faq-tabcontent {
                                    color: #1f3748;

                                    div {
                                        color: #24475f;

                                        .dkg-screen-faq-accodionCon {
                                            color: #1f3748;
                                            border: inherit !important;
                                            .dkg-screen-faq-accodion {
                                                border: none !important;
                                                border-bottom: 1px solid #4d5a6b !important;
                                                background-color: #1f3748;
                                                color: #fff;
                                                border-bottom: 1px solid #4d5a6b;
                                                min-height: 52px;
                                                position: relative;

                                                .MuiAccordionSummary-content {
                                                    p {
                                                        color: #fff;
                                                        font-size: 14px;
                                                    }
                                                }


                                                .MuiAccordionSummary-content.Mui-expanded {
                                                    p.MuiTypography-root.MuiTypography-body1 {
                                                        color: #fff;
                                                        font-size: 14px;
                                                        font-family: inherit;
                                                    }
                                                }

                                                .MuiAccordionSummary-expandIconWrapper.Mui-expanded,
                                                .MuiAccordionSummary-expandIconWrapper {
                                                    position: absolute;
                                                    right: 10px;

                                                    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
                                                        color: #fff;
                                                    }
                                                }
                                            }

                                            &:first-child {
                                                .dkg-screen-faq-accodion {
                                                    border-radius: 5px 5px 0 0 !important;
                                                }
                                            }

                                            &:last-child {
                                                .dkg-screen-faq-accodion {
                                                    border-radius: 0 0 5px 5px;
                                                }
                                            }

                                            .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
                                                .MuiCollapse-wrapper.MuiCollapse-vertical {
                                                    .MuiCollapse-wrapperInner.MuiCollapse-vertical {
                                                        .MuiAccordion-region {
                                                            .MuiAccordionDetails-root {
                                                                background-color: #24475f;
                                                                border-radius: 0 0 5px 5px;

                                                                p.MuiTypography-root.MuiTypography-body1 {
                                                                    color: #fff;
                                                                }

                                                                textarea.dkg-faq-answertextArea {
                                                                    border: none;
                                                                    outline: 0;
                                                                    box-shadow: none;
                                                                    color: #fff;
                                                                    background-color: #24475f !important;
                                                                    font-size: 14px;
                                                                    resize: none;
                                                                    border: none !important;
                                                                    width: 100% !important;
                                                                    cursor: text;
                                                                    caret-color: #24475f;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.fade .dk-candidateProfile80.modal-dialog {
    &.dkg-jobsfaq-popupMain{
        right: -75%;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }
  }

  .modal.show .dk-candidateProfile80.modal-dialog {
    &.dkg-jobsfaq-popupMain{
        right: 0;

    }
  }
