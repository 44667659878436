.dkg-application-view-modal{
    .modal-dialog{
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 600px !important;
        max-width: 600px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        .modal-content{
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dkg-app-progress-modal-header{
                background-color: #3C97B6;
                border-radius: 0;
                padding: 14px 15px !important;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.dkg-app-widthraw-modal-header{
                    background-color: #ca464f;
                    
                }
                .modal-title {
                    color: #fff;
                    text-transform: capitalize;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;


                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;
                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }
            .dkg-app-progress-modal-body{
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-cand-progress-app-mainCon{
                    width: 100%;
                    padding-left: 2.25rem;
                    .dkg-progress-app-listCon{
                        position: relative;
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        .dkg-progress-app-list{
                            position: relative;
                            min-height: 75px;
                            counter-increment: list;
                            padding-left: 0.5rem;
                            justify-content: center;
                            align-content: center;
                            position: middle;
                            &:before {
                                content: "";
                                position: absolute;
                                left: -1.55rem;
                                top: 52px;
                                height: 80%;
                                width: 1px;
                                border-left: 1px solid #848484;
                            }
                            &:after {
                                content: counter(list);
                                position: absolute;
                                top: 26px;
                                left: -2.5rem;
                                width: 26px;
                                height: 26px;
                                border-radius: 50%;
                                background: transparent;
                                color: #848484;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 2rem;
                                text-align: center;
                                border: 1px solid #848484;
                            }
                            &.current{
                                &:after {
                                    background: #fff;
                                    color: #777;
                                    font-weight: bold;
                                }
                            }
                            &:last-child{
                                &:before{
                                    height: 30%;
                                }
                            }
                            .dkg-progress-app-card-Con{
                                background-color: #fff;
                                color: #333;
                                border-radius: 5px;
                                height: 75px;
                                margin-bottom: 0.75rem;
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                .dkg-app-progress-imgcols{
                                    width: 70px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 0 10px;
                                    .dkg-app-progress-img{
                                        width: 40px;
                                        height: 40px;
                                        display: inline-block;
                                        border-radius: 50%;
                                        background-size: cover;
                                        background-repeat: no-repeat;
                                        border: 1px solid #ddd;

                                    }
                                }
                                .dkg-app-porgess-statuscols{
                                    width: 100%;
                                    display: flex;
                                    justify-content: space-between;
                                    .dkg-app-status-firstCols,
                                    .dkg-app-status-seocndCols,
                                    .dkg-app-status-thirdCols{
                                        width: 100%;
                                        text-align: center;
                                        .dkg-status-title{
                                            position: relative;
                                            top: 3px;
                                            font-size: 12px;
                                        }
                                        .dkg-progress-status-item{
                                            height: 30px;
                                            line-height: 15px;
                                            border: 1px solid transparent;
                                            margin-top: 5px;
                                        }
                                        .dkg-progess-app-date{
                                            position: relative;
                                            top: 3px;
                                            font-size: 12px; 
                                        }

                                    }
                                    .dkg-app-status-firstCols,
                                    .dkg-app-status-seocndCols{
                                        .dkg-progress-status-item{
                                            border-right: 1px solid #f2f2f2;
                                        }
                                    }
                                }
                                .dkg-app-progress-date-cols{
                                    width: 195px;
                                    display: flex;
                                    justify-content: center;
                                    display: none;
                                    .dkg-progess-app-date{
                                        position: relative;
                                        top: 12px;
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            .dkg-app-widthraw-modal-body{
                background-color: #eaeaea;
                padding: 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-app-widthraw-desc-con{
                    .dkg-app-withdraw-title{
                        font-size: 20px;
                        text-align: center;
                        font-weight: 600;
                        margin: 25px 0;
                        margin-bottom: 55px;
                    }
                    .dkg-app-widthraw-desc{
                        font-size: 1.15rem;
                        margin-bottom: 1.25rem;
                    }
                }
                .dkg-app-withdraw-ulCon{
                    .dkg-app-withdraw-ul{
                        margin-bottom: 2rem;
                        .dkg-app-withdraw-li{
                            list-style: none;
                            display: flex;
                            margin-bottom: 0.5rem;
                            .dkg-app-withdraw-checkbox{
                                margin-right: 0.5rem;
                                input[type="checkbox"]{
                                    background-color: white;
                                    border-radius: 0;
                                    &:checked {
                                        background-color: #03a786; /* bright green */
                                    }
                                
                                }
                            }
                            .dkg-app-withdraw-reson{
                                font-size: 14px;
                                position: relative;
                                top: -2px;                            
                            }
                        }
                    }
                    .dkg-appwithdraw-textarea-Con{
                        .dkg-appwithdraw-textarea{
                            width: 100%;
                            text-align: left;
                            border: 1px solid #ddd;
                            height: 120px;
                            padding: 5px 10px;
                            font-size: 13px;
                        }
                    }
                }
                .dkg-appwithdraw-btnCon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 25px;
                    div{
                        div{
                            .dkg-appwithdraw-btn{
                                background-color: #ca464f;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
.modal.dkg-application-view-modal.fade .modal-dialog {
    right: -600px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dkg-application-view-modal.show .modal-dialog {
    right: 0;
}

.modal.dkg-application-view-modal {
    z-index: 9999 !important;
}