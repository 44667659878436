.dk-navBarMain.dk-recruitmentNavbar.dkg-ManagementMainMenu-234 {
    background: #884C5E !important;

    ul {
        li {
            a {
                background: #884C5E;

                &:hover {
                    color: #333;
                    background: #fff;
                }
            }

            a.active {
                background: #fff;

                &:hover {
                    color: #333;
                }
            }
        }
    }
}