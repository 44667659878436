.cursor-pointer {
    cursor: pointer;
}

.dk-pipedocs-previewModal {
    position: fixed;
    right: 0;
    top: 0;
    margin: 0 !important;
    min-width: 73%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .modal-content {
        .dk-pipedocs-preview-modalbody {
            padding: 0;

            iframe {
                display: block !important;
                width: 100%;
                height: calc(100vh - 46px);
            }
        }

        // .modal-header {
        //     margin: 0 !important;
        //     padding: 0;
        //     border-bottom: none;

        //     .close {
        //         padding: 0;
        //         display: inline-block;
        //         margin: 0;
        //         background: #26597d;
        //         position: absolute;
        //         right: -16px;
        //         width: 35px;
        //         height: 35px;
        //         z-index: 99;
        //         opacity: 1;
        //         text-shadow: none;
        //         color: #fff;
        //         border-radius: 50%;
        //         top: -13px;
        //         outline: none;
        //         box-shadow: none;

        //         &:hover {
        //             background: #4297d3;
        //             transition: all 0.5s;
        //         }
        //     }
        // }
        .dkg-pipeline-candModal-header {
            background-color: #3c97b6;
            padding: 12px 10px;
            border-radius: 0;

            .modal-title {
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;
                font-size: 1.2rem;
                color: #fff;
            }
        }
    }
}

.modal.fade .modal-dialog.dk-pipedocs-previewModal {
    right: -73%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .modal-dialog.dk-pipedocs-previewModal {
    right: 0;

}

.modal.dk-modalCvRight {
    .modal-dialog {
        margin: 0 !important;
        float: right;
        min-width: 60%;

        .modal-content {
            .modal-header {
                background: #26597d;
                border-radius: 0;
                padding: 10px 12px;
                border-bottom: none;

                .modal-title {
                    color: #fff;
                    display: block;
                    font-size: 16px;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    a {
                        color: #fff;
                        display: inline-block;
                        margin: 0 7px;

                        &:hover {
                            color: #fff;
                        }
                    }
                }

                .close {
                    color: #fff;
                    font-size: 22px;
                    margin: 0;
                    opacity: 1;
                    outline: none;
                    padding: 0;
                    position: absolute;
                    right: 8px;
                    text-shadow: none;
                    top: 10px;
                }
            }
        }
    }
}
.modal-dialog.dk-pipedocs-previewModal{
    .dkg-candprof-cvdownload-header{
        position: relative !important;
        display: flex;
        justify-content: flex-start !important;
        height: 38px;
        .dkg-cvdownload-title{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 10px !important;
            -ms-transform: translate(10px, -50%) !important;
            transform: translate(10px, -50%) !important;
        }
        .dkg-cv-back-forw-iconCon{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            .dkg-backwordIcon,
            .dkg-forwordIcon{
               svg{
                color: #fff;
                width: 21px;
                height: 21px;
                cursor: pointer;
                &:hover{
                    opacity: 0.85;
                }
               } 
            }
        }
    }
}