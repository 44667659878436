.dk-notificationsnewModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 900px !important;
        max-width: 900px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dk-notificationsnewModalHeader {
                background-color: #234158 !important;
                padding: 12px 10px;
                border-radius: 0;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                .dk-unreadEmails{
                    color: #fff;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover{
                       color: #333;
                       background-color: #eaeaea;
                       transition: ease-in-out all 0.5s;
                    }
                }
                .dkg-addnew-notification {
                    color: #fff;
                    cursor: pointer;

                }

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;

                    .dk-plusicon-con {
                        position: absolute;
                        right: -20px;
                        cursor: pointer;

                        i {
                            color: #e3dfdf;
                            font-size: 16px;
                        }
                    }
                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dk-notificationsnewModalBody {
                background-color: #fff;
                padding: 25px 15px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-compy-noti-left-boxcon {
                    .dkg-compy-noti-box-cols {
                        .dk-moduleNoti-box.active {
                            background: #244158;

                            p {
                                color: #ddd;

                            }

                            .d-timeflex {
                                border-left: 1px solid #1d5989 !important;

                                small {
                                    color: #ddd;

                                    &:last-child {
                                        border-top: 1px solid #1d5989 !important;
                                    }

                                    svg {
                                        color: #ddd !important;
                                    }
                                }
                            }

                            .sbtitle {
                                color: #ddd !important;
                                padding: 0 10px;
                            }

                            &:hover {
                                background-color: #fff;
                                transition: all 0.5s;

                                .dk-notificationText {
                                    color: #333;

                                    .dkg-compy-noti-boxs-Header {
                                        color: #333;

                                        .d-timeflex {
                                            border-left: 1px solid #ddd !important;

                                            small {
                                                color: #333;

                                                &:last-child {
                                                    border-bottom: none;
                                                    border-top: 1px solid #ccc !important;
                                                }
                                            }
                                        }
                                    }

                                    p {
                                        color: #333;
                                    }

                                    .dk-notification-tags {
                                        .sbtitle {
                                            color: #333;
                                        }
                                    }

                                    .d-timeflex {
                                        small {
                                            svg {
                                                color: #333;
                                            }
                                        }
                                    }

                                    .dkg-compy-noti-boxs-Header {
                                        .timeflex {
                                            span {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-notificationText {
                                border-left: none !important;
                                .dk-notification-w100{
                                    p{
                                        &:hover{
                                            color: #629bd3;
                                        }
                                    }
                                }

                            }

                            .dk-notificationImg {
                                span {
                                    color: #333;
                                }
                            }
                        }

                        .dk-moduleNoti-box {
                            background: #eaeaea;
                            display: flex;
                            align-items: center;
                            padding: 0 12px;
                            padding-right: 0;
                            border-left: 4px solid #2e97b6;
                            margin-bottom: 12px;
                            border-top: 1px solid #ddd;
                            border-bottom: 1px solid #ddd;
                            border-right: 1px solid #ddd;
                            transition: all 0.5s;
                            cursor: pointer;
                            min-height: 80px;

                            &:hover {
                                background-color: #fff;
                                transition: all 0.5s;

                                .dk-notificationText {
                                    color: #333;

                                    p {
                                        color: #333;
                                    }

                                    .dk-notification-tags {
                                        .sbtitle {
                                            color: #333;
                                        }
                                    }

                                    .d-timeflex {
                                        small {
                                            svg {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }

                            .dk-notificationImg {
                                width: 100%;
                                max-width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                margin-right: 12px;
                                margin-top: 0;

                                img {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                }

                                span {
                                    text-align: center;
                                    display: block;
                                    margin-top: 3px;
                                    font-size: 16px;
                                    color: #8a8a8a;
                                }
                            }

                            .dk-notificationText {
                                font-weight: 400;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                border-right: none;
                                padding:0 15px;

                                .sbtitle {
                                    font-weight: 500;
                                    padding: 0 10px;
                                    position: relative;
                                    bottom: 6px;

                                    span {
                                        font-weight: 300;
                                    }
                                }

                                .dk-notification-tags {
                                    display: flex;

                                    .sbtitle {
                                        font-weight: 400;
                                        display: flex;
                                        align-items: center;
                                        color: #333;
                                        margin-right: 30px;
                                        font-size: 13px;

                                        svg {
                                            display: inline-block;
                                            margin-right: 3px;
                                        }
                                    }

                                    a {
                                        display: inline-block;
                                        background: #dadada;
                                        padding: 0px 12px;
                                        border-radius: 30px;
                                        color: #333;
                                        text-decoration: none;
                                        margin-right: 7px;

                                        &:last-child {
                                            margin-right: 0;
                                        }

                                        span.closeTags {
                                            display: inline-block;
                                            background: #ccc;
                                            color: #000;
                                            padding: 2px 8px;
                                            border-radius: 0 30px 30px 0;
                                            margin-left: 5px;

                                            &:hover {
                                                background: #333;
                                                color: #fff;
                                                transition: all 0.5s;
                                            }
                                        }
                                    }
                                }

                                .d-timeflex {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: flex-end;
                                    width: 100%;
                                    border-left: 1px solid #ccc;
                                    margin-left: 0;
                                    min-height: 78px;
                                    padding: 0;
                                    width: 100%;
                                    max-width: 120px;
                                    white-space: nowrap;

                                    small {
                                        display: inline-block;
                                        margin-right: 0;
                                        width: 100%;
                                        max-width: 100%;
                                        text-align: center;
                                        padding: 8px 10px;

                                        svg {
                                            font-size: 15px;
                                            display: inline-block;
                                            position: relative;
                                            bottom: 1px;
                                            margin-right: 3px;
                                            color: #333;
                                        }

                                        &:first-child {
                                            position: relative;
                                            top: 3px;
                                        }

                                        &:last-child {
                                            border-bottom: none;
                                            border-top: 1px solid #ccc;
                                        }
                                    }
                                    &:last-child{
                                        border-right: 1px solid #ddd;
                                    }
                                }

                                p {
                                    margin: 0;
                                    font-size: 17px;
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    justify-content: space-between;
                                    padding-left: 10px;
                                    &:hover{
                                        color: #629bd3;
                                    }
                                    span {
                                        width: 100%;
                                        display: inline-block;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        font-size: 14px;
                                    }

                                    small {
                                        width: 100%;
                                        text-align: right;
                                        max-width: 80px;
                                    }
                                }

                                small {
                                    display: block;
                                    width: 100%;
                                    max-width: 75px;
                                    margin-top: 0;
                                    font-size: 12px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-notificationsnewModal.fade .modal-dialog {
    right: -900px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-notificationsnewModal.show .modal-dialog {
    right: 0;
}

.modal.dk-notificationsnewModal {
    z-index: 9999 !important;
}

.dk-warningMain {
    li {
        display: block;
        background: #fff;
        color: #333;
        padding: 0;
        border-bottom: 1px solid #ccc;
        position: relative;

        .dk-warningHead {
            display: inline-block;
            position: absolute;
            right: 8px;
            top: 3px;

            a {
                display: inline-block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: #6e6e6e;
                text-align: center;
                color: #fff;
                font-size: 10px;
                line-height: 17px;
            }
        }

        .dk-warningCols {
            padding: 15px 15px;
            position: relative;

            .dk-warningTime {
                font-size: 12px;
                color: #333;
            }
        }

        h6 {
            font-weight: 600;
            font-size: 16px;
            text-transform: uppercase;
        }

        p {
            color: #333;
        }
    }

    form {
        .form-group {
            display: flex;
            align-items: center;
            margin-bottom: 1.25rem;

            label {
                font-size: 13px;
                margin-bottom: 0;
                min-width: 160px;
                text-align: left;
            }

            .dk-recuiter-drop-con12 {
                display: flex;
                justify-content: center;
                width: 100%;

                .dk-recuiter-dropdown {
                    .dk-recuiter-dropdown-toggle {
                        background-color: transparent !important;
                        outline: none;
                        border: none;
                        box-shadow: none;
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        padding: 0;
                        margin: 0;

                        .dkg-noti-recuiter-img {
                            display: inline-block;
                            width: 35px;
                            height: 35px;
                            border-radius: 50%;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    .dk-noti-recuiter-dropdown-menu {
                        padding: 5px 5px;
                        border-radius: 0;
                        margin: 0 !important;
                        border: none;
                        background-color: #234158;
                        transform: inherit !important;
                        top: -6px !important;
                        left: 49px !important;
                        right: in !important;
                        right: inherit !important;

                        &::before {
                            content: '';
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 8px 15px 8px 0;
                            border-color: transparent #234158 transparent transparent;
                            line-height: 0px;
                            _border-color: #000000 #234158 #000000 #000000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
                            position: absolute;
                            left: -13px;
                            top: 14px;
                        }

                        a {
                            display: inline-block;
                            height: 35px;
                            height: 35px;
                            margin: 0 3px;
                            padding: 0;

                            &:focus {
                                background: transparent !important;
                            }

                            img {
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                            }

                            &:hover {
                                background: transparent;
                            }
                        }
                    }
                }
            }

            .dkg-notification-task-drop12 {
                width: 100%;

                .dkg-notification-task-dropdown {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .dkg-noti-task-dropdown-toggle {
                        box-shadow: none;
                        background-color: #fff;
                        padding: 7px 10px;
                        width: 100%;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        text-transform: capitalize;
                        font-size: 13px;
                        color: gray;
                        margin-left: 0;
                        margin-right: 0;
                        border: 1px solid #ddd;
                        margin: 0;
                        height: 32px;
                        line-height: 26px;

                        &:after {
                            color: gray;
                            display: inline-block;
                            font-size: 16px;
                            position: absolute;
                            right: 11px;
                            top: 16px;
                        }
                    }

                    .dkg-noti-task-dropdown-menu {
                        border-radius: 0;
                        padding: 0;
                        width: 100%;
                        overflow-y: auto;
                        max-height: 220px;

                        .dkg-noti-task-dropdown-item {
                            border-bottom: 1px solid #ddd;
                            padding: 6px 7px;
                            font-size: 13px;

                            &:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }

                &.dkg-payment-task-readonly-drop12 {
                    .dropdown {
                        .dropdown-toggle {
                            background-color: #f2f2f2;

                        }
                    }
                }

                .dkg-invoice-addnewPopup {
                    .MuiGrid-root.MuiGrid-container {
                        .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
                            width: 100%;
                            margin-top: 0;
                            margin-bottom: 0;

                            .MuiInputBase-root.MuiInput-root {
                                input {
                                    background-color: #fff;
                                }

                                .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
                                    margin-left: 0;

                                    .MuiButtonBase-root.MuiIconButton-root {
                                        padding: 5px 6px;
                                        background: #fff;
                                        border-radius: 0;
                                        border: 1px solid #ddd;
                                        border-left: none;
                                    }
                                }
                            }
                        }
                    }
                }

                &.dkg-invoice-addnewPopup.dkg-hidelabel-text {
                    .MuiGrid-root.MuiGrid-container {
                        .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
                            width: 100%;
                            margin-top: 0;
                            margin-bottom: 0;

                            .MuiInputBase-root.MuiInput-root {
                                input {
                                    background-color: #fff;
                                    color: #26597d;
                                }

                                .MuiInput-underline.Mui-error:after {
                                    border-bottom: none !important;
                                }
                            }

                            .MuiFormHelperText-root.Mui-error {
                                display: none;
                            }

                            .MuiInput-underline:after {
                                border-bottom-color: transparent !important;
                            }
                        }
                    }
                }
            }

            .dkg-addnew-date-timeCon {
                display: flex;
                align-items: center;
                width: 100%;

                .MuiFormControl-root {
                    &.MuiFormControl-marginNormal {
                        margin: 0;
                        width: 100%;
                    }

                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                        &::before {
                            display: none;
                        }

                        &::after {
                            display: none;
                        }

                        input {
                            text-align: center;
                            background-color: #fff;
                            border: 1px solid #ddd;
                        }

                        .MuiInputAdornment-positionEnd {
                            margin-left: 0;

                            .MuiIconButton-root {
                                padding: 6px 7px;
                                background-color: #26597d;
                                border-radius: 0;
                                border: 1px solid #ccc;
                                border-left: 0;

                                .MuiIconButton-label {
                                    svg {
                                        color: #fff;
                                        font-size: 1.25rem;
                                    }
                                }
                            }
                        }
                    }
                }

                .dkg-readonly-datetime-picker {
                    color: #234158;

                    .MuiFormControl-root {
                        &.MuiFormControl-marginNormal {
                            margin: 0;
                            width: 100%;
                        }

                        .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                            input {
                                text-align: center;
                                background-color: #f2f2f2;
                            }

                            .MuiInputAdornment-positionEnd .MuiIconButton-root {
                                cursor: text;
                            }
                        }
                    }

                }
            }

            .dkg-sub-input-group {

                // .dkg-sub-input-group-prepend {
                // }
                .dkg-sub-form-control {
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 3px;
                    height: 35px;
                    line-height: 35px;
                    min-height: 35px;
                    padding: 0 10px !important;
                    text-align: left;
                    font-size: 12px;
                }
            }

            textarea {
                border: 1px solid #ddd !important;
                background: #fff;
                box-shadow: none !important;
                height: 110px;
                border-radius: 3px;
                resize: vertical;
                text-align: left;

                &:focus::placeholder {
                    color: transparent;
                }
            }

            .dk-notiAddnewBtn-Con {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                .dk-notiAddnewBtn {
                    display: inline-block;
                    padding: 8px 25px;
                    background: #234158;
                    border: none;
                    outline: none;
                    border-radius: 5px;
                    color: #fff;
                    font-family: 'Poppins', sans-serif;
                    text-transform: uppercase;
                    font-weight: 500;

                    &:hover {
                        background: #3c97b6;
                        transition: all 0.5s;
                    }
                }
            }
        }
        .col-12{
            iframe{
                display: block !important;
                width: 100%;
                max-width: calc(100% - 160px);
                margin-left: auto;
            }
        }
    }
}