.modal-dialog.dkmb-rightFliterModal {
    position: fixed;
    top: 0;
    transform: inherit !important;
    right: 0;
    min-width: 430px;
    max-width: 430px;
    margin: 0 !important;
    height: 100vh;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
    z-index: 9999;

    .modal-content {
        border-radius: 0;
        border: none;
        height: 100vh;

        .modal-header.dkmb-Fliterheader {
            padding: 8px 0;
            text-align: center;
            background: #d8d3d3;
            color: #000;

            .modal-title.dkmb-FliterTitle {
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
            }
        }

        .modal-body.dkmb-Fliterbody {
            padding: 0;
            background-color: #234158;

            .dk-leftSideMenu {
                .nav-item {
                    background-color: #eaeaea;

                    .nav-link {
                        border-radius: 0;
                        color: #333;
                        text-transform: uppercase;
                        font-size: 13px;
                        padding: 12px;

                        i {
                            display: inline-block;
                            width: 22px;
                            text-align: left;
                            font-size: 14px;
                        }

                        svg {
                            display: inline-block;
                            font-size: 15px;
                            margin-right: 0.45rem;
                        }
                    }

                    .nav-link.active,
                    & .show>.nav-link {
                        color: #ddd;
                        background-color: #234158 !important;
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    .dk-rightMainContent {
                        padding: 1.25rem;
                        height: 95vh;
                        overflow-y: auto;

                        .dk-satusList {
                            width: 100%;
                            display: block;
                            text-align: center;
                            max-width: 90%;
                            margin: 0 auto;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li.dk-fliterList {
                                    margin: 20px 0 0;

                                    a {
                                        padding: 5px 10px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 35px;
                                        line-height: 22px;
                                        font-size: 0.95rem;

                                        .dk-statusCounter {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-filterList {
                            display: flex;
                            justify-content: left;
                            align-items: center;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    a {
                                        color: #fff;
                                        font-size: 13px;

                                        span {
                                            color: #ff9800;
                                            margin-left: 5px;
                                        }
                                    }
                                }
                            }
                        }

                        .dk-recruitersfilterList {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            ul {
                                list-style: none;
                                margin-bottom: 0;

                                li {
                                    a {
                                        color: #fff;
                                        font-size: 13px;
                                        display: block;
                                        width: 100%;
                                        margin-top: 15px;
                                        margin-bottom: 10px;

                                        img {
                                            width: 40px;
                                            height: 40px;
                                            border-radius: 50%;
                                        }

                                        span {
                                            color: #ff9800;
                                            margin-left: 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.fade .modal-dialog.dkmb-rightFliterModal {
    right: -340px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .modal-dialog.dkmb-rightFliterModal {
    right: 0px;
}

.modal {
    z-index: 9999 !important;
}

.dk-qulaifyTopHeadTitle .dk-filterTagsCon .dk-filterbtnGrp .dk-filterbtntext.btn-primary {
    background: #366995 !important;
}

.dk-qulaifyTopHeadTitle .dk-filterTagsCon .dk-filterbtnGrp .dk-filterbtntext {
    background: #366995;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    color: #fff;
    font-size: 12px !important;
    line-height: 10px;
    padding: 5px 7px;
    text-transform: inherit;
}

.dk-qulaifyTopHeadTitle .dk-filterTagsCon .dk-filterbtnGrp .dk-filterbtnicon.btn-primary {
    background-color: #234158 !important;
}

.dk-qulaifyTopHeadTitle .dk-filterTagsCon .dk-filterbtnGrp .dk-filterbtnicon {
    background: #234158;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    font-size: 11px;
    padding: 4px 7px;
}

.fr-btn-grp {
    margin: 0px !important;
}