.dkg-paidservices-homeMain{
   margin-top: 155px;
   height: calc(100vh -  155px);
   overflow-y: auto;
   background-color: #eaeaea;
   .dkg-paidservices-main-banner{
      background-image: url(../../../../../assets/images/banner-service.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      left: 0px;
      top: 0;
      height: 520px;
      width: 100%;
      .dkg-paidservice-main-con{
         width: 100%;
         max-width: 1600px;
         margin: 0 auto;
         height: 100%;
         position: relative;
         .dkg-service-btncontent-con{
            // width: 385px;
            padding: 70px 20px;        
            .dkg-paidservice-content{
               .dkg-paidservice-title{
                  color: #ddd;
                  // font-family: Aleo !important;
                  font-family: "Poppins", sans-serif !important;
                  font-weight: bolder;
                  font-size: 36px;
               }
               .dkg-paidservice-subtitle{
                  color: #ddd;
                  max-width: 500px;
                  font-size: 15px;
               }
            }
            .dkg-book-appointment-btnCon{
               margin-top: 30px;
               .dkg-book-appointment-btn{
                  display: inline-block;
                  padding: 10px 25px;
                  background-color: #00466b;
                  color: #e9e6e6;
                  text-transform: capitalize;
                  border-radius: 25px;
                  font-size: 1rem;
                  .dkg-calender-icon{
                     margin-left: 0.95rem;
                     svg{
                        color: #e9e6e6;
                     }
                  }
                  &:hover{
                     box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.16) inset, 0px 0px 10px 0px #33E7EA;
                     transition: 0.5s ease-in-out;
                  }
               }
            }
         }
         .dkg-paidservice-bottom-sec{
            position: absolute;
            left: 0;
            bottom: -60px;
            width: 100%;
            .dkg-paidservice-bottom-ul{
               list-style: none;
               display: flex;
               align-items: center;
               width: 100%;
               .dkg-paidservice-bottom-li{
                  background-color: #00466b;
                  color: #e9e6e6;
                  width: 100%;
                  max-width: 16.6666%;
                  height: 160px;
                  text-align: center;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  border: 1px solid #787474;
                  border-right: none;
                  cursor: pointer;
                  &:last-child{
                     border-right: 1px solid #787474;
                  }
                  &:hover{
                     box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
                  }
                  .dkg-service-icon-con{
                     .dkg-service-icon{
                        display: inline-block;
                        width: 60px;
                        height: 60px;
                     }
                     padding-bottom: 1rem;
                  }
                  .dkg-service-name{
                     font-size: 1.35rem;
                  }
               }
            }
         }
      }
   }
}