.dkg-fullimage-view-modal-21{
    .modal-dialog{
        max-width: 900px;

        .modal-content{
            .dkg-fullimage-modalheader-21{
                display: none;
            }
            .dkg-fullimage-modalbody-21{
                padding: 0;
                padding-bottom : 50px;
                width: 100%;
                position: relative;
                .dkg-fullimage-crossicon{
                    position: absolute;
                    right: -10px;
                    top: -14px;                
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f2f2f2;
                    border-radius: 50%;
                    z-index: 10;
                    cursor: pointer;
                    svg{
                        width: 14px;
                        height: 14px;

                    }
                    &:hover{
                        background-color: #ddd;
                        svg{
                            color: #666;
                        }
                    }
                }
                .dkg-fullimage-carousel{
                    position: relative;
                    .carousel-indicators{
                        display: none;
                    }
                    .carousel-control-prev{
                        position: absolute;
                        top: inherit;
                        bottom: -43px;
                        left: 43%;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-color: #006E8F;
                        opacity: 1;
                        &:hover{
                            opacity: 1;
                        }
                    }
                    .carousel-control-next{
                        position: absolute;
                        top: inherit;
                        bottom: -43px;
                        right: 43%;
                        width: 35px;
                        height: 35px;
                        border-radius: 50%;
                        background-color: #006E8F;
                        opacity: 1;
                        &:hover{
                            opacity: 1;
                        }
                    }
                    .carousel-inner{
                        .dkg-image-carousel-item{
                            .dkg-carousel-image{
                                height: 460px;
                            }
                        }
                    }
                }
                .dkg-fullimage-pagination{
                    position: absolute;
                    left: 10px;
                    top: inherit; 
                    bottom: 13px;               
                    z-index: 10;
                    .dkg-pagination-title{
                        color: #333;
                        font-size: 0.95rem;
                    }
                }
            }
        }
    }
}