.dkg-feature-column{
    display: flex;
    align-items: center;
    justify-content: center;
    label{
        margin-bottom: 0;
    }
}
.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    &:checked + .react-switch-label .react-switch-button {
      left: 100%;
      transform: translateX(-100%);
      background-color: #02a786;
    }
  }
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background-color: #ddd;
    border-radius: 25px;
    position: relative;
    transition: background-color 0.2s;
    &:active .react-switch-button {
      width: 40px;
    }
    & .react-switch-button {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-radius: 25px;
      transition: 0.2s;
      background-color: #da3d5e;
    }
  }
  
  
  