.dk-subMenuMain.dkg-mainModuleSubmenu {
    ul {
        li {
            max-width: 9.999% !important;
        }

        a.active-blue {
            background: #26596d;
            color: #fff;
        }

        a {
            background: #eaeaea;
            border-right: 1px solid #ccc;

            span.dk-notiBadge {
                display: inline-flex;
                border-radius: 3px;
                font-size: 14px;
                color: #2c71b2;
                margin: 0;
                align-items: center;
                justify-content: center;
                font-weight: 400;
            }

            .dk-workplan-badgeCon.dk-archivedbgCon {
                background: #d91010;
            }

            span.dk-badgeTracking {
                background-color: #207993;
                display: inline-block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                line-height: 18px;
                font-size: 10px;
                margin: 0 5px;
                position: relative;
                bottom: 1px;
            }

            &.active.recruiters-bg {
                background-color: #fff !important;
                color: #333 !important;
            }
        }

        a.blue-active {
            background: #22445f;
            color: #fff;
        }

        a.gray-active {
            background: #eaeaea;
        }
    }
}

.dk-subMenuMain.dkg-subMainMenu.dk-mainMailboxSubmenu {
    background: #fff;

    ul {
        li {
            a {
                background: #fff;
            }

            a.gray-active {
                background: #eaeaea;
            }
        }
    }
}

.dk-subMenuMain {
    ul {
        li {
            a {
                border-right: 1px solid #ccc;
            }
        }
    }
}

.dk-subMenuMain.dkg-subMainMenu.dk-borderNone {
    ul {
        li {
            a {
                border-right: none;
            }
        }
    }
}