.dkg-comming-soonMain{
    margin-top: 155px;
    background-color: #eaeaea;
    height: calc(100vh - 169px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .dkg-comming-maintitle-Con{
        width: 100%;
        // max-width: 1600px;
        // margin: 0 auto;
        padding: 15px 25px;
        height: calc(100vh - 172px);
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        .dkg-comming-maintitle{
            font-size: 2.85rem;
            font-weight: bold;
            color: #0068AD;
            margin-top: 12rem;
            margin-bottom: 3rem;
            text-align: center;
            display: none;
        }
        .dkg-comming-subtitleCon{
            margin-top: 1.5rem;
            text-align: center;
            width: 100%;
            .dkg-comming-subtitle{
            font-size: 2rem;
            font-weight: bold;
            color: #e7e3e3;
            margin-top: 1rem;       
            text-align: center;     
        }
        }
        .dkg-comming-soon-boxCon{
            position: absolute;
            bottom: 0;
            right: 3.15%;
            display: none;
            .dkg-comming-soon-boxcols{
                background-color: #FEBE15;
                height: 170px;
                width: 170px;
                transform: rotate(10deg);
                border-radius: 15px;
                position: relative;
                top: -142px;
                left: -18px;
                &::before{
                    content: "";
                    display: inline-block;
                    height: 90%;
                    right: -50px;
                    position: absolute;
                    top: 147px;
                    width: 10px;
                    transform: rotate(138deg);
                    background-color: #000;
                }
                .dkg-comming-soon-box{
                    height: 150px;
                    width: 150px;
                    border: 7px solid #000;
                    position: relative;
                    top: 10px;
                    left: 10px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .dkg-comming-message{
                        font-size: 2.3rem;
                        font-weight: bold;
                        color: #000;
                        transform: rotate(306deg);
                        text-align: justify;
                        display: block;
                        .dkg-span-message{
                            font-size: 2.3rem;
                            font-weight: bold;
                            color: #000;
                        }
                    }
                
                }
            }
        }
    }
}