.dk-candidate-emailModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 60% !important;
        max-width: 60%;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dk-candidate-emailModalHeader {
                // background-color: #234158;
                background-color: #3c97b6;
                padding: 12px 10px;
                border-radius: 0;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dk-plusicon-con {
                    color: #fff;
                    cursor: pointer;
                }

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    .dk-plusicon-con {
                        position: absolute;
                        right: -20px;
                        cursor: pointer;

                        i {
                            color: #e3dfdf;
                            font-size: 16px;
                        }
                    }
                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dk-candidate-emailModalBody {
                background-color: #eaeaea;
                padding: 25px;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-compy-noti-left-boxcon {
                    display: none;
                    .dkg-compy-noti-box-cols {
                        .dk-moduleNoti-box.active {
                            background: #244158;
    
                            p {
                                color: #ddd;
                                
                            }
    
                            .d-timeflex {
                                border-left: 1px solid #1d5989 !important;
    
                                small {
                                    color: #ddd;
    
                                    &:last-child {
                                        border-top: 1px solid #1d5989 !important;
                                    }
    
                                    svg {
                                        color: #ddd !important;
                                    }
                                }
                            }
    
                            .sbtitle {
                                color: #ddd !important;
                                padding: 0 10px;
                            }
    
                            &:hover {
                                background-color: #fff;
                                transition: all 0.5s;
    
                                .dk-notificationText {
                                    color: #333;
    
                                    .dkg-compy-noti-boxs-Header {
                                        color: #333;
    
                                        .d-timeflex {
                                            border-left: 1px solid #ddd !important;
    
                                            small {
                                                color: #333;
    
                                                &:last-child {
                                                    border-bottom: none;
                                                    border-top: 1px solid #ccc !important;
                                                }
                                            }
                                        }
                                    }
    
                                    p {
                                        color: #333;
                                    }
    
                                    .dk-notification-tags {
                                        .sbtitle {
                                            color: #333;
                                        }
                                    }
    
                                    .d-timeflex {
                                        small {
                                            svg {
                                                color: #333;
                                            }
                                        }
                                    }
    
                                    .dkg-compy-noti-boxs-Header {
                                        .timeflex {
                                            span {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }
    
                            .dk-notificationText {
                                border-left: none !important;
                                .dk-notification-w100{
                                    p{
                                        &:hover{
                                            color: #629bd3;
                                        }
                                    }
                                }

                            }
    
                            .dk-notificationImg {
                                span {
                                    color: #333;
                                }
                            }
                        }
    
                        .dk-moduleNoti-box {
                            background-color: #fff;
                            display: flex;
                            align-items: center;
                            padding: 0 12px;
                            padding-right: 0;
                            border-left: 4px solid #2e97b6;
                            margin-bottom: 12px;
                            border-top: 1px solid #ddd;
                            border-bottom: 1px solid #ddd;
                            border-right: none;
                            transition: all 0.5s;
                            cursor: pointer;
                            min-height: 80px;
    
                            &:hover {
                                background-color: #f2f2f2;
                                transition: all 0.5s;
    
                                .dk-notificationText {
                                    color: #333;
    
                                    p {
                                        color: #333;
                                    }
    
                                    .dk-notification-tags {
                                        .sbtitle {
                                            color: #333;
                                        }
                                    }
    
                                    .d-timeflex {
                                        small {
                                            svg {
                                                color: #333;
                                            }
                                        }
                                    }
                                }
                            }
    
                            .dk-notificationImg {
                                width: 100%;
                                max-width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                margin-right: 12px;
                                margin-top: 0;
    
                                img {
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                }
    
                                span {
                                    text-align: center;
                                    display: block;
                                    margin-top: 3px;
                                    font-size: 16px;
                                    color: #8a8a8a;
                                }
                            }
    
                            .dk-notificationText {
                                font-weight: 400;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                border-right: none;
                                padding:0 15px;
                                padding-right: 0;
                                .sbtitle {
                                    font-weight: 500;
                                    padding: 0 10px;
                                    position: relative;
                                    bottom: 6px;
    
                                    span {
                                        font-weight: 300;
                                    }
                                }
    
                                .dk-notification-tags {
                                    display: flex;
    
                                    .sbtitle {
                                        font-weight: 400;
                                        display: flex;
                                        align-items: center;
                                        color: #333;
                                        margin-right: 30px;
                                        font-size: 13px;
    
                                        svg {
                                            display: inline-block;
                                            margin-right: 3px;
                                        }
                                    }
    
                                    a {
                                        display: inline-block;
                                        background: #dadada;
                                        padding: 0px 12px;
                                        border-radius: 30px;
                                        color: #333;
                                        text-decoration: none;
                                        margin-right: 7px;
    
                                        &:last-child {
                                            margin-right: 0;
                                        }
    
                                        span.closeTags {
                                            display: inline-block;
                                            background: #ccc;
                                            color: #000;
                                            padding: 2px 8px;
                                            border-radius: 0 30px 30px 0;
                                            margin-left: 5px;
    
                                            &:hover {
                                                background: #333;
                                                color: #fff;
                                                transition: all 0.5s;
                                            }
                                        }
                                    }
                                }
    
                                .d-timeflex {
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: flex-end;
                                    width: 100%;
                                    border-left: 1px solid #ccc;
                                    margin-left: 0;
                                    min-height: 78px;
                                    padding: 0;
                                    width: 100%;
                                    max-width: 120px;
                                    white-space: nowrap;
    
                                    small {
                                        display: inline-block;
                                        margin-right: 0;
                                        width: 100%;
                                        max-width: 100%;
                                        text-align: center;
                                        padding: 8px 10px;
                                        &.dkg-caseid-span{
                                            color: blue;
                                            cursor: pointer;
                                        }    
                                        svg {
                                            font-size: 15px;
                                            display: inline-block;
                                            position: relative;
                                            bottom: 1px;
                                            margin-right: 3px;
                                            color: #333;
                                        }
    
                                        &:first-child {
                                            position: relative;
                                            top: 3px;
                                        }
    
                                        &:last-child {
                                            border-bottom: none;
                                            border-top: 1px solid #ccc;
                                        }
                                        &.dkg-envlope-action-cols{
                                            a{
                                                display: inline-block;
                                                width: 24px;
                                                height: 24px;
                                                line-height: 24px;
                                                font-size: 14px;
                                                border-radius: 3px;
                                                &.dkg-view-icon{
                                                    background-color: #2196f3;
                                                    svg{
                                                        color: #fff;
                                                    }
                                                    &:hover{
                                                        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                                        transition: all ease 0.5s;
                                                    }
                                                }
                                                &.dkg-delete-icon{
                                                    background-color: #dc3545;
                                                    svg{
                                                        color: #fff;
                                                    }
                                                    &:hover{
                                                        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                                        transition: all ease 0.5s;
                                                    }
                                                }

                                            }
                                        }
                                    }
                                    &:last-child{
                                        border-right: 1px solid #ddd;
                                    }
                                }
    
                                p {
                                    margin: 0;
                                    font-size: 17px;
                                    display: flex;
                                    align-items: center;
                                    width: 100%;
                                    justify-content: space-between;
                                    padding-left: 10px;
                                    &:hover{
                                        color: #629bd3;
                                    }
                                    span {
                                        width: 100%;
                                        display: inline-block;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        // white-space: nowrap;
                                        font-size: 14px;
                                    }
    
                                    small {
                                        width: 100%;
                                        text-align: right;
                                        max-width: 80px;
                                    }
                                }
    
                                small {
                                    display: block;
                                    width: 100%;
                                    max-width: 75px;
                                    margin-top: 0;
                                    font-size: 12px;
                                    text-align: right;
                                }
                            }
                        }
                    }
                }
                .dkg-email-noti-tableCon{
                    .dkg-email-noti-table{
                        border: none;
                        border-collapse: collapse;
                        width: 100%;
                        thead{
                            tr{
                                th{
                                    background-color: transparent;
                                    color: #333;
                                    font-size: 12px;
                                    text-align: center;
                                    white-space: nowrap;
                                    padding: 10px 7px;
                                    border: none;
                                    border-bottom: none;
                                    vertical-align: middle;
                                    position: sticky;
                                    top: 0;
                                    z-index: 9;
                                }
                            }
                        }
                        tbody{
                            tr{
                                margin-bottom: 2.5rem;
                                td{
                                    background-color: #fff;
                                    color: #4d4a4a;
                                    font-size: 12px;
                                    font-weight: 400;
                                    text-align: center;
                                    white-space: nowrap;
                                    padding: 7px !important;
                                    border: 1px solid #ddd;
                                    vertical-align: middle;
                                    text-transform: capitalize;
                                    position: relative;

                                    &:first-child{
                                        border-left: 4px solid #2e97b6;
                                    }
                                    .dk-notificationImg{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                         img{
                                             width: 40px;
                                             height: 40px;
                                             border-radius: 50%;
                                         }
                                    }
                                    .dkg-caseid-span{
                                        color: blue;
                                        cursor: pointer;
                                    }
                                    .dkg-envlope-action-cols{
                                        a{
                                            display: inline-block;
                                            width: 24px;
                                            height: 24px;
                                            line-height: 24px;
                                            font-size: 14px;
                                            border-radius: 3px;
                                            &.dkg-view-icon{
                                                background-color: #f2f2f2;
                                                svg{
                                                    color: #4d4a4a;
                                                }
                                                &:hover{
                                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                                    transition: all ease 0.5s;
                                                }
                                            }
                                            &.dkg-delete-icon{
                                                background-color: #f2f2f2;
                                                svg{
                                                    color: #4d4a4a;
                                                }
                                                &:hover{
                                                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
                                                    transition: all ease 0.5s;
                                                }
                                            }

                                        }
                                    }
                                    .dkg-env-readpopup{
                                        span{
                                            text-align: left;
                                            cursor: pointer;
                                            &:hover{
                                                color: #629bd3;
                                            }
                                        }
                                    }
                                    &:nth-child(03){
                                        max-width: 320px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: inherit !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-candidate-emailModal.fade .modal-dialog {
    right: -60%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-candidate-emailModal.show .modal-dialog {
    right: 0;
}

.modal.dk-candidate-emailModal {
    z-index: 9999 !important;
}