.dkg-consults-mainpageCon{
    margin-top: 154px;
    background-color: #eaeaea;
    padding: 15px 0;
    padding-bottom: 0;
    height: calc(100vh - 168px);
    overflow-y: auto;
    overflow-x: hidden;

    .dkg-consults-container{
        width: 100%;
        // max-width: 1200px;
        // margin: 0 auto;
        .dkg-consults-mainRow{
            .dkg-consults-mainCol{
                .dkg-consults-page-title{
                    font-weight: 700;
                    font-size: 24px;
                    color: #333;
                    line-height: 1.2em;
                    text-align: center;
                    margin-top: 10px;
    
                }
                .dkg-consults-page-text{
                    font-size: 1.10rem;
                    color: #333;
                    text-align: justify;
                    padding: 10px 0;
                    padding-bottom: 0;
                    width: 100%;
                    text-align: center;
                    max-width: 75%;
                    margin: 0 auto;
                }
                .dkg-service-content-Con{
                    margin-top: 0.94375rem;
                    border-top: 1px solid #ddd;
                    width: 100%;
                    .dkg-service-dropdown-header{
                        width: 100%;
                        padding: 1.5rem 0.94375rem;
                        background-color: #1f394c;
                        height: calc(100vh - 275px);
                        overflow-y: auto;
                        overflow-x: hidden;
                        .dkg-consults-card-headercon{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                        }
                        .dkg-service-dropdowns-con{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;
                            gap: 1rem;
                            .dkg-service-dropdown{
                                .dkg-service-dropdown-toggle{
                                    border-radius: 30px;
                                    margin: 0 0 0 auto;
                                    border: 1px solid #ccc;
                                    padding: 0.35rem 1.35rem;
                                    position: relative;
                                    min-width: 50px;
                                    display: -webkit-inline-box;
                                    display: -ms-inline-flexbox;
                                    display: inline-flex;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    box-shadow: none;
                                    background-color: #fff !important;
                                    color: #666;
                                    text-transform: capitalize;
                                }
                                .dkg-service-dropdown-menu{
                                    border-color: 1px solid #ddd;
                                    border-radius: 15px;
                                    margin-top: 9px;
                                    scrollbar-width: thin;
                                    .dkg-service-down-item{
                                        font-size: 0.9rem;
                                    }
                                }
                            }
                        }
                        .dkg-consults-btnCon{
                            .dk-filterbtntext {
                                background: #eaeaea;
                                font-size: 12px !important;
                                color: #333;
                                border: 1px solid #ccc;
                                padding: 0.35rem 0.75rem;
                                border-top-left-radius: 30px;
                                border-bottom-left-radius: 30px;
                                line-height: 10px;
                                text-transform: inherit;

                                &.btn-primary {
                                    background: #eaeaea !important;
                                }
                            }

                            .dk-filterbtnicon {
                                background: #eaeaea;
                                font-size: 11px;
                                padding: 0.35rem 0.75rem;
                                border-top-right-radius: 30px;
                                border-bottom-right-radius: 30px;

                                &.btn-primary {
                                    background-color: #eaeaea !important;
                                    color: #333;
                                }
                            }
                        }
                        .dkg-consults-cards-Con{
                            padding: 1rem 0;
                            .dkg-consults-card{
                                margin: 0 auto;
                                padding: 1.25rem;
                                border-radius: 1rem;
                                border: 1px solid #ddd;
                                .dkg-card-flexCon{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-direction: row;
                                    gap: 1rem;
                                    width: 100%;
                                    .dkg-consults-experts-Con{
                                        width: 25%;
                                        .dkg-experts-first-cols{
                                            width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            .dkg-consults-imgCon{
                                                height: 180px;
                                                width: 180px;
                                                border-radius: 50%;
                                                overflow: hidden;
                                                border: 1px solid #ddd;
                                                .dkg-consults-img{
                                                    height: 100%;
                                                    width: 100%;
                                                    -o-object-fit: cover;
                                                    object-fit: cover;
                                                    -o-object-position: top;
                                                    object-position: top;
                                                }
                                            }
                                        }
                                    }
                                    .dkg-consults-profile-desc{
                                        width: 65%;
                                        border-right: 1px solid #ddd;
                                        padding: 0 0.94375rem;
                                        .dkg-consultant-name{
                                            font-size: 1.5rem;
                                            font-weight: 600;
                                            color: #333;
                                            line-height: 1.25;
                                            .dkg-verify-consult{
                                                padding-left: 0.5rem;
                                                .dkg-verify-consult-img{
                                                    width: 22px;
                                                    height: 22px;
                                                    aspect-ratio: auto 22 / 22;
                                                }
                                            }
                                        }
                                        .dkg-consult-job-profile{
                                            font-size: 1.125rem;
                                            font-weight: 400;
                                            color: #666;
                                        }
                                        .dkg-jobs-consult-keyboards{
                                            border-top: 1px solid #0000002d;
                                            border-bottom: 1px solid #0000002d;
                                            padding: 1.25rem 0;
                                            display: flex;
                                            align-items: flex-start;
                                            justify-content: flex-start;
                                            flex-wrap: wrap;
                                            gap: 1rem;
                                            .dkg-jobs-consult-key{
                                                padding: 6px 15px;
                                                background-color: #E3EDFF;
                                                color: #0c233b;
                                                border-radius: 20px;
                                                font-size: 12px;
                                                line-height: normal;
                                                font-weight: 500;
                                            }
                                        }
                                        .dkg-consults-bioSec{
                                            padding: 1rem 0;
                                            .dkg-consult-bio{
                                               font-size: 0.95rem;
                                               color: #333;
                                            }
                                            .dkg-readmore-title{
                                                color: #3c97b6;
                                                display: flex;
                                                align-items: center;
                                                justify-content: flex-end;
                                            }
                                        }
                                    }
                                    .dkg-consults-package-desc{
                                        width: 30%;
                                        .dkg-package-title{
                                            width: 100%;
                                            text-align: center;
                                            font-size: 1.5rem;
                                            color: #333;
                                            font-weight: 600;
                                        }
                                        .dkg-package-cardcon{
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: column;
                                            width: 100%;
                                            .dkg-package-card{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                border: 1px solid #ddd;
                                                margin-bottom: 1rem;
                                                padding: 0.45rem 0.94375rem;
                                                border-radius: 0.325rem;
                                                width: 100%;
                                                .dkg-card-leftcon{
                                                    .dkg-card-left-title {
                                                        font-weight: 500;
                                                        font-size: 15px;
                                                        display: inline-block;
                                                        color: #333;
                                                    }
                                                    .dkg-card-left-subtitle{
                                                        font-size: 11px;
                                                        color: #555;
                                                    }
                                                }
                                                .dkg-card-rightcon{
                                                    .dkg-package-price{
                                                        padding: 6px 15px;
                                                        background-color: #E3EDFF;
                                                        color: #0c233b;
                                                        border-radius: 5px;
                                                        font-size: 15px;
                                                        line-height: normal;
                                                        font-weight: 500;
                                                        border: none;
                                                    }
                                                }
                                                &:hover{
                                                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
                                                    transition: color 0.15s ease-in-out,
                                                    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                                    padding: 0.45rem 0.94375rem;
                                                }
                                            }
                                            .dkg-viewplans-btnCon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;
                                                .dkg-viewplans-btn{
                                                    display: inline-block;
                                                    background-color: #3c97b6;
                                                    border: none;
                                                    font-size: 0.95rem;
                                                    padding: 0.65rem 0.94375rem;
                                                    color: #fff;
                                                    width: 100%;
                                                    max-width: 50%;
                                                    border-radius: 5px;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-consults-mainpageCon{
        margin-top: 35px;
        height: calc(100vh - 49px);
        // overflow: hidden;
        .dkg-consults-container{
            .dkg-consults-mainRow{
                .dkg-consults-mainCol{
                    // .dkg-consults-page-title{

                    // }
                    .dkg-service-content-Con{
                        .dkg-service-dropdown-header{
                            .dkg-service-dropdowns-con{
                                flex-wrap: wrap;
                            }
                            .dkg-consults-cards-Con{
                                padding: 0 1rem;
                                .dkg-consults-card{
                                    padding: 1rem;
                                    .dkg-card-flexCon{
                                        display: block;
                                        .dkg-consults-experts-Con{
                                            .dkg-experts-first-cols{
                                                .dkg-consults-imgCon{
                                                    width: 85px;
                                                    height: 85px;
                                                }
                                            }
                                        } 
                                        .dkg-consults-profile-desc{
                                            border: none;
                                            .dkg-consultant-name{
                                                width: 100%;
                                                text-align: center;
                                            }
                                            .dkg-consult-job-profile{
                                                width: 100%;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}