.dk-readmail-notificationsnewModal {
    color: #333;
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 550px !important;
        max-width: 550px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
        .modal-content {
            border-radius: 5px;
            border: none;
            background-color: transparent;
            .dk-readmail-notificationsnewModalHeader {
                color: #fff;
                background: #26597d;
                padding: 14px 10px;
                border-radius: 0;
                border-bottom: none;
                .modal-title {
                    margin-top: 0;
                    font-size: 17px;
                    font-weight: 500;
                    font-family: Roboto, sans-serif;
                    text-align: center;
                    width: 100%;
                    text-transform: uppercase;
                    color: #fff;
                }

                .close {
                    font-size: 1.58em;
                    padding: 0px;
                    right: 5px;
                    position: absolute;
                    top: 8px;
                    opacity: 1;
                    color: #fff !important;
                    text-shadow: none;
                    outline: none;
                    font-weight: normal;
                    padding: 0.35rem;
                    margin: 0;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            .dk-readmail-notificationsnewModalBody {
                color: #333;
                background-color: #fff;
                padding: 0;
                border-radius: 0;
                height: calc(100vh - 55px);
                .dkg-mail-message{
                    color: #333;
                    font-size: 13px;
                    text-align: left;
                    padding: 15px;
                }
            }
        }
    }
}
.modal.dk-readmail-notificationsnewModal.fade .modal-dialog {
    right: -550px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-readmail-notificationsnewModal.show .modal-dialog {
    right: 0;
}