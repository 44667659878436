.dkg-myacc-payments-main-Con {
    margin-top: 123px;
    background-color: #eaeaea;
    padding: 25px 15px;
    height: 100vh;

    .dkg-payments-mainCon {
        .dkg-payments-boxCon {
            width: 100%;

            .dk-myacc-payments-TopHead {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                .dk-myacc-payments-LeftTopHead {
                    display: flex;
                    align-items: center;

                    .dk-SrchInputBox {
                        .input-group {
                            max-width: 160px;

                            input {
                                padding: 0.5rem 0.55rem;
                                background-color: #fff !important;
                                margin: 0;
                                box-shadow: none;
                                border: 1px solid #ddd !important;
                                line-height: 1.3rem;
                                height: 35px;
                                border-radius: 0;

                                &:focus::placeholder {
                                    color: transparent;
                                }
                            }

                            .input-group-append {
                                margin-left: -1px;

                                button {
                                    padding: 0.5rem 0.55rem;
                                    background-color: #fff !important;
                                    margin: 0;
                                    box-shadow: none;
                                    border: 1px solid #ddd;
                                    border-left: none;
                                    line-height: 1.3rem;
                                    height: 35px;
                                    border-radius: 0;
                                    border-left: 1px solid #ddd;

                                    i {
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }

                    .dkg-addnew-paymentbtn {

                        a,
                        button {
                            i {
                                line-height: 22px;
                                width: 35px;
                                height: 35px;
                                background-color: #fff;
                                border: 1px solid #ddd;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                color: #333;
                            }

                            &.dk-cricleBtn {
                                border: none;
                                background-color: transparent;
                            }
                        }
                    }
                }

                .dk-myacc-payments-HeadertitleCon {
                    position: absolute;
                    left: 50%;
                    top: 10px;

                    .dk-myacc-payments-title {
                        color: #26597d;
                        position: absolute;
                        display: inline-block;
                        white-space: nowrap;
                        text-transform: uppercase;
                        font-size: 18px;
                        font-weight: 600;
                    }
                }

                .dk-myacc-payments-rightTopHead {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .dkg-refresh-paymentbtnCon {
                        button {
                            border: none;
                            background-color: transparent;

                            i {
                                line-height: 22px;
                                width: 35px;
                                height: 35px;
                                background-color: #fff;
                                border: 1px solid #ddd;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 14px;
                                color: #333;
                            }
                        }
                    }
                }
            }
        }

        .dkg-taskpayment-tableCon-12 {
            margin-top: 6px;
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100vh - 225px);
            padding-bottom: 0;
            position: relative;

            .dkg-payment-table {
                thead {
                    tr {
                        th {
                            color: #fff;
                            background: #234158;
                            border-left: none;
                            font-size: 12px;
                            text-align: center;
                            padding: 10px 5px;
                            white-space: nowrap;
                            border-bottom: none;
                            text-align: center;
                            text-transform: uppercase;
                            vertical-align: middle;
                            position: sticky;
                            top: 0;
                            border-top: none;
                            z-index: 9;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            background-color: #fff;
                            border-bottom: 1px solid #ddd;
                            font-size: 13px !important;
                            font-weight: 400;
                            padding: 8px 5px 8px 7px;
                            text-align: center;
                            vertical-align: middle;
                            white-space: nowrap;

                            // &:nth-child(07),
                            // &:nth-child(08) {
                            //     text-align: left;
                            // }
                            &:nth-child(12) {
                                font-weight: 600;
                            }

                            // &:nth-child(13) {
                            //     max-width: 240px;
                            //     min-width: 240px;
                            //     overflow: hidden;
                            //     padding-left: 30px;
                            //     text-align: left;
                            //     text-overflow: ellipsis;
                            //     white-space: nowrap;

                            //     &:hover {
                            //         color: #629bd3;
                            //         cursor: pointer;
                            //     }
                            // }

                            .dk-checkinputIconCon {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                .dk-checkinputIcon {
                                    left: 10px;
                                    position: relative;
                                    top: 2px;

                                    input {
                                        cursor: pointer;
                                        width: 15px;
                                        height: 15px;

                                        &[type="radio"]:checked+label:after {
                                            background-color: #34976e;

                                        }
                                    }

                                    input[type=checkbox] {
                                        background-color: #f1f1f1;
                                        -webkit-appearance: none;
                                        appearance: none;
                                        background: #e4e8ec;
                                        border: 1px solid #ccc;
                                        border-radius: 0;
                                        box-sizing: border-box;
                                        box-sizing: content-box;
                                        cursor: pointer;
                                        height: 12px;
                                        position: relative;
                                        transition: all .3s linear;
                                        width: 12px;

                                    }

                                    input[type=checkbox]:checked,
                                    input[type="radio"]:checked {
                                        background-color: #34976e;
                                    }

                                    input[type="radio"]:checked+label:after {
                                        background-color: #34976e;

                                    }
                                }
                            }

                            .dk-payment-imgCon {
                                .dk-payment-img {
                                    border-radius: 50%;
                                    cursor: pointer;
                                    height: 30px;
                                    margin: 0 2px;
                                    width: 30px;
                                }
                            }

                            .dkgpaynotea-title {
                                &:hover {
                                    color: #629bd3;
                                }
                            }

                            .dkg-invoiceIcon {
                                svg {
                                    width: 23px;
                                    height: 23px;
                                    color: #0a6a7a;
                                }
                            }

                            .dkg-paymentstusdropdowncon-14 {
                                .dropdown {
                                    .dropdown-toggle {
                                        display: inline-block;
                                        color: #fff;
                                        padding: 0px;
                                        font-size: 13px;
                                        margin: 0;
                                        box-shadow: none;
                                        text-transform: capitalize;
                                        white-space: nowrap;
                                        min-width: 80px;
                                        border-radius: 15px;
                                        padding: 0;

                                        &::after {
                                            display: none;
                                        }

                                        .caseDiv {
                                            border-radius: 15px;
                                            padding: 2px 15px;
                                        }

                                    }

                                    .dropdown-menu {
                                        border-radius: 0;
                                        padding: 0;
                                        position: absolute !important;
                                        top: 27px !important;
                                        width: 100%;
                                        min-width: 120px;
                                        max-width: 120px;
                                        left: 50% !important;
                                        transform: translate(-50%) !important;

                                        .dropdown-item {
                                            padding: 6px 10px;
                                            font-size: 13px;
                                            border-bottom: 1px solid #dadada;
                                            padding: 5px 7px;
                                            font-size: 12px;

                                            &:last-child {
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }

                                .dkg-payment-statusdropdown-24 {
                                    .dkg-payment-status-dropdown-toggle-24 {
                                        min-width: 110px !important;
                                        max-width: 110px !important;

                                        &.dkg-pt-priority-textUpperCase {
                                            text-transform: uppercase !important;
                                            min-width: 80px !important;
                                            max-width: 80px !important;

                                            .caseDiv {
                                                padding: 2px 5px !important;
                                            }
                                        }
                                    }

                                    .dkg-pt-priority-dropmenu-234 {
                                        .dropdown-item {
                                            text-transform: uppercase !important;
                                        }
                                    }
                                }

                            }

                            .commentBoxCon {
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                .numCon {
                                    background: #234158;
                                    border-radius: 50%;
                                    color: #fff;
                                    cursor: pointer;
                                    height: 25px;
                                    line-height: 25px;
                                    width: 25px;

                                    &:hover {
                                        background-color: #fff;
                                        color: #333;
                                        transition: all .5s;
                                    }
                                }

                                &.zeroComment {
                                    .zeroCommentCount {
                                        background-color: #fff !important;
                                        color: #333 !important;
                                    }
                                }
                            }
                        }
                    }
                }

                tfoot {
                    tr {
                        td {
                            &:nth-child(12) {
                                background-color: #fff;
                                font-size: 13px !important;
                                font-weight: 600;
                                padding: 8px 5px 8px 7px;
                                text-align: center;
                                vertical-align: middle;
                                white-space: nowrap;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }

}