.dkg-feedback-service-mainpageCon{
    margin-top: 154px;
    background-color: #eaeaea;
    padding: 15px;
    height: calc(100vh - 154px);
    overflow-y: auto;
    overflow-x: hidden;

    .dkg-feedback-service-container{
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        .dkg-feedback-service-mainRow{
            .dkg-feedback-service-mainCol{
                padding-bottom: 5px;
                .dkg-fd-service-page-title{
                    font-weight: 700;
                    font-size: 24px;
                    color: #333;
                    line-height: 1.2em;
                    text-align: center;
                    margin-top: 10px;
                }
                .dkg-fd-service-page-text {
                    font-size: 1.1rem;
                    color: #333;
                    padding: 10px 0;
                    padding-bottom: 0;
                    width: 100%;
                    text-align: center;
                    max-width: 75%;
                    margin: 0 auto;
                    padding-bottom: 1rem;
                }
            }
            .dkg-cn-feedback-maincard{
                width: 100%;
                max-width: 1040px;
                margin: 0 auto;            
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fff;
                border-radius: 5px;
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                padding: 1.25rem 3rem;
                // padding-bottom: 2.25rem;
                .dkg-cn-feedback-leftcon{
                    width: 50%;
                    position: relative;
                    top: -32px;

                    .dkg-cn-feedback-ul{
                        list-style: none;
                        margin-bottom: 0;
                        .dkg-cn-feedback-li{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 2.04rem;
                            .dkg-feedback-li-icon{
                                .dkg-cn-feedback-icon{
                                    width: 42px;
                                    height: 42px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border-radius: 5px;
                                    background-color: #f2f2f2;
                                    svg{
                                        width: 22px;
                                        height: 22px;
                                        color: #777;
                                    }
                                }
                            }
                            .dkg-feedback-li-titleCon{
                                padding-left: 1.12rem;
                                .dkg-feedback-li-title{
                                    font-size: 1.32rem;
                                    font-weight: bold;
                                    color: #666;
                                    font-family: 'Roboto';
                                }
                                .dkg-feedback-li-subtitle{
                                    font-size: 0.95rem;
                                    color: #777;

                                }
                            }
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                .dkg-cn-feedback-rihtcon{
                    width: 50%;
                    .cn-feedback-right-title{
                        font-size: 1.9rem;
                        font-weight: bold;
                        color: #333;
                        display: none;
                        display: none;
                    }
                    .cn-feedback-right-subtitle{
                        font-size: 1rem;
                        color: #777;
                    }
                    .dkg-cn-feedback-form{
                        margin-top: 1.25rem;
                        .dkg-cn-feedback-formgrp{
                            margin-bottom: 1.25rem;
                            &.dkg-cn-fd-form-inputgrp{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .dkg-cn-feedback-input{
                                    background-color: #f2f2f2;
                                    color: #333;
                                    border: 1px solid #ddd;
                                    border-radius: 5px;
                                    padding: 10px 12px;
                                    text-align: left;
                                    &::placeholder {
                                        color: #999;
                                        opacity: 1; /* Firefox */
                                    }
                                    &::-ms-input-placeholder { /* Edge 12 -18 */
                                    color: #999;
                                    }
                                    
                                }
                            }
                            .dkg-cn-feedback-message{
                                width: 100%;
                                background-color: #f2f2f2;
                                border: 1px solid #ddd;
                                border-radius: 5px;
                                height: 130px;
                                text-align: left;
                                padding: 10px 12px;
                                &::placeholder {
                                    color: #999;
                                    opacity: 1; /* Firefox */
                                }
                                &::-ms-input-placeholder { /* Edge 12 -18 */
                                color: #999;
                                }
                            }
                            .dkg-cn-feedback-dropdown{
                                width: 100%;
                                .dkg-cn-fd-dropdown-toogle{
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    box-shadow: none;
                                    background-color: #f2f2f2;
                                    border: 1px solid #ddd;
                                    border-radius: 5px;
                                    padding: 5px 10px;
                                    margin: 0;
                                    color: #999;
                                    position: relative;
                                    text-transform: capitalize;
                                    font-size: 0.92rem;
                                    &::after{
                                        position: absolute;
                                        right: 10px;
                                        color: #777;
                                        margin-left: 0.40em;
                                        vertical-align: 0.40em;
                                        content: "";
                                        border-top: 0.43em solid;
                                        border-right: 0.43em solid transparent;
                                        border-bottom: 0;
                                        border-left: 0.35em solid transparent;
                                    }
                                }
                                .dkg-cn-fd-dropdown-menu{
                                    border-radius: 0;
                                    width: 100%;
                                    .dkg-cn-fd-dropdown-item{
                                        padding: 7px 10px;
                                        font-size: 0.95rem;
                                        text-align: left;
                                    }
                                }
                            }
                            &:last-child{
                                margin-bottom: none;
                            }
                        }
                        .dkg-feedback-submit-btnCon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            max-width: 185px;
                            margin: 0 auto;
                            .dkg-feedback-submit-btn{
                                background-color: #3c97b6;
                                color: #fff;
                                width: 100%;
                                margin: 0;
                                padding: 10px 5px;
                                border-radius: 5px;
                                .dkg-feedback-plane{
                                    padding-left: 0.5rem;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}