.dkg-myacc-faqmain-Con {
    margin-top: 153px;
    background-color: #eaeaea;
    padding: 30px 15px;
    height: calc(100vh - 167px);
    overflow-y: auto;
    .dkg-faq-mainCon {
        // @media (min-width: 768px) {
        //     .dkg-faq-boxCon {
        //         flex: 0 0 88.666667% !important;
        //         max-width: 88.666667% !important;
        //         margin-left: 8.33% !important;
        //     }
        // }

        .dkg-faq-boxCon {
            .dkg-myaccount-faq-pageCon {
                margin-top: 0;
                width: 100%;
                max-width: 1600px;
                margin: 0 auto;
                .dkg-myaccount-faq-tab-mainCon {
                    // @media (min-width: 768px) {
                    //     .dkg-myaccount-faq-tabCon {
                    //         flex: 0 0 19.666667% !important;
                    //         max-width: 19.666667% !important;

                    //     }
                    // }

                    .dkg-myaccount-faq-tabCon {
                        .dkg-myaccount-faq-tab-navpills {
                            .dkg-myaccount-faq-tab-nav-item {
                                border-radius: 0;

                                &:last-child {
                                    border-radius: 0 0 5px 5px;
                                }

                                .dkg-myaccount-faq-tab-navlink {
                                    margin: 0 !important;
                                    padding: 10px 15px;
                                    display: block;
                                    cursor: pointer;
                                    background: #24475f;
                                    color: #fff;
                                    border-radius: 0;
                                    border-bottom: 1px solid #607f92 !important;
                                    position: relative;
                                    border: 0;
                                    font-size: 14px;

                                    &.active {
                                        background: #1f3748;

                                        &:before {
                                            content: "";
                                            width: 0;
                                            height: 0;
                                            border-bottom: 10px solid transparent;
                                            border-top: 10px solid transparent;
                                            border-left: 12px solid #1f3748;
                                            position: absolute;
                                            top: 12px;
                                            right: -12px;
                                        }
                                    }

                                    i {
                                        font-size: 16px;
                                        margin-right: 8px;
                                        color: #ddd;
                                    }
                                }

                                &:first-child {
                                    .dkg-myaccount-faq-tab-navlink {
                                        border-radius: 5px 5px 0 0;

                                        &.active {
                                            background: #1f3748;
                                            border-radius: 5px 5px 0 0;
                                        }

                                    }
                                }

                                &:last-child {
                                    .dkg-myaccount-faq-tab-navlink {
                                        border-radius: 0 0 5px 5px;

                                        &.active {
                                            background: #1f3748;
                                            border-radius: 0 0 5px 5px;
                                        }

                                    }
                                }
                            }
                        }

                    }

                    .dkg-myaccount-faq-tabCon-10 {
                        .dkg-myaccount-faq-tab-navpills {
                            .dkg-myaccount-faq-tab-nav-item {
                                border-radius: 0;

                                &:last-child {
                                    border-radius: 0;
                                }

                                .dkg-myaccount-faq-tab-navlink {
                                    margin: 0 !important;
                                    padding: 10px 15px;
                                    display: block;
                                    cursor: pointer;
                                    background: #24475f;
                                    color: #fff;
                                    border-radius: 0;
                                    border-bottom: 1px solid #607f92 !important;
                                    position: relative;
                                    border: 0;
                                    font-size: 14px;

                                    &.active {
                                        background: #1f3748;

                                        &:before {
                                            content: "";
                                            width: 0;
                                            height: 0;
                                            border-bottom: 10px solid transparent;
                                            border-top: 10px solid transparent;
                                            border-left: 12px solid #1f3748;
                                            position: absolute;
                                            top: 12px;
                                            right: -12px;
                                        }
                                    }

                                    i {
                                        font-size: 16px;
                                        margin-right: 8px;
                                        color: #ddd;
                                    }
                                }

                                &:first-child {
                                    .dkg-myaccount-faq-tab-navlink {
                                        border-radius: 5px 5px 0 0;

                                        &.active {
                                            background: #1f3748;
                                            border-radius: 5px 5px 0 0;
                                        }

                                    }
                                }

                                &:last-child {
                                    .dkg-myaccount-faq-tab-navlink {
                                        border-radius: 0 0 5px 5px;

                                        &.active {
                                            background: #1f3748;
                                            border-radius: 0 0 5px 5px;
                                        }

                                    }
                                }
                            }
                        }
                    }
                    @media (min-width: 768px) {
                        .dkg-myaccount-faq-tabCon-10 {
                            // flex: 0 0 78.333333%;
                            // max-width: 78.333333%;
                            // margin-left: 2%;

                            .dkg-compliance-navpills {
                                margin: 0;
                                padding: 0;
                                display: flex;
                                background: #fff;
                                border-radius: 5px;
                                // overflow: auto;
                                overflow: visible;
                                // margin-bottom: -1px;

                                .dkg-compliance-navitem {
                                    list-style: none;
                                    border-bottom: none;
                                    text-align: center;
                                    width: 100%;
                                    max-width: 12%;

                                    &:first-child {
                                        border-top-left-radius: 5px;

                                        .nav-link {
                                            &.active {
                                                background: #1f394c !important;
                                                color: #fff;
                                                border-top-left-radius: 5px;
                                            }
                                        }
                                    }

                                    &:last-child {
                                        border-bottom: none;
                                        // border-radius: 0 0 5px 5px;

                                        .nav-link {
                                            &.active {
                                                background: #1f394c !important;
                                                color: #fff;
                                                // border-radius: 0 0 5px 5px;
                                            }
                                        }
                                    }

                                    .nav-link {
                                        color: #313131;
                                        display: block;
                                        padding: 10px 10px;
                                        position: relative;
                                        white-space: nowrap;
                                        border-radius: 0;
                                        text-align: center;
                                        text-transform: uppercase;

                                        &.active {
                                            background: #1f394c !important;
                                            color: #fff;

                                            // &::before {
                                            //     border-bottom: 10px solid transparent;
                                            //     border-left: 12px solid #1f3748;
                                            //     border-top: 10px solid transparent;
                                            //     content: "";
                                            //     height: 0;
                                            //     position: absolute;
                                            //     right: -12px;
                                            //     top: 12px;
                                            //     width: 0;
                                            // }
                                        }

                                    }
                                }
                            }

                            .dkg-compliance-tabContent {
                                .dkg-compliance-tabpane {
                                    border-radius: 0 0 5px 5px;
                                    border: none;

                                    .card-header {
                                        padding: 20px 5px 0;
                                        border-bottom: none;
                                        background: #1f394c;
                                        border-radius: 0;
                                        text-align: center;
                                        color: #fff;
                                        font-size: 20px;
                                        font-weight: bold;
                                        // border-radius: 5px 5px 0 0;
                                    }

                                    .card-body {
                                        background: #1f394c;
                                        color: #fff;
                                        padding: 10px 30px 30px;
                                        border-radius: 0 0 5px 5px;

                                        .dkg-whitebgColor {
                                            border-radius: 4px;
                                            padding: 30px;
                                            background: #fff;

                                            p {
                                                color: #333;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dkg-myaccount-faq-tabCon-10 {
                        .dkg-myaccount-faq-tabcontent {
                            .dkg-screen-faq-accodionCon {
                                .dkg-screen-faq-accodion {
                                    border: none !important;
                                    border-bottom: 1px solid #4d5a6b !important;

                                    .dkg-screen-faq-accodion-suMM {
                                        background-color: #1f3748;
                                        color: #fff;
                                        border-bottom: 1px solid #4d5a6b;
                                        min-height: 52px;

                                        .MuiIconButton-root.MuiAccordionSummary-expandIcon {
                                            .MuiIconButton-label {
                                                color: #fff;

                                            }
                                        }
                                    }

                                    &:first-child {
                                        .dkg-screen-faq-accodion-suMM {
                                            border-radius: 5px 5px 0 0;

                                            div {
                                                p {
                                                    font-size: 15px !important;
                                                }
                                            }
                                        }
                                    }

                                    &:last-child {
                                        .dkg-screen-faq-accodion-suMM {
                                            border-radius: 0 0 5px 5px;
                                        }
                                    }

                                    .MuiCollapse-root.MuiCollapse-entered {
                                        .MuiCollapse-wrapper {
                                            .MuiCollapse-wrapperInner {
                                                div {
                                                    .MuiAccordionDetails-root {
                                                        background-color: #24475f;
                                                        color: #fff;
                                                        cursor: text;
                                                        p{
                                                            width: 100%;
                                                            cursor: text;
                                                            textarea{
                                                                &:focus{
                                                                    outline: none;
                                                                    box-shadow: none;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dkg-cand-faq-moCon{
        display: none;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dkg-myacc-faqmain-Con{
        margin-top: 35px;
        height: calc(100vh - 49px);
        overflow-y: auto;
        padding: 0.94375rem;
        .dkg-cand-faq-moCon{
            display: block;
            .dkg-faq-header-centerCon{
                padding-top: 1.25rem;
                width: 100%;
                .dkg-faq-DropdownCon{
                    width: 100%;
                    .dkg-faq-Dropdown{
                        width: 100%;
                        max-width: 70%;
                        margin: 0 auto;
                        .dkg-faq-Dropdown-toggle{
                            text-transform: capitalize;
                            box-shadow: none;
                            background-color: #fff;
                            border: 1px solid #ddd;
                            font-size: 0.85rem;
                            padding: 0.45rem 0.7rem;
                            margin: 0;
                            width: 100%;
                            text-align: left;
                            position: relative;
                            &::after{
                                position: absolute;
                                top: 1.02rem;
                                right: 0.85rem;
                            }
                        }
                        .dkg-faq-Dropdown-Menu{
                            border-radius: 0;
                            min-width: 180px;
                            overflow-y: auto;
                            padding: 0;
                            border-radius: 0;
                            .dkg-abouts-Dropdown-Item,
                            .dropdown-item{
                                border-bottom: 1px solid #ddd;
                                font-size: 0.85rem;
                                padding: 0.5rem 0.95rem;
                                &:last-child{
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
            .dkg-cand-faq-mo-accordianCon{
                margin-top: 1rem;
                .dkg-myaccount-faq-tabcontent {
                    .dkg-screen-faq-accodionCon {
                        .dkg-screen-faq-accodion {
                            border: none !important;
                            border-bottom: 1px solid #4d5a6b !important;

                            .dkg-screen-faq-accodion-suMM {
                                background-color: #1f3748;
                                color: #fff;
                                border-bottom: 1px solid #4d5a6b;
                                min-height: 52px;

                                .MuiIconButton-root.MuiAccordionSummary-expandIcon {
                                    .MuiIconButton-label {
                                        color: #fff;

                                    }
                                }
                            }

                            &:first-child {
                                .dkg-screen-faq-accodion-suMM {
                                    border-radius: 5px 5px 0 0;

                                    div {
                                        p {
                                            font-size: 15px !important;
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                .dkg-screen-faq-accodion-suMM {
                                    border-radius: 0 0 5px 5px;
                                }
                            }

                            .MuiCollapse-root.MuiCollapse-entered {
                                .MuiCollapse-wrapper {
                                    .MuiCollapse-wrapperInner {
                                        div {
                                            .MuiAccordionDetails-root {
                                                background-color: #24475f;
                                                color: #fff;
                                                cursor: text;
                                                overflow: hidden;
                                                p{
                                                    width: 100%;
                                                    cursor: text;
                                                    textarea{
                                                        &:focus{
                                                            outline: none;
                                                            box-shadow: none;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dkg-faq-mainCon{
            display: none !important;
        }
    }
}