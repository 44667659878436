.dkg-paidservices-mainpageCon{
    margin-top: 155px;
    height: calc(100vh - 155px);
    background-color: #eaeaea;
    padding: 15px;
    overflow-y: auto;
    .dkg-paidservices-con{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        padding: 0 15px;
        padding-top: 0;

        .dkg-paidservices-mainRow{
            .dkg-paidservices-mainCol{
                padding-bottom: 5px;
                width: 100%;
                text-align: center;
                .dkg-services-page-title{
                    font-weight: 700;
                    font-size: 24px;
                    color: #333;
                    line-height: 1.2em;
                    text-align: center;
                    margin-top: 10px;
                }
                .dkg-services-page-text{
                    font-size: 1.1rem;
                    color: #333;
                    padding: 10px 0;
                    padding-bottom: 0;
                    width: 100%;
                    text-align: center;
                    max-width: 75%;
                    margin: 0 auto;
                }
            }
            .dkg-paidservices-leftcon,
            .dkg-paidservices-rightcon{
                margin-top: 1rem;
                .dkg-paidservice-title{
                    background: #1f394c;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    color: #ebdede;
                    font-size: 16px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 10px;
                    text-align: center;
                    margin-bottom: 0;
                    text-transform: uppercase;
                }
                .dkg-paidservice-subtitle{
                    background-color: #fff;
                    color: #333;
                    padding: 1.15rem 15px;
                    text-align: center;
                    width: 100%;
                    border: 1px solid #ddd;
                    height: calc(100vh - 322px);
                    overflow-y: auto;
                    .dkg-paid-subtitle-desc{
                        font-size: 14px;
                        text-align: justify;
                        margin-bottom: 0;
                        color: #666;

                    }
                }
                .dk-dashboard-panel{
                    &.dkg-account-benfits-panel{
                        background-color: #fff;
                        .dkg-account-benfits-table-con{
                            // height: calc(100vh - 325px);
                            // overflow-y: auto;
                            .dkg-account-benfits-table{
                                border: 1px solid #ddd !important;
                                height: 100%;
                                margin-bottom: 1rem;
                                thead{
                                    tr{
                                        th{
                                            background-color: #1f394c;
                                            color: #ebdede;
                                            border: 1px solid #aaa;
                                            padding: 10px 15px;
                                            text-align: center;
                                            vertical-align: middle;
                                            position: sticky;
                                            z-index: 9;
                                            &:nth-child(02),
                                            &:nth-child(03),
                                            &:nth-child(04){
                                                width: 210px;
                                            }
                                            &:nth-child(05){
                                                width: 510px;
                                            }
                                            &:nth-child(06),
                                            &:nth-child(07){
                                                width: 210px;
                                            }
                                        }
                                    }
                                }
                                tbody{
                                    height: 100%;
                                    tr{
                                        td{
                                            border-bottom: 1px solid #ddd;
                                            padding: 10px;
                                            text-align: center;
                                            vertical-align: middle;
                                            &:nth-child(2) {
                                                background-color: #f2f2f2;
                                                color: #445;
                                                font-size: 14px !important;
                                                text-transform: capitalize;
                                                border: 1px solid #ddd;
                                                border-left: none;
                                            }
                                            &:nth-child(02),
                                            &:nth-child(03),
                                            &:nth-child(04){
                                                width: 210px;
                                            }
                                            &:nth-child(05){
                                                width: 510px;
                                            }
                                            &:nth-child(06),
                                            &:nth-child(07){
                                                width: 210px;
                                            }
                                            &:nth-child(1),
                                            &:nth-child(2),
                                            &:nth-child(3){
                                                background-color: #fff;
                                            }
                                            // &:nth-child(04){
                                            //     border-right: none;
                                            //     text-align: center;
                                            // }
                                            .dkg-benifts-iconCon {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .dkg-benifts-icon {
                                                    width: 28px;
                                                    height: 28px;
                                                }
                                            }
                                            .dkg-benifts-tf-iconCon{
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                .dkg-benifts-tf-icon{
                                                    svg{
                                                        width: 22px;
                                                        height: 22px;
                                                    }
                                                }
                                            }
                                            .dkg-buy-btn-con{
                                                .dkg-buy-btn{
                                                    background-color: #3c97b6;
                                                    color: #fff;
                                                    padding: 0.5rem 1.5rem;
                                                }
                                            }
                                        }
                                        &:first-child{
                                            border-top: none;
                                        }
                                        &:last-child{
                                            td{
                                                border-bottom: none;
                                            }
                                        }
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .dkg-paidservices-leftcon{
                @media (min-width: 768px){
                    &.col-md-6{
                        flex: 0 0 47%;
                        max-width: 47%;
                        // margin-right: auto;
                        .dkg-paidservice-firstcols{
                            margin-bottom: 1rem;
                            &:nth-child(01){
                                .dkg-paidservice-subtitle{
                                    height: 130px;
                                    border-left: 3px solid #1f394c;
                                }
                            }
                            
                        }
                        .dkg-paidservice-secondcols{
                            .dkg-paidservice-subtitle{
                                height: 255px;
                            }
                        }
                    }
                }
            }
            .dkg-paidservices-rightcon{
                @media (min-width: 768px){
                    &.col-md-6{
                        flex: 0 0 53%;
                        max-width: 53%;
                        // margin-left: auto;
                    }
                }
            }
        }
        
    }
}