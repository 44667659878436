.dk-myacc-myprofilesummMain {
    margin-top: 153px;
    background-color: #eaeaea;
    width: 100%;
    height: 100vh;
    padding: 0 20px;
    height: calc(100vh - 153px);
    overflow-y: auto;
    .dkg-myacc-profile-summ-234{
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        height: 100%;
        .dkg-logins-dashboard-boxsCon {
            width: 100%;
            display: flex;
            padding: 0;
            padding-top: 15px;
            height: 100%;
            .dkg-logins-LeftCon {
                width: 50%;
                height: 50%;
            }
            .dkg-logins-RightCon {
                width: 50%;
                height: 50%;
                .dkg-loginsdetalis-boxRow {
                    display: flex;
                    .dk-loginsdetalisCols {
                        background: #fff;
                        border-radius: 5px;
                        border: 1px solid #ddd;
                        width: 100%;
                        margin: 9px;
                        .title {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-transform: uppercase;
                            font-weight: 500;
                            padding: 6px 10px;
                            background-color: #ddd;
                            color: #666;
                            border-radius: 5px 5px 0 0;
                            font-size: 15px;
                            border-bottom: 1px solid #ccc;
                            span.dk-itTeamImg {
                                display: inline-block;
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                                margin-right: 10px;
    
                                img {
                                    display: inline-block;
                                    width: 32px;
                                    height: 32px;
                                    border-radius: 50%;
                                }
                            }
                        }    
                        .dk-itTeamBox {
                            display: flex;
                            // align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            // height: calc(100vh - 220px);
                            .dk-itTeamBox-cols {
                                margin: 0 15px;
                                text-align: center;
                                font-weight: 400;
    
                                span.dk-itteamSummary-no {
                                    display: inline-block;
                                    margin-bottom: 3px;
                                }
    
                                span.text-blue {
                                    display: block;
                                    font-weight: 500;
                                    color: #3c97b6;
                                }
    
                                span.text-yellow {
                                    display: block;
                                    font-weight: 500;
                                    color: #d58b5d;
                                }
    
                                span.text-green {
                                    display: block;
                                    font-weight: 500;
                                    color: #3a9469;
                                }
                            }
                            &.dkg-mysaved-doc-box{
                                display: block;
                                .dkg-mysaved-doc-tablecols{
                                    .dkg-mysaved-doc-tableCon{
                                        .dkg-mysaved-doc-table{
                                            thead{
                                                tr{
                                                    th{
                                                        background-color: #EBEBEB;
                                                        border: 1px solid #ddd;
                                                        border-bottom: none;
                                                        color: #333;
                                                        font-size: 13px;
                                                        font-weight: 400;
                                                        padding: 9px 7px;
                                                        text-transform: uppercase;
                                                        position: -webkit-sticky;
                                                        position: sticky;
                                                        text-align: center;
                                                        top: 0;
                                                        vertical-align: middle;
                                                        white-space: nowrap;
                                                        z-index: 9999;
        
                                                    }
                                                }
                                            }
                                            tbody{
                                                tr{
                                                    td{
                                                        background-color: #fff;
                                                        border: 1px solid #ddd;
                                                        color: #333;
                                                        font-size: 13px;
                                                        font-weight: 400;
                                                        padding: 9px 7px;
                                                        text-align: center;
                                                        white-space: nowrap;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .dkg-unread-body-cols{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 100%;
                                .dkg-unread-blockNo{
                                    font-size: 18px;
                                    font-weight: bold;
                                    color: #2e97b6;
                                    cursor: pointer;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
            &:last-child{
                padding-top: 0;
            }
        }
        .dkg-loginsdetalis-boxRow {
            display: flex;
            .dk-loginsdetalisCols {
                background: #fff;
                border-radius: 5px;
                border: 1px solid #ddd;
                width: 100%;
                margin: 9px;
                // height: 260px;
                .title {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    font-weight: 500;
                    padding: 6px 10px;
                    background-color: #ddd;
                    color: #666;
                    border-radius: 5px 5px 0 0;
                    font-size: 15px;
                    border-bottom: 1px solid #ccc;
    
                    span.dk-itTeamImg {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        margin-right: 10px;
    
                        img {
                            display: inline-block;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                        }
                    }
                }
                .dk-itTeamBox {
                    height: 220px;
                    .dk-itTeamBox-cols {
                        margin: 0 15px;
                        text-align: center;
                        font-weight: 400;
    
                        span.dk-itteamSummary-no {
                            display: inline-block;
                            margin-bottom: 3px;
                        }
    
                        span.text-blue {
                            display: block;
                            font-weight: 500;
                            color: #3c97b6;
                        }
    
                        span.text-yellow {
                            display: block;
                            font-weight: 500;
                            color: #d58b5d;
                        }
    
                        span.text-green {
                            display: block;
                            font-weight: 500;
                            color: #3a9469;
                        }
                    }
                    .dkg-acc-status-ul{
                        list-style: none;
                        margin-bottom: 0;
                        width: 100%;
                        padding: 0;
                        .dkg-acc-status-il{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            padding: 8px 10px;
                            .dkg-acc-status-lefttitle{
                                font-size: 13px;
                                color: #333;
                            }
                            .dkg-acc-status-righttitle{
                                color: #2e97b6;
                                font-size: 13px;
                            }
                            &:first-child{
                                padding-top: 12px;
                            }
                        }
                    }
                    &.dkg-myprofile-box{
                        flex-direction: column;
                        // height: 105px;
                        min-height: 70px;
                        &:first-child{
                            margin-bottom: 15px;
                        }
                        .dkg-profile-1stcolumn{
                            // height: 105px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #eaeaea;
                            flex-direction: column;
                            .dkg-profile-title{
                                font-size: 1.2rem;
                                color: #333;
                            }
                        } 
                        .dkg-profile-2ndcolumn{
                            // height: 105px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: #fff;
                            height: 85px;
                            .dkg-profile-title{
                                font-size: 1.2rem;
                            }
                        } 
                    }
                    .dkg-unread-body-cols{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        flex-direction: column;
                        .dkg-profile-cvicon-con{
                            .dkg-profile-cvicon{
                                width: 48px;
                                height: 48px;
                                cursor: pointer;
                            }
                        }
                        .dkg-unread-blockNo{
                            font-size: 18px;
                            font-weight: bold;
                            color: #2e97b6;
                            cursor: pointer;
                            text-transform: capitalize;
                            margin-top: 0.45rem;
                        }
                    }
                }
                &.dkg-myprofile-cols{
                    height: inherit;
                    background-color: transparent;
                }
                .dkg-mysaved-doc-tableCon{
                    height: 240px;
                    overflow-y: auto;
                    .dkg-mysaved-doc-table{
                        thead{
                            tr{
                                th{
                                    background-color: #EBEBEB;
                                    border: 1px solid #ddd;
                                    border-bottom: none;
                                    color: #333;
                                    font-size: 13px;
                                    font-weight: 400;
                                    padding: 9px 7px;
                                    position: -webkit-sticky;
                                    position: sticky;
                                    text-align: center;
                                    top: 0;
                                    vertical-align: middle;
                                    white-space: nowrap;
                                    z-index: 99;
                                    text-transform: uppercase;
                                    &:nth-child(01){
                                        min-width: 60px;
                                    }
                                    &:nth-child(02){
                                        min-width: 120px;
                                    }
                                  
                                    &:nth-child(03){
                                        min-width: 190px;
                                    }
                                    &:nth-child(04){
                                        min-width: 70px;
                                    }
                                    &:nth-child(05){
                                        min-width: 260px;
                                    }
                                }
                            }
                        }
                        tbody{
                            tr{
                                td{
                                    background-color: #fff;
                                    border: 1px solid #ddd;
                                    color: #333;
                                    font-size: 13px;
                                    font-weight: 400;
                                    padding: 9px 7px;
                                    text-align: center;
                                    &:nth-child(01){
                                        min-width: 60px;
                                    }
                                    &:nth-child(02){
                                        min-width: 120px;
                                    }
                                    
                                    &:nth-child(03){
                                        min-width: 190px;
                                    }
                                    &:nth-child(04){
                                        min-width: 70px;
                                    }
                                    &:nth-child(05){
                                        min-width: 260px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &:last-child{
        padding-top: 0;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .dk-myacc-myprofilesummMain{
        margin-top: 35px;
        height: calc(100vh - 35px);
        padding: 0 1rem;
        padding-bottom: 1.75rem;
        .dkg-myacc-profile-summ-234{
            .dkg-myacc-profile-Row{
                .dkg-logins-dashboard-boxsCon {
                    display: block;
                    padding-bottom: 0.5rem;
                    .dkg-logins-LeftCon,
                    .dkg-logins-RightCon{
                        width: 100%;
                        height: inherit;
                        .dkg-loginsdetalis-boxRow{
                            display: block;
                            .dk-loginsdetalisCols{
                                margin: 0;
                                margin-bottom: 1rem;
                                .title{
                                    font-size: 0.95rem;
                                    border-radius: 0;
                                }
                                .dk-itTeamBox {
                                    .dkg-acc-status-ul {
                                        .dkg-acc-status-il {
                                            .dkg-acc-status-lefttitle,
                                            .dkg-acc-status-righttitle{
                                                font-size: 0.8rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}