.dkg-myacc-profileModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 40% !important;
        max-width: 40%;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dkg-myacc-profileModal-Header {
                background-color: #234158;
                border-radius: 0;
                padding: 14px 15px !important;
                width: 100%;
                border-bottom: none;
                display: flex;
                // justify-content: space-between;
                align-items: center;
                position: relative;
                height: 52px;
                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    text-align: center;
                    font-size: 14px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                .modalTitleCon {
                    padding: 0;
                    color: #fff;
                    font-size: 14px;
                }
                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: -5px;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dkg-myacc-profileModal-Body {
                background-color: #eaeaea;
                padding: 0;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dkg-myacc-profile-maincon{
                    .dkg-myprofile-maincon-123{
                        .dkg-myprofile-leftSidemenu{
                           .dkg-acc-myprofile-navpills{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            margin-bottom: 0;
                            height:  inherit !important;
                            border-radius: 0;
                            .dkg-acc-myprofile-navitem{
                                width: 100%;
                                max-width: 33.3333%;
                                border-bottom: none;
                                .dkg-acc-myprofile-navlink{
                                    width: 100%;
                                    text-align: center;
                                    border-radius: 0 !important;
                                }
                            }
                           } 
                           .dkg-acc-myprofile-tabCon{
                            .dkg-acc-myprofile-tabpane{
                                .dk-billingHistroy-fluid{
                                    .dk-contactPerson-form{
                                        height: inherit !important;
                                        overflow: inherit !important;
                                    }
                                }
                            }
                           }
                        }
                    }
                }
                &.dkg-myacc-myprofile-body-34{
                    .dkg-myprofile-mainpageCon{
                        &.dkg-myacc-profile-maincon{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

.modal.dkg-myacc-profileModal.fade .modal-dialog {
    right: -40%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dkg-myacc-profileModal.show .modal-dialog {
    right: 0;
}

.modal.dkg-myacc-profileModal {
    z-index: 9999 !important;
}

.MuiDialog-root {
    z-index: 9999 !important;
}
.dkg-myprofile-mainpageCon {
    &.dkg-myacc-profile-maincon{
        color: inherit;
        padding: 0;
    }
    background-color: #eaeaea;
    &.dkg-restlogin-mainpageCon {
        margin-top: 153px !important;
        height: calc(100vh - 167px);
    }

    .dk-billingHistroy-fluid {
        .dk-contactPerson-form {
            padding: 15px;
            background-color: #eaeaea;
            border-radius: 5px;
            height: calc(100vh - 435px);
            overflow-y: auto;

            .dkg-bankimg-Con {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;

                img {
                    width: 50px;
                }

                .dkg-banktitle {
                    font-size: 18px;
                    font-weight: 600
                }
            }

            &.dkg-resetform-Con-234 {
                margin-top: 100px !important;
                padding: 0 !important;
                height: inherit;
                overflow-y: inherit;
                .dkg-reset-leftCon{
                    .dkg-reset-infoLeftCard{
                        background-color: #fff;
                        border-left: 5px solid #9d265c;
                        border-radius: 0.25rem;
                        padding: 1.25rem;
                        p{
                            font-size: 14px;
                        }
                    }
                }
                .dkg-reset-rightCon{
                    &.dkg-deleteAcc-rightCon{
                        .dk-complaince-main{
                            .title{
                                background: #1f394c;
                                padding: 0 10px;
                                color: #fff;
                                font-size: 18px;
                                text-align: center;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                                height: 45px;
                                line-height: 45px;
                            }
                            .dk-complaince-cols{
                                text-align: left;
                                padding: 20px 35px;
                                background-color: #fff;
                                p {
                                    font-size: 14px;
                                }
                                .sb-title{
                                    font-weight: 700;
                                    margin-bottom: 10px;
                                }
                                ul{
                                    padding: 0 18px;
                                    li{
                                        list-style-type: disc;
                                    }
                                }
                                // div{
                                //     input[type="checkbox"]{
                                //         background-color: #fff !important;
                                //     }
                                // }
                                .dk-complaince-field{
                                    .d-flex{
                                        .dk-deleteBtn{
                                            margin-top: 20px !important;
                                            display: inline-block !important;
                                            padding: 10px 25px;
                                            background-color: #e2445b !important;
                                            color: #fff;
                                            border-color: #e2445b;
                                            min-width: 172px !important;
                                            text-transform: capitalize;
                                            line-height: 13px;
                                            height: 36px;
                                        }
                                    }
                                }
                                .dkg-delete-checkbox-div{
                                    .dk-smsTableCheckbox{
                                        input[type="checkbox"] {
                                            cursor: pointer;
                                            appearance: none;
                                            background: #fff;
                                            &::checked {
                                                background: #31a886 !important;
                                            }
                                        }
                                    }
                                }
                                &.dkg-delete-acc-cols{
                                    padding: 0;
                                    .dkg-delete-acc-header{
                                        p{
                                            color: #8a8a8a;
                                            width: 100%;
                                            text-align: justify;
                                            padding: 10px 15px;
                                            background-color: #f2f2f2;
                                            border-bottom: 1px solid #ddd;
                                            margin-bottom: 1rem !important;
                                            font-size: 0.95rem;
                                        }
                                    }
                                    .dkg-delete-acc-body{
                                        padding: 20px 35px;
                                        .dkg-delete-checkbox-div{
                                            .dk-smsTableCheckbox{
                                                input[type="checkbox"] {
                                                    cursor: pointer;
                                                    appearance: none;
                                                    background: #fff;
                                                    &::checked {
                                                        background: #31a886 !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }
            }

            form {
                .rtitle {
                    background: #1f394c;
                    padding: 0 10px;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    height: 45px;
                    line-height: 45px;
                    margin-bottom: 0;
                }

                .form-group {
                    margin-bottom: 1rem;
                    display: flex;
                    align-items: center;

                    &.uplaod-image {
                        .dk-contactPerson-image {
                            width: 100%;
                            max-width: 120px;
                            border: 1px solid #ccc;
                            padding: 0;
                            margin-right: 15px;
                            height: 120px;
                            display: flex;
                            align-items: center;
                            background: #fff;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;

                            img {
                                width: 100%;
                                object-fit: cover;
                                border-radius: 50%;
                                height: 120px;
                                object-position: top center;
                            }
                        }

                        .dk-edit-userImgCon {
                            width: 100%;
                            max-width: 85px;
                            border: 1px solid #ccc;
                            padding: 0;
                            margin-right: 15px;
                            height: 85px;
                            display: flex;
                            align-items: center;
                            background: #fff;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            margin-bottom: 10px;

                            input {
                                width: 100%;
                                height: 100px;
                                max-width: 100px;
                                position: relative;
                                z-index: 9;
                                opacity: 0;
                                cursor: pointer;
                            }

                            span.dk-myprofile-userImgCon {
                                position: absolute;
                                top: -1px;
                                left: 14px;
                                cursor: pointer;
                                width: 100%;
                                max-width: 85px;
                                border-radius: 50%;

                                img.dk-myprofile-userImg {
                                    width: 100%;
                                    height: auto;
                                    border-radius: 50%;
                                }

                                figure {
                                    .dkg-update-profile-234 {
                                        max-width: 120px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        height: 120px;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        overflow: hidden;
                                        border-radius: 50%;
                                        position: relative;
                                        right: 4px;
                                    }
                                }
                            }

                            span.dk-myprofile-editicon {
                                position: absolute;
                                bottom: 25px;
                                left: 75px;
                                background-color: #234158;
                                width: 27px;
                                height: 27px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;

                                svg {
                                    color: #fff;
                                }
                            }
                        }
                    }

                    label {
                        width: 100%;
                        max-width: 140px;
                        margin-bottom: 0;
                        font-size: 13px;
                    }

                    input {
                        width: 100%;
                        height: 35px;
                        border-radius: 0;
                        background-color: #fff;
                        text-align: left;
                        border: 1px solid #ddd;
                    }

                    .myacc-resetinput-type {
                        background-color: #fff;

                        &:focus {
                            outline: 0;
                            box-shadow: none;
                        }
                    }

                    .dk-passHideShow {
                        position: absolute;
                        right: 14px;
                        top: 0px;
                        cursor: pointer;
                        padding: 5px 6px;
                        color: #6c757d;
                        border: 1px solid #6c757d;
                        background-color: #fff !important;

                        svg {
                            color: #6c757d;
                        }

                        &:hover {
                            background-color: #6c757d !important;

                            svg {
                                color: #fff;
                            }
                        }
                    }

                    .dkg-birtday-rowCon {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .dkg-datejoing-frame {
                            background-color: #eaeaea;
                            text-align: left;
                            border: 1px solid #ddd;
                            position: relative;
                            height: 35px;
                            line-height: 26px;
                        }

                        .dkg-job-birthayicon-con {
                            position: absolute;
                            right: 13px;
                            top: 0;
                            border-left: 1px solid #ddd;
                            height: 35px;
                            padding: 0 9px;
                            line-height: 32px;

                        }
                    }

                    .dk-myprofile-textArea {
                        border: 1px solid #ddd;
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 14px;
                        border-radius: 0;
                        text-align: left;
                        background-color: #eaeaea;
                        text-align: left;
                        color: #333;
                    }

                    .dk-myprofile-Dropdown {
                        width: 100%;

                        .dk-myprofile-dropdown-toggle {
                            background-color: #fff;
                            border: 1px solid #ced4da;
                            height: 40px;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            box-shadow: none;
                            text-transform: capitalize;
                            font-size: 13px;
                        }

                        .dk-myprofile-DropdownMenu {
                            border-radius: 0;
                            padding: 0;
                            width: 100%;

                            .dropdown-item {
                                padding: 8px 10px;
                                border-bottom: 1px solid #ced4da;
                                font-size: 13px;

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }
                    }

                    &.dkg-reset-form-group {
                        margin-bottom: 2.5rem !important;

                        &:last-child {
                            margin-bottom: 1.75rem !important;
                        }

                        &.dkg-reset-last-form-group {
                            margin-bottom: 2.5rem !important;
                            &:last-child {
                                margin-bottom: 0.75rem !important;
                            }
                        }
                    }
                }

                .dkg-myprofile-lastrow {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-left: 135px;

                    .form-group {
                        display: block;

                        label {
                            max-width: inherit;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }

                .dk-ownerForm-note {
                    background: #fff;
                    padding: 22px 20px;
                    margin-top: 23px;
                    border: 1px solid #ddd;
                    border-left: 4px solid #2d96b6 !important;
                }

                .dkg-resetbtn-Con-24 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-top: 1em;
                    margin-bottom: 1.25rem;

                    .dkg-cancelbtn {
                        display: inline-block;
                        font-weight: 400;
                        color: #212529;
                        text-align: center;
                        vertical-align: middle;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        background-color: transparent;
                        border: 1px solid transparent;
                        padding: 0.375rem 0.75rem;
                        font-size: 1rem;
                        line-height: 1.5;
                        text-transform: capitalize;
                        box-shadow: none;
                        transition: all 0.25s ease-in-out;
                        margin-right: 8px;

                        &:hover {
                            color: #fff;
                            background-color: #c82333;
                            border-color: #bd2130;
                            border-radius: 5px !important;
                        }
                    }

                    .dkg-resetbtn {
                        background-color: #67818e !important;
                        border: 1px solid #67818e !important;
                        border-radius: 0.1875rem;
                        color: #fff;
                        display: inline-block;
                        font-size: 13px;
                        line-height: 1.5385;
                        padding: 5px 12px;
                        position: relative;
                        text-align: center;
                        text-transform: capitalize;

                        &:hover {
                            background-color: #1f394c !important;
                            border: 1px solid #1f394c !important;
                        }
                    }
                }
                .dkg-reset-login-body{
                    background-color: #fff;
                    border-radius: 0 0 5px 5px;
                    .dkg-delete-acc-header {
                        p {
                            color: #8a8a8a;
                            width: 100%;
                            text-align: justify;
                            padding: 10px 15px;
                            background-color: #f2f2f2;
                            border-bottom: 1px solid #ddd;
                            margin-bottom: 1rem !important;
                            font-size: 0.95rem;
                        }
                    }
                }
            }
        }
    }

}

.dkg-myprofile-maincon-123 {
    .dkg-myprofile-leftSidemenu {
        .dkg-acc-myprofile-navpills {
            background-color: #fff;
            // border-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            display: flex;
            margin: 0;
            overflow: visible;
            padding: 0;
            // height: 100vh;
            height: calc(100vh - 210px);

            .dkg-acc-myprofile-navitem {
                border-bottom: 1px solid #607f92;
                list-style: none;
                text-align: center;
                width: 100%;

                &:first-child {
                    border-top-left-radius: 5px;

                    .dkg-acc-myprofile-navlink {
                        color: #333;

                        &.active {
                            border-top-left-radius: 5px;
                            color: #333;

                        }
                    }
                }

                // &:last-child {
                //     .dkg-acc-myprofile-navlink {
                //         &.active {
                //             border-radius: 0 0 5px 5px;
                //         }
                //     }
                // }

                .dkg-acc-myprofile-navlink {
                    border-radius: 0;
                    color: #333;
                    display: block;
                    padding: 10px;
                    position: relative;
                    text-align: left;
                    white-space: nowrap;

                    &.active {
                        background: #eaeaea;
                        color: #333;
                    }
                }
            }
        }
    }

    .dkg-myprofile-rightSide {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;

        .dkg-acc-myprofile-tabCon {
            .dkg-acc-myprofile-tabpane {
                .dk-billingHistroy-fluid {
                    .dk-contactPerson-form {
                        padding: 25px 15px;
                        background-color: #fff;
                        border-radius: 5px;
                        height: calc(100vh - 220px);

                        overflow-y: auto;

                        &.dkg-resetform-Con-234 {
                            margin-top: 80px !important;
                            padding: 0 !important;
                        }

                        form {
                            .rtitle {
                                background: #1f394c;
                                padding: 0 10px;
                                color: #fff;
                                font-size: 18px;
                                text-align: center;
                                border-top-left-radius: 5px;
                                border-top-right-radius: 5px;
                                height: 45px;
                                line-height: 45px;
                                margin-bottom: 1.25rem;
                            }

                            .form-group {
                                margin-bottom: 1rem;
                                display: flex;
                                align-items: center;

                                &.uplaod-image {
                                    .dk-contactPerson-image {
                                        width: 100%;
                                        max-width: 120px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        margin-right: 15px;
                                        height: 120px;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        overflow: hidden;
                                        border-radius: 50%;

                                        img {
                                            width: 100%;
                                            object-fit: cover;
                                            border-radius: 50%;
                                            height: 120px;
                                            object-position: top center;
                                        }
                                    }

                                    .dk-edit-userImgCon {
                                        width: 100%;
                                        max-width: 85px;
                                        border: 1px solid #ccc;
                                        padding: 0;
                                        margin-right: 15px;
                                        height: 85px;
                                        display: flex;
                                        align-items: center;
                                        background: #fff;
                                        justify-content: center;
                                        overflow: hidden;
                                        border-radius: 50%;
                                        margin-bottom: 10px;

                                        input {
                                            width: 100%;
                                            height: 100px;
                                            max-width: 100px;
                                            position: relative;
                                            z-index: 9;
                                            opacity: 0;
                                            cursor: pointer;
                                        }

                                        span.dk-myprofile-userImgCon {
                                            position: absolute;
                                            top: -1px;
                                            left: 14px;
                                            cursor: pointer;
                                            width: 100%;
                                            max-width: 85px;
                                            border-radius: 50%;

                                            img.dk-myprofile-userImg {
                                                width: 100%;
                                                height: auto;
                                                border-radius: 50%;
                                            }

                                            figure {
                                                .dkg-update-profile-234 {
                                                    max-width: 120px;
                                                    border: 1px solid #ccc;
                                                    padding: 0;
                                                    height: 120px;
                                                    display: flex;
                                                    align-items: center;
                                                    background: #fff;
                                                    justify-content: center;
                                                    overflow: hidden;
                                                    border-radius: 50%;
                                                    position: relative;
                                                    right: 4px;
                                                }
                                            }
                                        }

                                        span.dk-myprofile-editicon {
                                            position: absolute;
                                            bottom: 25px;
                                            left: 75px;
                                            background-color: #234158;
                                            width: 27px;
                                            height: 27px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            border-radius: 50%;

                                            svg {
                                                color: #fff;
                                            }
                                        }
                                    }
                                }

                                label {
                                    width: 100%;
                                    max-width: 140px;
                                    margin-bottom: 0;
                                    font-size: 13px;
                                }

                                input {
                                    width: 100%;
                                    height: 35px;
                                    border-radius: 0;
                                    background-color: #eaeaea;
                                    text-align: left;
                                    border: 1px solid #ddd;
                                }

                                .myacc-resetinput-type {
                                    background-color: #fff;

                                    &:focus {
                                        outline: 0;
                                        box-shadow: none;
                                    }
                                }

                                .dk-passHideShow {
                                    position: absolute;
                                    right: 14px;
                                    top: 0px;
                                    cursor: pointer;
                                    padding: 5px 6px;
                                    color: #6c757d;
                                    border: 1px solid #6c757d;
                                    background-color: #fff !important;

                                    svg {
                                        color: #6c757d;
                                    }

                                    &:hover {
                                        background-color: #6c757d !important;

                                        svg {
                                            color: #fff;
                                        }
                                    }
                                }

                                .dkg-birtday-rowCon {
                                    display: flex;
                                    align-items: center;
                                    width: 100%;

                                    .dkg-datejoing-frame {
                                        background-color: #eaeaea;
                                        text-align: left;
                                        border: 1px solid #ddd;
                                        position: relative;
                                        height: 35px;
                                        line-height: 26px;
                                    }

                                    .dkg-job-birthayicon-con {
                                        position: absolute;
                                        right: 13px;
                                        top: 0;
                                        border-left: 1px solid #ddd;
                                        height: 35px;
                                        padding: 0 9px;
                                        line-height: 32px;

                                    }
                                }

                                .dk-myprofile-textArea {
                                    border: 1px solid #ddd;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                    font-size: 14px;
                                    border-radius: 0;
                                    text-align: left;
                                    background-color: #eaeaea;
                                    text-align: left;
                                    color: #333;
                                }

                                .dk-myprofile-Dropdown {
                                    width: 100%;

                                    .dk-myprofile-dropdown-toggle {
                                        background-color: #fff;
                                        border: 1px solid #ced4da;
                                        height: 40px;
                                        width: 100%;
                                        margin: 0;
                                        padding: 0;
                                        box-shadow: none;
                                        text-transform: capitalize;
                                        font-size: 13px;
                                    }

                                    .dk-myprofile-DropdownMenu {
                                        border-radius: 0;
                                        padding: 0;
                                        width: 100%;

                                        .dropdown-item {
                                            padding: 8px 10px;
                                            border-bottom: 1px solid #ced4da;
                                            font-size: 13px;

                                            &:last-child {
                                                border-bottom: none;
                                            }
                                        }
                                    }
                                }

                                &.dkg-reset-form-group {
                                    margin-bottom: 2.5rem !important;

                                    &:last-child {
                                        margin-bottom: 1.2rem !important;
                                    }

                                    &.dkg-reset-last-form-group {
                                        margin-bottom: 0 !important;
                                    }
                                }
                            }

                            .dkg-myprofile-lastrow {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                margin-left: 135px;

                                .form-group {
                                    display: block;

                                    label {
                                        max-width: inherit;
                                        width: 100%;
                                        text-align: center;
                                    }
                                }
                            }

                            .dk-ownerForm-note {
                                background: #fff;
                                padding: 22px 20px;
                                margin-top: 23px;
                                border: 1px solid #ddd;
                                border-left: 4px solid #2d96b6 !important;
                            }

                            .dkg-resetbtn-Con-24 {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                margin-top: 1em;
                                margin-bottom: 1.25rem;

                                .dkg-cancelbtn {
                                    display: inline-block;
                                    font-weight: 400;
                                    color: #212529;
                                    text-align: center;
                                    vertical-align: middle;
                                    -webkit-user-select: none;
                                    -moz-user-select: none;
                                    -ms-user-select: none;
                                    user-select: none;
                                    background-color: transparent;
                                    border: 1px solid transparent;
                                    padding: 0.375rem 0.75rem;
                                    font-size: 1rem;
                                    line-height: 1.5;
                                    text-transform: capitalize;
                                    box-shadow: none;
                                    transition: all 0.25s ease-in-out;
                                    margin-right: 8px;

                                    &:hover {
                                        color: #fff;
                                        background-color: #c82333;
                                        border-color: #bd2130;
                                        border-radius: 5px !important;
                                    }
                                }

                                .dkg-resetbtn {
                                    background-color: #67818e !important;
                                    border: 1px solid #67818e !important;
                                    border-radius: 0.1875rem;
                                    color: #fff;
                                    display: inline-block;
                                    font-size: 13px;
                                    line-height: 1.5385;
                                    padding: 5px 12px;
                                    position: relative;
                                    text-align: center;
                                    text-transform: capitalize;

                                    &:hover {
                                        background-color: #1f394c !important;
                                        border: 1px solid #1f394c !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .dkg-resetbtn-Con-24 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 1em;
        margin-bottom: 1.25rem;

        .dkg-cancelbtn {
            display: inline-block;
            font-weight: 400;
            color: #212529;
            text-align: center;
            vertical-align: middle;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            text-transform: capitalize;
            box-shadow: none;
            transition: all 0.25s ease-in-out;
            margin-right: 8px;

            &:hover {
                color: #fff;
                background-color: #c82333;
                border-color: #bd2130;
                border-radius: 5px !important;
            }
        }

        .dkg-resetbtn {
            background-color: #67818e !important;
            border: 1px solid #67818e !important;
            border-radius: 0.1875rem;
            color: #fff;
            display: inline-block;
            font-size: 13px;
            line-height: 1.5385;
            padding: 5px 12px;
            position: relative;
            text-align: center;
            text-transform: capitalize;

            &:hover {
                background-color: #1f394c !important;
                border: 1px solid #1f394c !important;
            }
        }
    }
}