.dk-pushNotification-main {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    width: 100%;
    max-width: 350px;

    .dk-pushNotificationCols {
        width: 100%;
        border-radius: 5px;
        color: #fff;
        position: relative;
        border-top: none;
        padding-bottom: 0;

        &:last-child {
            margin-bottom: 0;
        }

        .dk-notificationHead {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #da3d5e;
            color: #fff;
            padding: 5px 15px;
            border-radius: 5px 5px 0 0;
            font-family: 'Poppins', sans-serif !important;
            font-weight: 500;
        }

        .dk-miniIcons {
            display: inline-block;
            cursor: pointer;
            margin-left: 5px;
        }

        .dk-closePopup {
            display: inline-block;
            color: #fff;
            outline: none;
            font-size: 16px;

            &:hover {
                color: #ccc;
            }
        }

        .dk-userDetail {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            font-family: 'Poppins', sans-serif !important;
            padding: 0 15px 5px;
            padding-top: 8px;
            padding-right: 0;
            background: #333;
            border: 1px solid #5c737d;

            .dk-remindCols {
                padding: 8px 10px 5px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 100%;
                padding-left: 0;

                .dk-remindItems.dropdown {
                    width: 125px;
                    margin-left: 15px;
                }

                .dk-snoozeCols {
                    position: relative;
                    width: 115px;

                    span.snoozeIco {
                        position: absolute;
                        left: 7px;
                        top: 4px;
                        color: #fff;
                        z-index: 99;
                    }

                    button {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px 7px;
                        text-transform: inherit;
                        box-shadow: none;
                        border-radius: 3px;
                        border: 1px solid #4e4e4e;
                        color: #ddd;
                        background: #404040;
                        outline: none;
                        width: 100%;
                        font-size: 12px;

                        &:hover {
                            background: #636363;
                            transition: all 0.5s;
                        }
                    }

                    .MuiFormControl-root {
                        border: 1px solid #ccc;

                        .MuiInputLabel-root {
                            display: block;
                            margin: 0;
                            color: #fff;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            z-index: 99;
                        }

                        .MuiOutlinedInput-root {
                            padding: 0;
                            height: 30px;
                            border-radius: 3px;
                        }

                        .MuiOutlinedInput-input {
                            padding: 0 10px;
                            border: none;
                            width: 100%;
                            padding-left: 23px;
                            background: #333;
                        }

                        .MuiButtonBase-root {
                            padding: 0;
                            color: #fff;
                            outline: none;
                            position: relative;
                            right: 15px;

                            svg {
                                font-size: 15px;
                            }
                        }

                        fieldset {
                            display: none;
                        }
                    }

                    input {
                        text-align: center;
                        background: #333;
                        border: 1px solid #ccc;
                        border-radius: 3px;
                        color: #fff;
                        font-size: 13px;
                        outline: none;
                        box-shadow: none;
                        height: 30px;
                        padding-left: 24px;
                    }

                    input::-webkit-input-placeholder {
                        color: #fff;
                    }

                    input::-moz-placeholder {
                        color: #fff;
                    }

                    input:-ms-input-placeholder {
                        color: #fff;
                    }

                    input:-moz-placeholder {
                        color: #fff;
                    }
                }

                .dk-remindItems.dropdown {
                    .dropdown-toggle {
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 4px 0;
                        text-transform: inherit;
                        box-shadow: none;
                        border: 1px solid #4e4e4e;
                        background: #404040;
                        color: #ddd;
                        outline: none;
                        width: 100%;
                        margin-bottom: 5px;
                        font-size: 12px;

                        &:hover {
                            background: #636363;
                            transition: all 0.5s;
                        }

                        &::after {
                            margin-left: 8px;
                        }
                    }

                    &:last-child {
                        .dropdown-toggle {
                            margin-bottom: 0;
                        }
                    }

                    .dropdown-menu {
                        padding: 0;
                        min-width: 86px;
                        width: 100%;

                        a {
                            display: block;
                            width: 100%;
                            font-size: 12px;
                            border-bottom: 1px solid #ccc;
                            padding: 5px 10px;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }

            p {
                margin: 0;
                display: block;
                width: 100%;
                margin-bottom: 5px;
                font-weight: 400;
                font-size: 14px;
                padding: 10px 0;
            }

            .dk-userTitle {
                font-weight: 600;
                font-size: 14px;
            }

            .dk-userImg {
                display: inline-block;
                width: 40px;
                margin-right: 10px;

                img {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }
        }

        .dk-notification-text {
            font-family: "Poppins", sans-serif !important;
            padding-left: 0;
            padding-bottom: 0px;
            text-align: right;
            padding-right: 0;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            white-space: nowrap;

            p {
                margin: 0;
                display: block;
                width: 100%;
                margin-bottom: 5px;
                margin-top: -6px;
                font-weight: 500;
            }

            small {
                color: #fff;
                opacity: 1;
                display: inline-flex;
                align-items: center;
                margin-right: 0;
                font-weight: 300;
                font-size: 11px;

                &:first-child {
                    margin-right: 0;
                }

                svg {
                    display: inline-block;
                    margin-right: 5px;
                    margin-top: -1px;
                    color: #e7e7e7;
                }
            }
        }
    }
}

.css-j5h6pi-MuiPopper-root {
    z-index: 9999 !important;
}