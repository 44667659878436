.modal-dialog.dk-adsCreAddNewPop {
    /* min-width: 30%; */
    width: 280px;

}

.modal-dialog.dk-adsCreAddNewPop .modal-content {
    box-shadow: none;
    background-color: transparent;
}

.modal-dialog.dk-adsCreAddNewPop .modal-content .modal-body {
    padding: 20px 30px;
    background-color: #F2F2F2;
    /* width: 350px; */
    border-radius: 6px;
}

.dk-adsCreditInCon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
}

.dk-adsCreditInCon .firstInput {
    max-width: 100px;
}

.dk-adsCreditInCon .firstInput label {
    width: 100%;
    text-align: center;
}

.dk-adsCreditInCon .firstInput input.form-control {
    border-radius: 0;
    text-align: center;
    background-color: #E2E7F6;
    outline: 0;
}

.dk-adsCreditInCon .firstInput input.form-control:focus {
    box-shadow: none;
}

.dk-adsCreditInCon .SecondInput {
    max-width: 100px;
}

.dk-adsCreditInCon .SecondInput label {
    width: 100%;
    text-align: center;
}

.dk-adsCreditInCon .SecondInput input.form-control {
    border-radius: 0;
    text-align: center;
}

.dk-adsCreditBtnCon {
    width: 100%;
    text-align: center;
}

.dk-adsCreditBtnCon .dk-calcsaveBtn {
    border-radius: 35px;
    color: #fff !important;
    padding: 8px 20px;
    letter-spacing: 1px;
    background: #5e737d !important;
    min-width: 90px;
    box-shadow: none;
}

.dk-adsCreditBtnCon .dk-calcsaveBtn:hover {
    background-color: #374252 !important;
    transition: all .5s;
    text-decoration: none;
    box-shadow: none;
}