.dk-task-for-dropdown-24 {

    .dk-task-for-dropdown-toggle,
    .btn-primary.dk-task-for-dropdown-toggle:not([disabled]):not(.disabled):active,
    .btn-primary.dk-task-for-dropdown-toggle:not([disabled]):not(.disabled).active,
    .show>.btn-primary.dk-task-for-dropdown-toggle.dropdown-toggle {
        background-color: transparent !important;
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        box-shadow: none !important;

        &:hover {
            background-color: transparent !important;
        }

        &::after {
            position: absolute;
            top: 15px;
            right: 10px;
            z-index: 99999;
            color: #000000bf;
            font-size: 15px;
        }
    }

    .dk-task-for-dropdown-menu {
        padding: 0;
        border-radius: 0;
        width: 100%;
        border: none;
        min-width: 180px;

        input {
            border-radius: 0;
            border: 1px dotted #ddd;
            border-bottom: none;
            background-color: #f2f2f2;
            padding: 7px 9px;

        }

        .dkg-task-ul-con-24 {
            border-radius: 0;
            margin: 0;
            padding: 0;
            border: 1px solid #7f9db9;
            border-radius: 0 0 3px 3px;
            position: absolute;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            z-index: 101;
            background-color: #fff;
            overflow-y: scroll;
            max-height: 250px;
            list-style: none;
            left: -1px;
            right: -1px;
            box-shadow: 0 8px 24px rgba(0, 0, 0, 0.10);

            .dropdown-item {
                padding: 5px;
                font-size: 12px;
                color: #333;
                border-bottom: 1px solid #dddddd;

                &.active,
                &:hover {
                    background-color: #39f;
                    color: #fff;
                }

                &:last-child {
                    border-bottom: none;
                }

                &:after {
                    display: none !important;
                }

                .dk-userCon {
                    img {
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                    }
                }

                .dk-taskforuserName {
                    font-size: 13px;
                    margin-left: 20px;
                    margin-top: 5px;
                }
            }

        }
    }
}