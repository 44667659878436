.dkg-myaccsubMenu-Main{
    width: 100%;
    max-width: 1600px;
    margin: auto;
    .dk-myaccsubMenuMain {
        background-color: #eaeaea;
        position: fixed;
        top: 125px;
        left: 9.19%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto !important;
        border-radius: 0 0 5px 5px;
        overflow: hidden;
        flex: 0 0 88.666667%;
        max-width: 88.5966667%;
        z-index: 99;
         ul {
             list-style: none;
             display: flex;
             align-items: center;
             margin-bottom: 0;
             width: 100%;
             background-color: #fff;
             li {
                 width: 100%;
                 max-width: 10%;
                 min-width: 10%;
                 text-align: center;
                 border-right: 1px solid #ddd;
                 a {
                     font-size: 14px;
                     padding: 12px 5px;
                     width: 100%;
                     background-color: #fff;
                     display: flex;
                     color: #333;
                     border: none !important;
                     justify-content: center;
                     white-space: nowrap;
                    &.active,
                    &:hover{
                     background-color: #eaeaea;
                    } 
                 }
                 &:first-child{
                    border-left: 1px solid  #ddd;
                 }
                 &:last-child{
                    border-right: 1px solid #ddd;
                 }
             }
         }
    }
}