.modal-dialog.dk-adstarckDeletePop {
    min-width: 35%;
}
.modal-dialog.dk-adstarckDeletePop .modal-content {
    background-color: transparent;
}
.modal-dialog.dk-adstarckDeletePop .modal-content .dk-adstrackDelBody{
    padding: 35px 15px 22px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fff;
}
.modal-dialog.dk-adstarckDeletePop .modal-content .dk-adstrackDelBody p{
    color: #e2445b;
}
.modal-dialog.dk-adstarckDeletePop .modal-content .dk-adstrackDelFooter{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid transparent;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;

}
.modal-dialog.dk-adstarckDeletePop .modal-content .dk-adstrackDelFooter button.dk-nodelBtn{
    border: 1px solid #333;
    color: #333 !important;
    background-color: transparent !important;
    display: inline-block;
    width: 100%;
    max-width: 90px;
    border-radius: 5px;
    padding: 7px 0;
}
.modal-dialog.dk-adstarckDeletePop .modal-content .dk-adstrackDelFooter button.dk-yesdelBtn {
    background-color: #e2445b !important;
    border: 1px solid #e2445b;
    color: #fff;
    display: inline-block;
    width: 100%;
    max-width: 90px;
    border-radius: 5px;
    padding: 7px 0;
}