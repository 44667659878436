.dk-navBarMain.dk-clientinfo-navbar{
    background: #5E766E;
    position: fixed;
    top: 52px;
    z-index: 9;
    width: 100%;
    left: 0;
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        max-width: 1600px;
        margin: auto;
        li{
            display: inline-block;
            width: 100%;
            max-width:9.09%;
            a{
                width: 100%;
                background-color: #5E766E;
                display: flex;
                color: hsla(0,0%,100%,.9);
                font-size: 14px;
                padding: 13px 0!important;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                min-height: 46px;
                max-height: 46px;
                font-family: Verdana,sans-serif;
                transition: all 0.5s;
                span.dk-badge{
                    background-color: #5E766E;
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    color: #fff;
                    text-align: center;
                    line-height: 18px;
                    font-size: 11px;
                    margin: 0 5px;
                }
                &:hover{
                    background: #eaeaea;
                    color: #333;
                    transition: all 0.5s;
                }
                i{
                    font-size: 11px;
                    display: inline-block;
                    margin: 0 2px;
                }
            }
            a.active{
                background: #fff;
                color:#333;
            }
            a.gray-active{
                background: #eaeaea;
                color:#333;
            }
            a.activeDark{
                background: #2e5a7c;
                color: #fff;
            }
        }
    }
}