.dkg-emaillogs-mainCon{
    .dkg-emaillogs-tableCon{
        height: calc(100vh - 237px);
        margin-top: 6px;
        overflow-x: auto;
        overflow-y: auto;
        padding-bottom: 0;
        position: relative;
        .dkg-emaillogs-table{
            thead{
                tr{
                    th{
                        background-color: #eaeaea;
                        border: 1px solid #ddd;
                        border-bottom: none;
                        color: #333;
                        font-size: 13px;
                        padding: 9px 7px;
                        position: -webkit-sticky;
                        position: sticky;
                        text-align: center;
                        top: 0;
                        vertical-align: middle;
                        white-space: nowrap;
                        z-index: 9;
                    }
                }
            }
            tbody{
                tr{
                    td{
                       background-color: #fff;
                        border: 1px solid #ddd;
                        color: #4d4a4a;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 0 7px!important;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        &:nth-child(03){
                            padding: 0 !important;
                            border: 1px solid gray;
                        }
                        // &:nth-child(06){
                        //     text-align: left;
                        // }
                        .dkg-userimg-Con{
                            .dkg-userimg{
                                border-radius: 50%;
                                height: 30px;
                                width: 30px;
                                object-fit: cover;
                                border: 1px solid #ddd;
                                margin-right: 0.5rem;
                            }
                        }
                        .dkg-email-log-status {
                            cursor: pointer;
                            height: 40px;
                            line-height: 40px;
                            padding: 0 7px;
                        }
                        .dkg-jobtitle-cols{
                            text-align: left;
                            width: 100%;
                            color: #629bd3;
                            &:hover{
                                cursor: pointer;
                                color: #333;
                            }
                        }
                        &:nth-child(01){
                            padding: 0 !important;
                        }
                        .dk-out-emailicon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 5px 0;
                            .dk-out-email-img{
                                width: 22px;
                                height: 22px;
                            }
                        }
                        .dkg-email-actions-cols{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .dkg-email-action-icon{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 22px;
                                height: 22px;
                                svg{
                                    color: #696666;
                                }
                                &:hover{
                                    box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
                                    transition: all .5s ease;
                                   
                                }
                            }
                        }
                           
                    }
                }
            }
        }
    }
}