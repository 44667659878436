.dk-candWorkFlowMainCon {
    margin-top: 15px;

    .dk-workFlowTopHead {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dk-LeftHead {
            a {
                background-color: #26597d;
                border: none;
                color: white;
                padding: 6px 10px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
            }
        }

        .dk-CentreHead {
            h2 {
                font-size: 15px;
                line-height: 1.5;
            }
        }

        .dk-RightHead {
            a {
                i {
                    color: #fff;
                }

                background-color: #7a8ba2;
                color: #fff;
                padding: 6px 10px;
                text-align: center;
                font-size: 12px;
                cursor: pointer;
                border: 1px solid #9e9595;
            }
        }
    }

    .dk-workFlowTableCon {
        margin-top: 1rem;

        .dk-workFlowTable.dkworkflowTable-main {
            margin-bottom: 0;

            thead {
                tr th {
                    background: #26597d;
                    color: #e2dada;
                    font-size: 12px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 5px 10px;
                    border: 1px solid #ccc;
                    border-bottom: none;
                    vertical-align: middle;
                    white-space: nowrap;
                }
            }

            tbody {
                tr td {
                    background-color: #fff;
                    color: #333;
                    font-size: 12px;
                    text-align: center;
                    white-space: nowrap;
                    padding: 5px 10px;
                    border: 1px solid #ddd;
                    border-bottom: none;
                    vertical-align: middle;
                    white-space: nowrap;

                    .dk-commentCols {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 25px;
                        height: 25px;
                        background-color: #eaeaea;
                        border-radius: 50%;
                        line-height: 25px;
                        cursor: pointer;

                        &:hover {
                            background: #234158;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }

                    &:nth-child(02) {
                        background-color: #009a9a;
                        color: #fff;
                    }

                    // &:nth-child(03) {
                    //     background-color: #009a9a;
                    //     color: #fff;
                    // }

                    &:nth-child(10) {
                        background-color: #009a9a;
                        color: #fff;
                    }

                    &:nth-child(05) {
                        background-color: #fff !important;
                        color: #333 !important;
                    }

                    &:nth-child(06) {
                        background-color: #fff !important;
                        color: #333 !important;
                    }

                    .jobIdCoumn {
                        color: #333;

                        i {
                            background: #297483;
                            padding: 5px;
                            text-align: center;
                            border-radius: 2px;
                            color: #fff;
                        }
                    }

                    .recImgCon {
                        img {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                        }
                    }

                }
            }
        }
    }
}


.dk-timelineModal-main.modal.right {
    .modal-dialog {
        width: 100%;
        max-width: 90%;
        margin: 0 !important;
        position: fixed;
        height: 100vh;
        top: 0;
        right: -90%;

        .modal-content {
            box-shadow: none;
            border: none;
            border-radius: 0;

            .modal-header {
                border-bottom: none;
                background-color: #297483;
                height: 30px;

                .close {
                    margin: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: 1;
                    text-shadow: none;
                    background: #297483;
                    color: #fff;
                    padding: 0;
                    display: inline-block;
                    width: 35px;
                    height: 30px;
                    border-radius: 0;
                    font-size: 16px;
                    text-align: center;
                    line-height: 27px;
                    z-index: 999;
                    outline: none;
                }
            }

            .modal-body {
                height: calc(100vh - 1px);

                .dk-candWorkFlowMainCon {
                    margin-top: 32px;
                }
            }
        }
    }
}

.dk-timelineModal-main.modal.right.fade {
    .modal-dialog {
        right: 0;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}