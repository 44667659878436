.dk-add-new-notificationsnewModal {
    .modal-dialog {
        min-width: 50%;

        .modal-content {
            border-radius: 5px;
            border: none;
            background-color: transparent;

            .dk-add-new-notificationsnewModalHeader {
                color: #fff;
                background: #26597d;
                padding: 8px 10px;
                border-radius: 5px 5px 0 0;
                border-bottom: none;
                .modal-title {
                    margin-top: 0;
                    font-size: 17px;
                    font-weight: 500;
                    font-family: Roboto, sans-serif;
                    text-align: center;
                    width: 100%;
                    text-transform: uppercase;
                    color: #fff;
                }

                .close {
                    font-size: 1.58em;
                    padding: 0px;
                    right: 10px;
                    position: absolute;
                    top: 4px;
                    opacity: 1;
                    color: #fff !important;
                    text-shadow: none;
                    outline: none;
                    font-weight: normal;
                    padding: 0.35rem;
                    margin: 0;

                    &:hover {
                        opacity: 0.75;
                    }
                }
            }

            .dk-add-new-notificationsnewModalBody {
                color: #333;
                background-color: #fff;
                padding: 0;
                border-radius: 0 0 5px 5px;
                // .dkg-form-group{
                //     width: 100%;
                //     margin-bottom: 1rem;
                //     .dkg-notification-label{
                //         margin-bottom: 0.5rem;
                //         display: block;
                //         font-size: 13px;
                //         color: #999;
                //     }
                //     .dkg-user-select-dropdwnCon{
                //         .dkg-user-Drodown{
                //             .dkg-user-dropdwn-toggle{
                //                 margin: 0;
                //                 padding: 0;
                //                 box-shadow: none;
                //                 &::after{
                //                     display: none;
                //                 }
                //                 .dkg-user-img{
                //                     width: 35px;
                //                     height: 35px;
                //                     border-radius: 50%;
                //                     object-fit: cover;
                //                     border: 1px solid #ddd;
                //                 }
                //             }
                //             .dkg-user-dropdwn-menu{
                //                 background-color: #f2f2f2;
                //                 left: 22px!important;
                //                 padding: 5px;
                //                 top: -10px!important;
                //                 transform: translate(22px)!important;
                //                 -webkit-transform: translate(22px)!important;
                //                 .d-flex{
                //                     .dkg-user-dropitem{
                //                         display: inline-block;
                //                         height: 35px;
                //                         margin: 0 3px;
                //                         padding: 0;
                //                         width: 35px;
                //                         .dkg-user-item-img{
                //                             width: 35px;
                //                             height: 35px;
                //                             object-fit: cover;
                //                             border-radius: 50%;
                //                         }
                //                     }
                //                 }
                //                 &:after {
                //                     content: "";
                //                     width: 0;
                //                     height: 0;
                //                     border-color: transparent #f2f2f2 transparent transparent;
                //                     border-style: solid;
                //                     border-width: 8.5px 12px 8.5px 0;
                //                     line-height: 0;
                //                     _border-color: #000 #ccc #000 #000;
                //                     _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                //                     position: absolute;
                //                     left: -12px;
                //                     top: 15px;
                //                 }
                //             }
                //         }
                //     }
                //     .dkg-noti-DrodownCon{
                //         width: 100%;
                //         .dkg-noti-Drodown{
                //             display: flex;
                //             align-items: center;
                //             justify-content: center;
                //             width: 100%;
                //             .dkg-noti-dropdwn-toggle{
                //                 background-color: #fff;
                //                 border: 1px solid #ddd;
                //                 box-shadow: none;
                //                 color: gray;
                //                 display: flex;
                //                 align-items: center;
                //                 justify-content: space-between;
                //                 font-size: 13px;
                //                 margin: 0;
                //                 padding: 7px 10px;
                //                 text-transform: capitalize;
                //                 width: 100%;
                //             }
                //             .dkg-noti-dropdwn-menu{
                //                 border-radius: 0;
                //                 max-height: 220px;
                //                 overflow-y: auto;
                //                 padding: 0;
                //                 width: 100%;
                //                 .dkg-noti-dropitem{
                //                     border-bottom: 1px solid #ddd;
                //                     padding: 5px 7px;
                //                     &:last-child{
                //                         border-bottom: none;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                //     .form-control{
                //         border: 1px solid #ddd;
                //     }
                //     .dkg-add-new-notibtnCon{
                //         display: flex;
                //         align-items: center;
                //         justify-content: center;
                //         width: 100%;
                //         div{
                //             div{
                //                 .dkg-add-noti-btn{
                //                     background-color: #396a95;
                //                     border: 1px solid #396a95;
                //                     border-radius: 0.1875rem;
                //                     color: #fff;
                //                     display: inline-block;
                //                     font-size: 13px;
                //                     line-height: 1.5385;
                //                     margin-left: 0;
                //                     padding: 5px 12px;
                //                     text-align: center;
                //                     text-transform: uppercase;
                //                     &:hover{
                //                         background-color: #234158;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
                .dk-warningMain {
                    form {
                        background-color: #eaeaea;
                        padding: 15px 25px;
                        border-radius: 0;
        
                        .row {
                            .col-12 {
                                .form-group {
                                    .dkg-sub-form-control {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}