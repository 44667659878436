body{
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  height: auto!important;
}
html {
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}
html::-webkit-scrollbar {
  width: 0px;
}

html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0 solid slategrey;
}
.modal-open .modal {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Segoe UI",Arial,sans-serif !important;
}

.dk-passHideShow{
  background-color: transparent !important;
  color: #fff !important;
}

.dk-resendPass{
  text-align: center;
}

.dk-resendPass a{
  color:#fff;
  text-decoration: none;
}

.dk-resendPass a:hover{
  color: #ccc;
}

.modal .modal-dialog{
  height: auto !important;
  margin:15px auto !important;
}
/* iframe{
  display: none !important;
} */