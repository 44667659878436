.dk-navBarMain {
    &.dk-accountingNavbar {
        &.dk-businessOverviewMainMenu {
            background-color: #884c5e;

            ul {
                li {
                    a {
                        background-color: #884c5e;

                        &.active {
                            background-color: #fff;
                            color: #333;
                        }

                        &.gray-active {
                            background-color: #eaeaea;
                            color: #333;
                        }

                        &:hover {
                            background-color: #eaeaea;
                        }
                    }

                    &:first-child {
                        a {
                            &:hover {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}