.dkg-mailbox-editor-mainCon{
    width: 100%;
    max-width: inherit !important;
    margin: 0 inherit;
    .jodit-react-container{
        margin-top: 5px;
        .jodit-container{
            height: calc(100vh - 215px) !important;
            width: 100% !important;
            max-width: inherit !important;
            .jodit-workplace{
                height: inherit !important;
            }
            .jodit-status-bar{
                display: none;
            }
        }
    }
    .dkg-mail-sendbtn-Con{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        top: 54px;
        div{
            div{
                button{
                    padding: 5px 17px;
                    background: #cd5360;
                    color: #fff;
                    border-radius: 5px;
                    &:hover{
                        background: #27597e !important;
                        transition: all 0.5s;
                        
                    }
                    .MuiButton-label{
                        color: #fff;
                    }
                }
            }
        }
    }
}    
