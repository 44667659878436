.dk-notificationsModal {
    .modal-dialog {
        position: fixed;
        margin: auto;
        right: 0;
        top: -15px;
        min-width: 620px !important;
        max-width: 620px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        .modal-content {
            height: 100%;
            overflow-y: auto;
            border-radius: 0px;

            .dk-notificationsModalHeader {
                // background-color: #234158;
                background-color: #3c97b6;
                padding: 12px 10px;
                border-radius: 0;
                width: 100%;
                border-bottom: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .dk-plusicon-con {
                    color: #fff;
                    cursor: pointer;
                }

                .modal-title {
                    color: #fff;
                    text-transform: uppercase;
                    margin: 0;
                    opacity: .90;
                    font-size: 1.2rem;
                    position: relative;
                    padding-top: 1px;
                    line-height: 1.8rem;
                    width: 100%;
                    text-align: center;

                    .dk-plusicon-con {
                        position: absolute;
                        right: -20px;
                        cursor: pointer;

                        i {
                            color: #e3dfdf;
                            font-size: 16px;
                        }
                    }
                }

                .close {
                    margin: 0;
                    padding: 0;
                    text-shadow: none;
                    opacity: .90;
                    color: #fff;
                    // font-size: 1.82rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 53px;
                    position: absolute;
                    right: 2px;
                    top: 0;

                    &:hover {
                        background-color: #eaeaea;
                        color: #333;
                    }
                }
            }

            .dkg-addnew-doc-modalbody {
                background-color: #eaeaea;
                padding: 0;
                height: calc(100vh - 45px);
                overflow-y: auto;
                .dk-warningMain {
                    form {
                        background-color: #eaeaea;
                        padding: 15px 25px;
                        border-radius: 0;
        
                        .row {
                            .col-12 {
                                .form-group {
                                    .dkg-sub-form-control {
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.dk-notificationsModal.fade .modal-dialog {
    right: -620px;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.dk-notificationsModal.show .modal-dialog {
    right: 0;
}

.modal.dk-notificationsModal {
    z-index: 9999 !important;
}