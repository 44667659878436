.dkg-desired-job-tabCon{
    .dkg-desiredjob-header{
        border: 1px solid #ccc;
        border-bottom: none;
        padding: 9px 10px;
        background-color: #1f394c;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .dkg-contact-title{
            font-weight: 600;
            font-size: 18px;
            text-transform: uppercase;   
            margin-bottom: 0;    
            color: #d7d7d7; 
        }
        .dkg-desired-info{
            // position: absolute;
            // left: 55%;
            // top: 2px;
            transform: translate(55% , 2%);
            background-color: #eaeaea;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 1px solid #ddd;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-left: 0.5rem;
            svg{
                width: 11px;
                height: 11px;
            }
        }
    }
    .dkg-desired-job-mainCon{
        background-color: #fff;
        border: 1px solid #ccc;
        height: calc(100vh - 270px);
        .dkg-desired-textarea-Con{
            .dkg-desired-textarea{
                background-color: #f2f2f2;
                height: 55px;
                padding: 7px 15px;
                display: flex;
                align-items: center;
                justify-content:center;
            }
        }
        .dkg-desired-center-sec{
            border-top: 1px solid #ddd;
            background-color: #fff;
            // height: calc(100vh - 410px);
            .dkg-mailbox-editor-mainCon{
                max-height: inherit;
                .jodit-react-container{
                    margin-top: 0;
                    .jodit-container{
                        height: calc(100vh - 412px) !important;
                        border: none;
                        border-bottom: 1px solid #dadada;
                        .jodit-toolbar__box{
                            border-top: none;
                        }
                        .jodit-workplace{
                            .jodit-wysiwyg{
                                min-height: inherit !important;
                            }
                        }
                    }
                }
            }
            &.dkg-desired-job-centersec{
                width: 100%;
                display: flex;
                .dkg-desired-job-leftCon{
                    width: 50%;
                    border-right: 1px solid #ddd;
                    height: calc(100vh - 329px);
                    .dkg-desired-job-titleCon{
                        padding: 65px 30px 10px 30px;
                        .dkg-desired-job-title{
                            font-weight: 600;
                            font-size: 19px;
                            text-transform: uppercase;
                            margin-bottom: 0;
                            color: #333;
                            text-transform: capitalize;
                        }
                    }
                    .dkg-selected-tagCon{
                        .dkg-selected-title{
                            color: #333;
                            display: block;
                            font-size: 1.15rem;
                            padding: 7px 10px;
                            position: relative;
                            width: 100%;
                            font-weight: 600;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                        }
                        .dkg-st-tag-job-btn-tagCon{
                            align-items: flex-start;
                            display: flex;
                            justify-content: flex-start;
                            width: 100%;
                            flex-wrap: wrap;
                            padding: 5px 7px;
                            .dkg-st-tag-btntext{
                                display: inline-block;
                                border-radius: 12px;
                                color: #445;
                                font-size: 13px!important;
                                padding: 6px 22px;
                                text-transform: capitalize;
                                background-color: #f2f2f2 !important;
                                border: 1px solid #ddd;
                                box-shadow: none;
                                position: relative;
                                margin-top: 8px;
                                    .dkg-tag-icon {
                                        color: #445;
                                        cursor: pointer;
                                        display: none;
                                        margin-left: 5px;
                                        padding: 0;
                                        position: absolute;
                                        right: 5px;
                                        top: 6px;
                                    }
                                    &:hover{
                                        .dkg-tag-icon{
                                            display: block;
                                            i{
                                                color: #445;
                                            }
                                        }
                                    }
                                }
                                
                            }
                    }
                }
                .dkg-createcv-textarea{
                    width: 100%;
                    height: calc(100vh - 413px);
                    padding: 7px 15px;
                    border: none;
                    border-bottom: 1px solid #ddd;
                    &:focus{
                        outline: 0;
                    }
                }
            }
            .dkg-desired-job-rightCon{
                width: 50%;
                height: calc(100vh - 328px);
                .dkg-dj-rightfirst-cols{
                    height: 60%;
                    border-bottom: 1px solid #ddd;
                    .dkg-saved-cv-tag-title{
                        color: #333;
                        display: block;
                        font-size: 1.15rem;
                        padding: 7px 10px;
                        position: relative;
                        width: 100%;
                        font-weight: 600;
                        text-align: center;
                        border-bottom: 1px solid #ddd;
                    }
                    .dkg-desired-job-btn-tagCon{
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        width: 100%;
                        flex-wrap: wrap;
                        padding: 5px 7px;
                        .dkg-desired-btntext{ 
                        display: inline-block;
                        border-radius: 12px;
                        color: #fff;
                        font-size: 13px;
                        padding: 6px 22px;
                        text-transform: capitalize;
                        background-color: #366995 !important;
                        box-shadow: none;
                        position: relative;
                        margin-top: 8px;
                        .dkg-tag-icon {
                            color: #fff;
                            cursor: pointer;
                            display: none;
                            margin-left: 5px;
                            padding: 0;
                            position: absolute;
                            right: 5px;
                            top: 6px;
                        }
                        &:hover{
                            .dkg-tag-icon{
                                display: block;
                                i{
                                    color: #fff;
                                }
                            }
                        }
                    }
                
                
                        }
                }
                .dkg-dj-rightsecond-cols{
                    height: 40%;
                    width: 100%;
                    background-color: #DCE5E9;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    justify-content: center;
                    .dkg-desired-job-inputCon{
                        width: 100%;
                        .dkg-createnew-tag-title{
                            color: #333;
                            display: block;
                            font-size: 1.15rem;
                            padding: 7px 10px;
                            position: relative;
                            width: 100%;
                            font-weight: 600;
                            text-transform: uppercase;
                            text-align: center;
                        }                                    
                        
                        .dkg-desired-job-inputCon{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            // height: 90%;
                            .dkg-desired-job-input{
                                border: 1px solid #ddd;
                                background-color: #fff;
                                width: 100%;
                                max-width: 90%;
                                text-align: left;
                                margin-left: 10px;

                            }
                            .dkg-desired-job-save-btnCon{
                                margin-top: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                div{
                                    div{
                                        .dkg-desired-job-save-btn{
                                            background-color: #26597d;
                                            // text-transform: capitalize;
                                            &:hover{
                                                color: #fff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            
                
            }
        }
        .dkg-desired-save-btnCon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 25px 0;
            background-color: #f2f2f2;
            div{
                div{
                    .dkg-desired-save-btn{
                        background-color:  rgb(38, 89, 125);
                        &:hover{
                            color: #fff;
                        }
                    }
                }
            }
        }
        &.dkg-myresume-newblock-main{
            .dkg-myprofile-con{
                width: 100%;
                max-width: 1140px;
                margin: 0 auto;
                padding-top: 0;
                .dkg-myprofile-mainRow{
                    .dkg-myprofile-leftcon,
                    .dkg-myprofile-rightcon{
                        border: 1px solid #d7d7d7;
                        padding: 0;
                        border-radius: 5px;
                        margin-top: 20px;
                        .dkg-profile-frm-title{
                            background: #1f394c;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                            color: #ebdede;
                            font-size: 18px;
                            height: 50px;
                            line-height: 50px;
                            padding: 0 10px;
                            text-align: center;
                            margin-bottom: 0;
                        
                        }
                        .dkg-profile-frm-subtitle{
                            background-color: #f2f2f2;
                            color: #333;
                            padding: 1.15rem 15px;
                            text-align: center;
                            width: 100%;
                            margin-bottom: 0;
                            height: 50px;
                            line-height: 50px;
                            border-bottom: 1px solid #ddd;
                            .dkg-frm-subtitle-desc{
                                font-size: 14px;
                                text-align: center;
                                margin-bottom: 0;
                                color: #666;
        
                            }
                        }
                        .dkg-profile-frmgrp{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            margin-bottom: 1rem;
                            padding: 0 15px;
                            .dkg-profile-label{
                                min-width: 220px;
                                max-width: 220px;
                                margin-bottom: 0;
                                font-size: 13px;
                                .dkg-profile-star{
                                    color: #e2445b;
                                }
                            }
                            .dkg-profile-input{
                                font-size: 13px;
                                border: 1px solid #ddd;
                                background-color: #f2f2f2;
                                &::placeholder {
                                    color: #676767;
                                    opacity: 1; /* Firefox */
                                    font-size: 13px;
                                }  
                                &::-ms-input-placeholder { /* Edge 12-18 */
                                    color: #676767;
                                    font-size: 13px;
                                }
                                &.dkg-dullgrey-input{
                                    &::placeholder {
                                        color: #676767;
                                        font-size: 13px;
                                        opacity: 1; /* Firefox */
                                    }  
                                    &::-ms-input-placeholder { /* Edge 12-18 */
                                        color: #676767;
                                        font-size: 13px;
                                    }
                                }
                                
                            }
                            .dkg-info-uplaod-image {
                                .dk-contactPerson-image {
                                    width: 100%;
                                    max-width: 120px;
                                    border: 1px solid #ccc;
                                    padding: 0;
                                    margin-right: 15px;
                                    height: 120px;
                                    display: flex;
                                    align-items: center;
                                    background: #fff;
                                    justify-content: center;
                                    overflow: hidden;
                                    border-radius: 50%;
        
                                    img {
                                        width: 100%;
                                        object-fit: cover;
                                        border-radius: 50%;
                                        height: 120px;
                                        object-position: top center;
                                    }
                                }
        
                                .dk-edit-userImgCon {
                                    width: 100%;
                                    max-width: 100px;
                                    border: 1px solid #ccc;
                                    padding: 0;
                                    margin-right: 15px;
                                    height: 100px;
                                    display: flex;
                                    align-items: center;
                                    background: #fff;
                                    justify-content: center;
                                    // overflow: hidden;
                                    border-radius: 50%;
                                    margin-bottom: 10px;
                                    position: relative;
                                    input {
                                        width: 100%;
                                        height: 100px;
                                        max-width: 100px;
                                        position: relative;
                                        z-index: 9;
                                        opacity: 0;
                                        cursor: pointer;
                                    }
        
                                    span.dk-myprofile-userImgCon {
                                        position: absolute;
                                        top: -1px;
                                        left: 0;
                                        cursor: pointer;
                                        width: 100%;
                                        max-width: 100px;
                                        border-radius: 50%;
        
                                        img.dk-myprofile-userImg {
                                            width: 100%;
                                            height: auto;
                                            border-radius: 50%;
                                        }
        
                                        figure {
                                            .dkg-update-profile-234 {
                                                max-width: 120px;
                                                border: 1px solid #ccc;
                                                padding: 0;
                                                height: 120px;
                                                display: flex;
                                                align-items: center;
                                                background: #fff;
                                                justify-content: center;
                                                overflow: hidden;
                                                border-radius: 50%;
                                                position: relative;
                                                right: 4px;
                                            }
                                        }
                                    }
        
                                    span.dk-myprofile-editicon {
                                        position: absolute;
                                        bottom: 17px;
                                        right: -2px;
                                        background-color: #234158;
                                        width: 30px;
                                        height: 30px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border-radius: 50%;
        
                                        svg {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                            .dkg-dob-inputCon{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                .dkg-dob-input{
                                    margin-right: 0.7rem;
                                    background-color: #f2f2f2;
                                    border: 1px solid #ddd;
            
                                }
                            }
                            &:last-child{
                                margin-bottom: 1.5rem;
                            }
                        }
                        .dkg-resume-rightcon{
                            height: calc(100vh - 340px);
                        }
                        &.dkg-resume-leftcon,
                        &.dkg-resume-rightcon{
                            border: none;
                            .dkg-resume-leftbody,
                            .dkg-resume-rightbody{
                                border: none;
                                height: 275px !important;
                                background-color: #fff;
                                border-radius: 0 0 5px 5px;
                                border: 1px solid #ddd;
                                border-top: none;
                            }
                            .dkg-profile-frm-title{
                                margin-bottom: 0;
                            }
                        }
                    }
                    .dkg-myprofile-leftcon {
                        background-color: #fff;
             
                        @media (min-width: 768px){
                            &.col-md-6 {
                                flex: 0 0 46%;
                                max-width: 46%;
                                position: relative;
                                &::after{
                                    content: "";
                                    position: absolute;
                                    right: -48px;
                                    top: 0;
                                    border-right: 0.5px solid #ccc;
                                    height: 100%;
                                    display: none;
                                }
                            }
                        }
                        &.dkg-resume-leftcon{
                            .dkg-resume-frm-title{
                                text-transform: inherit;
                                border: 1px solid #ddd;
                            }
                            .dkg-resume-leftbody{
                                width: 100%;
                                height: calc(100vh - 250px);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                .dkg-white-block{
                                    width: 150px;
                                    height: 150px;
                                    background-color: transparent;
                                    border: 1px solid transparent;
                                    position: relative;
                                    top: 5px;
                                    .dkg-myresume-cvimgCon{
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        position: absolute;
                                        top: -18px;
                                        left: 0;
                                        .dkg-myresume-cvimg{
                                            width: 100px;
                                            height: auto;
                                            background-repeat: no-repeat;
                                            background-size: cover;                                    
                                        }
                                        input {
                                            width: 100%;
                                            height: 100px;
                                            max-width: 100px;
                                            position: relative;
                                            z-index: 9;
                                            opacity: 0;
                                            cursor: pointer;
                                        }
            
                                        span.dkg-myresume-sapnimgCon {
                                            position: absolute;
                                            top: -1px;
                                            left: 0;
                                            cursor: pointer;
                                            width: 100%;
                                            max-width: 100px;
                                            border-radius: 50%;
            
                                            img.dkg-myresume-cvimg {
                                                width: 100%;
                                                height: auto;
                                            }
                                        }
                                    }
                                }
                                .dkg-resume-view-btnCon{
                                    margin-top: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    position: relative;
                                    top: -10px;
                                    .dkg-resume-view-btn{
                                        display: inline-block;
                                        background-color: #f2f2f2;
                                        color: #333;
                                        border: 1px solid #ddd;
                                        text-transform: capitalize;
                                        border-radius: 0;
                                        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                        padding: 7px 12px;
                                        font-size: 0.875rem;
                                        box-sizing: border-box;
                                        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                        font-weight: 500;
                                        line-height: 1.75;
                                        letter-spacing: 0.02857em;
                                        &:hover{
                                            box-shadow: none;
                                            background-color: #3C97B6;
                                            color: #fff;
                                        }
                                        .dkg-resume-icon{
                                            margin-left: 0.5rem;
                                            svg{
                                                width: 0.95rem;
                                                height: 0.95rem;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }
                        }
        
                    }
                    .dkg-myprofile-rightcon{
                        background-color: #fff;
        
                        @media (min-width: 768px){
                            &.col-md-6 {
                                flex: 0 0 46%;
                                max-width: 46%;
                                margin-left: auto;
                            }
                        }
                        &.dkg-profile-info-rightCon{
                            color: inherit;
                        }
                        &.dkg-resume-rightcon{
                            .dkg-resume-frm-title{
                                text-transform: inherit;
                                border: 1px solid #ddd;
                            }
                            .dkg-resume-rightbody{
                                width: 100%;
                                height: calc(100vh - 250px);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                .dkg-white-block{
                                    width: 150px;
                                    height: 150px;
                                    background-color: transparent;
                                    border: 1px solid transparent;
                                    position: relative;
                                    top: -5px;
                                    .dkg-myresume-cvimgCon{
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .dkg-myresume-cvimg{
                                            width: 100px;
                                            height: 100px;
                                            background-repeat: no-repeat;
                                            background-size: cover;
                                        }
                                        input {
                                            width: 100%;
                                            height: 100px;
                                            max-width: 100px;
                                            position: relative;
                                            z-index: 9;
                                            opacity: 0;
                                            cursor: pointer;
                                        }
            
                                        span.dkg-myresume-sapnimgCon {
                                            position: absolute;
                                            top: -1px;
                                            left: 0;
                                            cursor: pointer;
                                            width: 100%;
                                            max-width: 100px;
                                            border-radius: 50%;
        
                                            img.dkg-myresume-cvimg {
                                                width: 100%;
                                                height: auto;
                                            }
                                        }
                                    }
                                }
                                .dkg-resume-btngrp-Con{
                                    margin-top: 30px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    position: relative;
                                    top: -10px;
                                    .dkg-resume-btn{
                                        .dkg-resume-view-btn{
                                            display: inline-block;
                                            background-color: #f2f2f2;
                                            color: #333;
                                            border: 1px solid #ddd;
                                            text-transform: capitalize;
                                            border-radius: 0;
                                            box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                                            padding: 7px 12px;
                                            font-size: 0.875rem;
                                            box-sizing: border-box;
                                            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                                            font-weight: 500;
                                            line-height: 1.75;
                                            letter-spacing: 0.02857em;
                                            &:hover{
                                                box-shadow: none;
                                                background-color: #3C97B6;
                                                color: #fff;
                                            }
                                            .dkg-resume-icon{
                                                margin-left: 0.5rem;
                                                svg{
                                                    width: 0.95rem;
                                                    height: 0.95rem;
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.dkg-cv-preview-main{
            .dkg-cvpreview-center-sec{
                border-top: 1px solid #ddd;
                background-color: #fff;
                height: calc(100vh - 328px);
                .dkg-cvpreview-textareaCon{
                    .dkg-cvpreview-textarea{
                        background-color: #fff;
                        height: 55px;
                        padding: 7px 15px;
                        text-align: left;
                        width: 80%;
                        margin: 50px auto;
                        border: 1px solid #ddd;
                    }
                    .dkg-cvpreview-save-btnCon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        padding: 10px 0;
                        .dkg-cvpreview-save-btn{
                            background-color: #26597d;
                            &:hover{
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .dkg-desired-summ-body-234{
            width: 100%;
            height: calc(100vh - 200px);
            overflow-y: auto;
            .dkg-desired-summ-box-cols{
                width: 100%;
                height: 100%;
                max-height: 12.5%;
                display: flex;
                border-bottom: 1px solid #ccc;
                .dkg-desired-summ-1stcols{
                    width: 275px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-right: 1px solid #ccc;
                    background-color: #ddd;
                    .dkg-desired-summ-title{
                        font-size: 1rem;
                        padding: 0 15px;
                        text-transform: uppercase;
                    }
                }
                .dkg-desired-summ-2ndcols{
                    width: 100%;
                    height: 100%;
                    .dkg-desired-texareaCon{
                        width: 100%;
                        height: 100%;
                        .dkg-desired-texarea{
                            width: 100%;
                            text-align: left;
                            padding: 5px 10px;
                            height: 100%;
                        }
                    }
                    .dkg-myprofile-summCon{
                        width: 100%;
                        height: 100%;
                        .dkg-myprofile-summ-btn-tagCon{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            flex-wrap: wrap;
                            padding: 0 10px;
                            .dkg-desired-btntext{
                                display: inline-block;
                                border-radius: 12px;
                                color: #fff;
                                font-size: 13px;
                                padding: 6px 22px;
                                text-transform: capitalize;
                                background-color: #366995 !important;
                                box-shadow: none;
                                position: relative;
                                .dkg-tag-icon{
                                    color: #fff;
                                    cursor: pointer;
                                    display: none;
                                    margin-left: 5px;
                                    padding: 0;
                                    position: absolute;
                                    right: 5px;
                                    top: 6px;
                                }
                                &:hover{
                                    .dkg-tag-icon{
                                        display: block;
                                        i{
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dkg-covermsg-textCon{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        padding: 7px 10px;
                        .dkg-covermsg-text{
                            margin-bottom: 0;
                        }
                    }
                }
                &:first-child{
                    border-top: 1px solid #ddd;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
    }
}