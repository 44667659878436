.dkg-create-newtask-idmodal {
    .modal-dialog {
        width: 100%;
        max-width: 300px;
        min-width: 300px !important;

        .modal-content {
            border-radius: 5px;
            border: none;
            box-shadow: none;

            .dkg-create-newtask-idmodal-body {
                padding: 25px;

                .d-flex {
                    .form-group {
                        margin: 0 10px 10px !important;

                        label {
                            text-align: center;
                            display: block;

                            input {
                                border: 1px solid #ddd !important;
                                border-top: 1px solid #ddd !important;
                                min-width: 110px;
                            }
                        }
                    }

                    .dk-saveBtn {
                        display: inline-block;
                        padding: 8px 22px;
                        background-color: #26597d;
                        text-transform: uppercase;
                        color: #fff;
                        outline: none;
                        border: none;
                        border-radius: 5px;

                        &:hover {
                            background: #26597d;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}