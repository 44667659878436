.dk-subMenuMain{
    &.res-sub-menu{
        // @media(min-width:992px){
        //     ul{
        //         li{
        //             display: none;
        //         }
        //     }
        // }
        @media(max-width:992px){
            top: 120px;
            background: transparent;
            z-index: 999;
            ul{
                li{
                    max-width: inherit;
                    min-width: 100%;
                    display: block;
                    &:first-child{
                        a{
                            background-color: #2e97b6 !important;
                            color: #fff;
                            display: block;
                            height: 44px;
                        }
                    }
                    a{
                        display: block;
                        background: #f5f5f5;
                        border-bottom: 1px solid #ddd;
                        height: 0px;
                        overflow: hidden;
                        transition: all 0.5s;
                        &.active{
                            background-color: #2e97b6;
                            color: #fff;
                            display: block;
                            height: 44px;
                        }
                    }
                }
                &.res-mobileNav.show{
                    box-shadow: 2px 1px 2px 1px rgb(0 0 0 / 20%);
                    display: block;
                    li{
                        a{
                            display: block;
                            background:#f5f5f5;
                            border-bottom: 1px solid #ddd;
                            height: 44px;
                            transition: all 0.5s;
                            &.active, &:hover{
                                background-color: #2e97b6;
                                color: #fff;
                                display: block;
                                border: none;
                                height:44px;
                            }
                        }
                        &:last-child{
                            a{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        .res-sub-btn{
            display: none;
            width: 100%;
            max-width: 40px;
            background: transparent;
            border: none;
            outline: none;
            position: absolute;
            top: 2px;
            right: 15px;
            line-height: 5px;
            text-align: right;
            color: #fff;
            font-size: 16px;
            @media(max-width:992px){
                display: block;
                z-index: 9;
                &:hover{
                    background: #067185;
                }
            }
        }
    }
}


// &.dkg-mo-new-mainNavbar{
//     position: fixed;
//     top: 120px;
//     ul{
//         li{
//             max-width: inherit;
//             min-width: 100%;
//             display: block;
//             &:first-child{
//                 a{
//                     background-color: #2e97b6 !important;
//                     color: #fff;
//                 }
//             }
//         }

//     }
// }