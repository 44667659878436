.dkg-recntes-tabpanecon-12 {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    padding-right: 0;
    margin-left: 30px;

    // height: calc(100vh - 125px);
    // overflow-y: auto;
    .dkg-prescreen-form-leftCon {
        flex: 0 0 15%;
        max-width: 15%;

        a {
            color: #333;
            font-size: 13px;
            position: relative;
            top: 2px;
            left: -30px;
            text-transform: uppercase;
            font-weight: 600;
            white-space: nowrap;
        }
    }

    .dkg-prescreen-form-rightCon {
        padding: 0 15px;
        flex: 0 0 85%;
        max-width: 85%;

        .form-group {
            display: flex;
            justify-content: space-between;

            // label {
            //     color: #333;
            //     font-size: 13px;
            //     position: relative;
            //     top: 2px;
            //     text-transform: capitalize;
            //     font-weight: 600;
            //     max-width: 220px;
            //     min-width: 220px;
            //     text-transform: uppercase;
            // }

            .dkg-interviewnotes-editor12 {
                width: 100%;

                .dkg-interviewnotes-area {
                    background-color: #fff;
                    min-height: 136px;
                    max-height: 136px;
                    border-radius: 5px;
                    cursor: pointer;
                    border: 1px solid #ddd;
                    width: 100%;
                    text-align: left;
                }

                .rdw-editor-wrapper {
                    padding: 0;
                    border: 1px solid #ddd;

                    .rdw-editor-toolbar {
                        margin: 0;
                        border-top: none;

                        .rdw-colorpicker-wrapper,
                        .rdw-link-wrapper,
                        .rdw-embedded-wrapper,
                        .rdw-image-wrapper,
                        .rdw-remove-wrapper,
                        .rdw-history-wrapper,
                        .rdw-emoji-wrapper {
                            display: none;
                        }
                    }

                    .editor-class {
                        &.rdw-editor-main {
                            background-color: #eaeaea;
                            min-height: 136px;
                            max-height: 136px;
                            padding: 0 15px;
                        }
                    }
                }
            }
        }
    }
}