.dk-AccwithNnoDropDwnCon{
    .dk-AmDropDwn{
        .dk-AmDropDwnToggle{
            box-shadow: none;
            background-color: transparent;
            padding: 0 !important;
            margin: 0;
            &::after{
                display: none;
            }
            img{
               width: 32px;
               height: 32px;
               border-radius: 50%;
               border: 1px solid  #ddd;
            }
            i{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                border: 1px solid  #ddd;  
                background-color: #eaeaea;
                font-size: 15px;
            }
        }
        .dk-AmDropDwnMenu.dropdown-menu{
            display: flex;
            padding: 5px;
            left: 45px !important;
            top: -20px !important;
            transform: inherit !important;
            -webkit-transform: inherit !important;
            background-color: #eaeaea;
            min-height: 68px;
            max-height: 68px;
            &:after {
                content: "";
                width: 0;
                height: 0;
                border-color: transparent #ccc transparent transparent;
                border-style: solid;
                border-width: 8.5px 12px 8.5px 0;
                line-height: 0;
                _border-color: #000 #ccc #000 #000;
                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                position: absolute;
                left: -12px;
                top: 25px;
            }
            .dropdown-item{
                margin: 0 4px;
                padding:0;
                width: 32px;
                height: 32px;
                &:hover{
                    background-color: transparent;
                }
                img{
                    width: 100%;
                    border-radius: 50%;
                    display: block;
                }
                span.counterNo{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 5px;
                }
            }
        }
    }
}