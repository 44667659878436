.dk-overViewCon{
    .dk-panelAbout{
        display: flex;
        flex-flow: row wrap;
        .leftCon{
            flex-basis: 85%;
            .title {
                margin-left: 0;
                margin-bottom: 5px;
                font-size: 16px;
                font-weight: 600;
                color: #333;
                background: transparent;
            }
        }
        .leftRight{
            flex-basis: 15%;
            .dk-resumeDownload{
                width: 100%;
                max-width: 120px;
                margin-left: 40px;
                position: relative;
                overflow: hidden;
                input {
                    width: 100%;
                    height: 110px;
                    max-width: 150px;
                    position: relative;
                    z-index: 9;
                    opacity: 0;
                    cursor: pointer;
                }
                span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    cursor: pointer;
                    width: 100%;
                    img {
                        width: 100%;
                        max-width:55px;
                        height: auto;
                    }
                }
            }
        }
    }
    .suMMBlock{
        .title{
            margin-bottom: 5px;
            font-size: 17px;
            font-weight: 600;
            color: #333;
            padding: 10px;
            width: 100%;
            text-align: center;
        }
        .suMMListCon{
            ul{
                padding: 15px;
                border: 1px solid #bfbfbf;
                border-radius: 5px;
                display: block;
                list-style-type: disc;
                li{
                    margin-bottom: 5px;
                    display: list-item;
                    list-style-type: disc;
                    margin-left: 20px;
                    font-size: 14px;
                }
            }
        }
    }
    .dk-colsTitle{
        margin-top: 25px;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #333;
    }
    .dk-skillsCols {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 8px 0 8px 50px;
        a {
            display: inline-block;
            border: 1px solid #6f6f6f;
            margin-right: 8px;
            padding: 6px 18px 5px;
            border-radius: 30px;
            font-size: 14px;
            color: #333;
        }
    }
    .dk-viewLanguage {
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 65px;
        a {
            display: inline-block;
            border-radius: 5px;
            font-size: 14px;
            color: #333;
            margin-right: 20px;
            img {
                width: 28px;
                height: 20px;
                object-fit: cover;
            }
        }
    }
    .dk-skillsCols {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 8px 0 8px 50px;
        a {
            display: inline-block;
            border: 1px solid #6f6f6f;
            margin-right: 8px;
            padding: 6px 18px 5px;
            border-radius: 30px;
            font-size: 14px;
            margin-bottom: 5px;
            color: #333;
        }
    }
}