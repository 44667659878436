.dk-pipecheckduplicateModal.modal-dialog {
    position: fixed;
    right: 0;
    top: 0;
    margin: 0 !important;
    min-width: 75%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);

    .modal-content {
        background-color: transparent;
        border-radius: 0;

        .modal-header {
            background-color: #234158;
            padding: 12px 10px;
            border-radius: 0;

            .modal-title {
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;
                font-size: 1.20rem;
                color: #fff;
            }

            .close {
                text-shadow: none;
                opacity: 1;
                color: #fff;
            }

            .close:hover {
                text-shadow: none;
                opacity: 0.91;
                color: #fff;
            }
        }

        .modal-body {
            background-color: #fff;
            padding: 30px 25px 22px;
            border-radius: 0;
            min-height: calc(100vh - 50px);

            .dk-duplicateFormCon {
                .dk-duplicateFormGruop {
                    margin-bottom: 1.25rem;
                    position: relative;

                    .dkg-duplicate-title {
                        text-align: center;
                        width: 100%;
                        color: #848484;
                    }

                    input.form-control {
                        background-color: #eaeaea;
                        border: 1px solid #ddd;
                        font-size: 15px;
                        padding: 20px 15px;
                        border-radius: 0;

                        &:focus {
                            box-shadow: none;
                            outline: 0;
                        }
                    }

                    .dkg-crosssbtnCon14 {
                        position: absolute;
                        right: -8px;
                        top: 12px;
                        cursor: pointer;
                    }
                }

                .duplicateBtnCon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    flex-direction: row;

                    button.dk-srchBtn {
                        color: #fff;
                        background-color: #485569;
                        padding: 7px 15px;
                        border-radius: 5px;
                        font-size: 14px;

                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }
                    }

                    button.dk-srchBtn.clear {
                        color: #333;
                        background-color: #FFF;
                        // border: 1px solid #485569;
                        box-shadow: none;
                        margin-right: 20px;
                        padding: 8px 15px;

                        &:hover {
                            outline: 0;
                            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
                        }
                    }
                }

                .no-duplicate {
                    padding: 0;
                    margin: 0;
                    font-weight: bolder;
                    color: green;
                    text-align: center;
                    font-size: 14px;
                }

                .dk-check-dupplicate-tableCon {
                    height: calc(100vh - 265px);
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding-bottom: 0;
                    position: relative;

                    .dk-check-dupplicate-table {
                        margin-bottom: 0;

                        thead {
                            tr th {
                                text-align: center;
                                vertical-align: middle;
                                color: #fff;
                                background: #3CA8BD;
                                border: 1px solid #ccc;
                                padding: 9px 7px;
                                font-size: 13px;
                                vertical-align: middle;
                                position: sticky;
                                top: 0;
                                white-space: nowrap;
                                z-index: 9;
                            }
                        }

                        tbody {
                            tr td {
                                background: #f1f1f1;
                                color: #4d4a4a;
                                font-size: 13px;
                                font-weight: 400;
                                text-align: center;
                                white-space: nowrap;
                                padding: 0px 7px;
                                border-left: 1px solid #ccc;
                                vertical-align: middle;
                                text-transform: capitalize;

                                &:nth-child(05),
                                &:nth-child(06),
                                &:nth-child(07),
                                &:nth-child(08) {
                                    padding: 0;
                                    border: 1px solid gray;
                                }
                                .dkg-caseopen-dropdown{
                                    position: relative;
                                    .dkg-caseopen-dropdown-toggle{
                                        box-shadow: none;
                                        color: #fff;
                                        font-size: 13px;
                                        margin: 0;
                                        padding: 0!important;
                                        text-transform: capitalize;
                                        white-space: nowrap;
                                        width: 100%;
                                        height: 40px;
                                        border-radius: 0;
                                        &::after{
                                            display: none;
                                        }
                                    }
                                    .dkg-caseopen-dropdown-menu{
                                        border-radius: 0;
                                        padding: 0;
                                        -webkit-transform: translate(-1px,35px)!important;
                                        transform: translate(-1px,35px)!important;
                                        .dkg-caseopen-dropdown-item{
                                            border-bottom: 1px solid #aaa;
                                            font-size: 13px;
                                            padding: 6px 10px;
                                            text-align: left;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                                .dkg-dup-id-prviewCon {

                                    .dkg-dup-sn-counter {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        .dk-checkinputIcon {
                                            input[type="checkbox"] {
                                                position: relative;
                                                top: 3px;
                                                right: -4px;
                                                cursor: pointer;
                                                appearance: none;
                                                background: #fff;
                                                border-radius: 1px;
                                                box-sizing: border-box;
                                                width: 14px;
                                                height: 14px;
                                                border-width: 0;
                                                transition: all 0.3s linear;
                                                border: solid #ddd 1px;
                                                z-index: 0;
                                            }

                                            input[type="checkbox"]:checked {
                                                background-color: #34976e !important;
                                            }
                                        }
                                    }


                                }

                                .dk-dup-recimgCon {
                                    position: relative;

                                    img.recruiter-img {
                                        width: 30px;
                                        height: 30px;
                                        border-radius: 50%;
                                    }
                                }

                                .dk-dup-recimgCon {
                                    .tooltipMain {
                                        img {
                                            border-radius: 50% !important;
                                        }
                                    }
                                }

                                .profile-location-text {
                                    color: #000;
                                    font-weight: 600;

                                }

                                .caseDiv {
                                    height: 40px;
                                    line-height: 40px;
                                    color: #FFF
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal.fade .dk-pipecheckduplicateModal.modal-dialog {
    right: -75%;
    -webkit-transition: opacity 0.3s linear, right .3s ease-out;
    -moz-transition: opacity 0.3s linear, right .3s ease-out;
    -o-transition: opacity 0.3s linear, right .3s ease-out;
    transition: opacity 0.3s linear, right .3s ease-out;
}

.modal.show .dk-pipecheckduplicateModal.modal-dialog {
    right: 0;
}

// .pipe-newCVTableCon{
//     margin-top: 6px;
//     overflow-y: auto;
//     overflow-x: auto;
//     height: calc(100vh - 280px);
//     padding-bottom: 0;
//     position: relative;
//     .dkpipeline-newCVTable{
//         margin-bottom: 0;
//         thead tr th {
//             background: #485569;
//             color: #fff;
//             font-size: 13px;
//             text-align: center;
//             white-space: nowrap;
//             padding: 9px 15px;
//             border: 1px solid #ccc;
//             border-bottom: none;
//             vertical-align: middle;
//             position: sticky;
//             top: 0;
//             border-top: none;
//             z-index: 9;