.dk-candModalHeader{
    &.dkg-srch-info-header{
        padding: 12px 10px !important;
        button.close{
            top: 1px !important;
        }
    }
}
.dk-notification-faqModalBody{
    .dkg-notifaq-MainPage{
        .dkg-notifaq-trainingBoxMain {
            padding: 0 15px;
            padding-top: 15px;
            height: calc(100vh - 61px);
            .dkg-srchinfo-keywordCon{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: 50%;
                margin: 0 auto;
                padding-bottom: 15px;
                .dkg-srchinfo-keyword{
                    position: relative;
                    width: 100%;

                    input{
                        background-color: #fff;
                        border: 1px solid #ddd;
                        padding: 9px 10px;
                        height: 40px;
                        line-height: 22px;
                        border-radius: 25px;
                        width: 100%;
                    }
                    .dkg-srch-icon{
                        svg{
                            position: absolute;
                            right: 15px;
                            top: 14px;
                            color: grey;
                            cursor: pointer;
                        }
                    }
                }
            }    
            .col-md-3 {
                flex: 25%;
                max-width: 25%;
                padding-left: 8px;
                padding-right: 8px;
    
                .dkg-os-training-box {
    
                    &:hover {
                        figure {
                            img {
                                transform: scale(.7);
                                transition: all .4s ease-in-out;
                            }
                        }
                    }
    
                    a {
                        background: #eaeaea;
                        border: 1px solid #d8d8d8;
                        min-height: 105px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 16px;
                        border-radius: 5px;
                        color: #1a3445;
                        transform: scale(.9);
                        transition: all .4s ease-in-out;
    
                        figure {
                            margin: 0 !important;
                            min-height: auto;
                            // display: none;
    
                            img {
                                transform: scale(.9);
                                transition: all .4s ease-in-out;
                                max-width: 48px;
                                max-height: 48px;
                            }
                        }
    
                        h3 {
                            font-size: 18px;
                            line-height: 20px;
                            margin: 0 8px;
                            font-weight: 500;
                            top: 0;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    
        .dkg-notifaq-trainingBoxMain::-webkit-scrollbar {
            width: 0;
        }
    
        .dkg-notifaq-trainingBoxMain::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
        }
    
        .dkg-notifaq-trainingBoxMain::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0px solid slategrey;
        }
    }
}