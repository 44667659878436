.dk-commentModalMain {
    .modal-dialog {
        position: fixed;
        margin: auto;
        width: 100%;
        min-width: 50% !important;
        max-width: 50% !important;
        height: 100%;
        right: -50%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .modal-content {
        border-radius: 0;
        box-shadow: none;
        height: 100vh;

        .modal-body {
            background: #dee1e3;
            border-radius: 0 0 5px 5px;
            padding-bottom: 1.25rem;
        }
    }

    .modal-header {
        background: #26597d;
        padding: 8px 10px;
    }

    .modal-title {
        margin-top: 0;
        font-size: 17px;
        font-weight: 500;
        font-family: Roboto, sans-serif;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        color: #fff;
    }

    .close {
        font-size: 1.58em;
        padding: 0px;
        right: 4px;
        position: absolute;
        top: 4px;
        opacity: 1;
        color: #fff !important;
        text-shadow: none;
        outline: none;
        font-weight: normal;
        padding: 0.35rem;
        margin: 0;
    }
}

.modal.right .modal-dialog {
    right: 0;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.comments-container {
    width: 100%;

    .dk-commentCategoryFlex {
        display: flex;

        a {
            display: inline-block;
            background: #f2f2f2;
            border: 1px solid #ddd;
            border-right: none;
            width: 100%;
            color: #333;
            font-size: 14px;
            text-align: center;
            padding: 8px 0;

            &:last-child {
                border-right: 1px solid #ddd;
            }

            .dk-categoryTags-img {
                display: inline-block;
                margin: 0 5px;
                position: relative;
                bottom: 2px;

                img {
                    width: 100%;
                    max-width: 20px;
                }
            }
        }

        a.active {
            background: #fff;
            border-bottom: none;
        }
    }

    .scrollBox {
        overflow: auto;
        height: calc(100vh - 278px);
        width: 100%;

        ul.comments-list {
            padding-left: 0;
            margin-top: 1rem;
            position: relative;
            list-style: none;
            float: left;
            width: 100%;

            &::before {
                content: '';
                width: 2px;
                height: 100%;
                background: #c7cacb;
                position: absolute;
                left: 25px;
                top: 0;
            }

            li {
                margin-bottom: 15px;
                display: block;
                position: relative;
                float: left;
                width: 100%;

                .comment-main-level {
                    .comment-avatar {
                        width: 50px;
                        height: 50px;
                        position: relative;
                        z-index: 99;
                        float: left;
                        border: 3px solid #FFF;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        border-radius: 4px;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .comment-box {
                        width: 90%;
                        float: right;
                        position: relative;
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
                        padding: 0;

                        .dk-commentAction-dropdown {
                            .dropdown {
                                .dropdown-toggle {
                                    padding: 2px 10px;
                                    border: 1px solid #ccc;
                                    border-radius: 3px;
                                    margin: 0;
                                    box-shadow: none;
                                    background: #dee1e3;
                                    margin-left: 12px;

                                    i {
                                        display: inline-block;
                                        margin-left: 5px;
                                    }
                                }

                                .dropdown-menu {
                                    transform: inherit !important;
                                    left: inherit !important;
                                    right: 0 !important;
                                    top: 25px !important;
                                    padding: 0;
                                    border-radius: 0;
                                    border: 1px solid #ddd;
                                    width: 100%;
                                    min-width: 86px;
                                    max-width: 86px;

                                    a {
                                        display: block;
                                        width: 100%;
                                        font-size: 12px;
                                        border-bottom: 1px solid #ddd;
                                        padding: 5px 12px;

                                        &:last-child {
                                            border-bottom: none;
                                        }

                                        i {
                                            margin-left: 0;
                                            margin-right: 3px;
                                        }
                                    }
                                }
                            }
                        }

                        &::before {
                            border-width: 11px 13px 11px 0;
                            border-color: transparent rgba(0, 0, 0, 0.05);
                            left: -12px;
                        }

                        &::after {
                            content: '';
                            height: 0;
                            width: 0;
                            position: absolute;
                            display: block;
                            border-width: 10px 12px 10px 0;
                            border-style: solid;
                            border-color: transparent #FCFCFC;
                            top: 8px;
                            left: -11px;
                        }

                        .comment-head {
                            background: #FCFCFC;
                            padding: 7px 12px;
                            border-bottom: 1px solid #E5E5E5;
                            overflow: visible;
                            -webkit-border-radius: 4px 4px 0 0;
                            -moz-border-radius: 4px 4px 0 0;
                            border-radius: 4px 4px 0 0;

                            .d-none {
                                display: none !important;
                            }

                            .dk-commentTagsline {
                                display: flex;
                                align-items: center;
                                margin-left: 30px;

                                li {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #03658c;
                                    margin: 0;
                                    margin-right: 10px;
                                    display: inline-block;
                                    background: #dee1e3;
                                    padding: 1px 10px;
                                    border-radius: 3px;
                                }

                                .dk-commentCategory-tags {
                                    display: flex;
                                    align-items: center;
                                    display: flex;
                                    align-items: center;
                                    padding: 1px 10px;
                                    background: #dee1e3;
                                    border-radius: 3px;
                                    color: #333;

                                    img {
                                        width: 16px;
                                        display: inline-block;
                                        margin-right: 5px;
                                        position: relative;
                                        bottom: 0;
                                    }
                                }
                            }

                            span {
                                float: left;
                                color: #999;
                                font-size: 13px;
                                position: relative;

                                .date {
                                    margin-right: 10px;
                                    display: inline;
                                    font-size: 14px;
                                    font-weight: 400;

                                    i {
                                        display: inline-block;
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }

                        .headIcons {
                            i {
                                margin-left: 10px;
                                display: inline-block;
                                color: #666;
                                cursor: pointer;

                                &:hover {
                                    color: #03658c;
                                    transition: all 0.5s;
                                }
                            }
                        }

                        .comment-content {
                            background: #FFF;
                            padding: 12px;
                            font-size: 14px;
                            color: #595959;
                            -webkit-border-radius: 0 0 4px 4px;
                            -moz-border-radius: 0 0 4px 4px;
                            border-radius: 0 0 4px 4px;
                            font-weight: 400;
                        }

                    }
                }
            }

            .comments-list-reply {
                float: right;
                width: 96%;
                margin-top: 15px;

                li {
                    position: relative;

                    &::before {
                        content: '';
                        width: 60px;
                        height: 2px;
                        background: #c7cacb;
                        position: absolute;
                        top: 25px;
                        left: -60px;
                    }
                }
            }
        }
    }

    .dk-commentReplyBox {
        width: 100%;
        margin-top: 10px;
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
        padding: 0 25px;
        z-index: 99;

        .wrapper-class.rdw-editor-wrapper {
            padding: 0;
            border: none;
            background: #fff;

            .editor-class.rdw-editor-main {
                background: #fff;
                padding: 8px;

                .rdw-editor-toolbar.toolbar-class {
                    border: none;
                    border-bottom: 1px solid #ddd;
                }

                // .DraftEditor-root {
                //     .DraftEditor-editorContainer {
                //         span {
                //             font-weight: normal;
                //         }
                //     }
                // }
            }
        }

        .rdw-editor-main {
            min-height: 64px;
            max-height: 64px;
        }

        textarea {
            border: 1px solid #ccc !important;
            box-shadow: none !important;
            height: 70px;
            resize: vertical;
            background: #fff;
            border-top: none !important;
        }

        .dk-commentSubmitBtn {
            display: block;
            margin-top: 10px;
            text-align: center;

            button {
                background: #26597d;
                display: inline-block;
                padding: 10px 25px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                outline: none;
                border: none;
                border-radius: 3px;

                &:hover {
                    background: #234158;
                    transition: all 0.5s;
                }
            }
        }
    }
}

.tooltipMain {
    position: inherit;
    display: inline-block;

    img {
        border-radius: 0%;
    }

    .tooltipText {
        display: none;
        white-space: nowrap;
        background: #333;
        border-radius: 3px;
        color: #fff;
        padding: 2px 8px;
        position: absolute;
        left: 50%;
        top: 42px;
        transform: translate(-50%);
        z-index: 99;
        font-size: 11px;
        text-transform: capitalize;

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7.5px 12px 7.5px;
            border-color: transparent transparent #333333 transparent;
            line-height: 0px;
            _border-color: #000000 #000000 #333333 #000000;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
            position: absolute;
            top: -11px;
            left: 50%;
            transform: translate(-50%);
        }
    }

    &:hover {
        .tooltipText {
            display: inline-block;
            display: none;
        }
    }
}

.dk-candidateComment-modalMain {
    .modal-header {
        border-bottom: none;
    }

    .modal-body {
        padding: 0;
    }

    .nav {
        display: flex;
        flex-wrap: nowrap;

        .nav-item {
            display: inline-block;
            width: 100%;

            a {
                display: inline-block;
                padding: 8px 12px;
                background: #f2f2f2;
                border-right: 1px solid #ddd;
                color: #333;
                outline: none;
                box-shadow: none;
                width: 100%;
                text-align: center;
                white-space: nowrap;
                font-size: 14px;

                &:hover {
                    background: #234158;
                    color: #fff;
                    transition: all 0.5s;
                }
            }

            a.active {
                background: #dee1e3;

                &:hover {
                    color: #333;
                }
            }

            &:last-child {
                a {
                    border-right: none;
                }
            }
        }
    }

    .tab-content {
        .dk-commentTabs-panel {
            padding: 20px 15px;
        }
    }
}

.batch-actions-menu-wrapper.dk-action-popupMain.dk-comment-edtior-popupMain1 {
    z-index: 99999;
    margin: auto;
    height: 364px;

    .dk-commentReplyBox {
        .wrapper-class.rdw-editor-wrapper {
            background-color: #fff !important;
            padding: 0;
            border: none !important;
            border-bottom: 1px solid #ddd;

        }

        .editor-class.rdw-editor-main {

            min-height: 230px;
            max-height: 230px;
            border-bottom: 1px solid #ddd;
            overflow-y: auto;
            cursor: pointer;
        }

        .dk-commentSubmitBtn {
            background-color: #fff;
            padding: 12px 5px;

            div {
                div {
                    .dk-sendBtn {
                        background: #2685a5;
                        border: 1px solid #2685a5;
                        border-radius: 0.1875rem;
                        color: #fff;
                        display: inline-block;
                        font-size: 13px;
                        line-height: 1.5385;
                        padding: 5px 12px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 100px;
                    }
                }
            }
        }
    }
}

.dkg-comment-editorPopup-234 {
    .modal-dialog {
        min-width: 720px;
        max-width: 720px;

        .modal-content {
            border-radius: 5px;

            .dkg-comment-editorPopup-Header {
                background-color: #eaeaea;
                padding: 7px 5px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                .modal-title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    opacity: 0.85;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .dkg-comment-editorPopup-Body {
                padding: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;

                .dkg-comment-editor-12 {
                    .wrapper-class.rdw-editor-wrapper {
                        // min-height: 230px;
                        // height: 230px;
                        // overflow-y: auto;
                        border: none;
                        padding: 0;

                        .rdw-editor-toolbar.toolbar-class {
                            border-top: none;

                            .rdw-inline-wrapper {
                                .rdw-option-wrapper {

                                    &:nth-child(05),
                                    &:nth-child(06),
                                    &:nth-child(07) {
                                        display: none;
                                    }
                                }
                            }

                            // .rdw-inline-wrapper,
                            // .rdw-block-wrapper,
                            // .rdw-fontsize-wrapper,
                            // .rdw-fontfamily-wrapper,
                            // .rdw-dropdown-wrapper,
                            // .rdw-list-wrapper {
                            //     font-size: 14px;
                            // }

                            .rdw-colorpicker-wrapper,
                            .rdw-link-wrapper,
                            .rdw-embedded-wrapper,
                            .rdw-emoji-wrapper,
                            .rdw-image-wrapper,
                            .rdw-remove-wrapper,
                            .rdw-history-wrapper {
                                display: none;
                            }
                        }

                        .editor-class.rdw-editor-main {
                            min-height: 230px;
                            max-height: 230px;
                            overflow-y: auto;
                            margin: 0 0;
                            border: 1px solid #f3f3f3;
                            padding: 7px 0;
                        }
                    }
                }

                .dk-commentSubmitBtn {
                    background-color: #fff;
                    padding: 12px 5px;
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;

                    div {
                        div {
                            .dk-sendBtn {
                                background: #26597d;
                                border: 1px solid #26597d;
                                border-radius: 0.1875rem;
                                color: #fff;
                                display: inline-block;
                                font-size: 13px;
                                line-height: 1.5385;
                                padding: 5px 12px;
                                text-align: center;
                                text-transform: uppercase;
                                width: 100px;

                                &:hover {
                                    opacity: 0.95;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}