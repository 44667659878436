.dk-addNewInvoiceModal-main.modal {
    .modal-dialog {
        margin: 0 !important;
        position: fixed;
        width: 100%;
        max-width: 600px;
        min-width: 600px;

        .modal-content {
            box-shadow: none;
            border: none;

            .modal-header {
                align-items: center;
                background-color: #234158;
                border-bottom: none;
                border-radius: 0;
                display: flex;
                justify-content: space-between;
                padding: 14px 15px !important;
                width: 100%;

                .modal-title {
                    color: #fff;
                    font-size: 1.2rem;
                    line-height: 1.8rem;
                    margin: 0;
                    opacity: .9;
                    padding-top: 1px;
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;
                }

                .close {
                    align-items: center;
                    color: #fff;
                    display: flex;
                    height: 53px;
                    justify-content: center;
                    margin: 0;
                    opacity: .9;
                    padding: 0;
                    position: absolute;
                    right: 2px;
                    text-shadow: none;
                    top: 0;
                    width: 30px;
                }
            }

            .modal-body {
                background-color: #eaeaea;
                height: calc(100vh - 45px);
                overflow-y: auto;
                padding: 15px;

                .form-group {
                    display: flex;
                    align-items: center;

                    .dk-addInvoice-title {
                        width: 100%;
                        max-width: 165px;
                    }

                    .dk-addInvoice-label.dk-invoiceDatepicker {
                        .MuiGrid-container {
                            width: 100%;

                            .MuiFormControl-marginNormal {
                                margin: 0;
                                width: 100%;
                                background: #fff;
                                border: 1px solid #ddd;

                                .MuiInputBase-root {
                                    width: 100%;
                                    padding-left: 10px;

                                    input {
                                        padding: 8px 0 8px;
                                    }

                                    &::before {
                                        display: none !important;
                                    }

                                    &::after {
                                        display: none !important;
                                    }
                                }
                            }
                        }
                    }

                    .dk-addInvoice-label {
                        width: 100%;

                        label {
                            margin: 0;
                            width: 100%;
                        }

                        input[type="file"] {
                            padding: 3px 5px;
                        }

                        .dropdown {
                            .dropdown-toggle {
                                box-shadow: none;
                                text-transform: inherit;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                                background: #fff;
                                border: 1px solid #ddd;
                                font-size: 13px;
                                font-weight: 400;
                                height: 35px;
                                text-align: center;
                                color: gray;

                                &::after {
                                    position: absolute;
                                    right: 10px;
                                    top: 15px;
                                    font-size: 20px;
                                }
                            }

                            .dropdown-menu {
                                padding: 0;
                                border-radius: 0;
                                border: 1px solid #ddd;
                                margin: 0 !important;
                                width: 100%;
                                text-align: center;
                                transform: inherit !important;
                                top: 36px !important;

                                a {
                                    font-weight: 300;
                                    padding: 7px 10px;
                                    border-bottom: 1px solid #ddd;
                                    background: #f2f2f2;

                                    &:last-child {
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                    }

                    .dk-invoiceAdd-btn {
                        width: 100%;
                        text-align: center;
                        margin-top: 15px;

                        button {
                            background: #2685a5;
                            border: 1px solid #2685a5;
                            border-radius: 0.1875rem;
                            color: #fff;
                            display: inline-block;
                            font-size: 13px;
                            line-height: 1.5385;
                            margin-left: 120px;
                            padding: 5px 12px;
                            text-align: center;
                            text-transform: uppercase;
                            width: 100px;
                            outline: none;

                            &:hover {
                                background: #234158;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
}