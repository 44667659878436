@keyframes initial-loading {

    0%,
    100% {
        transform: translate(-34px, 0);
    }

    50% {
        transform: translate(96px, 0);
    }
}

.initial-load-animation {
    padding-top: 96px;
    width: 200px;
    margin: 0 auto;
    transform: scale(1);
    transition: transform .5s ease;
}

.initial-load-animation .linkedin-image {
    text-align: center;
    width: 100%;
    max-width: 150px;
    margin: auto;
    margin-bottom: 10px;
}

.initial-load-animation .linkedin-image img {
    width: 130px;
}

.initial-load-animation .loading-bar {
    width: 130px;
    height: 2px;
    margin: 0 auto;
    border-radius: 2px;
    background-color: #cfcfcf;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transform: rotateY(0);
    transition: transform .3s ease-in;
}

.initial-load-animation .loading-bar .blue-bar {
    height: 100%;
    width: 68px;
    position: absolute;
    transform: translate(-34px, 0);
    background-color: #0073b1;
    border-radius: 2px;
    animation: initial-loading 1.5s infinite ease;
}

.initial-load-animation .fade-load .linkedin-image {
    transform: translate(0, 10px);
    opacity: 0;
}

.initial-load-animation .fade-load .loading-bar {
    transform: rotateY(90deg);
}