.dk-qulaifyRightFilter {
  .fa-times-circle {
    z-index: 100;
  }

  .statusFilter {
    right: 0px;
  }
}

.StatusdropdownFilter {
  .dropdown-item {
    color: #fff;

    &:hover {
      background: #485569 !important;
    }
  }

  .dropdown-menu.show {
    max-height: 400px;
    overflow-y: auto;
    font-size: 12px;
    background: #485569;
  }

  .btn-primary.dropdown-toggle {
    background: #fff !important;
    box-shadow: none;
    border: 1px solid #ddd;
    color: #333;
    margin: 0;
    padding: 8px 20px;
    min-width: 120px;
    max-width: 120px;

    &::after {
      color: #6c757d;
      display: block;
      font-size: 1.2rem;
      position: absolute !important;
      right: 10px;
      top: 16px;
    }
  }

  .dropdown-menu.show {
    padding: 0;

    .dropdown-item {
      padding: 0.5rem 0.5rem;
      display: flex;
      justify-content: space-between;

      .dropdown-filter-count {
        background-color: #3c4c62;
        border-radius: 2px;
        color: #fff;
        padding: 0px 5px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}