.dkg-company-album-mainCon{
    background-color: #fff;
    height: calc(100vh - 235px);
    overflow-y: auto;
    width: 100%;
    padding: 15px 30px;
    padding-bottom: 45px;
    .dkg-company-albumCon{
        width: 100%;
        .dkg-company-mainRow{
          .dkg-company-album-cols{
            padding: 15px;
            .dkg-company-album-box{
                text-align: center;
                background: #f5f5f5;
                margin: 0;
                border-radius: 5px;
                padding-bottom: 1rem;
                .dkg-company-album-box-header{
                    padding: 8px 15px;
                    border-bottom: 1px solid #ddd;
                    border-radius: 5px 5px 0 0;
                    display: block;
                    text-align: center;
                    background: #f5f5f5;
                    height: 37px;
                    .dkg-album-box-header-title{
                        font-size: 1.10rem;
                        font-weight: bold;
                        color: #6c6b6b;
                        text-transform: uppercase;
                    }
                }
                .dkg-company-album-figure{
                    margin: 0 0 1rem ;
                    .dkg-company-album-imgCon{
                        width: 100%;
                        height: 210px;
                        border-radius: 0;
                        overflow: hidden;                 
                        .dkg-company-album-img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 0;  
                        }
                    }
                }
                .dkg-company-bottomCon{
                    .dkg-upload-mediabtn{
                        box-shadow: none;
                        color: #fff;
                        background-color: #6c757d;
                        border-color: #6c757d;
                        margin: 0;
                        padding: 9px 15px;
                        border-radius: 5px;
                        margin-right: 0.5rem;
                        width: 80px;
                    }
                    .dkg-edit-mediabtn{
                        box-shadow: none;
                        color: #212529;
                        background-color: #d39e00;
                        border-color: #c69500;
                        margin: 0;
                        padding: 9px 15px;
                        border-radius: 5px;
                        width: 80px;

                    }
                }
            }
          }  
        }
    }
}