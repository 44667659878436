.dkg-feedback-mainCon{
    margin-top: 152px;
    height: calc(100vh - 152px);
    padding: 15px 25px;
    width: 100%;
    background-color: #eaeaea;
    .dkg-feedback-container{
        display: flex;
        justify-content: center;
        align-items: center;
        .dkg-feedback-mainRow{
            color: #38607C;
            .dkg-feedback-mainCol{
                .dkg-feedback-title{
                    margin: 30px 0 0;
                    padding: 0 0 15px;
                    color: #243b4c;
                    text-align: center;
                    font-weight: 600;
                    font-size: 26px;
                    text-transform: uppercase;
                }
                p{
                    max-width: 600px;
                    color: #333;

                }
                .dkg-feedback-card{
                    background-color: #243b4c;
                    min-width: 600px;
                    background-clip: border-box;
                    border-radius: 1px;
                    border: 1px solid #38607C;
                    border-radius: 6px;
                    overflow: hidden;
                    margin: 0 auto 40px;
                    .dkg-feedback-cardRow{
                        .dkg-feedback-cardCol{
                            form{
                                padding: 30px;
                                .form-group{
                                    margin-bottom: 1rem;
                                    .form-control{
                                        height: 34px;
                                        padding: 0.1rem 0.5rem;
                                        font-size: 14px;
                                        background-color: #fff;
                                        line-height: 1.3;
                                        border: #eee 1px solid;
                                        text-align: left;
                                    }
                                    textarea.form-control {
                                        height: 80px;
                                        text-align: left;
                                    }
                                }
                                .dkg-priacy-trmsCon{
                                    p{
                                        width: 100%;
                                        text-align: center;
                                        font-size: 14px;
                                        color: #ddd;
                                        input[type="checkbox"] {
                                            background: #fff;
                                            border-radius: 0;
                                            margin-right: 0.5rem;
                                        }
                                        a{
                                            margin: 0 0.5rem;
                                        }
                                    }
                                }
                                .dkg-feedback-btnCon{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    margin-top: 10px;
                                    .dkg-feedback-btn{
                                        background-color: #38607C !important;
                                        border-color: #38607C !important;    
                                        color: #fff !important;
                                        border-radius: 0.25rem;
                                        &:hover{
                                            background-color: #2e96b6 !important;
                                            border-color: #2e96b6 !important;    
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}