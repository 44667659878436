.dk-navBarMain.dk-accountingNavbar.dk-dkglobalAdminTrainingMainMenu.dk-trainingModuleNav {
    ul {
        li {
            max-width: 11%;
        }
    }
}

.dk-navBarMain.dk-accountingNavbar {
    &.dkg-training-shecdule-Navbar {
        ul {
            background-color: #3c97b6 !important;

            li {
                a {
                    width: 100%;
                    background-color: #3c97b6 !important;
                }
            }
        }
    }
}