.dkg-projects-summModal{
    .modal-dialog{
        min-width: 800px;
        max-width: 800px;
        .modal-content{
            border-radius: 5px ;
            background-color: transparent;
            .dkg-projects-summModalHeader{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                background-color: #3c97b6;
                border-radius: 5px 5px 0 0;
                position: relative;
                padding: 7px 10px;
                .modal-title{
                    color: #fff;
                    font-size: 18px;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    opacity: 1;
                    &:hover{
                        color: #fff;
                        opacity: 0.8;
                    }
                }
            }
            .dkg-projects-summModalBody{
                border-radius: 0 0 5px 5px;
                background-color: #fff;
            }
        }
    }
}