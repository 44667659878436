.dkg-myacc-invoices-main-Con {
    margin-top: 123px;
    background-color: #eaeaea;
    padding: 25px 15px;
    height: 100vh;

    .dkg-invoices-mainCon {
        .dkg-invoices-boxCon {

            .dk-moduledkFinanceProcess-rightPanel {
                width: 100%;
                position: relative;
                top: -10px;

                .dk-financeYears-main {
                    display: inline-flex;
                    position: absolute;
                    left: 0;
                    top: 10px;

                    .dk-financeYears-cols {
                        a {
                            margin-right: 18px;
                            font-size: 14px;
                            font-weight: 600;
                            color: #737373;
                            display: inline-block;
                            border-radius: 3px;
                            border-bottom: 3px solid transparent;
                        }

                        a.active {
                            border-bottom: 3px solid #3c97b6;
                            color: #3c97b6;
                        }
                    }
                }

                .dk-financeYears-addNew {
                    position: absolute;
                    right: 0;
                    top: 7px;

                    a {
                        display: inline-block;
                        background: #fff;
                        padding: 5px 13px;
                        border-radius: 3px;
                        color: #333;
                        border: 1px solid #ddd;

                        &:hover {
                            background: #2e75b6;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                }

                @media(max-width:992px) {
                    margin-left: 0;
                    height: calc(100vh - 185px);
                    padding: 0 12px;
                    margin-top: 10px;
                }

                .title {
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 600;
                    padding: 11px 0;

                    @media(max-width:992px) {
                        text-align: left;
                        padding: 10px 15px;
                    }
                }

                .dk-getStartedRight-detail {
                    .nav {
                        position: absolute;
                        right: 18px;
                        top: 8px;

                        .nav-item {
                            margin-right: 18px;

                            &:last-child {
                                margin-right: 0;
                            }

                            a {
                                border-bottom: 3px solid transparent;
                                box-shadow: none;
                                color: #737373;
                                font-family: Roboto, sans-serif !important;
                                font-size: 16px;
                                font-weight: 400;
                                padding: 0 0 2px;
                                text-transform: capitalize;
                            }

                            a.active {
                                border-bottom: 3px solid #3c97b6;
                                color: #3c97b6;
                            }
                        }
                    }
                }

                .dk-moduledkFinanceProcess-rightCols.dk-financeClientsBgTransparent {
                    background: #eaeaea;

                    .dk-financeClients-tableMain {
                        padding: 0;
                    }
                }

                .dk-moduledkFinanceProcess-rightCols {
                    width: 100%;
                    background: #fff;
                    height: calc(100vh - 165px);
                    border: none;

                    .dk-financeClients-tableMain {
                        padding: 25px;

                        table {
                            margin-bottom: 0;
                            border: none;

                            tr {
                                th {
                                    padding: 10px 10px;
                                    vertical-align: middle;
                                    font-size: 13px;
                                    background: #244158;
                                    border-bottom: none;
                                    color: #fff;
                                    text-align: center;
                                    border-color: #8e8e8e;
                                }

                                td {
                                    background: #fff;
                                    color: #333;
                                    vertical-align: middle;
                                    font-size: 13px;
                                    text-align: center;
                                    padding: 4px 10px;

                                    &:nth-child(02) {
                                        padding: 0;
                                    }

                                    &:nth-child(06) {
                                        text-align: left;
                                    }

                                    .dk-invoiceStatus {
                                        .dropdown {
                                            .dropdown-toggle {
                                                padding: 0;
                                                margin: 0;
                                                box-shadow: none;
                                                border: none;
                                                color: #fff;
                                                text-transform: inherit;
                                                width: 100%;

                                                div {
                                                    width: 100%;
                                                    height: 38px;
                                                    line-height: 38px;
                                                    font-size: 14px;
                                                }

                                                &::after {
                                                    display: none;
                                                }
                                            }

                                            .dropdown-menu {
                                                padding: 0;
                                                border-radius: 0;
                                                border: none;
                                                width: 100%;
                                                min-width: 110px;
                                                transform: inherit !important;
                                                top: 40px !important;
                                                margin: 0 !important;

                                                a {
                                                    display: block;
                                                    font-size: 14px;
                                                    padding: 6px 10px;
                                                    color: #fff;
                                                }
                                            }
                                        }
                                    }

                                    .dk-financeAction {
                                        a {
                                            display: inline-block;
                                            width: 30px;
                                            height: 30px;
                                            line-height: 30px;
                                            border-radius: 3px;
                                            color: #fff;
                                            text-align: center;
                                            margin: 0 3px;

                                            &:nth-child(01) {
                                                background: #ffb11c;
                                            }

                                            &:nth-child(02) {
                                                background: #3a9469;
                                            }
                                        }
                                    }

                                    .dk-checkinputIcon {
                                        left: 10px;
                                        position: relative;
                                        top: 2px;

                                        input[type=checkbox] {
                                            background-color: #f1f1f1;

                                            &:checked {
                                                background-color: #34976e;
                                            }
                                        }
                                    }
                                }
                            }

                            tfoot {
                                tr {
                                    td {
                                        padding: 10px 8px;
                                    }
                                }
                            }
                        }
                    }

                    @media(max-width:992px) {
                        height: auto;
                        min-height: 300px;
                        border-top: none;
                    }

                    .dk-getStartedRight-detail {
                        .dk-rightDetail-panel {
                            border-top: 1px solid #ddd;

                            .d-flex {
                                @media(max-width:992px) {
                                    flex-wrap: wrap;
                                }
                            }

                            .dk-detailLefttabsMain {
                                background: #eaeaea;
                                border-left: 1px solid #ddd;
                                display: inline-block;
                                height: calc(100vh - 165px);
                                margin-right: 0;
                                max-width: 160px;
                                width: 100%;

                                @media(max-width:992px) {
                                    height: auto;
                                    max-width: 100%;
                                }

                                .MuiBox-root::-webkit-scrollbar {
                                    width: 5px;
                                    height: 5px;
                                }

                                .MuiBox-root::-webkit-scrollbar-track {
                                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                                }

                                .MuiBox-root::-webkit-scrollbar-thumb {
                                    background-color: darkgrey;
                                    outline: 0px solid slategrey;
                                }

                                .MuiBox-root {
                                    width: 100%;

                                    @media(max-width:992px) {
                                        display: flex;
                                        overflow-x: auto;
                                    }

                                    .MuiTabs-root {
                                        flex-wrap: wrap;
                                        min-height: auto;
                                        overflow: visible;
                                        width: 100%;

                                        .MuiTabs-scroller {
                                            overflow: visible !important;
                                            white-space: normal;

                                            .MuiTabs-indicator {
                                                display: none;
                                            }

                                            .MuiTabs-flexContainer {
                                                flex-wrap: wrap;
                                                justify-content: space-between;
                                                overflow: visible;

                                                .MuiButtonBase-root {
                                                    align-items: flex-start;
                                                    align-items: left;
                                                    border-bottom: 1px solid #ddd;
                                                    color: #757575;
                                                    display: block;
                                                    flex-shrink: inherit;
                                                    font-size: 15px;
                                                    font-weight: 400;
                                                    max-width: 100%;
                                                    min-height: auto;
                                                    min-width: inherit !important;
                                                    opacity: 1;
                                                    outline: none;
                                                    overflow: visible;
                                                    padding: 12px 15px;
                                                    position: relative;
                                                    text-align: center;
                                                    text-transform: capitalize;
                                                    width: 100%;

                                                    @media(max-width:992px) {
                                                        white-space: nowrap;
                                                    }
                                                }

                                                .MuiButtonBase-root.Mui-selected {
                                                    background: #fff;
                                                    color: #606060;
                                                    border-right: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}