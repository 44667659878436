.grid-table-row {
    .dk-AmDropDwnCon {
        .dk-AmDropDwn {
            .dk-AmDropDwnToggle {
                box-shadow: none;
                background-color: transparent;
                padding: 0 !important;
                margin: 0;

                &::after {
                    display: none;
                }

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                }
            }

            .dk-AmDropDwnMenu.dropdown-menu {
                padding: 5px;
                top: -7px !important;
                transform: translate(22px, 0px) !important;
                -webkit-transform: translate(-42px, 0px) !important;
                background-color: #eaeaea;

                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-color: transparent transparent transparent #ccc;
                    border-style: solid;
                    border-width: 8.5px 0 8.5px 10.5px;
                    line-height: 0;
                    _border-color: #000 #ccc #000 #000;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                    position: absolute;
                    right: -12px;
                    left: auto;
                    top: 15px;
                }

                .dropdown-item {
                    margin: 0 4px;
                    padding: 0;
                    width: 32px;
                    height: 32px;

                    &:hover {
                        background-color: transparent;
                    }

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}